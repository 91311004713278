import { ReservationSummaryCard } from 'components/resSummary/ReservationSummaryCard';
import CardContent from '@mui/material/CardContent';
import { Grid } from '@mui/material';
import { useTranslations } from 'components/shared/i18n';
import { RouterPaths } from 'app/router/RouterPaths';
import { parseUrn } from 'utils/urnUtils';
import { useAppSelector } from 'redux/hooks';
import { selectBillingAccountPayer, selectPayers } from 'redux/selectors/bookingEditor';
import { FC, useMemo } from 'react';
import { useGetRenterProfile } from 'services/renter/useGetRenterProfile';
import { mapLoadingState } from 'components/shared/ui/spinner/loadableView/LoadableViewUtils';
import { PaymentDetails } from 'components/flexFlow/payment/PaymentDetails';
import { Body1, Caption, ehiTheme, Subtitle1 } from '@ehi/ui';
import { paymentTypes } from 'utils/paymentUtils';

export const PaymentSummary: FC = () => {
  const { t } = useTranslations();
  const payers = useAppSelector(selectPayers);
  const billToAccount = useAppSelector(selectBillingAccountPayer);
  const { driverData, isRenterProfileLoading, isRenterProfileError } = useGetRenterProfile();

  const paymentMethod = useMemo(() => {
    return parseUrn(payers?.person?.paymentMethod);
  }, [payers?.person]);

  const getPaymentDetails = (paymentMethod: string): JSX.Element => {
    switch (paymentMethod) {
      case paymentTypes.Other:
        return <Subtitle1 bold>{t('common.other')}</Subtitle1>;
      case paymentTypes.Debit_Card:
        return <Subtitle1 bold>{t('payment.debit')}</Subtitle1>;
      case paymentTypes.Credit_Card:
        return (
          <>
            <Body1 bold>{t('payment.credit')}</Body1>
            {driverData && (
              <PaymentDetails
                selectedValue={{ id: paymentMethod, label: '' }}
                driverData={driverData}
                isInlineSummary
              />
            )}
          </>
        );
      case paymentTypes.Business_Payer:
        return <></>;
      default:
        return (
          <Subtitle1 color={ehiTheme.palette.text.secondary} data-testid='noPaymentMessage'>
            {t('payment.noPaymentSelected')}
          </Subtitle1>
        );
    }
  };

  return (
    <ReservationSummaryCard
      title={t('reservationSummary.payment')}
      loading={mapLoadingState(isRenterProfileLoading, isRenterProfileError)}
      path={RouterPaths.Payment}
      data-testid='paymentSummaryCard'>
      <CardContent>
        <Grid container direction={'column'}>
          <Grid item xs>
            {(billToAccount || paymentMethod === paymentTypes.Business_Payer) && (
              <div style={{ marginBottom: ehiTheme.spacing(1) }}>
                <Caption>{t('rateAndBilling.billTo.pageTitle')}</Caption>
                <Subtitle1 bold>{t('payment.directBill')}</Subtitle1>
              </div>
            )}
            <Caption>{paymentMethod && paymentMethod !== paymentTypes.Business_Payer && t('common.renter')}</Caption>
            {getPaymentDetails(paymentMethod)}
          </Grid>
        </Grid>
      </CardContent>
    </ReservationSummaryCard>
  );
};
