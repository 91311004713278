import { FC, useState } from 'react';
import { Body1, ehiTheme } from '@ehi/ui';
import { Box, Tooltip } from '@mui/material';
import InfoIcon from '@mui/icons-material/Info';
import { useTranslations } from 'components/shared/i18n';

export const ConsentQuestion: FC = () => {
  const { t } = useTranslations();
  const [open, setOpen] = useState<boolean>(false);

  const handleTooltipClose = (): void => {
    setOpen(false);
  };

  const handleTooltipOpen = (): void => {
    setOpen(true);
  };

  return (
    <Box display={'flex'}>
      <Body1 data-testid={'consent-question'}>{t('consent.question')}</Body1>
      <Tooltip
        onClose={handleTooltipClose}
        open={open}
        onOpen={(): void => setOpen(true)}
        disableInteractive
        title={t('consent.infoIconMessage')}
        placement='top'
        sx={{ color: ehiTheme.palette.primary.main, paddingLeft: ehiTheme.spacing(0.5) }}
        arrow
        slotProps={{
          popper: {
            disablePortal: true,
          },
        }}>
        <InfoIcon onClick={handleTooltipOpen} style={{ cursor: 'pointer' }} />
      </Tooltip>
    </Box>
  );
};
