import { Box } from '@mui/material';
import { FC } from 'react';
import { SpacingWrapperProps } from './ReservationRouteTypes';
import { useNotes } from 'components/notes/useNotes';
import { ehiTheme } from '@ehi/ui';

export const ReservationPageSpacingWrapper: FC<SpacingWrapperProps> = ({ children }) => {
  const { isNotesPage } = useNotes();

  if (isNotesPage) {
    return (
      <Box
        sx={{
          height: '0',
          flexGrow: '1',
          background: '#fff',
        }}>
        {children}
      </Box>
    );
  }

  return <Box sx={{ marginBottom: ehiTheme.spacing(0), padding: ehiTheme.spacing(0), flexGrow: 1 }}>{children}</Box>;
};
