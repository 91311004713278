import { getDefaultAxiosInstance } from 'components/shared/axios/axiosConfiguration';
import { RatePlan, SearchRatePlansRequest } from './ratePlanTypes';
const BASE_PATH = '/rental/rate/ratePlan';
const SEARCH_RATE_PLANS = '/search-rate-plans';

export const searchRatePlans = async (data: SearchRatePlansRequest): Promise<RatePlan[]> => {
  const response = await getDefaultAxiosInstance().post(`${BASE_PATH}${SEARCH_RATE_PLANS}`, data, {
    genericUrl: `${BASE_PATH}${SEARCH_RATE_PLANS}`,
  });
  return response.data;
};

export const getRatePlanById = async (ratePlanId: string): Promise<RatePlan> => {
  const response = await getDefaultAxiosInstance().get(`${BASE_PATH}/${ratePlanId}`, {
    genericUrl: `${BASE_PATH}/{ratePlanId}`,
  });
  return response.data;
};
