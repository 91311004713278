import { FC, useCallback, useMemo, useState } from 'react';
import { useTranslations } from 'components/shared/i18n';
import { Caption2 } from 'components/shared/ui/styles/Typography.styles';
import {
  StyledResultsLink,
  StyledRunButton,
  StyledRunningSubtitle,
  VerificationFailIcon,
  VerificationItems,
  VerificationNeedUpdateIcon,
  VerificationRunningItems,
  VerificationSuccessIcon,
} from 'components/flexFlow/driver/driverVerification/DriverVerification.styles';
import { useAppSelector } from 'redux/hooks';
import { selectBookingEditorId, selectPickup } from 'redux/selectors/bookingEditor';
import { Body1, ehiTheme } from '@ehi/ui';
import { Box, CircularProgress } from '@mui/material';
import { useAlert } from 'components/shared/alert/AlertContext';
import { BulletedItem, BulletedList, disabledText } from 'components/shared/ui/styles/Global.styles';
import { DriverData } from 'components/shared/uiModels/driver/driverDataTypes';
import {
  getDriverVerificationStatus,
  VerificationStatus,
  verifyRequiredFields,
} from 'components/flexFlow/driver/driverVerification/driverVerificationUtils';
import { NoteBanner } from 'components/shared/banner/NoteBanner';
import { useGetIdentityVerification } from 'services/identityVerification/useGetIdentityVerification';
import { selectVerificationResults } from 'redux/selectors/driver';
import { useRefreshEditor } from 'hooks/bookingEditor/useRefreshEditor';

export type DriverVerificationType = {
  driverDetails: DriverData;
  readOnly: boolean;
};

export const DriverVerification: FC<DriverVerificationType> = ({ driverDetails, readOnly }) => {
  const { t } = useTranslations();
  const { showAlert } = useAlert();
  const [loading, setLoading] = useState(false);
  const pickup = useAppSelector(selectPickup);
  const bookingEditorId = useAppSelector(selectBookingEditorId);
  const verificationHistory = useAppSelector(selectVerificationResults);
  const { runElectronicIdentityVerification } = useGetIdentityVerification();
  const { refreshEditor } = useRefreshEditor();
  const hasRequiredFields = useMemo(() => {
    return verifyRequiredFields(driverDetails);
  }, [driverDetails]);

  const verificationStatus = useMemo(() => {
    return getDriverVerificationStatus(driverDetails, verificationHistory);
  }, [driverDetails, verificationHistory]);

  const resultsLinkDisabled = useMemo(() => {
    return readOnly || !hasRequiredFields || verificationStatus === VerificationStatus.NotRun;
  }, [readOnly, hasRequiredFields, verificationStatus]);

  const getVerificationIcon = useMemo(() => {
    if (verificationStatus === VerificationStatus.NotRun) return;
    if (verificationStatus === VerificationStatus.Success) {
      return <VerificationSuccessIcon fontSize='small' />;
    } else if (verificationStatus === VerificationStatus.Failed) {
      return <VerificationFailIcon fontSize='small' />;
    } else if (verificationStatus === VerificationStatus.NeedsUpdate) {
      return <VerificationNeedUpdateIcon fontSize='small' transform='rotate(225)' />;
    }
  }, [verificationStatus]);

  const handleNoteBannerLinkClick = useCallback(async (): Promise<void> => {
    await showAlert({
      title: t('driver.driverVerification.seeDetailsTitle'),
      subtitle: t('driver.driverVerification.requiredForChecks'),
      description: (
        <BulletedList data-testid='requiredFieldsList' sx={{ color: '#000000DE', paddingTop: 0 }}>
          <BulletedItem sx={{ margin: 0 }}>{t('driver.driverVerification.addressRequired')}</BulletedItem>
          <BulletedItem sx={{ margin: 0 }}>{t('driver.driverVerification.DOBRequired')}</BulletedItem>
        </BulletedList>
      ),
    });
  }, [showAlert, t]);

  const handleClick = useCallback(async () => {
    if (pickup?.branch) {
      setLoading(true);
      const response = await runElectronicIdentityVerification(driverDetails, pickup?.branch).finally(() => {
        setLoading(false);
      });

      if (response?.errors) {
        await showAlert({
          responseMessages: response?.errors,
        });
      }
      await refreshEditor(bookingEditorId);
    }
  }, [driverDetails, pickup?.branch, runElectronicIdentityVerification, showAlert, bookingEditorId, refreshEditor]);

  return (
    <Box data-testid='driverVerificationSection' display={'flex'} flexDirection={'column'} gap={ehiTheme.spacing(0.5)}>
      <Caption2 data-testid='driverVerificationHeader'>{t('driver.driverVerification.checks')}</Caption2>
      {loading ? (
        <VerificationRunningItems data-testid='verificationRunningItems'>
          <Body1 sx={{ color: disabledText }}>{`${t('driver.driverVerification.checkInProgress')}...`}</Body1>
          <CircularProgress size={20} />
          <StyledRunningSubtitle sx={{ textTransform: 'uppercase', color: ehiTheme.palette.primary.main }}>
            {t('driver.driverVerification.runningCheck')}
          </StyledRunningSubtitle>
        </VerificationRunningItems>
      ) : (
        <VerificationItems data-testid='driverVerificationItems'>
          {getVerificationIcon}
          <StyledResultsLink data-testid='driverVerificationResultsLink' disabled={resultsLinkDisabled}>
            {t('driver.driverVerification.renterVerification')}
          </StyledResultsLink>
          {!readOnly && (
            <StyledRunButton
              data-testid='driverVerificationRunButton'
              disabled={!hasRequiredFields}
              onClick={handleClick}
              variant='text'>
              {t('driver.driverVerification.run')}
            </StyledRunButton>
          )}
        </VerificationItems>
      )}
      {!hasRequiredFields && !readOnly && (
        <NoteBanner
          description={t('driver.driverVerification.additionInformationRequired')}
          handleLinkClick={handleNoteBannerLinkClick}
        />
      )}
    </Box>
  );
};
