import { TFunction } from 'i18next';
import {
  AdditionalDriverAncillaryFee,
  Equipment,
  MaxPeriodicRate,
  PerRentalRate,
  Protection,
  SimplePeriodicRate,
  VolumetricRate,
} from 'services/booking/bookingTypes';
import { parseUrn } from './urnUtils';
import { groupBy, uniqBy } from 'lodash';
import {
  BaseAncillaryType,
  BaseEquipment,
  BaseProtection,
  EquipmentFormFields,
  EquipmentFormModel,
  EquipmentItemFields,
  EquipmentItemModel,
  ProtectionFormModel,
  ProtectionItemModel,
  ProtectionsFormFields,
  ProtectionsItemFields,
} from 'components/flexFlow/peo/peoTypes';
import { Option } from 'components/shared/ui/OptionTypes';
import { formatCurrency } from 'utils/currencyUtils';
import { EquipmentType } from 'services/rentalReference/rentalReferenceTypes';

export const PER_RENTAL = 'PER_RENTAL';
export const SIMPLE_PERIODIC = 'SIMPLE_PERIODIC';
export const MAX_PERIODIC = 'MAX_PERIODIC';
export const INCLUDED = 'INCLUDED';
export const MANDATORY = 'MANDATORY';
export const OPTIONAL = 'OPTIONAL';
export const VOLUMETRIC = 'VOLUMETRIC';
export const GALLON = 'GALLON';
export const NOT_OFFERED = 'NOT_NORMALLY_OFFERED';
export const DO_NOT_OFFER = 'DO_NO_OFFER';

export const BLOCK_EQUIPMENT_CODES = ['SPR', 'SBE', 'PDE', 'LFA', 'SRG', 'RTL', 'HCR', 'HCL'];
export enum AddOns {
  Equipment = 'equipment',
  Protection = 'protection',
  Ancillary = 'ancillary',
}

export const DISABLE_EQUIPMENT_QUANTITY_CODE = ['COF', 'SXM'];
const EQUIPMENT_QUANTITY_CAP = 11;

export const getCounterOptions = (): Option[] => {
  const options: Option[] = [];
  for (let i = 0; i < EQUIPMENT_QUANTITY_CAP; i++) {
    options.push({
      value: i,
      label: i.toString(),
    });
  }
  return options;
};

export const isInstanceOfPerRentalRate = (o: any): o is PerRentalRate => {
  return o.type === PER_RENTAL;
};

export const isInstanceOfSimplePeriodicRate = (o: any): o is SimplePeriodicRate => {
  return o.type === SIMPLE_PERIODIC;
};

export const isInstanceOfMaxPeriodicRate = (o: any): o is MaxPeriodicRate => {
  return o.type === MAX_PERIODIC;
};

export const isInstanceOfVolumetricRate = (o: any): o is VolumetricRate => {
  return o.type === VOLUMETRIC;
};

export const getItemRates = (
  rate: SimplePeriodicRate | PerRentalRate | MaxPeriodicRate | VolumetricRate | undefined,
  t: TFunction<'translation'>,
  locale: string
): string => {
  if (isInstanceOfPerRentalRate(rate) && typeof rate?.costPerRental?.amount === 'number') {
    const formattedAmount = formatCurrency(
      rate?.costPerRental?.amount,
      rate?.costPerRental?.currencyCode ?? '',
      locale
    );

    return formattedAmount + t('rates.perRental');
  } else if (isInstanceOfSimplePeriodicRate(rate) && typeof rate?.costPerDay?.amount === 'number') {
    const formattedAmount = formatCurrency(rate?.costPerDay?.amount, rate?.costPerDay?.currencyCode ?? '', locale);

    return formattedAmount + t('rates.perDay');
  } else if (isInstanceOfMaxPeriodicRate(rate) && typeof rate?.maximumCostPerRental?.amount === 'number') {
    const maxPeriodicRate: MaxPeriodicRate = rate;
    const formattedAmount =
      maxPeriodicRate?.maximumCostPerRental?.amount &&
      formatCurrency(
        maxPeriodicRate.maximumCostPerRental.amount,
        maxPeriodicRate?.maximumCostPerRental?.currencyCode ?? '',
        locale
      );

    return formattedAmount + t('rates.perMax');
  } else if (isInstanceOfVolumetricRate(rate) && typeof rate?.costPerVolume?.amount === 'number' && rate?.unit) {
    const volumetricRate: VolumetricRate = rate;
    const formattedAmount = formatCurrency(
      volumetricRate?.costPerVolume?.amount,
      volumetricRate?.costPerVolume?.currencyCode ?? '',
      locale
    );

    return formattedAmount + (volumetricRate.unit === GALLON ? t('rates.perGallon') : t('rates.perLiter'));
  }
  return '';
};

export const getPeoTitle = (code: string, details: any[]): string => {
  const foundItem = details?.find((val) => val.code === code);
  return foundItem ? foundItem.name : code;
};

export const formattedEquipmentTypes = (
  equipmentDetails: EquipmentType[],
  items: Equipment[] = []
): BaseEquipment[] => {
  const groupByType: any = groupBy(items, 'type');
  const uniqEquipments = uniqBy(items, 'type');

  const equipmentList = uniqEquipments.map((item: Equipment) => {
    const type = parseUrn(item?.type);
    const quantity = groupByType[item.type as string]?.length;
    const title = getPeoTitle(type ?? '', equipmentDetails);
    return {
      quantity: quantity,
      code: type,
      type: item?.type ?? '',
      selectability: item.selectability ?? '',
      rate: item.rate,
      addOnType: AddOns.Equipment,
      title: title,
    };
  });

  return equipmentList.sort((a, b) => a.title?.localeCompare(b.title ?? ''));
};

export const formatProtection = (item: Protection, category?: string): BaseProtection => {
  return {
    code: parseUrn(item.type),
    category: item.category || category,
    selectability: item?.selectability,
    rate: item.rate,
    type: item?.type,
    addOnType: AddOns.Protection,
  };
};

export const formatAncillaryType = (item: AdditionalDriverAncillaryFee): BaseAncillaryType => {
  return {
    code: parseUrn(item.type),
    rate: item.rate,
    type: item?.type ?? '',
    addOnType: AddOns.Ancillary,
  };
};
export const Selectability = {
  OPTIONAL,
  NOT_OFFERED,
  INCLUDED,
  MANDATORY,
  DO_NOT_OFFER,
};

export const getSelectabilityDescription = (selectability: string, t: TFunction<'translation'>): string => {
  switch (selectability) {
    case Selectability.INCLUDED:
      return t('peo.included');
    case Selectability.MANDATORY:
      return t('peo.accountRequests');
    case Selectability.NOT_OFFERED:
      return t('peo.notNormallyOffered');
    case Selectability.DO_NOT_OFFER:
      return t('peo.doNotOffer');
    default:
      return t('peo.optional');
  }
};

export const createSelectableProtections = (
  selected: BaseProtection[],
  available: BaseProtection[]
): ProtectionItemModel[] => {
  const selectedProtections: ProtectionItemModel[] = selected.map((protection) => {
    return {
      [ProtectionsItemFields.Item]: protection,
      [ProtectionsItemFields.Checked]: true,
    };
  });
  const availableProtections: ProtectionItemModel[] = available.map((protection) => {
    return {
      [ProtectionsItemFields.Item]: protection,
      [ProtectionsItemFields.Checked]: false,
    };
  });

  return selectedProtections.concat(availableProtections);
};

export const protectionsInitialValues = (
  selectedProtections: BaseProtection[],
  availableProtections: BaseProtection[]
): ProtectionFormModel => {
  return {
    [ProtectionsFormFields.Items]: createSelectableProtections(selectedProtections, availableProtections).sort((a, b) =>
      `${a.item.category}${a.item.type}`.localeCompare(`${b.item.category}${b.item.type}`)
    ),
  };
};

export const createSelectableEquipment = (
  selected: BaseEquipment[],
  available: BaseEquipment[]
): EquipmentItemModel[] => {
  const selectedEquipment: EquipmentItemModel[] = selected.map((equipment) => {
    return {
      [EquipmentItemFields.Item]: equipment,
      [EquipmentItemFields.Checked]: true,
      [EquipmentItemFields.Quantity]: equipment.quantity,
    };
  });
  const availableEquipments: EquipmentItemModel[] = available.map((equipment) => {
    return {
      [EquipmentItemFields.Item]: equipment,
      [EquipmentItemFields.Checked]: false,
      [EquipmentItemFields.Quantity]: 0,
    };
  });

  return selectedEquipment.concat(availableEquipments);
};

export const equipmentInitialValues = (
  selectedEquipments: BaseEquipment[],
  availableEquipments: BaseEquipment[]
): EquipmentFormModel => {
  return {
    [EquipmentFormFields.Items]: createSelectableEquipment(selectedEquipments, availableEquipments),
  };
};

export const getQuantity = (peo: BaseProtection | BaseEquipment | BaseAncillaryType): string | undefined => {
  if (peo.hasOwnProperty('quantity')) {
    const quantity = (peo as BaseEquipment).quantity;
    return quantity > 1 ? ` (${quantity})` : '';
  }
};
