import { FC, ReactNode, SyntheticEvent, useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { ARROW_DOWN_KEY, ARROW_UP_KEY, EMPTY_VALUE, ENTER_KEY, TAB_KEY } from 'utils/constants';
import { useTranslations } from 'components/shared/i18n';
import { useFormContext } from 'react-hook-form';
import { Checkbox, IconButton, ListItemIcon, ListItemText, MenuItem, Radio, Switch, TextField } from '@mui/material';
import { Body1, ehiTheme, Subtitle1 } from '@ehi/ui';
import { Check, Search } from '@mui/icons-material';
import {
  ButtonContainer,
  EhiButtonReset,
  FooterSection,
  HeaderContainer,
  HeaderMenuItem,
  HeaderSection,
  HeaderTitle,
  StyledFormTextField,
  SubHeaderSection,
  SubHeaderTitle,
} from 'components/shared/forms/Forms.styles';
import { OptionItem, SelectDropDownProps, SelectionVariant } from 'components/shared/forms/FormFieldTypes';
import CloseIcon from '@mui/icons-material/Close';
import { EhiDivider } from 'components/shared/ui/styles/Divider.styles';
import { parseUrn } from 'utils/urnUtils';

const SEARCH_FIELD_ID = 'search-field';
const FOOTER_BUTTON_ID = 'footer-button';

type MenuState = {
  isOpen: boolean;
  anchorEl: HTMLElement | null;
};

export const SelectMenu: FC<SelectDropDownProps> = ({
  formFieldName,
  label,
  primaryList,
  secondaryList,
  selectionVariant = SelectionVariant.NONE,
  defaultSelection,
  required = false,
  allToggleLabel,
  inputAllText,
  searchFieldPlaceholder,
  onClose,
  footerText,
  textColor,
  autoFocus = false,
}: SelectDropDownProps) => {
  const [searchTerm, setSearchTerm] = useState<string>(EMPTY_VALUE);
  const [headerHeight, setHeaderHeight] = useState(0);
  const [scrollMarginTop, setScrollMarginTop] = useState(0);
  const [menuState, setMenuState] = useState<MenuState>({ isOpen: false, anchorEl: null });
  const subHeaderRef = useRef<HTMLDivElement | null>(null);
  const listRef = useRef<HTMLDivElement | null>(null);
  const inputRef = useRef<HTMLInputElement | null>(null);
  const prevFocusRef = useRef<HTMLElement | null>(null);

  const { t } = useTranslations();
  const { watch, setValue, resetField } = useFormContext();
  const selectedItems: string[] | string = watch(formFieldName);
  const isMultiSelectDropdown = Array.isArray(selectedItems);

  useEffect(() => {
    if ((subHeaderRef.current && subHeaderRef.current.offsetHeight > 0) || headerHeight > 0) {
      setScrollMarginTop(subHeaderRef.current ? subHeaderRef.current.offsetHeight + headerHeight : headerHeight);
    }
  }, [headerHeight]);

  const fullOptionList: OptionItem[] = useMemo(() => {
    return secondaryList ? [...primaryList.list, ...secondaryList.list] : [...primaryList.list];
  }, [primaryList.list, secondaryList]);
  const allOptionsSelected = useMemo(() => {
    return fullOptionList && Array.isArray(selectedItems) && fullOptionList.length === selectedItems.length;
  }, [fullOptionList, selectedItems]);

  const toggleAllOptions = useCallback(() => {
    if (defaultSelection) {
      allOptionsSelected
        ? resetField(formFieldName)
        : setValue(
            formFieldName,
            primaryList.list.map((item) => item.id)
          );
    } else {
      allOptionsSelected ? setValue(formFieldName, []) : resetField(formFieldName);
    }
  }, [allOptionsSelected, setValue, defaultSelection, resetField, formFieldName, primaryList.list]);

  const handleReset = useCallback(() => {
    setSearchTerm(EMPTY_VALUE);
    resetField(formFieldName);
  }, [formFieldName, resetField]);

  const handleOpen = useCallback((event: SyntheticEvent<Element, Event>) => {
    setMenuState({ isOpen: true, anchorEl: event.currentTarget as HTMLElement });
  }, []);

  const handleClose = useCallback(() => {
    setSearchTerm(EMPTY_VALUE);
    setMenuState({ isOpen: false, anchorEl: menuState.anchorEl });
    onClose && onClose();
  }, [menuState.anchorEl, onClose]);

  const renderSelectedOptions = useCallback(() => {
    if (!isMultiSelectDropdown) {
      const selectedOption = fullOptionList?.find((option) => selectedItems && selectedItems.includes(option.id));
      return selectedOption ? selectedOption.label : EMPTY_VALUE;
    } else {
      if (selectedItems.length === 0 || allOptionsSelected) {
        return inputAllText ? inputAllText : t('common.all');
      } else {
        const label = fullOptionList.find((option) => option.id === selectedItems[0])?.label;
        return selectedItems.length === 1 ? label : `${label?.substring(0, 15)}..., +${selectedItems?.length - 1}`;
      }
    }
  }, [isMultiSelectDropdown, fullOptionList, selectedItems, allOptionsSelected, inputAllText, t]);

  const SearchIcon = (): ReactNode => {
    return <Search color={'secondary'} style={{ padding: ehiTheme.spacing(1) }} />;
  };

  const getSelectedValues = useCallback(() => {
    if (!isMultiSelectDropdown && (selectedItems === EMPTY_VALUE || !selectedItems?.includes(searchTerm))) {
      return EMPTY_VALUE;
    }

    return selectedItems;
  }, [isMultiSelectDropdown, searchTerm, selectedItems]);

  const setHeaderRefHeight = useCallback(
    (ref: HTMLDivElement | null) => {
      // We need the header height to determine the offset for the scrollable content list
      if (ref?.offsetHeight && headerHeight !== ref?.offsetHeight) {
        setHeaderHeight(ref?.offsetHeight);
      }
    },
    [headerHeight]
  );

  const getOptionList = useCallback(
    (optionList: OptionItem[]) => {
      return optionList
        .filter((option) => searchTerm.length === 0 || option.label?.toLowerCase().includes(searchTerm.toLowerCase()))
        .map((option) => {
          const isOptionSelected = isMultiSelectDropdown
            ? selectedItems.indexOf(option?.id) > -1
            : selectedItems === option.id;
          let shouldItemAutoFocus = false;
          if (
            ((isMultiSelectDropdown || !isOptionSelected) && fullOptionList[0].id === option.id) ||
            (isOptionSelected && !isMultiSelectDropdown)
          ) {
            shouldItemAutoFocus = true;
          }
          return (
            <MenuItem
              key={option.id}
              id={option.id}
              value={option.id}
              autoFocus={shouldItemAutoFocus}
              onFocusVisible={(): void => {
                // This prevents the focused item from being hidden from the sticky header when scrolling with arrow key
                window.document.getElementById(option.id)?.scrollIntoView();
              }}
              data-testid={`option-${option.id.includes('urn') ? parseUrn(option.id) : option.id}`}
              style={{ padding: ehiTheme.spacing(1.5, 2), color: textColor }}>
              {selectionVariant === SelectionVariant.CHECKBOX && (
                <ListItemIcon style={{ justifyContent: 'flex-start' }}>
                  <Checkbox tabIndex={-1} checked={isOptionSelected} style={{ paddingLeft: 0 }} />
                </ListItemIcon>
              )}
              {selectionVariant === SelectionVariant.RADIO && (
                <ListItemIcon style={{ justifyContent: 'flex-start' }}>
                  <Radio tabIndex={-1} checked={isOptionSelected} style={{ paddingLeft: 0 }} />
                </ListItemIcon>
              )}
              <ListItemText
                disableTypography
                primary={
                  <Subtitle1
                    style={{
                      whiteSpace: 'normal',
                      overflow: 'hidden',
                      textOverflow: 'ellipsis',
                      paddingRight: ehiTheme.spacing(1),
                    }}>
                    {!isMultiSelectDropdown && defaultSelection === option.id
                      ? `${option.label} (${t('common.default')})`
                      : option.label}
                  </Subtitle1>
                }
              />
              {selectionVariant === SelectionVariant.CHECKMARK && isOptionSelected && (
                <ListItemIcon style={{ justifyContent: 'flex-end' }}>
                  <Check tabIndex={-1} color={'action'} data-testid={'checkmark'} />
                </ListItemIcon>
              )}
            </MenuItem>
          );
        });
    },
    [defaultSelection, fullOptionList, isMultiSelectDropdown, searchTerm, selectedItems, selectionVariant, t, textColor]
  );

  const isFocusedItemValid = (headers: HTMLElement[], optionItems: HTMLElement[]): boolean => {
    const currentFocusedElement = document.activeElement as HTMLElement;
    return !!(
      headers.find((header) => header === currentFocusedElement) ||
      optionItems.find((item) => item === currentFocusedElement)
    );
  };

  const handleKeyDown = (event: any): void => {
    if (listRef.current) {
      const focusableHeaderItems: HTMLElement[] = Array.from(listRef.current.querySelectorAll('[role="group"]'));
      const focusableMenuItems: HTMLElement[] = Array.from(listRef.current.querySelectorAll('[role="option"]')).filter(
        (item) => item.id !== 'sub-header' && item.id !== 'header-section'
      ) as HTMLElement[];
      const footerButton = document.getElementById(FOOTER_BUTTON_ID);

      const isLastHeaderItemFocused = focusableHeaderItems[focusableHeaderItems.length - 1] === prevFocusRef.current;
      const isFirstOptionItemFocused = focusableMenuItems[0] === prevFocusRef.current;
      const isFooterButtonFocused = document.activeElement === footerButton;

      if (event.key === ARROW_DOWN_KEY && isLastHeaderItemFocused) {
        (focusableMenuItems[0] as HTMLElement)?.focus();
      } else if (event.key === ARROW_UP_KEY) {
        if (isFirstOptionItemFocused || !prevFocusRef.current) {
          (focusableHeaderItems[focusableHeaderItems.length - 1] as HTMLElement)?.focus();
        } else if (isFooterButtonFocused) {
          (focusableMenuItems[0] as HTMLElement)?.focus();
        }
      } else if (event.key === TAB_KEY) {
        if (isFooterButtonFocused) {
          handleClose();
        } else if (footerButton && prevFocusRef.current !== inputRef.current) {
          footerButton.focus();
        }
      }

      if (isFocusedItemValid(focusableHeaderItems, focusableMenuItems)) {
        prevFocusRef.current = document.activeElement as HTMLElement;
      }
    }
  };

  return (
    <StyledFormTextField
      name={formFieldName}
      data-testid={`${formFieldName}SelectMenu`}
      label={required ? `${label}*` : label}
      autoFocus={autoFocus}
      InputLabelProps={{ shrink: !!allToggleLabel || (!isMultiSelectDropdown && selectedItems.length > 0) }}
      select
      sx={{
        '& fieldset': {
          borderRadius: 0,
        },
        '&& .Mui-focused': {
          color: '#000000',
        },
      }}
      SelectProps={{
        open: menuState.isOpen,
        multiple: isMultiSelectDropdown,
        displayEmpty: true,
        notched: !!allToggleLabel || (!isMultiSelectDropdown && selectedItems.length > 0),
        value: getSelectedValues(),
        renderValue: () => renderSelectedOptions(),
        MenuProps: {
          variant: 'menu',
          marginThreshold: null,
          onKeyDown: handleKeyDown,
          onClose: (): void => {
            // Leaving empty so the reset button gets focused first after the first tab click
          },
          ref: listRef,
          anchorEl: menuState.anchorEl,
          anchorOrigin: {
            vertical: 'bottom',
            horizontal: 'left',
          },
          transformOrigin: {
            vertical: 'top',
            horizontal: 'left',
          },
          sx: {
            '&& .Mui-selected': {
              backgroundColor: selectionVariant !== SelectionVariant.NONE ? '#ffffff' : undefined,
            },
            '&& .Mui-focusVisible': {
              backgroundColor: '#4D789D14',
            },
            '&& .MuiMenuItem-root': {
              scrollMarginTop: scrollMarginTop,
            },
            transition: 'none !important',
          },
          slotProps: {
            paper: {
              style: {
                width: 300,
                minWidth: 300,
                maxHeight: window.innerHeight - (menuState.anchorEl?.getBoundingClientRect().bottom ?? 0),
              },
            },
            root: { sx: { '.MuiList-root': { padding: 0 } } },
          },
        },
        onClose: handleClose,
        onOpen: handleOpen,
      }}>
      <HeaderSection id={'header-section'} ref={setHeaderRefHeight} data-testid='headerContainer'>
        <HeaderContainer>
          <HeaderTitle>{label}</HeaderTitle>
          <IconButton size='small' onClick={handleClose} tabIndex={-1}>
            <CloseIcon color={'secondary'} data-testid='closeButton' />
          </IconButton>
        </HeaderContainer>
        {searchFieldPlaceholder && (
          <HeaderMenuItem
            role={'group'}
            id={SEARCH_FIELD_ID}
            onKeyDown={(e: any): void => {
              if (e.key === ENTER_KEY && inputRef.current) {
                inputRef.current.focus();
              }
            }}>
            <TextField
              value={searchTerm}
              placeholder={searchFieldPlaceholder}
              data-testid='searchField'
              inputRef={inputRef}
              fullWidth
              type={'text'}
              InputProps={{
                startAdornment: SearchIcon(),
              }}
              onChange={(e): void => setSearchTerm(e.target.value)}
              onKeyDown={(e): void => {
                if (e.key === TAB_KEY && inputRef.current) {
                  inputRef.current.blur();
                  document.getElementById(SEARCH_FIELD_ID)?.focus();
                  prevFocusRef.current = inputRef.current;
                } else {
                  e.stopPropagation();
                }
              }}
            />
          </HeaderMenuItem>
        )}
        {allToggleLabel && (
          <HeaderMenuItem
            role={'group'}
            onKeyDown={(e: any): void => {
              if (e.key === ENTER_KEY) {
                toggleAllOptions();
              }
            }}>
            <Body1 color={'#000000de'}>{allToggleLabel}</Body1>
            <Switch
              tabIndex={-1}
              checked={allOptionsSelected}
              onChange={toggleAllOptions}
              data-testid='allOptionsSwitch'
              edge={'end'}
            />
          </HeaderMenuItem>
        )}
      </HeaderSection>
      {primaryList.subHeader && (
        <SubHeaderSection id={'sub-header'} ref={subHeaderRef} style={{ top: headerHeight }}>
          <SubHeaderTitle data-testid='primarySubHeader'>{primaryList.subHeader}</SubHeaderTitle>
        </SubHeaderSection>
      )}
      {getOptionList(primaryList.list)}
      {secondaryList?.list && <EhiDivider />}
      {secondaryList?.subHeader && (
        <SubHeaderSection id={'sub-header'} ref={subHeaderRef} style={{ top: headerHeight }}>
          <SubHeaderTitle data-testid='secondarySubHeader'>{secondaryList.subHeader}</SubHeaderTitle>
        </SubHeaderSection>
      )}
      {secondaryList?.list && getOptionList(secondaryList.list)}
      {footerText && (
        <FooterSection>
          <ButtonContainer>
            <EhiButtonReset
              tabIndex={-1}
              disableFocusRipple
              onClick={handleReset}
              id={FOOTER_BUTTON_ID}
              data-testid='resetButton'
              sx={{ margin: `${ehiTheme.spacing(0.5, 0.5, 0, 0)}` }}>
              {footerText}
            </EhiButtonReset>
          </ButtonContainer>
        </FooterSection>
      )}
    </StyledFormTextField>
  );
};
