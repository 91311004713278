import { useCallback } from 'react';
import { BusinessPayer, BusinessPayerType } from 'services/booking/bookingTypes';
import { EHI_DOMAINS, generateReferenceUrn, generateUrnForBillingAccount } from 'utils/urnUtils';
import { useAppSelector } from 'redux/hooks';
import { selectBookingEditorId, selectBrand, selectPickup } from 'redux/selectors/bookingEditor';
import { getAppConfigCache } from 'services/appConfig/appConfigService';
import { updateBusinessPayers } from 'services/booking/bookingService';
import { useUpdateAndRefreshEditor } from 'hooks/bookingEditor/useUpdateAndRefreshEditor';
import { EhiErrors, HttpStatusCode } from 'services/types/EhiErrorsTypes';
import { useTranslations } from 'components/shared/i18n';
import { BillingAccountErrorCodes, safelyCatchError } from 'utils/errorUtils';
import { useAccountSearchContext } from 'context/accountSearch/AccountSearchContext';
import { useAlert } from 'components/shared/alert/AlertContext';
import { validateBillingAccount } from 'services/businessAccount/businessAccountService';
import { hasBillingNumberRequiredErrorCodes } from 'utils/rateAndBillingUtils';
import { useBranchInfoByUrnQuery, useCountriesQuery } from 'services/location/locationQueries';
import { BillingAvailability } from 'services/businessAccount/businessAccountTypes';
import { getCountry } from 'utils/countryUtils';
import { EMPTY_VALUE } from 'utils/constants';
import { isTruckLocation } from 'utils/locationUtils';

export type AddOrModifyBillToProps = {
  accountNumber: string;
  billingNumber: string;
  handleCloseModal: () => void;
};

export type BillToResponseState = {
  errorMessage: string | undefined;
};

export type useRateAndBillingHookReturn = {
  addOrModifyBillTo: (props: AddOrModifyBillToProps) => Promise<BillToResponseState>;
  checkBillingAccountErrors: (billingAccountErrors: EhiErrors) => BillToResponseState;
};

export const useBillTo = (): useRateAndBillingHookReturn => {
  const { t } = useTranslations();
  const bookingEditorId = useAppSelector(selectBookingEditorId);
  const pickup = useAppSelector(selectPickup);
  const { data: branchInfo } = useBranchInfoByUrnQuery(pickup?.branch);
  const { data: countries } = useCountriesQuery();
  const brand = useAppSelector(selectBrand);
  const appConfig = getAppConfigCache();
  const defaultEhiDatabase = appConfig?.defaultEhiDatabase ?? '';
  const { showAlert } = useAlert();
  const { isAccountNumberFieldHidden } = useAccountSearchContext();
  const { updateAndRefresh } = useUpdateAndRefreshEditor();

  const addOrModifyBillTo = useCallback(
    async ({
      accountNumber,
      billingNumber,
      handleCloseModal,
    }: AddOrModifyBillToProps): Promise<BillToResponseState> => {
      let message = undefined;
      const billingAvailability: BillingAvailability = {
        country: getCountry(countries, branchInfo?.addresses?.[0].country?.code)?.urn ?? EMPTY_VALUE,
        brand: brand ?? EMPTY_VALUE,
        lineOfBusiness: isTruckLocation(branchInfo?.type)
          ? generateReferenceUrn(
              EHI_DOMAINS.businessAccount.name,
              EHI_DOMAINS.businessAccount.lineOfBusiness.name,
              EHI_DOMAINS.businessAccount.lineOfBusiness.truck,
              defaultEhiDatabase
            )
          : generateReferenceUrn(
              EHI_DOMAINS.businessAccount.name,
              EHI_DOMAINS.businessAccount.lineOfBusiness.name,
              EHI_DOMAINS.businessAccount.lineOfBusiness.car,
              defaultEhiDatabase
            ),
      };

      try {
        // Note: Always validate as user can modify the billing number
        const { isValid, ehiMessages } = await validateBillingAccount({
          accountNumber: accountNumber.trim().toUpperCase(),
          billingNumber: billingNumber.trim().toUpperCase(),
          billingAvailability: billingAvailability,
        });
        if (isValid) {
          const request: BusinessPayer[] = [
            {
              type: BusinessPayerType.BILLING_ACCOUNT,
              billingAccount: generateUrnForBillingAccount(accountNumber, billingNumber, defaultEhiDatabase),
            } as BusinessPayer,
          ];

          const updateResponse = await updateAndRefresh(async () => updateBusinessPayers(bookingEditorId, request));

          if (updateResponse?.errors?.length) {
            await showAlert({ responseMessages: updateResponse?.errors });
          } else {
            handleCloseModal();
          }
        } else {
          if (ehiMessages && ehiMessages[0].code === BillingAccountErrorCodes.BillingNumberNotApproved) {
            message = t('rateAndBilling.billTo.unapprovedBillingNumber');
          } else {
            message = ehiMessages ? ehiMessages[0].localizedMessage : t('rateAndBilling.billTo.invalidBillingNumber');
          }
        }
      } catch (error: any) {
        const ehiErrorsResponse = safelyCatchError(error);
        if (ehiErrorsResponse.status === HttpStatusCode.NotFound) {
          message = t('rateAndBilling.billTo.invalidBillingNumber');
        } else {
          message = ehiErrorsResponse.errors?.[0].localizedMessage;
        }
      }
      return {
        errorMessage: message,
      };
    },

    [
      bookingEditorId,
      branchInfo?.addresses,
      branchInfo?.type,
      brand,
      countries,
      defaultEhiDatabase,
      showAlert,
      t,
      updateAndRefresh,
    ]
  );

  const checkBillingAccountErrors = useCallback(
    (billingAccountErrors: EhiErrors): BillToResponseState => {
      let message = undefined;
      if (billingAccountErrors.status === HttpStatusCode.NotFound) {
        isAccountNumberFieldHidden
          ? showAlert({
              description: t('rateAndBilling.billTo.noBillingAccount'),
            })
          : (message = t('rateAndBilling.billTo.noBillingAccount'));
      } else {
        const errorCodes = billingAccountErrors.errors?.map((message) => message.code);
        if (errorCodes?.includes(BillingAccountErrorCodes.InvalidAccount)) {
          message = t('rateAndBilling.invalidAccountNumber');
        } else if (hasBillingNumberRequiredErrorCodes(errorCodes)) {
          // Error codes APE00197, APE00027, and APE00185 all indicate that the Billing Account Exists is “True” and BNR flag is ‘Yes’.
          // This will allow user to manually enter billing number.
          message = undefined;
        } else {
          message = billingAccountErrors?.errors?.[0].localizedMessage;
        }
      }
      return {
        errorMessage: message,
      };
    },
    [isAccountNumberFieldHidden, showAlert, t]
  );

  return {
    addOrModifyBillTo,
    checkBillingAccountErrors,
  };
};
