import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import CardHeader from '@mui/material/CardHeader';
import { styled } from '@mui/material';
import { EhiButton, ehiTheme } from '@ehi/ui';

export const StyledCard = styled(Card)(({ theme, onClick }) => ({
  margin: theme.spacing(1, 1, 2, 1),
  cursor: onClick ? 'pointer' : 'unset',
}));

export const StyledCardHeader = styled(CardHeader)(({ theme }) => ({
  padding: theme.spacing(0),
}));

export const StyledCardAction = styled(EhiButton)(({ theme }) => ({
  margin: theme.spacing(1),
}));

export const StyledListItemCard = styled(Card)(({ onClick }) => ({
  padding: 0,
  boxShadow: '0 1px 1px rgba(0, 0, 0, 0.14), 0 2px 1px rgba(0, 0, 0, 0.12), 0 1px 3px rgba(0, 0, 0, 0.2)',
  borderRadius: ehiTheme.spacing(0.5),
  cursor: onClick ? 'pointer' : 'unset',

  '&.isSelected': {
    background: '#4d789d',
    color: 'white',
  },
}));

export const CardContentWithoutHeader = styled(CardContent)(({ theme }) => ({
  paddingTop: theme.spacing(2),
}));

export const StyledFlexFlowCard = styled(Card)(({ onClick }) => ({
  cursor: onClick ? 'pointer' : 'unset',
  padding: 0,
}));

export const StyledCardInnerContainer = styled(Card)(({ theme, onClick }) => ({
  margin: theme.spacing(2, 3, 3, 3),
  cursor: onClick ? 'pointer' : 'unset',
}));
