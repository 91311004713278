import { Box, Grid } from '@mui/material';
import { Body1, EhiButton, ehiTheme } from '@ehi/ui';
import { ConsentRadioGroup } from 'components/flexFlow/driver/consent/ConsentRadioGroup';
import { useForm } from 'react-hook-form';
import { FC, useMemo, useState } from 'react';
import { useTranslations } from 'components/shared/i18n';
import { ConsentFields, ConsentProps, ConsentStatus } from 'components/flexFlow/driver/consent/ConsentFormTypes';
import { Caption2 } from 'components/shared/ui/styles/Typography.styles';
import { useAppDispatch, useAppSelector } from 'redux/hooks';
import { selectIsReadOnlyFlow } from 'redux/selectors/bookingEditor';
import { EMPTY_VALUE } from 'utils/constants';
import { useReservationFlow } from 'hooks/useReservationFlow';
import {
  selectConsentCountry,
  selectConsentPhoneNumber,
  selectConsentStatus,
  selectRetrievedConsentData,
  selectConsentEdited,
} from 'redux/selectors/consent';
import { setSelectedStatus, setConsentEdited } from 'redux/slices/consent/consentSlice';
import {
  hasPreviousConsentData,
  consentFormValidationSchema,
  getReadOnlyConsent,
} from 'components/flexFlow/driver/consent/consentUtils';
import { useYupValidationResolver } from 'components/shared/forms/useYupValidationResolver';
import { SavingFormProvider } from 'context/saveAction/SavingFormProvider';
import { ServiceResultType } from 'services/types/ServiceResultTypes';
import { ConsentQuestion } from './ConsentQuestion';
import { accountTypes } from 'components/flexFlow/rateAndBilling/editDialogs/accountSearchUtils';
import { useRateSourceAccountDetails } from 'services/businessAccount/useRateSourceAccountDetails';

export const ConsentSection: FC<ConsentProps> = ({ driverDetails }) => {
  const { t } = useTranslations();
  const readOnly = useAppSelector(selectIsReadOnlyFlow);
  const { isModifyFlow, isCreateFlow } = useReservationFlow();
  const consentData = useAppSelector(selectRetrievedConsentData);
  const country = useAppSelector(selectConsentCountry);
  const phoneNumber = useAppSelector(selectConsentPhoneNumber);
  const status = useAppSelector(selectConsentStatus);
  const isConsentEdited = useAppSelector(selectConsentEdited);
  const [modifyReadOnly, setModifyReadOnly] = useState(isModifyFlow);
  const resolver = useYupValidationResolver(consentFormValidationSchema(t, isModifyFlow));
  const { rateSourceInfo } = useRateSourceAccountDetails();
  const SHOW_ASTERISK = '*';

  const formMethods = useForm({
    resolver: resolver,
    defaultValues: {
      [ConsentFields.Country]: country,
      [ConsentFields.PhoneNumber]: phoneNumber,
      [ConsentFields.Status]: status,
      [ConsentFields.UpdateCheckbox]: false,
    },
  });

  const initialDispatch = useAppDispatch();
  const hasConsentData = useMemo(() => {
    return hasPreviousConsentData(consentData);
  }, [consentData]);

  const isReadOnlyConsentStatus = useMemo(() => {
    return hasConsentData && modifyReadOnly && !isConsentEdited;
  }, [hasConsentData, modifyReadOnly, isConsentEdited]);

  const displayReadOnlyConsentStatus = useMemo(() => {
    const readOnlyConsent = getReadOnlyConsent(t, consentData);
    return (
      <Box>
        {readOnlyConsent?.map((consent, index) => {
          return (
            <Body1 data-testid={consent.dataTestId} key={`${consent.dataTestId}-${index}`}>
              {consent.value}
            </Body1>
          );
        })}
      </Box>
    );
  }, [consentData, t]);

  const displayConsentRadioGroup: boolean = !hasConsentData || !modifyReadOnly || isConsentEdited;

  const disableEdit = useMemo(() => {
    const consentStatus = (consentData && consentData?.status) ?? undefined;
    return consentStatus !== ConsentStatus.Accepted || !modifyReadOnly || isConsentEdited;
  }, [consentData, modifyReadOnly, isConsentEdited]);

  const handleClick = (): void => {
    setModifyReadOnly(false);
    initialDispatch(setConsentEdited(true));
    if (EMPTY_VALUE === status) {
      initialDispatch(setSelectedStatus(ConsentStatus.Accepted));
    }
  };

  const isConsentStatusRequired: boolean =
    (readOnly || isCreateFlow || isModifyFlow) && accountTypes.Tour_Operator !== rateSourceInfo?.type;

  const handleSubmit = async (): Promise<ServiceResultType> => {
    return { success: true };
  };

  return (
    <>
      {readOnly ? (
        <Box>
          <Grid item xs={12}>
            <Caption2>
              {t('consent.consentStatus', { required: isConsentStatusRequired ? SHOW_ASTERISK : EMPTY_VALUE })}
            </Caption2>
            {displayReadOnlyConsentStatus}
          </Grid>
        </Box>
      ) : (
        <SavingFormProvider {...formMethods} submitFn={handleSubmit}>
          <Box display={'flex'} flexDirection={'column'} data-testid={'consentSection'}>
            <ConsentQuestion />
            <Box
              display={'flex'}
              marginTop={ehiTheme.spacing(1)}
              marginBottom={displayConsentRadioGroup ? ehiTheme.spacing(-2) : 0}>
              <Caption2>
                {t('consent.consentStatus', { required: isConsentStatusRequired ? SHOW_ASTERISK : EMPTY_VALUE })}
              </Caption2>
              {hasConsentData && isModifyFlow && (
                <EhiButton
                  style={{ padding: 0, margin: 0, minWidth: '4rem', marginLeft: disableEdit ? ehiTheme.spacing(1) : 0 }}
                  data-testid='consentEditButton'
                  variant={disableEdit ? 'contained' : 'text'}
                  disabled={disableEdit}
                  onClick={handleClick}>
                  {t('common.edit')}
                </EhiButton>
              )}
            </Box>
            {isReadOnlyConsentStatus && displayReadOnlyConsentStatus}

            {displayConsentRadioGroup && (
              <ConsentRadioGroup driverDetails={driverDetails} modifyReadOnly={modifyReadOnly} />
            )}
          </Box>
        </SavingFormProvider>
      )}
    </>
  );
};
