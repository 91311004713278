import { UseQueryResult } from '@tanstack/react-query';
import { getPaymentHistory } from 'services/payment/PaymentHistoryService';
import { PaymentHistoryResponse } from 'services/payment/paymentHistoryTypes';
import { useResScopeQuery } from 'context/queryClient/queryUtil';
const PAYMENT_HISTORY_QUERY_KEY = 'paymentHistory';

export const useGetPaymentHistoryQuery = (resNumber: string): UseQueryResult<PaymentHistoryResponse> => {
  return useResScopeQuery({
    queryKey: [PAYMENT_HISTORY_QUERY_KEY, resNumber],
    queryFn: (): Promise<PaymentHistoryResponse> => getPaymentHistory(resNumber),
    enabled: !!resNumber,
  });
};
