import { FC, useMemo, useState } from 'react';
import { EhiButton, ehiTheme } from '@ehi/ui';
import { useTranslations } from 'components/shared/i18n';
import { useNavigate } from 'react-router-dom';
import { RouterPaths } from 'app/router/RouterPaths';
import { CreateQuickResProps, QuickResFields, QuickResValues } from './QuickResTypes';
import { useYupValidationResolver } from 'components/shared/forms/useYupValidationResolver';
import { FormProvider, useForm } from 'react-hook-form';
import QuickResDateTime from 'components/quickRes/QuickResDateTime';
import QuickResRateSource from 'components/quickRes/QuickResRateSource';
import QuickResVehicle from 'components/quickRes/QuickResVehicle';
import QuickResDriver from 'components/quickRes/QuickResDriver';
import QuickResContact from 'components/quickRes/QuickResContact';
import { quickResInitialValues, quickResValidationSchema } from 'components/quickRes/quickResUtils';
import CustomerHeader from 'components/customerHeader/CustomerHeader';
import { generateSearchParams } from 'utils/routing/urlUtils';
import { InternalTransactionParams } from 'utils/routing/InternalTransactionParams';
import { TransactionTypes } from 'utils/routing/TransactionTypes';
import { LoadingState } from 'components/shared/ui/spinner/loadableView/LoadableViewTypes';
import { loadEhiLocationCookie } from '@ehi/location';
import NetworkError from 'components/shared/errors/NetworkError';
import { useEffectOnlyOnce } from 'hooks/useEffectOnlyonce';
import { saveToReservation } from 'services/booking/bookingService';
import { selectBookingEditorId } from 'redux/selectors/bookingEditor';
import { useAppSelector } from 'redux/hooks';
import { getLocationHeaderFromUrl } from 'components/shared/preprocessor/ReservationSessionHelper';
import { QuickResCancelModal } from './QuickResCancelModal';
import { safelyCatchError } from 'utils/errorUtils';
import { useAlert } from 'components/shared/alert/AlertContext';
import { useReservationSessionHelper } from 'components/shared/preprocessor/useReservationSessionHelper';
import { Dialog } from 'components/shared/ui/dialogs/Dialog';
import { useLocationQuery } from 'services/location/locationQueries';
import { useDateTimeFormater } from 'utils/routing/useDatetimeFormater';
import { omit } from 'lodash';
import { useQuickResHelper } from 'components/quickRes/useQuickResHelper';
import { FullScreenSpinner } from 'components/shared/ui/spinner/FullScreenSpinner';
import { LoadableView } from 'components/shared/ui/spinner/loadableView/LoadableView';
import { Box, Grid } from '@mui/material';
import { ErrorBanner } from 'components/shared/errors/ErrorBanner';
import { useStartQuickReservationSession } from 'components/shared/preprocessor/useStartQuickReservationSession';
import { TextAreaField } from 'components/shared/forms/TextAreaField';
import { useQuickResModalContext } from 'components/quickRes/QuickResModalContext';
import { logDebug } from 'utils/logUtils';
import { ADDITIONAL_INFO_ISSUE_CODES, INCOMPLETE_RES_OVERRIDE_ERROR_CODES } from 'utils/bookingUtils';
import { StyledDivider } from 'components/quickRes/QuickRes.styles';

const QuickResModal: FC<CreateQuickResProps> = ({ open, onSaveAndExit, onSaveAndContinue }) => {
  const { t } = useTranslations();
  const { createQuickResSession } = useStartQuickReservationSession();
  const navigate = useNavigate();
  const resolver = useYupValidationResolver(quickResValidationSchema(t));
  const { getLocalizedDateTime } = useDateTimeFormater();
  const cookieLocation = loadEhiLocationCookie();
  const { data: locationQuery } = useLocationQuery(cookieLocation?.peoplesoftId ?? '');
  const [loadingState, setLoadingState] = useState<LoadingState>(LoadingState.LOADING);
  const PREFERENCES_MAX_CHARS = 150;

  const formInitialValues: QuickResValues = useMemo(
    () => quickResInitialValues(t, locationQuery?.location, getLocalizedDateTime),
    [getLocalizedDateTime, locationQuery?.location, t]
  );

  const formMethods = useForm<QuickResValues>({
    resolver: resolver,
    defaultValues: formInitialValues,
  });

  const { errors } = formMethods.formState;
  const showBannerMessage = useMemo(() => {
    return !!(errors.returnDate || errors.returnTime);
  }, [errors.returnDate, errors.returnTime]);

  const preferencesValue = formMethods.getValues(QuickResFields.Preferences);
  const formFieldValues = omit(formMethods.watch(), [
    QuickResFields.CurrentLocationTime,
    QuickResFields.CurrentLocationTimezone,
    QuickResFields.CurrentLocationUrn,
    QuickResFields.AccountName,
    QuickResFields.AccountType,
  ]);
  const formFieldsHasValues = formFieldValues ? Object.values(formFieldValues).some((value) => !!value) : false;
  const bookingEditorId = useAppSelector(selectBookingEditorId);
  const [showCancelDialog, setShowCancelDialog] = useState(false);
  const { showAlert } = useAlert();
  const { clearEditorSession } = useReservationSessionHelper();
  const { createProfile, handleAddingRemarks } = useQuickResHelper();
  const { onBlurFunction } = useQuickResModalContext();

  const handleCancel = (): void => {
    onBlurFunction?.()
      .catch(logDebug)
      .finally(() => setShowCancelDialog(true));
  };

  const handleSaveAndExit = async (): Promise<void> => {
    onBlurFunction?.().then(async (): Promise<void> => {
      setLoadingState(LoadingState.LOADING);
      const remarksAdded = await handleAddingRemarks(handleSaveError, bookingEditorId, preferencesValue);
      if (!remarksAdded) return;
      await createProfile(
        bookingEditorId,
        formFieldValues.lastName,
        formFieldValues.firstName,
        formFieldValues.contactType,
        formFieldValues.phone,
        formFieldValues.countryCode,
        formFieldValues.age
      );

      try {
        const { headers } = await saveToReservation(bookingEditorId, {
          overrideIssue: [...INCOMPLETE_RES_OVERRIDE_ERROR_CODES, ...ADDITIONAL_INFO_ISSUE_CODES],
        });
        const resNum = getLocationHeaderFromUrl(headers?.location);
        setLoadingState(LoadingState.SUCCESS);
        clearEditorSession();
        onSaveAndExit(resNum);
      } catch (error) {
        await handleSaveError(error);
      }
    }, logDebug);
  };

  const handleSaveError = async (error: unknown): Promise<void> => {
    const ehiErrorsResponse = safelyCatchError(error);
    const responseMessages = ehiErrorsResponse?.errors;
    if (ehiErrorsResponse?.errors) {
      await showAlert({ responseMessages: responseMessages });
      setLoadingState(LoadingState.SUCCESS);
    } else {
      setLoadingState(LoadingState.ERROR);
    }
  };

  const handleSaveAndContinue = async (): Promise<void> => {
    onBlurFunction?.().then(async (): Promise<void> => {
      setLoadingState(LoadingState.LOADING);
      const remarksAdded = await handleAddingRemarks(handleSaveError, bookingEditorId, preferencesValue);
      if (!remarksAdded) return;
      await createProfile(
        bookingEditorId,
        formFieldValues.lastName,
        formFieldValues.firstName,
        formFieldValues.contactType,
        formFieldValues.phone,
        formFieldValues.countryCode,
        formFieldValues.age
      );

      try {
        const { headers } = await saveToReservation(bookingEditorId, {
          overrideIssue: [...INCOMPLETE_RES_OVERRIDE_ERROR_CODES, ...ADDITIONAL_INFO_ISSUE_CODES],
        });
        const resNum = getLocationHeaderFromUrl(headers?.location);
        setLoadingState(LoadingState.SUCCESS);
        clearEditorSession();
        navigate(
          `${RouterPaths.PreProcessor}?${generateSearchParams({
            [InternalTransactionParams.TransactionType]: TransactionTypes.Modify,
            [InternalTransactionParams.Res]: resNum,
          })}`
        );
        onSaveAndContinue?.(resNum);
      } catch (error) {
        await handleSaveError(error);
      }
    }, logDebug);
  };

  useEffectOnlyOnce(async (): Promise<void> => {
    await createQuickResSession().then((loadingState) => {
      setLoadingState(loadingState ?? LoadingState.SUCCESS);
    });
  });

  return (
    <FormProvider {...formMethods}>
      <Dialog
        data-testid={'quick-res-modal'}
        contentPadding={0}
        open={open}
        title={t('common.newReservation')}
        a11yKey='content'
        showDividers
        maxWidth={'md'}
        fullWidth
        titleActions={[
          <EhiButton
            data-testid={'fullReservation'}
            key='res'
            disabled={loadingState === LoadingState.LOADING || formFieldsHasValues}
            onClick={(): void => {
              clearEditorSession();
              navigate(
                `${RouterPaths.PreProcessor}?${generateSearchParams({
                  [InternalTransactionParams.TransactionType]: TransactionTypes.CreateFullRes,
                })}`
              );
            }}>
            {t('quickReservation.fullReservation')}
          </EhiButton>,
        ]}
        actions={{
          tertiaryAction: {
            label: t('common.cancel'),
            overrideButtonProps: {
              onKeyDown: (e: any): void => {
                e && e.keyCode === 13 && handleCancel();
              },
              onMouseDown: handleCancel,
            },
          },
          secondaryAction: {
            label: t('quickReservation.saveAndExit'),
            overrideButtonProps: {
              onKeyDown: (e: any): void => {
                e && e.keyCode === 13 && handleSaveAndExit();
              },
              onMouseDown: handleSaveAndExit,
              disabled: !formMethods.formState.isValid,
            },
          },
          primaryAction: {
            label: t('quickReservation.saveAndContinue'),
            overrideButtonProps: {
              onKeyDown: (e: any): void => {
                e && e.keyCode === 13 && handleSaveAndContinue();
              },
              onMouseDown: handleSaveAndContinue,
              disabled: !formMethods.formState.isValid,
            },
          },
        }}
        additionalHeader={<CustomerHeader />}>
        <LoadableView
          loadingComponent={<FullScreenSpinner />}
          errorComponent={<NetworkError />}
          state={loadingState ?? LoadingState.SUCCESS}>
          <Box data-testid='quickResModal' style={{ padding: ehiTheme.spacing(4, 3, 4, 3) }}>
            {showBannerMessage && (
              <div
                style={{
                  padding: '4px 12px',
                }}>
                <ErrorBanner useShortError={true} errorMessage={t('validation.returnDateTimeError')} />
              </div>
            )}
            <Grid container gap={ehiTheme.spacing(3)}>
              <QuickResDateTime />
              <QuickResRateSource />
              <QuickResVehicle />
              <QuickResDriver />
              <QuickResContact />
              <Grid item style={{ padding: 0, width: '100%' }}>
                <StyledDivider />
              </Grid>

              <TextAreaField
                header={t('quickReservation.preferences')}
                name={QuickResFields.Preferences}
                maxChars={PREFERENCES_MAX_CHARS}
                rows={4}
                marginBottom={'0rem'}
                gridPadding={0}
                validateOnBlur={true}
                errorIconMarginBottom={8.0}
                errorIconMarginRight={1.0}
              />
            </Grid>
          </Box>
        </LoadableView>
        {showCancelDialog && (
          <QuickResCancelModal open={showCancelDialog} onClose={(): void => setShowCancelDialog(false)} />
        )}
      </Dialog>
    </FormProvider>
  );
};

export default QuickResModal;
