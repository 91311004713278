import { FC, useCallback, useMemo, useState } from 'react';
import { AppBar, Box, Grid } from '@mui/material';
import { DriverSearchForm } from 'components/flexFlow/driver/driverSearch/DriverSearchForm';
import { useRenterProfileSearch } from 'components/flexFlow/driver/driverSearch/useRenterProfileSearch';
import { DriverSearchProps, DriverSearchValues, SelectedDriverProfile } from './DriverSearchTypes';
import { MarginWrapper, StyledList } from 'components/shared/ui/styles/Global.styles';
import DriverSearchResult from './DriverSearchResult';
import { LoyaltyMembership, SearchResult } from 'services/renter/driverProfile/driverProfileTypes';
import { NoResultsView } from 'components/shared/ui/noResultsView/NoResultsView';
import { useTranslations } from 'components/shared/i18n';
import { ProgressOverlay } from 'components/shared/ui/spinner/ProgressOverlay';
import { useAppSelector } from 'redux/hooks';
import { selectAdditionalDrivers, selectBrand, selectDriverProfileRenter } from 'redux/selectors/bookingEditor';
import { useAlert } from 'components/shared/alert/AlertContext';
import { usePhoneTypesQuery } from 'services/renter/renterReferenceQueries';
import { calculateAge, toDateTimeString } from 'utils/dateUtils';
import { DriverData, NewDriver } from 'components/shared/uiModels/driver/driverDataTypes';
import { ConfirmationDialog } from 'components/flexFlow/driver/editDriver/ConfirmationDialog';
import { ADD, REMOVE } from 'components/flexFlow/driver/driverForm/driverFormUtils';
import { Body1, Body2, ehiTheme } from '@ehi/ui';
import { prioritizeByBrand } from './driverSearchUtils';
import {
  CURRENT,
  LOYALTY,
  PRIMARY_DRIVER,
  RATE_SOURCE,
} from 'components/flexFlow/driver/driverSearch/driverSearchUtils';
import { parseUrn } from 'utils/urnUtils';
import { Trans } from 'react-i18next';
import { StyledLink } from 'components/shared/ui/noResultsView/NoResultsViewStyles';
import { getDriverProfileLoyaltyMembership } from 'services/renter/driverProfile/driverProfileService';
import { retrieveBusinessAccount } from 'services/businessAccount/businessAccountService';
import { RenterWarningDialog } from 'components/flexFlow/driver/driverSearch/renterWarning/RenterWarningDialog';
import { useDriverLicenseValidation } from 'hooks/driverLicenseValidation/useDriverLicenseValidation';
import { ERROR_ALERT_VARIANT } from 'components/shared/alert/AlertDialogTypes';
import ErrorOutlineIcon from '@mui/icons-material/ErrorOutline';
import { HashPaths } from 'app/router/RouterPaths';
import { useLocation } from 'react-router-dom';
import { ReservationAccount } from 'services/businessAccount/businessAccountTypes';
import { safelyCatchError } from 'utils/errorUtils';
import { useSaveDriver } from 'components/flexFlow/driver/useSaveDriver';
import { RenterType } from 'services/booking/bookingTypes';
import { transformToDriverFromSearchResult } from 'services/renter/renterTransformer';

export const DriverSearch: FC<DriverSearchProps> = ({ navigateToDriverForm, onClose }) => {
  const { t } = useTranslations();
  const { search } = useRenterProfileSearch();
  const { showAlert } = useAlert();
  const { hash } = useLocation();

  const driverProfileRenter = useAppSelector(selectDriverProfileRenter);
  const additionalDrivers = useAppSelector(selectAdditionalDrivers);
  const selectedBrand = useAppSelector(selectBrand);

  const [loading, setLoading] = useState<boolean>(false);
  const [results, setResults] = useState<SearchResult[] | undefined>();
  const [driverData, setDriverData] = useState<DriverData | undefined>(undefined);
  const [dialogType, setDialogType] = useState<null | typeof PRIMARY_DRIVER | typeof RATE_SOURCE>(null);
  const [selectedDriverProfile, setSelectedDriverProfile] = useState<SelectedDriverProfile | undefined>(undefined);
  const [showRenterWarningDialog, setShowRenterWarningDialog] = useState<SearchResult | undefined>(undefined);

  const { changePrimaryDriverFromNewDriver, updateDriverProfileOnEditor } = useSaveDriver();
  const { validateExpirationDateAgainstRentalDates } = useDriverLicenseValidation();
  const { data: phoneTypeDomain, isFetching: isPhoneDomainLoading } = usePhoneTypesQuery();

  const isUpdatingPrimaryDriver = useMemo(() => hash === HashPaths.ReplaceDriver, [hash]);

  const handleRenterWarningDialog = (state: SearchResult | undefined): void => {
    setShowRenterWarningDialog(state);
  };

  const searchForRenter = useCallback(
    async (values: DriverSearchValues): Promise<SearchResult[] | undefined> => {
      const validateDrivers = (urn: string): boolean => {
        const prevDrivers = [...(driverProfileRenter ? [driverProfileRenter] : []), ...(additionalDrivers || [])];
        return prevDrivers.some(({ profile }) => profile === urn);
      };

      setLoading(true);
      return search(values)
        .then(async (results) => {
          const sortedResults = prioritizeByBrand(results, parseUrn(selectedBrand));
          const duplicateDriver = sortedResults.some((result) => result.urn && validateDrivers(result.urn));
          if (duplicateDriver) {
            await showAlert({
              variant: 'error',
              description: `${t('driverSearch.driverAlreadyExists')}`,
            });
            return;
          }

          setResults(sortedResults);
          return sortedResults;
        })
        .catch(() => {
          setResults([]);
          return [];
        })
        .finally(() => {
          if (values) {
            const dobString = toDateTimeString(values.dateOfBirth, t('format.yearMonthDay')) ?? undefined;
            setDriverData({
              type: RenterType.NO_PROFILE,
              phoneNumbers: [{ number: values.phoneNumber }],
              firstName: values.firstName,
              lastName: values.lastName,
              age: dobString ? calculateAge(dobString) : undefined,
            });
          }
          setLoading(false);
        });
    },
    [search, driverProfileRenter, additionalDrivers, selectedBrand, showAlert, t]
  );

  const getAccountDetailsForDriver = async (
    driverUrn: string | undefined,
    membershipProgramUrn: string | undefined
  ): Promise<ReservationAccount | undefined> => {
    let accountDetails: ReservationAccount | undefined;
    let alternateContractId: string | undefined;
    if (membershipProgramUrn && driverUrn) {
      try {
        const membership: LoyaltyMembership = await getDriverProfileLoyaltyMembership(
          parseUrn(driverUrn),
          parseUrn(membershipProgramUrn)
        );
        if (membership) {
          alternateContractId = membership?.rateContracts?.[0]?.alternateContractId;
          if (alternateContractId) {
            accountDetails = await retrieveBusinessAccount(alternateContractId);
          }
        }
      } catch (error) {
        const ehiErrorsResponse = safelyCatchError(error);
        if (!accountDetails) {
          onClose();
          await showAlert({
            variant: 'error',
            description: (
              <Box display={'flex'}>
                <ErrorOutlineIcon color={ERROR_ALERT_VARIANT} style={{ paddingRight: ehiTheme.spacing(1) }} />
                <Box>
                  <Body1>{t('error.serviceCallError')}</Body1>
                  <Body2 color={'#000000de'}>
                    {t('driverSearch.rateSourceError', { accountNumber: alternateContractId })}
                  </Body2>
                </Box>
              </Box>
            ),
          });
        } else {
          await showAlert({ responseMessages: ehiErrorsResponse.errors });
        }
      }
    }

    return accountDetails;
  };

  const handleAddDriver = async (searchResult: SearchResult): Promise<void> => {
    setLoading(true);
    const accountDetails = await getAccountDetailsForDriver(
      searchResult.urn,
      searchResult.loyaltyMembership?.loyaltyProgram
    );

    if (isUpdatingPrimaryDriver) {
      setSelectedDriverProfile({
        urn: searchResult.urn,
        loyaltyMembership: searchResult.loyaltyMembership,
        accountDetails: accountDetails,
      });
      if (accountDetails) {
        setDialogType(RATE_SOURCE);
      } else {
        setDialogType(PRIMARY_DRIVER);
      }
      setShowRenterWarningDialog(undefined);
      setLoading(false);
    } else {
      const newDriver: NewDriver = {
        driverInfo: transformToDriverFromSearchResult(searchResult),
        accountDetails,
        loyaltyMembership: searchResult.loyaltyMembership,
      };
      const { errors } = await updateDriverProfileOnEditor(newDriver).finally(() => setLoading(false));

      if (errors) {
        await showAlert({ responseMessages: errors });
      } else {
        if (
          searchResult.legalIdentifications &&
          searchResult.legalIdentifications.length > 0 &&
          searchResult.legalIdentifications[0].expirationDate
        ) {
          validateExpirationDateAgainstRentalDates(searchResult.legalIdentifications[0].expirationDate);
        }
        onClose();
      }
    }
  };

  // What triggers this function -> "Change Primary Driver" button clicked then add new driver through DriverSearch
  const handleConfirmChangePrimaryDriver = async (selection: string): Promise<void> => {
    setDialogType(null);

    if (selectedDriverProfile?.urn) {
      setLoading(true);

      const newDriver: NewDriver | undefined = {
        driverInfo: {
          type: RenterType.DRIVER_PROFILE,
          urn: selectedDriverProfile.urn,
          loyaltyProgram: {
            urn: selectedDriverProfile.loyaltyMembership?.loyaltyProgram,
          },
        },
        accountDetails: selectedDriverProfile.accountDetails,
        loyaltyMembership: selectedDriverProfile.loyaltyMembership,
      };
      const { errors } = await changePrimaryDriverFromNewDriver(selection, newDriver).finally(() => setLoading(false));

      if (errors) {
        await showAlert({ responseMessages: errors });
      } else {
        onClose();
      }
    }
  };

  const handleCancelChangePrimaryDriver = (): void => {
    setDialogType(null);
  };

  const handleModifyRateSelection = async (selection: string): Promise<void> => {
    if (selection !== LOYALTY) {
      // This will keep the same rate source that is currently on editor
      setSelectedDriverProfile({
        urn: selectedDriverProfile?.urn,
        loyaltyMembership: selectedDriverProfile?.loyaltyMembership,
        accountDetails: undefined,
      });
    }

    setDialogType(PRIMARY_DRIVER);
  };

  const dialogConfig =
    dialogType === RATE_SOURCE
      ? {
          title: t('driver.confirmRateSource'),
          description: t('driver.confirmRateSourcePrompt'),
          options: [
            { label: t('driver.keepCurrentRateSource'), value: CURRENT },
            { label: t('driver.useLoyaltyRateSource'), value: LOYALTY },
          ],
          onConfirm: handleModifyRateSelection,
          onCancel: handleCancelChangePrimaryDriver,
          defaultSelection: CURRENT,
        }
      : {
          title: t('driver.changePrimaryDriver'),
          description: t('driver.additionalDrivers.changePrimaryPrompt'),
          options: [
            { label: t('driver.additionalDrivers.makeAdditionalDriver'), value: ADD },
            { label: t('driver.removeFromReservation'), value: REMOVE },
          ],
          onConfirm: handleConfirmChangePrimaryDriver,
          onCancel: handleCancelChangePrimaryDriver,
          defaultSelection: undefined,
        };

  return (
    <>
      <AppBar position='sticky' color='inherit' style={{ boxShadow: 'none' }}>
        <DriverSearchForm search={searchForRenter} />
      </AppBar>
      <MarginWrapper sx={{ margin: ehiTheme.spacing(3) }}>
        {results && results.length > 0 && (
          <StyledList>
            <Grid item style={{ padding: ehiTheme.spacing(0) }}>
              <Body2 data-testid='result-header'>{`${results.length} ${t('driverSearch.results')}`} </Body2>
            </Grid>
            {results.map((renter, index: number) => (
              <DriverSearchResult
                showRenterWarningDialog={handleRenterWarningDialog}
                testId={`driverSearchResult-${index}`}
                searchResult={renter}
                key={index}
                onAddDriver={(renter: SearchResult): Promise<void> => handleAddDriver(renter)}
                phoneTypeDomain={phoneTypeDomain}
              />
            ))}
          </StyledList>
        )}
        {results && results.length === 0 && (
          <Box paddingTop={12}>
            <NoResultsView
              noResultsTitle='driverSearch.noResultsTitle'
              noResultsDescription={
                <Trans
                  i18nKey='driverSearch.noResultsAddInfo'
                  components={{
                    Link: (
                      <StyledLink
                        data-testid={'navigationLink'}
                        onClick={(): void => navigateToDriverForm(driverData)}
                      />
                    ),
                  }}
                />
              }
              isBackgroundColor={false}
            />
          </Box>
        )}
      </MarginWrapper>
      <ProgressOverlay inProgress={loading || isPhoneDomainLoading} />

      <ConfirmationDialog
        open={dialogType !== null}
        onConfirm={dialogConfig.onConfirm}
        onCancel={dialogConfig.onCancel}
        title={dialogConfig.title}
        description={dialogConfig.description}
        options={dialogConfig.options}
        defaultSelection={dialogConfig.defaultSelection}
      />
      {showRenterWarningDialog && (
        <RenterWarningDialog
          searchResult={showRenterWarningDialog}
          onCancel={(): void => {
            setShowRenterWarningDialog(undefined);
            onClose();
          }}
          onSubmit={(): void => {
            handleAddDriver?.(showRenterWarningDialog);
          }}
        />
      )}
    </>
  );
};
