/* eslint-disable */
/* tslint:disable */
/*
 * ---------------------------------------------------------------
 * ## THIS FILE WAS GENERATED VIA SWAGGER-TYPESCRIPT-API        ##
 * ##                                                           ##
 * ## AUTHOR: acacode                                           ##
 * ## SOURCE: https://github.com/acacode/swagger-typescript-api ##
 * ---------------------------------------------------------------
 */

type UtilRequiredKeys<T, K extends keyof T> = Omit<T, K> & Required<Pick<T, K>>;

/**
 * The locale in [ISO 639-3](https://iso639-3.sil.org/) for language and  [ISO 3166-1 alpha-3](https://www.iso.org/iso-3166-country-codes.html) for country code
 * @pattern ^[a-z]{3}-[A-Z]{3}$
 * @example "eng-USA"
 */
export type Locale = string;

/** This is how an Ehi-Workflow-Id should look when used in a header or parameter. */
export type EhiWorkflowId = string;

/**
 * This is how an X-B3-SpanId should look when used in a header or parameter.
 * This must be a string of 16 lower case hex characters representing a unique 64 bit integer.
 * @pattern ^[a-f0-9]{16}$
 * @example "48bed08e521e46e4"
 */
export type XB3SpanId = string;

/**
 * This is how an X-B3-TraceId should look when used in a header or parameter.
 * This must be a string of 16 or 32 lower case hex characters representing a unique 64 or 128 bit integer.
 * @pattern ^([a-f0-9]{16}|[a-f0-9]{32})$
 * @example "9815106142d4405980e8848b16130a7d"
 */
export type XB3TraceId = string;

/**
 * An identifier for a specific version of a resource. A comparison of two ETag values can determine whether two  representations of a resource are the same.
 * It can be used for optimistic locking and/or make caches be more efficient and save bandwidth, as a web server  does not need to resend a full response if the content was not changed.
 * @pattern ^"[A-Za-z0-9]+"$
 * @example ""cce8be58c54100999ebe228f8162e560""
 */
export type ETag = string;

/**
 * The unique name of a reason why a `Reservation` was canceled.
 * @format ehi-reference-urn
 * @pattern ^urn:com\.ehi:[a-zA-Z0-9]+:reference:rental:booking:reservation:cancellationReason:[A-Z]+(_[A-Z]+)*$
 * @example "urn:com.ehi:xqa:reference:rental:booking:reservation:cancellationReason:WEATHER_RELATED"
 */
export type CancellationReasonURN = string;

/**
 * Additional information about why the reservation is being canceled. If the reason is OTHER,  this field must be provided.
 * @maxLength 500
 * @example "Hurricane in Florida."
 */
export type CancelNote = string;

/** Request body to cancel a reservation. */
export interface CancelReservationRequest {
  /** The unique name of a reason why a `Reservation` was canceled. */
  reason?: CancellationReasonURN;
  /** Additional information about why the reservation is being canceled. If the reason is OTHER,  this field must be provided. */
  note?: CancelNote;
}

/** The details of the payment associated with the reservation to cancel. */
export interface PaymentCancellationDetails {
  /**
   * The amount that will be charged to the customer if they cancel the reservation a certain number of hours before the pickup date, usually 24 hours.
   * @example 49.99
   */
  cancelAmount?: number;
  /**
   * The amount that will be charged to the customer if they cancel the reservation in advance of the time period that the cancel amount is charged, usually greater than 24 hours before the pickup date.
   * @example 19.99
   */
  advanceCancelAmount?: number;
  /**
   * The reference number for the credit card authorization when taking a prepay reservation.
   * @maxLength 100
   */
  authorizationReference?: string;
  /**
   * The reference number whenever a transactional call is made to charge a credit card for a prepay reservation.
   * @maxLength 100
   */
  transactionReference?: string;
  /**
   * The reference number for the authorization of a refund to the credit card when canceling a prepay reservation.
   * @maxLength 100
   */
  refundAuthorizationReference?: string;
}

/** Request body to cancel a reservation that requires payment details. */
export interface CancelReservationWithPaymentRequest {
  /** The unique name of a reason why a `Reservation` was canceled. */
  reason?: CancellationReasonURN;
  /** Additional information about why the reservation is being canceled. If the reason is OTHER,  this field must be provided. */
  note?: CancelNote;
  /** The details of the payment associated with the reservation to cancel. */
  paymentDetails: PaymentCancellationDetails;
}

export interface Error {
  /**
   * A unique id for the particular error/validation.
   * This is **NOT** an overall response HTTP status code.
   * This allows callers to reference the error and potentially provide custom behaviors (such as custom error messages in a GUI) if needed.
   * @example "FOO123"
   */
  code: string;
  /** The locale in [ISO 639-3](https://iso639-3.sil.org/) for language and  [ISO 3166-1 alpha-3](https://www.iso.org/iso-3166-country-codes.html) for country code */
  locale: Locale;
  /**
   * A human readable string describing the error which can be shown to an end-user.
   * This SHOULD be translated based on the locale in the request.
   * If the requested locale does not have a translation, the service SHOULD return the closest  available translation based on language, then country.
   * @example "Last name is required"
   */
  localizedMessage: string;
  /**
   * A list of references to the specific element/field related to the error.
   * This may be useful to distinguish to which field(s) an error applies, as a single code could be used for multiple fields.
   * @example ["/user/lastname"]
   */
  paths?: string[];
  /**
   * The severity of the message.
   * @example "ERROR"
   */
  severity: 'INFO' | 'WARNING' | 'ERROR';
  /**
   * Any additional debugging details which could be useful by a developer.
   * This SHOULD never be shown to a user and MUST NOT include stack traces or convey sensitive details of the service.
   * @example "Example text for debugging"
   */
  supportInformation?: string;
}

export interface Errors {
  errors: Error[];
}

/**
 * A string representing an HTTP-date.
 * For more information, see [RFC-7231](https://tools.ietf.org/html/rfc7231#section-7.1.1.1)
 * @pattern ^(?:Sun|Mon|Tue|Wed|Thu|Fri|Sat), (?:0[1-9]{1}|[1-2][0-9]|3[0-1]) (?:Jan|Feb|Mar|Apr|May|Jun|Jul|Aug|Sep|Oct|Nov|Dec) (?:19\d{2}|20\d{2}) [0-2]\d:[0-5]\d:[0-5]\d GMT$
 * @example "Fri, 31 Dec 1999 23:59:59 GMT"
 */
export type HttpDate = string;

/**
 * Identifies the version of the Booking API.
 * @pattern ^\d{1,2}\.\d{1,2}\.\d{1,3}
 * @example "1.11.1"
 */
export type EhiApiVersion = string;

/**
 * The unique name of a `Reservation`
 * @format ehi-resource-urn
 * @pattern ^urn:com\.ehi:[a-z]+:rental\.booking:reservation:[a-zA-Z0-9]{1,10}$
 * @example "urn:com.ehi:xqa:rental.booking:reservation:8374296898"
 */
export type ReservationURN = string;

/**
 * The type of Reservation.
 * @example "RETAIL"
 */
export enum ReservationTypeURN {
  CORPORATE = 'CORPORATE',
  GOVERNMENT = 'GOVERNMENT',
  OTHER = 'OTHER',
  RETAIL = 'RETAIL',
  BODY_SHOP = 'BODY_SHOP',
  DEALERSHIP = 'DEALERSHIP',
  INSURANCE = 'INSURANCE',
}

/**
 * The unique name of a `Brand`
 * @format ehi-reference-urn
 * @pattern ^urn:com\.ehi:[a-zA-Z0-9]+:reference:rental:brand:(ENTERPRISE|ALAMO|NATIONAL)$
 * @example "urn:com.ehi:xqa:reference:rental:brand:ENTERPRISE"
 */
export type BrandURN = string;

/**
 * The unique name of the `Lifecycle` current for the `Reservation`.
 * @format ehi-reference-urn
 * @pattern ^urn:com\.ehi:[a-zA-Z0-9]+:reference:rental:booking:reservation:lifecycle:[A-Z]+(_[A-Z]+)*$
 * @example "urn:com.ehi:xqa:reference:rental:booking:reservation:lifecycle:RESERVED"
 */
export type LifecycleUrn = string;

/**
 * The status of a `Reservation` or the status that a `Reservation` would be if a `Reservation Editor` is saved.
 * * AWAITING_CONFIRMATION -  Awaiting for confirmation from a branch agent that the reservation can be fulfilled.
 * * INCOMPLETE - Reservation is missing data required or has data that is incompatible such that the reservation cannot be fulfilled in its current state.
 * * CONFIRMED - Reservation that was previously AWAITING_CONFIRMATION has been confirmed by a branch agent that the reservation can be fulfilled.
 * * SOLD - Reservation is in a state such that it can be fulfilled. No Branch Agent confirmation was required.
 * * FULFILLED – Reservation has had a rental agreement created from it. A reservation with this status cannot be modified or cancelled.
 * * MATCHED   – Reservation is matched to a ticket.
 * * DENIED    – Reservation has been denied, most likely because the renter was marked as do-not-rent.
 * * ABANDONED – Reservation was abandoned, not completed during the create process.
 * * NO_SHOW   – Reservation was not fulfilled, well past when it should have been.
 * * CANCELED  – Reservation was canceled by the renter.
 * * TRANSFERRED - Reservation has been transferred to another group.
 * @format ehi-reference-urn
 * @pattern ^urn:com\.ehi:[a-zA-Z0-9]+:reference:rental:booking:reservation:status:[A-Z]+(_[A-Z]+)*$
 * @example "urn:com.ehi:xqa:reference:rental:booking:reservation:status:CONFIRMED"
 */
export type StatusUrn = string;

/** The type of `Renter`. */
export enum RenterType {
  TRANSACTIONAL_PROFILE = 'TRANSACTIONAL_PROFILE',
  DRIVER_PROFILE = 'DRIVER_PROFILE',
  NO_PROFILE = 'NO_PROFILE',
}

/** Indicates whether the `Renter` arrived at an airport `Branch` via a flight or not. Used to drive whether airport surcharges should be applied for this `Reservation`. */
export interface WalkIn {
  /** Indicates whether `Status` can be updated.  Will be false for Locations that do not allow setting Walk In. */
  canSetWalkIn?: boolean;
  /** Indicates whether the driver walked in or flew in. */
  status?: boolean;
}

/**
 * Describes the mode or type of travel the `Renter` is going to use.
 * @example "AIRPLANE"
 */
export enum TravelType {
  AIRPLANE = 'AIRPLANE',
}

export interface BaseTravelInformation {
  /** Describes the mode or type of travel the `Renter` is going to use. */
  travelType: TravelType;
  /**
   * Expected arrival or departure date of the `Renter`.
   * @format date-time
   * @example "2019-09-19T17:57:08.113Z"
   */
  dateTime?: string;
}

/** Airplane travel information for the `Renter` */
export type Airplane = BaseTravelInformation & {
  /**
   * The two character code representing the name of the airline.
   * @minLength 2
   * @maxLength 2
   * @pattern ^[A-Z0-9]{2}$
   * @example "UA"
   */
  airline: string;
  /**
   * The name of the terminal.
   * @maxLength 80
   * @example "T2"
   */
  terminal?: string;
  /**
   * The number of the Flight the `Renter` will be arriving at when picking up the `Reservation`.
   * @example "U863"
   */
  flightNumber?: string;
};

export type TravelInformation = Airplane & {
  /** Describes the mode or type of travel the `Renter` is going to use. */
  travelType?: TravelType;
};

/** Base object that defines all common `Renter` properties. */
export interface BaseRenter {
  /** The type of `Renter`. */
  type: RenterType;
  /** Indicates whether the `Renter` arrived at an airport `Branch` via a flight or not. Used to drive whether airport surcharges should be applied for this `Reservation`. */
  walkIn?: WalkIn;
  arrivalTravelInformation?: TravelInformation;
}

/**
 * The unique name of a `Transactional Profile`
 * @format ehi-resource-urn
 * @pattern ^urn:com\.ehi:[a-zA-Z0-9]+:people:transactionalProfile:[0-9a-fA-F]{8}\-[0-9a-fA-F]{4}\-[0-9a-fA-F]{4}\-[0-9a-fA-F]{4}\-[0-9a-fA-F]{12}$
 * @example "urn:com.ehi:xqa:people:transactionalProfile:e014e1ab-c820-458d-a0ac-143b2c84c19b"
 */
export type TransactionalProfile = string;

/** One time use profile that represents the `Renter`. */
export type TransactionalProfileRenter = BaseRenter & {
  /** The unique name of a `Transactional Profile` */
  profile: TransactionalProfile;
};

/**
 * The unique name of a **Driver Profile**
 * @format ehi-resource-urn
 * @pattern ^urn:com\.ehi:[a-zA-Z0-9]+:renter:driverProfile:[0-9a-fA-F]{8}\-[0-9a-fA-F]{4}\-[0-9a-fA-F]{4}\-[0-9a-fA-F]{4}\-[0-9a-fA-F]{12}$
 * @example "urn:com.ehi:xqa:renter:driverProfile:8e5a7365-19d7-412c-a95b-773072bf9e42"
 */
export type DriverProfileURN = string;

/**
 * The unique name of a **Loyalty Program**
 * @format ehi-resource-urn
 * @pattern ^urn:com\.ehi:[a-zA-Z0-9]+:renter:loyaltyProgram:[A-Z_]+$
 * @example "urn:com.ehi:xqa:renter:loyaltyProgram:EMERALD_CLUB"
 */
export type LoyaltyProgramURN = string;

/** The `Renter`'s `Loyalty Program` to apply to the `Reservation`. */
export interface Membership {
  /** The unique name of a **Loyalty Program** */
  loyaltyProgram: LoyaltyProgramURN;
}

/** A permanent and reusable profile that represents the `Renter`.  May include loyalty membership. */
export type DriverProfileRenter = BaseRenter & {
  /** The unique name of a **Driver Profile** */
  profile: DriverProfileURN;
  /** The `Renter`'s `Loyalty Program` to apply to the `Reservation`. */
  membership?: Membership;
};

export interface Name {
  /**
   * @maxLength 35
   * @example "John"
   */
  given?: string;
  /**
   * @maxLength 60
   * @example "Smith"
   */
  surname: string;
}

/** A name and age on a `Reservation` or `Reservation Editor` that is not currently associated with any driver or transactional profile. */
export type NoProfileRenter = BaseRenter & {
  /**
   * The age of the `Renter`
   * @min 18
   * @max 99
   * @example 25
   */
  age?: number;
  name: Name;
};

/** The main driver associated with a `Reservation` or `Reservation Editor`. The `Renter` is legally responsible for any damage or incidentals incurred during the rental. */
export type Renter = (TransactionalProfileRenter | DriverProfileRenter | NoProfileRenter) & {
  /** The type of `Renter`. */
  type?: RenterType;
};

/**
 * The unique name of a `DriverProfile` or `TransactionalProfile`
 * @format ehi-resource-urn
 * @pattern ^urn:com\.ehi:[a-zA-Z0-9]+:(renter:driverProfile|people:transactionalProfile):[0-9a-fA-F]{8}\-[0-9a-fA-F]{4}\-[0-9a-fA-F]{4}\-[0-9a-fA-F]{4}\-[0-9a-fA-F]{12}$
 * @example "urn:com.ehi:xqa:renter:driverProfile:e014e1ab-c820-458d-a0ac-143b2c84c19b"
 */
export type Profile = string;

/** Additional driver is also allowed to drive the vehicle. Ancillary fees may apply. */
export interface AdditionalDriver {
  /** The unique name of a `DriverProfile` or `TransactionalProfile` */
  profile?: Profile;
  name?: Name;
}

/**
 * The unique name of an individual EHI `Branch`, such as rental branches.
 * @format ehi-resource-urn
 * @pattern ^urn:com\.ehi:[a-zA-Z]+:location:branch:[0-9]{7}$
 * @example "urn:com.ehi:xqa:location:branch:1019999"
 */
export type BranchURN = string;

/**
 * A uniform name given to how the customer will be picking up the rental vehicle.
 * @format ehi-reference-urn
 * @pattern ^urn:com\.ehi:[a-zA-Z0-9]+:reference:rental:pickupMethod:[A-Z_]+$
 * @example "urn:com.ehi:xqa:reference:rental:pickupMethod:DELIVERY"
 */
export type PickupMethodURN = string;

/**
 * The type code for `Destination`
 * @example "VEHICLE"
 */
export enum DestinationType {
  VEHICLE = 'VEHICLE',
  PERSON = 'PERSON',
}

/** A phone number in E.164 format with optional extension. */
export interface Phone {
  /**
   * ---
   * `Protected Data` may be masked under certain circumstance based on EHI's data masking rules.
   *
   * Masked Example: "••••3456"
   * @pattern ^\+?[1-9]\d{1,14}$
   * @example "+13145551234"
   */
  number: string;
  /**
   * ---
   * `Protected Data` may be masked under certain circumstance based on EHI's data masking rules.
   *
   * Masked Example: "••••3456"
   * @pattern ^\d{1,6}$
   * @example "123456"
   */
  extension?: string;
}

/** Base object that defines all common `Destination` properties. */
export interface BaseDestination {
  /** The type code for `Destination` */
  type: DestinationType;
  /** @maxItems 5 */
  addressInfo: string[];
  /**
   * Delivery or collection instructions
   * @maxLength 200
   */
  instructions?: string;
  /** A phone number in E.164 format with optional extension. */
  contactPhone?: Phone;
  /**
   * Name of the booking contact
   * @maxLength 35
   */
  contactName?: string;
}

export enum DistanceUnit {
  KILOMETER = 'KILOMETER',
  MILE = 'MILE',
}

/** Distance information */
export interface Distance {
  /**
   * @maxLength 6
   * @example 100
   */
  value?: number;
  unit?: DistanceUnit;
}

/** Information about where the branch will need to go to pick up or drop off a vehicle */
export type VehicleDestination = BaseDestination & {
  zone?: 'ONE' | 'TWO';
  /** Expected mileage for rental period */
  distance?: Distance;
};

/** Information about where the branch will need to go pick up or drop off the `Renter` */
export type PersonDestination = BaseDestination;

export type Destination = (VehicleDestination | PersonDestination) & {
  /** The type code for `Destination` */
  type?: DestinationType;
};

/**
 * The level of service that the customer will receive when arriving at the rental branch.
 *
 * Some example values include:
 *   - COUNTER
 *   - CHOICE
 *   - QUICKRENT
 *   - EMERALD_BOOTH
 *   - MEET_AND_GREET
 *   - EMERALD_AISLE
 *   - EMERALD_COUNTER
 *   - EMERALD_RESERVE
 *   - EXECUTIVE_SELECTION
 *   - FBO_DELIVERY
 *   - FAST_TRACK
 *   - RESERVE_TRACK
 *   - LOCAL_DELIVERY
 *   - PICK_UP
 * @format ehi-reference-urn
 * @pattern ^urn:com\.ehi:[a-zA-Z0-9]+:reference:rental:levelOfService:[A-Z_]+$
 * @example "urn:com.ehi:xqa:reference:rental:levelOfService:EMERALD_AISLE"
 */
export type LevelOfServiceURN = string;

export interface Pickup {
  /** The branch in which the rental vehicle will be picked up. */
  branch: BranchURN;
  /**
   * The `Branch` local time at which the `Reservation` will be picked up.
   * @format date-time
   * @example "2019-09-19T17:57:08.113Z"
   */
  dateTime?: string;
  /** The way that a customer obtains the rental vehicle. */
  method?: PickupMethodURN;
  /**
   * Information about where the `Branch` will need to go to pick up the `Renter` or deliver the vehicle.
   * ---
   * `Protected Data` may be masked under certain circumstance based on EHI's data masking rules.
   *
   * Protected Data Identifier: `pickup.destination`
   */
  destination?: Destination;
  selectedLevelOfService?: LevelOfServiceURN;
}

/**
 * A unique name given to how the customer will be returning the rental vehicle.
 * @format ehi-reference-urn
 * @pattern ^urn:com\.ehi:[a-zA-Z0-9]+:reference:rental:returnMethod:[A-Z_]+$
 * @example "urn:com.ehi:xqa:reference:rental:returnMethod:RIDE_BACK"
 */
export type ReturnMethodURN = string;

export interface Return {
  /** The location to which the rental vehicle will be returned. */
  branch: BranchURN;
  /**
   * Local time for the `Branch` where the vehicle will be returned.
   * @format date-time
   * @example "2019-09-19T17:57:08.113Z"
   */
  dateTime?: string;
  /** How the Renter will be return the vehicle to the renting `Branch`. */
  method?: ReturnMethodURN;
  /**
   * Information about where the `Branch` will need to go to drop off the `Renter` or collect the vehicle.
   * ---
   * `Protected Data` may be masked under certain circumstance based on EHI's data masking rules.
   *
   * Protected Data Identifier: `return.destination`
   */
  destination?: Destination;
}

/**
 * The unique identifier for a client application through which requests are sent to Booking.
 * @format ehi-reference-urn
 * @pattern ^urn:com\.ehi:[a-zA-Z0-9]+:reference:rental:booking:channel:[A-Z]+(_[A-Z]+)*$
 * @example "urn:com.ehi:xqa:reference:rental:booking:channel:GREENLIGHT_RESERVATION"
 */
export type BookingChannelUrn = string;

/** Record of the client application(s) through which the request to create the reservation, and the most recent request to edit the reservation, were sent. */
export interface BookingChannels {
  /** The channel through which the reservation was created. */
  created?: BookingChannelUrn;
  /** The channel through which the reservation was last updated. */
  lastUpdated?: BookingChannelUrn;
}

export interface Source {
  /**
   * Origin application name for the reservation.
   * @maxLength 255
   * @example "Expedia"
   */
  originApplication?: string;
  /**
   * Identifies the user who created the reservation.
   * @maxLength 255
   * @example "E273Y7"
   */
  originIdentity?: string;
  /**
   * Calling application name for the reservation.
   * @maxLength 255
   * @example "LNK"
   */
  callingApplication?: string;
  /**
   * Application or system that created this reservation.
   * @maxLength 255
   * @example "booking-bps"
   */
  createdBy?: string;
  /**
   * Legacy name for the application which created the `Reservation`.
   * @maxLength 255
   * @example "SCR"
   */
  legacyApplication: string;
  /**
   * UTC time for which the `Reservation` was received.
   * @format date-time
   * @example "2022-08-01T20:16:44Z"
   */
  createdDateTime?: string;
  /**
   * UTC time for which the `Reservation` was last modified
   * @format date-time
   * @example "2023-06-07T20:16:44Z"
   */
  lastUpdatedDateTime?: string;
  /**
   * A subset of values that could be populated when the `Source` equals `WEB`.
   * These values allow web clients to indicate where a `Reservation` came from on the web
   * or if it is a certain type of reservation, ie. `Prepay`, `Booked on behalf`, etc.
   * This element is only populated when the `Source` equals `WEB`.
   * @maxLength 255
   * @example "BUSRES"
   */
  legacyWebSourceCode?: string;
  /** Record of the client application(s) through which the request to create the reservation, and the most recent request to edit the reservation, were sent. */
  bookingChannels?: BookingChannels;
}

/**
 * ---
 * `Protected Data` may be masked under certain circumstance based on EHI's data masking rules.
 *
 * Masked Example: e•••••@yahoo.com
 * @format email
 * @example "somebody@test.com"
 */
export type EmailAddress = string;

/** Contains the travel agent details for `PointOfSale` information. */
export interface BookingAgent {
  /** Identifies a specific travel agent. */
  agentIdentifier?: string;
  /**
   * A unique value that identifies a travel agency.  This value can be any of the travel agency association identifiers: International Air Transport Association (IATA), Cruise Lines International Association (CLIA), etc.
   * @pattern ^[A-Z0-9]{1,8}$
   * @example "ABC12345"
   */
  agencyIdentifier?: string;
  /**
   * ---
   * `Protected Data` may be masked under certain circumstance based on EHI's data masking rules.
   *
   * Masked Example: e•••••@yahoo.com
   */
  email?: EmailAddress;
  /**
   * The travel agent's name
   * @example "John Smith"
   */
  name?: string;
  /** A phone number in E.164 format with optional extension. */
  phone?: Phone;
  /**
   * The travel agent's optional Cash-In Club number
   * @pattern ^CIC[0-9]{1,9}$
   * @example "CIC135440"
   */
  cashInClub?: string;
}

export interface PointOfSale {
  /** The branch in which the reservation was made. */
  location: BranchURN;
  /** Contains the travel agent details for `PointOfSale` information. */
  bookingAgent?: BookingAgent;
}

/**
 * ---
 *  `Protected Data` may be masked under certain circumstance based on EHI's data masking rules.
 *
 *  Protected Data Identifier: `contact`
 */
export interface Contact {
  /** @example "John Smith" */
  name?: string;
  /** A phone number in E.164 format with optional extension. */
  phone?: Phone;
  /**
   * ---
   * `Protected Data` may be masked under certain circumstance based on EHI's data masking rules.
   *
   * Masked Example: e•••••@yahoo.com
   */
  email?: EmailAddress;
}

/**
 * Specifies the type of reason for the `OnRequest`.
 * @example "SPECIAL_EQUIPMENT"
 */
export enum OnRequestReasonType {
  AFTER_HOURS = 'AFTER_HOURS',
  COLLECTION = 'COLLECTION',
  DELIVERY = 'DELIVERY',
  SPECIAL_EQUIPMENT = 'SPECIAL_EQUIPMENT',
  VEHICLE_CATEGORY = 'VEHICLE_CATEGORY',
}

/**
 * The universally unique name for a type of **Equipment**
 * @format ehi-reference-urn
 * @pattern ^urn:com\.ehi:[a-z]+:reference:rental:equipmentType:[A-Z]+(_[A-Z]+)*$
 * @example "urn:com.ehi:xqa:reference:rental:equipmentType:GPS"
 */
export type EquipmentTypeURN = string;

/**
 * Description providing any special instructions supporting `OnRequest.Reason`
 * @maxLength 50
 * @example "The customer wants GPS to help with navigation."
 */
export type OnRequestComment = string;

/** A customer request that a branch may accommodate but needs to be approved by a branch employee. This can include special equipment, vehicle class, after-hours pickup or return. */
export interface OnRequest {
  /** Specifies the type of reason for the `OnRequest`. */
  reason?: OnRequestReasonType;
  /** The universally unique name for a type of **Equipment** */
  equipmentType?: EquipmentTypeURN;
  /** Description providing any special instructions supporting `OnRequest.Reason` */
  comment?: OnRequestComment;
  /**
   * The branch employee that reviewed the request.
   * ---
   * `Protected Data` may be masked under certain circumstance based on EHI's data masking rules.
   *
   * Masked Example: e•••••
   *
   * Protected Data Identifier: `onRequest.reviewer`
   * @maxLength 30
   * @example "E12345"
   */
  reviewer?: string;
  /**
   * Indicates the current status of the `OnRequest`.
   * @example "CONFIRMED"
   */
  status?: 'CONFIRMED' | 'FAILED' | 'PENDING';
}

export interface CancellationInformation {
  /** The unique name of a reason why a `Reservation` was canceled. */
  reason?: CancellationReasonURN;
  /** Additional information about why the reservation is being canceled. If the reason is OTHER,  this field must be provided. */
  note?: CancelNote;
  /**
   * Date and time the reservation was canceled.
   * @format date-time
   * @example "2019-09-19T17:57:08.113Z"
   */
  canceledDateTime?: string;
  /**
   * Agent who canceled the `Reservation`.
   * @maxLength 30
   * @example "E12345"
   */
  canceledBy?: string;
}

/**
 * Remark is added to a reservation to pass along additional information that the branch might need to know.
 * @maxLength 150
 * @example "A freetext string representing a remark that should be prominently displayed to the branch."
 */
export type Remark = string;

/**
 * The unique name for a `Person Payer Payment Method`
 * @format ehi-reference-urn
 * @pattern ^urn:com\.ehi:[a-zA-Z0-9]+:reference:rental:booking:person:paymentMethod:[A-Z]+(_[A-Z]+)*$
 * @example "urn:com.ehi:xqa:reference:rental:booking:person:paymentMethod:CREDIT_CARD"
 */
export type PersonPaymentMethodURN = string;

/**
 * The unique name for a **Transient Store**.
 * @format ehi-resource-urn
 * @pattern ^urn:com\.ehi:[a-zA-Z0-9]+:payment\.store:transientStore:[a-zA-Z0-9\-_]+$
 * @example "urn:com.ehi:xqa:payment.store:transientStore:1b7f9862-afc5-43d3-849a-fbd432ac42a5"
 */
export type TransientStoreURN = string;

/** A 'Payer' object that represents the person paying for the rental. */
export interface PersonPayer {
  /** The unique name for a `Person Payer Payment Method` */
  paymentMethod?: PersonPaymentMethodURN;
  /** The unique name for a **Transient Store**. */
  transientStore?: TransientStoreURN;
}

/** Describes the type of 'Payer' object */
export enum BusinessPayerType {
  BILLING_ACCOUNT = 'BILLING_ACCOUNT',
  BILL_TO = 'BILL_TO',
}

/**
 * The unique id for a field of `AdditionalInfo` for a `BusinessAccount`
 * @format urn
 * @pattern ^urn:com\.ehi:[a-zA-Z0-9]+:businessAccount:account:[a-zA-Z0-9]{2,8}:additionalInformation:[0-9]{1,14}$
 * @example "urn:com.ehi:xqa:businessAccount:account:XJ12345:additionalInformation:8324967211"
 */
export type AdditionalInformationURN = string;

export interface AdditionalInformation {
  /** The unique id for a field of `AdditionalInfo` for a `BusinessAccount` */
  fieldId?: AdditionalInformationURN;
  /**
   * The provided value for a piece of 'Additional Information' for a 'Business Account'.
   * @maxLength 256
   * @example "Computer Science Department"
   */
  value?: string;
}

/** A 'Business Payer' object that can be a 'Billing Account Payer' or a 'Bill To Payer' */
export interface BusinessPayerBase {
  /** Describes the type of 'Payer' object */
  type?: BusinessPayerType;
  /** Any additional data that should be included with the 'Business Account'. */
  additionalInformation?: AdditionalInformation[];
}

/**
 * Billing Account URN
 * @format ehi-subresource-urn
 * @pattern ^urn:com\.ehi:[a-zA-Z0-9]+:businessAccount:account:[a-zA-Z0-9]{2,8}:billingAccount:[1-9][0-9]{2,14}$
 * @example "urn:com.ehi:xqa:businessAccount:account:XJ12345:billingAccount:539852572"
 */
export type BillingAccountURN = string;

/**
 * The type for the Billing Context.
 * @example "CENTRAL_BILLING"
 */
export enum BillingContextType {
  FIXED_VALUE_VOUCHER = 'FIXED_VALUE_VOUCHER',
  VEHICLE_CLASS_VOUCHER = 'VEHICLE_CLASS_VOUCHER',
  FULL_CREDIT_VOUCHER = 'FULL_CREDIT_VOUCHER',
  CENTRAL_BILLING = 'CENTRAL_BILLING',
}

/**
 * The unique name for a type of `Voucher`.
 * @format ehi-reference-urn
 * @pattern ^urn:com\.ehi:[a-zA-Z0-9]+:reference:payment:voucherType:[A-Z0-9]+$
 * @example "urn:com.ehi:xqa:reference:payment:voucherType:XOTTO"
 */
export type VoucherTypeURN = string;

/**
 * Vouchers are a method of payment used to settle a rental invoice.
 * Charges are allocated to the central bill account behind the voucher.
 * Thus, the central bill account behind the voucher receives an invoice.
 *
 * ## Vehicle Class Days
 * Usually issued by tour operators and are valid for a certain category of vehicle and a set numbers of days.
 * There are special charge allocation rules that apply for these types of vouchers where the business accounts
 * pay for only certain charges like T&M and insurance.
 *
 * ## Full Credit
 * All rental charges will be allocated to the business account providing the account will accept the charges.
 *
 * ## Fixed Value
 * These have a set value (e.g., $500.00) printed on the voucher. The amount of the voucher used is printed in the
 * payment section of the drivers invoice/receipt.  If the rental charges do not exceed the voucher value, then
 * the voucher is shrunk to pay for the charges and prevent a refund at the counter. The driver in this case must
 * get the refund from the travel agent or issuer.
 */
export interface BaseVoucher {
  /** The type for the Billing Context. */
  contextType: BillingContextType;
  /** The unique name for a type of `Voucher`. */
  type: VoucherTypeURN;
}

export enum CurrencyAmountType {
  MASKED = 'MASKED',
  UNMASKED = 'UNMASKED',
}

/**
 * Code which represents the currency(ISO 4217)
 * @pattern ^[A-Z]{3}$
 * @example "USD"
 */
export type CurrencyCode = string;

export interface BaseCurrencyAmount {
  type: CurrencyAmountType;
  /** Code which represents the currency(ISO 4217) */
  currencyCode: CurrencyCode;
}

/** Masked `Amount` is masked with three bullets before decimal, and two bullets at the end. */
export type MaskedCurrencyAmount = BaseCurrencyAmount & {
  /**
   * @format maskedAmount
   * @default "•••.••"
   * @pattern ^[•]{3}.[•]{2}$
   * @example "•••.••"
   */
  amount?: string;
};

/** A Currency Amount that is not masked. */
export type UnmaskedCurrencyAmount = BaseCurrencyAmount & {
  /**
   * @format currency
   * @example 112.25
   */
  amount?: number;
};

/**
 * May be masked, likely due to a negotiated rate source indicating that rates should be hidden.
 *
 *       ---
 * `Protected Data` may be masked under certain circumstance based on EHI's data masking rules.
 *
 * Masked Example: •••.••
 *
 * Protected Data Identifier: `secretRates`
 */
export type CurrencyAmount = (UnmaskedCurrencyAmount | MaskedCurrencyAmount) & {
  type?: CurrencyAmountType;
};

/** A `Voucher` that has a specific, fixed value. */
export type FixedValueVoucher = BaseVoucher & {
  id: string;
  /**
   * May be masked, likely due to a negotiated rate source indicating that rates should be hidden.
   *
   *       ---
   * `Protected Data` may be masked under certain circumstance based on EHI's data masking rules.
   *
   * Masked Example: •••.••
   *
   * Protected Data Identifier: `secretRates`
   */
  amount: CurrencyAmount;
};

/**
 * A vehicle class is a code used to sort and/or group vehicles.
 *
 * Vehicle classes consist of four letters, each representing one or more major features of a given vehicle.
 *
 * Here are how the different letters are interpreted:
 *   - First letter:     Size of the Vehicle
 *   - Second Letter:    Number of Doors
 *   - Third Letter:     Transmission & Drive
 *   - Fourth Letter:    Fuel & A/C
 * @format ehi-reference-urn
 * @pattern ^urn:com\.ehi:[a-zA-Z0-9]+:reference:vehicle:vehicleClass:[A-Z0-9]{4}$
 * @example "urn:com.ehi:xqa:reference:vehicle:vehicleClass:ICAR"
 */
export type VehicleClassURN = string;

/** One instance of the `VehicleClassDaysVoucher` with a specified number of days, applicable to a predefined vehicle class. */
export interface VehicleClassDaysInstance {
  id: string;
  /** @example 3 */
  numberOfDays: number;
}

/** A `Voucher` that gives the customer credit for a certain amount of days spent driving a specific vehicle class. There can be between 1 and 5 of this type in use per `Reservation`. All must apply to the same vehicle class. */
export type VehicleClassDaysVoucher = BaseVoucher & {
  /**
   * A vehicle class is a code used to sort and/or group vehicles.
   *
   * Vehicle classes consist of four letters, each representing one or more major features of a given vehicle.
   *
   * Here are how the different letters are interpreted:
   *   - First letter:     Size of the Vehicle
   *   - Second Letter:    Number of Doors
   *   - Third Letter:     Transmission & Drive
   *   - Fourth Letter:    Fuel & A/C
   */
  vehicleClass: VehicleClassURN;
  /** A collection of voucher instances. */
  voucherInstances: VehicleClassDaysInstance[];
};

/**
 * A `Voucher` that gives the customer full credit for the charges authorized by the Business Payer.
 *
 * **NOTE** - Depending on the setup of the `Account` the `Voucher` is associated with, a
 * `FullCreditVoucher` may not pay for add-ons such as `Protection` and `Equipment`.
 */
export type FullCreditVoucher = BaseVoucher & {
  id: string;
};

/** The central billing consolidates invoiced rental expenses for designated business accounts that are due for billing. */
export interface CentralBilling {
  /** The type for the Billing Context. */
  contextType: BillingContextType;
  /**
   * The unique, identifying number associated to a business designated for rental expenses.
   * @example "080275900010"
   */
  centralBillingNumber?: string;
}

export type BillingContext = (FixedValueVoucher | VehicleClassDaysVoucher | FullCreditVoucher | CentralBilling) & {
  /** The type for the Billing Context. */
  contextType?: BillingContextType;
};

/** A 'Business Payer' object that represents a billing account and context. */
export type BillingAccountPayer = BusinessPayerBase & {
  /** Billing Account URN */
  billingAccount: BillingAccountURN;
  billingContext?: BillingContext;
  /** Indicates whether a StreamedAirplus card was provided or not. */
  streamedAirplus?: boolean;
};

/**
 * A uniform resource name for a BillTo in the Payment Billing Authorization domain.
 * @format ehi-resource-urn
 * @pattern ^urn:com\.ehi:[a-zA-Z0-9]+:payment\.billingAuthorization:billTo:[a-zA-Z0-9-]{1,40}$
 * @example "urn:com.ehi:xqa:payment.billingAuthorization:billTo:7c7ee093-3cf3-4dfb-bde8-c37cc5c97407"
 */
export type BillToURN = string;

/** A 'Business Payer' object that represents a Bill To URN */
export type BillToPayer = BusinessPayerBase & {
  /** A uniform resource name for a BillTo in the Payment Billing Authorization domain. */
  billTo: BillToURN;
};

export type BusinessPayer = (BillingAccountPayer | BillToPayer) & {
  /** Describes the type of 'Payer' object */
  type?: BusinessPayerType;
};

export interface Payers {
  /** A 'Payer' object that represents the person paying for the rental. */
  person: PersonPayer;
  /**
   * List of business payers ordered by responsibility for charges. For example, the first business payer may cover all, part, or none of a given charge, resulting in each unpaid or partially paid charge falling to the next business payer. Each charge cascades through all the business payers until paid in full, or until no business payers are left to pay it, in which case the charge becomes the responsibility of the person payer.
   * @maxItems 1
   */
  business?: BusinessPayer[];
  billingReference?: string;
}

/** Describes the type of source that provided the rate. */
export enum RateSourceType {
  NEGOTIATED = 'NEGOTIATED',
  RETAIL = 'RETAIL',
}

export enum RentalReasonType {
  BUSINESS = 'BUSINESS',
  LEISURE = 'LEISURE',
}

/**
 * The unique name for a `Rate Product`
 * @format ehi-subresource-urn
 * @pattern ^urn:com\.ehi:[a-zA-Z]+:rental\.rate:brand:(?:ALAMO|ENTERPRISE|NATIONAL):rateProduct:[a-zA-Z0-9\+\/\-\ ]+$
 * @example "urn:com.ehi:xqa:rental.rate:brand:ALAMO:rateProduct:WEB123"
 */
export type RateProductURN = string;

/**
 * The universally unique name for a **Rental Day Cycle**.
 * @format ehi-reference-urn
 * @pattern ^urn:com\.ehi:[A-Za-z0-9]+:reference:rental:rentalDayCycle:[A-Z0-9_]+$
 * @example "urn:com.ehi:xqa:reference:rental:rentalDayCycle:24_HOUR"
 */
export type RentalDayCycleURN = string;

/**
 * The unique identifier for a set of rate qualifications.
 * @format urn
 * @pattern ^urn:com\.ehi:[a-zA-Z0-9]+:rental\.rate:qualification:[0-9]+$
 * @example "urn:com.ehi:xqa:rental.rate:qualification:12345678"
 */
export type RateQualificationUrn = string;

export interface HoursOrDays {
  /** @example "DAYS" */
  unit?: 'HOURS' | 'DAYS';
  /**
   * @format int32
   * @max 999
   * @example 3
   */
  value?: number;
}

/** Requirements for a duration of time that a reservation must meet in order to qualify for a particular rate. */
export interface DurationRequirements {
  minimum?: HoursOrDays;
  maximum?: HoursOrDays;
}

/** A day of the week and time for which this requirement applies. */
export interface DayOfWeekAndTime {
  /** @example "FRIDAY" */
  dayOfWeek?: 'MONDAY' | 'TUESDAY' | 'WEDNESDAY' | 'THURSDAY' | 'FRIDAY' | 'SATURDAY' | 'SUNDAY';
  /**
   * A time expressed in hours and minutes.
   * @pattern ^([01]\d|2[0-3]):([0-5]\d)$
   * @example "14:43"
   */
  time?: string;
}

/** Required day/time range for the start or end of a reservation in order to qualify for a particular rate. The `earliestDayAndTime` or `latestDayAndTime` may not be present if the range is open-ended. */
export interface SchedulingRequirement {
  /** A day of the week and time for which this requirement applies. */
  earliestDayAndTime?: DayOfWeekAndTime;
  /** A day of the week and time for which this requirement applies. */
  latestDayAndTime?: DayOfWeekAndTime;
}

/** Requirements for the schedule of a reservation in order to receive a specific rate. The start or end may not be present if there are no corresponding scheduling requirements. */
export interface SchedulingRequirements {
  /** Required day/time range for the start or end of a reservation in order to qualify for a particular rate. The `earliestDayAndTime` or `latestDayAndTime` may not be present if the range is open-ended. */
  start?: SchedulingRequirement;
  /** Required day/time range for the start or end of a reservation in order to qualify for a particular rate. The `earliestDayAndTime` or `latestDayAndTime` may not be present if the range is open-ended. */
  end?: SchedulingRequirement;
}

/** Requirements related to days of the week that a reservation must meet in order to qualify for a particular rate. */
export interface DayOfTheWeekRequirement {
  /** @example "FRIDAY" */
  dayOfWeek?: 'MONDAY' | 'TUESDAY' | 'WEDNESDAY' | 'THURSDAY' | 'FRIDAY' | 'SATURDAY' | 'SUNDAY';
  /** Indicates a reservation must include this day of the week within the collection of days of the week that the reservation spans. If true, `mustExclude` will be false. */
  mustInclude?: boolean;
  /** Indicates a reservation must **not** include this day of the week within the collection of days of the week that the reservation spans. If true, `mustInclude` will be false. */
  mustExclude?: boolean;
  /** Indicates that in order to qualify for the current rate, the rental should not be ended on this day. If the rental is ended, the rate may change or an extra day may still be charged. */
  overnightRequired?: boolean;
}

/** The conditions that were met for the rates currently in effect for the Reservation. */
export interface RateQualifications {
  /** The unique identifier for a set of rate qualifications. */
  id?: RateQualificationUrn;
  /** Requirements for a duration of time that a reservation must meet in order to qualify for a particular rate. */
  durationRequirements?: DurationRequirements;
  /** Requirements for the schedule of a reservation in order to receive a specific rate. The start or end may not be present if there are no corresponding scheduling requirements. */
  schedulingRequirements?: SchedulingRequirements;
  /**
   * Requirements related to days of the week that a reservation must meet in order to qualify for a particular rate.
   * @maxItems 7
   * @minItems 7
   */
  dayOfTheWeekRequirement?: DayOfTheWeekRequirement[];
  /**
   * The minimum number of days that will apply to charge calculations when qualified for the rate, even if the rental is ended early and the actual rental duration is shorter.
   * @format int32
   * @min 1
   */
  minimumDaysCharged?: number;
}

export interface BaseRateSource {
  /** Describes the type of source that provided the rate. */
  type: RateSourceType;
  rentalReason?: RentalReasonType;
  /** The unique name for a `Rate Product` */
  rateProduct?: RateProductURN;
  /**
   * Date and time for when the quote was given.
   * @format date-time
   * @example "2019-09-19T17:57:08-06:00"
   */
  quoteDateTime?: string;
  rentalDayCycle?: RentalDayCycleURN;
  /** The conditions that were met for the rates currently in effect for the Reservation. */
  rateQualifications?: RateQualifications;
}

/**
 * Business Account URN
 * @format ehi-resource-urn
 * @pattern ^urn:com\.ehi:[a-zA-Z0-9]+:businessAccount:account:[a-zA-Z0-9]{2,8}$
 * @example "urn:com.ehi:xqa:businessAccount:account:XJ12345"
 */
export type BusinessAccountURN = string;

/**
 * The unique name for a `Rate Plan`
 * @format ehi-resource-urn
 * @pattern ^urn:com\.ehi:[a-zA-Z0-9]+:rental\.rate:ratePlan:[0-9]+$
 * @example "urn:com.ehi:xqa:rental.rate:ratePlan:1234567890"
 */
export type RatePlanURN = string;

/** A `Business Account` and related data that is used to negotiate `Rate`s. */
export type NegotiatedRateSource = BaseRateSource & {
  /** Business Account URN */
  account: BusinessAccountURN;
  /** Any additional data that should be included with the 'Business Account' */
  additionalInformation?: AdditionalInformation[];
  /** Indicator of whether the `Business Account` is prohibited from vehicle upgrades. */
  upgradeProhibited?: boolean;
  /** The unique name for a `Rate Plan` */
  ratePlan?: RatePlanURN;
};

/** A non-negotiated `RateSource`. */
export type RetailRateSource = BaseRateSource;

export type RateSource = (NegotiatedRateSource | RetailRateSource) & {
  /** Describes the type of source that provided the rate. */
  type?: RateSourceType;
};

/**
 * A uniform resource name for a coupon in the rental coupon domain.
 * @format ehi-resource-urn
 * @pattern ^urn:com\.ehi:[a-zA-Z0-9]+:rental\.coupon:coupon:[A-Z0-9]+$
 * @example "urn:com.ehi:xqa:rental.coupon:coupon:ABC999"
 */
export type CouponURN = string;

export enum CouponType {
  STANDARD = 'STANDARD',
  REDEMPTION = 'REDEMPTION',
}

export interface BaseCoupon {
  /** A uniform resource name for a coupon in the rental coupon domain. */
  couponID?: CouponURN;
  type: CouponType;
  /**
   * Indicates the number of instances a specific coupon has been applied.
   * @min 1
   * @max 7
   * @example 1
   */
  quantityApplied?: number;
  /**
   * Indicates if any issues should be overridden and ignored if they arise using the specified coupon.
   * @example false
   */
  overrideIssues?: boolean;
}

/** A standard coupon. */
export type StandardCoupon = BaseCoupon;

/** A redemption coupon. */
export type RedemptionCoupon = BaseCoupon & {
  /**
   * The number of points used by the `Renter` to obtain the redemption coupon. Will only be populated for Enterprise Plus or One Club.
   * @example 600
   */
  loyaltyPointsUsed?: number;
  /**
   * The number of free days that the `Renter` has applied. Will only be populated for Emerald Club.
   * @example 3
   */
  freeDaysUsed?: number;
  /**
   * May be masked, likely due to a negotiated rate source indicating that rates should be hidden.
   *
   *       ---
   * `Protected Data` may be masked under certain circumstance based on EHI's data masking rules.
   *
   * Masked Example: •••.••
   *
   * Protected Data Identifier: `secretRates`
   */
  fixedAmount?: CurrencyAmount;
  /**
   * Redemption Transaction Id
   * @example 4650816
   */
  transactionId?: number;
};

/** Coupon associated with a `Reservation`. Up to three coupons may be associated with a `Reservation`, with up to seven instances of each Coupon. */
export type Coupon = (StandardCoupon | RedemptionCoupon) & {
  type?: CouponType;
};

/** Travel Partner Program on a `Reservation` or `Reservation Editor` which allows the `Renter` to earn rewards with a qualifying partner for rentals, instead of loyalty points.  A fee may apply. */
export interface TravelPartnerProgram {
  /**
   * The name of the program the `Renter` is earning rewards with.
   * @example "AA"
   */
  program: string;
  /**
   * An individual's unique identifier for a travel partner program.
   * ---
   * `Protected Data` may be masked under certain circumstance based on EHI's data masking rules.
   *
   * Protected Data Identifier: `travelPartnerProgram.membershipId`
   *
   * Masked Example: "••••9789"
   * @maxLength 24
   * @example "123456789"
   */
  membershipId: string;
}

/**
 * The unique name for a `Note ID`, made from the note type and sequence ID
 * @format ehi-subresource-urn
 * @pattern ^urn:com\.ehi:[a-zA-Z0-9]+:rental\.booking:reservation:[0-9]{9,}:note:[a-zA-Z0-9\-_]+$
 * @example "urn:com.ehi:xqa:rental.booking:reservation:124789122:note:U1lTVEVNMQ"
 */
export type NoteIdURN = string;

/** Free form notes created by users or system generated notes */
export interface Note {
  /** The note type */
  type?: 'MANUAL' | 'SYSTEM';
  /** Text content of the note */
  content: string;
  /** Creator of the note */
  creator?: string;
  /**
   * Date and time the note was written
   * @format date-time
   */
  creationDateTime?: string;
  /**
   * Origin Application name of the note.
   * @maxLength 255
   * @example "Expedia"
   */
  originApplication?: string;
  /** The unique name for a `Note ID`, made from the note type and sequence ID */
  id?: NoteIdURN;
}

/** Alternate reservation source and identifier. */
export interface AlternateReferences {
  /**
   * An alternate reservation identifier for Replacement Rental systems.
   * @example "ABC123"
   */
  replacementRentalReference?: string;
  /**
   * Unique identifier for bookings which come from CRS
   * @minLength 6
   * @maxLength 6
   * @pattern ^[A-Z0-9]{6}$
   * @example "TQUBSH"
   */
  passengerNameRecordReference?: string;
}

export interface Attestation {
  /**
   * The date and time the attestation was accepted.
   * @format date-time
   * @example "2019-09-19T17:57:08.113Z"
   */
  createdDateTime?: string;
  /**
   * The method by which the attestation was attested
   * @example "ELECTRONICALLY_ACCEPTED"
   */
  method?: 'ELECTRONICALLY_ACCEPTED' | 'SIGNATURE_ON_FILE' | 'ELECTRONICALLY_SIGNED';
}

export interface Attestations {
  localAddendum?: Attestation;
}

/**
 * Indicates that some rates and charge amounts should be hidden from the customer.
 * This only occurs in certain situations, and always when the customer is not paying
 * for the rental directly.
 * @example false
 */
export type SecretRates = boolean;

/**
 * The universal name for a type of `GeneralCondition`.
 * @format ehi-reference-urn
 * @pattern ^urn:com\.ehi:[a-zA-Z0-9]+:reference:rental:generalConditionType:[a-zA-Z_]+$
 * @example "urn:com.ehi:xqa:reference:rental:generalConditionType:BEST_RATING"
 */
export type GeneralConditionTypeURN = string;

/** Text that provides information about the current rental, relevant to branch agents and others working with the rental. */
export interface GeneralCondition {
  /**
   * General condition text
   * @example "Vehicle must be returned with a full tank of gas"
   */
  text?: string;
  /** The universal name for a type of `GeneralCondition`. */
  type?: GeneralConditionTypeURN;
}

/**
 * Array of carrier safety identifiers associated with driver or company that is renting vehicle
 * @maxItems 15
 */
export type CarrierSafetyIdentifiers = string[];

/** The `Renter's` repair vehicle information. */
export interface RepairVehicle {
  /**
   * Vehicle year
   * @format int32
   * @example 2020
   */
  year?: number;
  /**
   * Vehicle make
   * @maxLength 40
   * @example "BUICK"
   */
  make?: string;
  /**
   * The model of the vehicle
   * @maxLength 40
   * @example "LACROSSE"
   */
  model?: string;
  /**
   * The vehicle color
   * @example "BLUE"
   */
  color?: string;
}

/** The type of `Shop`. */
export enum ShopType {
  KNOWN_SHOP = 'KNOWN_SHOP',
}

/** Base object that defines all common `Shop` properties. */
export interface BaseShop {
  /** The type of `Shop`. */
  type: ShopType;
}

/** Known `Shop` information. */
export type KnownShop = BaseShop & {
  /** Business Account URN */
  account: BusinessAccountURN;
};

export type Shop = KnownShop & {
  /** The type of `Shop`. */
  type?: ShopType;
};

/** Information regarding the loss of a vehicle. */
export interface RecordOfLoss {
  /**
   * The date of the incident in which the vehicle was considered a loss.
   * @format date
   * @example "2022-08-01"
   */
  dateOfLoss?: string;
}

/** The `Renter's` vehicle repair and shop information. */
export interface Repair {
  /** The `Renter's` repair vehicle information. */
  vehicle?: RepairVehicle;
  shop?: Shop;
  /** Information regarding the loss of a vehicle. */
  recordOfLoss?: RecordOfLoss;
}

/** Response containing retrieved reservation information. */
export interface ReservationBase {
  /** The unique name of a `Reservation` */
  reservation: ReservationURN;
  /** The type of Reservation. */
  type?: ReservationTypeURN;
  /** The unique name of a `Brand` */
  brand: BrandURN;
  /** The unique name of the `Lifecycle` current for the `Reservation`. */
  lifecycle: LifecycleUrn;
  /**
   * The status of a `Reservation` or the status that a `Reservation` would be if a `Reservation Editor` is saved.
   * * AWAITING_CONFIRMATION -  Awaiting for confirmation from a branch agent that the reservation can be fulfilled.
   * * INCOMPLETE - Reservation is missing data required or has data that is incompatible such that the reservation cannot be fulfilled in its current state.
   * * CONFIRMED - Reservation that was previously AWAITING_CONFIRMATION has been confirmed by a branch agent that the reservation can be fulfilled.
   * * SOLD - Reservation is in a state such that it can be fulfilled. No Branch Agent confirmation was required.
   * * FULFILLED – Reservation has had a rental agreement created from it. A reservation with this status cannot be modified or cancelled.
   * * MATCHED   – Reservation is matched to a ticket.
   * * DENIED    – Reservation has been denied, most likely because the renter was marked as do-not-rent.
   * * ABANDONED – Reservation was abandoned, not completed during the create process.
   * * NO_SHOW   – Reservation was not fulfilled, well past when it should have been.
   * * CANCELED  – Reservation was canceled by the renter.
   * * TRANSFERRED - Reservation has been transferred to another group.
   */
  status: StatusUrn;
  /** The main driver associated with a `Reservation` or `Reservation Editor`. The `Renter` is legally responsible for any damage or incidentals incurred during the rental. */
  renter: Renter;
  additionalDriver?: AdditionalDriver[];
  pickup?: Pickup;
  return?: Return;
  source: Source;
  pointOfSale?: PointOfSale;
  /**
   * ---
   *  `Protected Data` may be masked under certain circumstance based on EHI's data masking rules.
   *
   *  Protected Data Identifier: `contact`
   */
  contact: Contact;
  onRequest?: OnRequest[];
  cancellationInformation?: CancellationInformation;
  /** Remark is added to a reservation to pass along additional information that the branch might need to know. */
  remark?: Remark;
  payers?: Payers;
  rateSource?: RateSource;
  /**
   * @maxItems 3
   * @minItems 0
   */
  coupon?: Coupon[];
  /** Travel Partner Program on a `Reservation` or `Reservation Editor` which allows the `Renter` to earn rewards with a qualifying partner for rentals, instead of loyalty points.  A fee may apply. */
  travelPartnerProgram?: TravelPartnerProgram;
  note?: Note[];
  /** Alternate reservation source and identifier. */
  alternateReferences?: AlternateReferences;
  attestations?: Attestations;
  /**
   * Indicates that some rates and charge amounts should be hidden from the customer.
   * This only occurs in certain situations, and always when the customer is not paying
   * for the rental directly.
   */
  secretRates?: SecretRates;
  /** List of conditions/provisions set up on the contract or product */
  generalConditions?: GeneralCondition[];
  /** If there is a previously booked Enterprise Mobility reservation number that is associated to the current reservation it will be present here */
  priorReservationNumber?: ReservationURN;
  /** Array of carrier safety identifiers associated with driver or company that is renting vehicle */
  carrierSafetyIdentifiers?: CarrierSafetyIdentifiers;
  /** The `Renter's` vehicle repair and shop information. */
  renterRepair?: Repair;
}

/** Indicates the way that charges were calculated for this line item. */
export enum ChargeLineType {
  DISTANCE = 'DISTANCE',
  DURATION = 'DURATION',
  PER_RENTAL = 'PER_RENTAL',
  PERCENTAGE = 'PERCENTAGE',
  VOLUME = 'VOLUME',
  VARIABLE = 'VARIABLE',
}

/**
 * May be masked, likely due to a negotiated rate source indicating that rates should be hidden.
 *       ---
 * `Protected Data` may be masked under certain circumstance based on EHI's data masking rules. Masked Example: •••.••
 * Protected Data Identifier: `secretRates`
 */
export type ReadOnlyCurrencyAmount = (UnmaskedCurrencyAmount | MaskedCurrencyAmount) & {
  type?: CurrencyAmountType;
};

/**
 * The unique name for a category of `Charges`.
 * @format ehi-reference-urn
 * @pattern ^urn:com\.ehi:[a-zA-Z0-9]+:reference:billing:chargeCategory:[A-Z]+(_[A-Z]+)*$
 * @example "urn:com.ehi:xqa:reference:billing:chargeCategory:MIS"
 */
export type ChargeCategoryURN = string;

/**
 * The unique name for a type of `Charge`.
 * @format ehi-reference-urn
 * @pattern ^urn:com\.ehi:[a-zA-Z0-9]+:reference:billing:chargeType:[A-Z]{2}\d{5}$
 * @example "urn:com.ehi:xqa:reference:billing:chargeType:ET00051"
 */
export type ChargeTypeURN = string;

export interface BaseChargeLine {
  /** Indicates the way that charges were calculated for this line item. */
  type?: ChargeLineType;
  /**
   * May be masked, likely due to a negotiated rate source indicating that rates should be hidden.
   *       ---
   * `Protected Data` may be masked under certain circumstance based on EHI's data masking rules. Masked Example: •••.••
   * Protected Data Identifier: `secretRates`
   */
  amount?: ReadOnlyCurrencyAmount;
  isIncludedInTimeAndMileage?: boolean;
  chargeCategory?: ChargeCategoryURN;
  chargeType?: ChargeTypeURN;
  /**
   * The unique name for the tax or surcharge type, if applicable.
   * @format ehi-reference-urn
   * @pattern ^urn:com\.ehi:[a-zA-Z0-9]+:reference:rental:(tax|surcharge):[a-zA-Z0-9]+
   * @example "urn:com.ehi:xqa:reference:rental:tax:US1TX2"
   */
  taxOrSurcharge?: string;
  isTaxable?: boolean;
}

export interface Volume {
  unit?: 'GALLON' | 'LITER';
  /** @example 10.1 */
  value?: number;
}

export type VolumeLineItem = BaseChargeLine & {
  volume?: Volume;
  /** A Currency Amount that is not masked. */
  costPerVolume?: UnmaskedCurrencyAmount;
};

export interface Duration {
  unit?: 'HOUR' | 'DAY' | 'WEEK' | 'MONTH';
  /** @example 10 */
  value?: number;
}

export type DurationLineItem = BaseChargeLine & {
  duration?: Duration;
  /**
   * May be masked, likely due to a negotiated rate source indicating that rates should be hidden.
   *
   *       ---
   * `Protected Data` may be masked under certain circumstance based on EHI's data masking rules.
   *
   * Masked Example: •••.••
   *
   * Protected Data Identifier: `secretRates`
   */
  costPerDuration?: CurrencyAmount;
};

export type DistanceLineItem = BaseChargeLine & {
  /** Distance information */
  distance?: Distance;
  /** A Currency Amount that is not masked. */
  costPerDistance?: UnmaskedCurrencyAmount;
};

export type PerRentalLineItem = BaseChargeLine;

export type PercentageLineItem = BaseChargeLine & {
  /** @example 0.25 */
  percentage?: number;
  /** A Currency Amount that is not masked. */
  baseAmount?: UnmaskedCurrencyAmount;
};

export type VariableLineItem = BaseChargeLine;

export type ChargeLine = (
  | VolumeLineItem
  | DurationLineItem
  | DistanceLineItem
  | PerRentalLineItem
  | PercentageLineItem
  | VariableLineItem
) & {
  /** Indicates the way that charges were calculated for this line item. */
  type?: ChargeLineType;
};

export interface Charges {
  chargeLine?: ChargeLine[];
  /**
   * May be masked, likely due to a negotiated rate source indicating that rates should be hidden.
   *       ---
   * `Protected Data` may be masked under certain circumstance based on EHI's data masking rules. Masked Example: •••.••
   * Protected Data Identifier: `secretRates`
   */
  totalCharges?: ReadOnlyCurrencyAmount;
  /**
   * Length of rental in days
   * @format int32
   * @example 24
   */
  calculatedDurationDays?: number;
}

export interface InventoryReporting {
  /**
   * This value determines whether to perform a One Way inventory check against the One Way Limit.
   * @example false
   */
  hasOneWayLimit?: boolean;
  /**
   * This value determines whether a freesell override is being used.
   * @example true
   */
  isUnrestricted?: boolean;
}

export interface VehicleClassSelectionWithoutRate {
  /** The vehicle class that is preferred by the `Renter`, in the form of a SIPP code. */
  preferred: VehicleClassURN;
  /** The rate type selected */
  type: 'PAYLATER' | 'PREPAY';
  /** The vehicle class reserved by the `Renter`, in the form of a SIPP code. */
  reserved?: VehicleClassURN;
  /** The vehicle class the `Renter` was charged for, in the form of a SIPP code. For example, the `Renter` may request an ICAR, but be eligible for a free upgrade to an FCAR, in which case the chargedVehicleClass would reflect the vehicle class they actually get charged for. */
  charged?: VehicleClassURN;
  /** The vehicle class that the customer is expected to drive off the rental lot, in the form of a SIPP code. */
  expectedDriven?: VehicleClassURN;
  /**
   * Time and mileage rate for the vehicle before discounts are applied.
   * @format currency
   * @min 0
   * @example 100.25
   */
  totalSeatPrice?: number;
  reporting?: InventoryReporting;
}

export enum VehicleRateType {
  PACKAGE_RATE = 'PACKAGE_RATE',
  VARIABLE_RATE = 'VARIABLE_RATE',
  PERIODIC_RATE = 'PERIODIC_RATE',
}

/** The `Amount` is always the amount that actively applies.  It may or may not match the `Original Amount` */
export interface PotentiallyAdjustableAmount {
  rateAdjustment?: 'NO_CHANGE' | 'ALL_CHANGE' | 'DECREASE_ONLY';
  /**
   * @format currency
   * @example 120
   */
  originalAmount?: number;
  /**
   * @format currency
   * @min 0
   * @example 120
   */
  amount: number;
  currencyCode?: CurrencyCode;
}

/** Describes the type of rate applicable to a reservation based on time duration. */
export interface BaseDuration {
  type?: VehicleRateType;
}

/** A rate duration describing a defined period of time */
export type PeriodicDuration = BaseDuration & {
  /**
   * @format int32
   * @min 0
   */
  hours?: number;
  /** @min 0 */
  days?: number;
  /**
   * @format int32
   * @min 0
   */
  weeks?: number;
  /**
   * @format int32
   * @min 0
   */
  months?: number;
};

/** Describes a rate duration applied for the whole reservation without individual time based rate amounts. */
export type PackageDuration = BaseDuration;

/** A rate duration with variable values depending on the day of the week */
export type VariableDuration = BaseDuration & {
  daysOfWeek?: VariableDuration[];
  /**
   * @format int32
   * @min 0
   * @example 2
   */
  weeks?: number;
  /**
   * @format int32
   * @min 0
   * @example 1
   */
  months?: number;
};

/** Describes the type of rate applicable to a reservation based on time duration. */
export type VehicleRateDuration = (PeriodicDuration | PackageDuration | VariableDuration) & {
  type?: VehicleRateType;
};

/**
 * Indicates if one-way rental is for Local or All One-Ways
 * @example "LOCAL"
 */
export enum OneWayCodeType {
  LOCAL = 'LOCAL',
  ONE_WAY = 'ONE_WAY',
}

/**
 * Different included distance adjustment types
 * @example "LIMITED"
 */
export enum IncludedDistanceType {
  LIMITED = 'LIMITED',
  UNLIMITED = 'UNLIMITED',
  RENTAL = 'RENTAL',
}

export enum DistanceAdjustmentType {
  NO_CHANGE = 'NO_CHANGE',
  ALL_CHANGE = 'ALL_CHANGE',
}

/** The distance that is included at no extra charge. */
export interface BaseIncludedDistance {
  /** Different included distance adjustment types */
  type?: IncludedDistanceType;
  distanceAdjustment?: DistanceAdjustmentType;
  unit?: DistanceUnit;
}

/**
 * The `Value` represents what actively applies.
 * It may or may not match the `Original Value`.
 * The `Original Value` is the amount prior to changes.
 * The `Original Value` may be null if the `Included Distance` type was changed from the original type.
 */
export interface PotentiallyAdjustableDistance {
  /**
   * @format int32
   * @min 0
   * @max 9999
   * @example 5
   */
  originalValue?: number;
  /**
   * @format int32
   * @min 0
   * @max 9999
   * @example 42
   */
  value?: number;
}

/**
 * Limited Distance allotment based on rental duration time.
 * Any distance driven beyond what is allotted will result in Excess Distance Charges.
 */
export type LimitedDistance = BaseIncludedDistance & {
  /**
   * The `Value` represents what actively applies.
   * It may or may not match the `Original Value`.
   * The `Original Value` is the amount prior to changes.
   * The `Original Value` may be null if the `Included Distance` type was changed from the original type.
   */
  hourly?: PotentiallyAdjustableDistance;
  /**
   * The `Value` represents what actively applies.
   * It may or may not match the `Original Value`.
   * The `Original Value` is the amount prior to changes.
   * The `Original Value` may be null if the `Included Distance` type was changed from the original type.
   */
  daily?: PotentiallyAdjustableDistance;
  /**
   * The `Value` represents what actively applies.
   * It may or may not match the `Original Value`.
   * The `Original Value` is the amount prior to changes.
   * The `Original Value` may be null if the `Included Distance` type was changed from the original type.
   */
  weekly?: PotentiallyAdjustableDistance;
  /**
   * The `Value` represents what actively applies.
   * It may or may not match the `Original Value`.
   * The `Original Value` is the amount prior to changes.
   * The `Original Value` may be null if the `Included Distance` type was changed from the original type.
   */
  monthly?: PotentiallyAdjustableDistance;
};

/** Unlimited Distance allotted for the rental. */
export type UnlimitedDistance = BaseIncludedDistance;

/** The distance allotted for the entire rental. Any distance driven beyond this will result in Excess Distance Charges. */
export type RentalDistance = BaseIncludedDistance & {
  /**
   * The `Value` represents what actively applies.
   * It may or may not match the `Original Value`.
   * The `Original Value` is the amount prior to changes.
   * The `Original Value` may be null if the `Included Distance` type was changed from the original type.
   */
  rental?: PotentiallyAdjustableDistance;
};

/** The distance that is included at no extra charge. */
export type IncludedDistance = (LimitedDistance | UnlimitedDistance | RentalDistance) & {
  /** Different included distance adjustment types */
  type?: IncludedDistanceType;
};

/**
 * The universally unique name for a specific **Rate Adjustment Reason**.
 * @format ehi-reference-urn
 * @pattern ^urn:com\.ehi:[a-z]+:reference:rental:rateAdjustmentReason:[A-Z]+(_[A-Z]+)*$
 * @example "urn:com.ehi:xga:reference:rental:rateAdjustmentReason:CUSTOMER_SATISFACTION"
 */
export type RateAdjustmentReasonURN = string;

/** An Object that defines all common `VehicleRate` properties */
export interface BaseVehicleRate {
  type?: VehicleRateType;
  /** The `Amount` is always the amount that actively applies.  It may or may not match the `Original Amount` */
  amountPerExcessDistance?: PotentiallyAdjustableAmount;
  totalIncludedDistance?: Distance;
  oneWayCharge?: UnmaskedCurrencyAmount;
  /** Describes the type of rate applicable to a reservation based on time duration. */
  duration?: VehicleRateDuration;
  /** Indicates if one-way rental is for Local or All One-Ways */
  oneWayCode?: OneWayCodeType;
  /** The distance that is included at no extra charge. */
  distance?: IncludedDistance;
  /** The universally unique name for a specific **Rate Adjustment Reason**. */
  adjustmentReason?: RateAdjustmentReasonURN;
}

/** Package rates for a specific period of time. */
export interface PackageDistance {
  /**
   * Distance Per rental.
   * @min 0
   * @max 9999
   */
  distancePerRental?: number;
  unit?: DistanceUnit;
}

/** Package rates that are masked to the client so that they cannot present them to the customer.  Normally used for travel agent or voucher reservations. */
export interface MaskablePackagePeriodicVehicleRate {
  /**
   * May be masked, likely due to a negotiated rate source indicating that rates should be hidden.
   *
   *       ---
   * `Protected Data` may be masked under certain circumstance based on EHI's data masking rules.
   *
   * Masked Example: •••.••
   *
   * Protected Data Identifier: `secretRates`
   */
  costPerHour?: CurrencyAmount;
  /**
   * May be masked, likely due to a negotiated rate source indicating that rates should be hidden.
   *
   *       ---
   * `Protected Data` may be masked under certain circumstance based on EHI's data masking rules.
   *
   * Masked Example: •••.••
   *
   * Protected Data Identifier: `secretRates`
   */
  costPerDay?: CurrencyAmount;
  /**
   * May be masked, likely due to a negotiated rate source indicating that rates should be hidden.
   *
   *       ---
   * `Protected Data` may be masked under certain circumstance based on EHI's data masking rules.
   *
   * Masked Example: •••.••
   *
   * Protected Data Identifier: `secretRates`
   */
  costPerWeek?: CurrencyAmount;
}

/** Package rates for a specific period of time. */
export interface PeriodicPackageVehicleRate {
  /** A Currency Amount that is not masked. */
  costPerHour?: UnmaskedCurrencyAmount;
  /** A Currency Amount that is not masked. */
  costPerDay?: UnmaskedCurrencyAmount;
  /** A Currency Amount that is not masked. */
  costPerWeek?: UnmaskedCurrencyAmount;
}

/** Package rate is set for a specific number of days included in the package. */
export type PackageVehicleRate = BaseVehicleRate & {
  /** The number of days that are included in the package. */
  packageDays?: number;
  /** Package rates for a specific period of time. */
  includedDistance?: PackageDistance;
  basePackageCost?: CurrencyAmount;
  /** Package rates that are masked to the client so that they cannot present them to the customer.  Normally used for travel agent or voucher reservations. */
  extraTimeRates?: MaskablePackagePeriodicVehicleRate;
  /** Package rates for a specific period of time. */
  extensionTimeRates?: PeriodicPackageVehicleRate;
  /** Package rates for a specific period of time. */
  includedExtensionDistance?: PackageDistance;
};

/**
 * The distance that is provided for that period of time.
 * @deprecated
 */
export interface PeriodicDistance {
  /**
   * Distance Per Hour.
   * @min 0
   * @max 9999
   */
  distancePerHour?: number;
  /**
   * Distance Per Day.
   * @min 0
   * @max 9999
   */
  distancePerDay?: number;
  /**
   * Distance Per Week.
   * @min 0
   * @max 9999
   */
  distancePerWeek?: number;
  /**
   * Distance Per Month.
   * @min 0
   * @max 9999
   */
  distancePerMonth?: number;
  unit?: DistanceUnit;
}

/**
 * Variable Rates offer a specific daily OR hourly rate for each day of the week.
 * These are by their very nature daily / hourly rates, and Variable Rates can additionally have a single Weekly Rate and Monthly Rate.
 */
export interface VariableDayVehicleRate {
  /** The day of the week. */
  dayOfWeekRate?: 'SUNDAY' | 'MONDAY' | 'TUESDAY' | 'WEDNESDAY' | 'THURSDAY' | 'FRIDAY' | 'SATURDAY';
  /** A Currency Amount that is not masked. */
  costPerHour?: UnmaskedCurrencyAmount;
  /** A Currency Amount that is not masked. */
  costPerDay?: UnmaskedCurrencyAmount;
}

/** A rate that can change based upon the day of the week. */
export type VariableVehicleRate = BaseVehicleRate & {
  /** The distance that is provided for that period of time. */
  includedDistance?: PeriodicDistance;
  /**
   * @maxItems 7
   * @minItems 7
   */
  dayOfWeekRate?: VariableDayVehicleRate[];
  /** The `Amount` is always the amount that actively applies.  It may or may not match the `Original Amount` */
  costPerWeek?: PotentiallyAdjustableAmount;
  /** The `Amount` is always the amount that actively applies.  It may or may not match the `Original Amount` */
  costPerMonth?: PotentiallyAdjustableAmount;
};

/** Any extra distance that is provided for that period of time. */
export interface ExtraPeriodicDistance {
  /**
   * Distance Per Hour.
   * @min 0
   * @max 9999
   */
  distancePerHour?: number;
  /**
   * Distance Per Day.
   * @min 0
   * @max 9999
   */
  distancePerDay?: number;
  unit?: DistanceUnit;
}

/** The rate charged for any extra time if the vehicle is not returned at the specified time. */
export interface ExtraTimeRate {
  /** The `Amount` is always the amount that actively applies.  It may or may not match the `Original Amount` */
  costPerHour?: PotentiallyAdjustableAmount;
  /** The `Amount` is always the amount that actively applies.  It may or may not match the `Original Amount` */
  costPerDay?: PotentiallyAdjustableAmount;
  /** Any extra distance that is provided for that period of time. */
  extraIncludedDistance?: ExtraPeriodicDistance;
}

/**
 * A rate that charges based upon length of rental, using one or more time-based amounts.
 * Anywhere from one to all of the time periods will be included in the rate.
 */
export type PeriodicVehicleRate = BaseVehicleRate & {
  /** The distance that is provided for that period of time. */
  includedDistance?: PeriodicDistance;
  /** This is the discount that will be applied to the time and distance rate when calculating the charges. */
  timeAndDistanceDiscount?: number;
  /** The `Amount` is always the amount that actively applies.  It may or may not match the `Original Amount` */
  costPerHour?: PotentiallyAdjustableAmount;
  /** The `Amount` is always the amount that actively applies.  It may or may not match the `Original Amount` */
  costPerDay?: PotentiallyAdjustableAmount;
  /** The `Amount` is always the amount that actively applies.  It may or may not match the `Original Amount` */
  costPerWeek?: PotentiallyAdjustableAmount;
  /** The `Amount` is always the amount that actively applies.  It may or may not match the `Original Amount` */
  costPerMonth?: PotentiallyAdjustableAmount;
  /** The rate charged for any extra time if the vehicle is not returned at the specified time. */
  extraTimeRate?: ExtraTimeRate;
};

/** An object that may be one of three different Vehicle Rate types. */
export type VehicleRate = (PeriodicVehicleRate | VariableVehicleRate | PackageVehicleRate) & {
  type?: VehicleRateType;
};

export type VehicleClassSelection = VehicleClassSelectionWithoutRate & {
  /** An object that may be one of three different Vehicle Rate types. */
  rate?: VehicleRate;
};

/** Common elements for `AddOns` such as `Equipment` or `Protections`, without the `Rate`. */
export interface Addon {
  chargeType?: ChargeTypeURN;
  /**
   * Whether this `AddOn`
   * - must be selected (MANDATORY),
   * - is included in the contract rate (INCLUDED),
   * - is added at the `Renter's` discretion (OPTIONAL),
   * - is indicated by the Business Account on the Rate Source that it shouldn't be offered (NOT_NORMALLY_OFFERED)
   */
  selectability?: 'INCLUDED' | 'MANDATORY' | 'OPTIONAL' | 'NOT_NORMALLY_OFFERED';
}

/** A physical item or service, other than the vehicle itself, that is offered as an add-on to a vehicle rental. */
export type EquipmentWithoutRate = Addon & {
  /** The universally unique name for a type of **Equipment** */
  type?: EquipmentTypeURN;
  /** Indicates whether or not the Equipment is on request */
  isOnRequest?: boolean;
  /** Indicates whether or not the equipment is adaptive.  This value will equate to true if the type matches one of the adaptive equipment, and false otherwise. */
  adaptive?: boolean;
};

export enum RateType {
  PER_RENTAL = 'PER_RENTAL',
  SIMPLE_PERIODIC = 'SIMPLE_PERIODIC',
  MAX_PERIODIC = 'MAX_PERIODIC',
  VOLUMETRIC = 'VOLUMETRIC',
  DISTANCE = 'DISTANCE',
}

/** A `Rate` which applies a single cost based on the entire rental. */
export interface PerRentalRate {
  type: RateType;
  /** The `Amount` is always the amount that actively applies.  It may or may not match the `Original Amount` */
  costPerRental: PotentiallyAdjustableAmount;
}

/** A `Rate` which applies based on a per-period basis. The rate can potentially be applied by hour, day, week, month, or a combination of multiple periods. */
export interface SimplePeriodicRate {
  type: RateType;
  /** The `Amount` is always the amount that actively applies.  It may or may not match the `Original Amount` */
  costPerHour?: PotentiallyAdjustableAmount;
  /** The `Amount` is always the amount that actively applies.  It may or may not match the `Original Amount` */
  costPerDay?: PotentiallyAdjustableAmount;
  /** The `Amount` is always the amount that actively applies.  It may or may not match the `Original Amount` */
  costPerWeek?: PotentiallyAdjustableAmount;
  /** The `Amount` is always the amount that actively applies.  It may or may not match the `Original Amount` */
  costPerMonth?: PotentiallyAdjustableAmount;
}

/** A `Rate` which applies based on a per-period basis. If the per-period application exceeds the `maximumCostPerRental`, then the amount charged will be the `maximumCostPerRental` */
export type MaxPeriodicRate = UtilRequiredKeys<SimplePeriodicRate, 'type'> & {
  type: RateType;
  /** A Currency Amount that is not masked. */
  maximumCostPerRental: UnmaskedCurrencyAmount;
};

/** A `Rate` which applies on a per-unit-of-volume basis. */
export interface VolumetricRate {
  type: RateType;
  unit: 'GALLON' | 'LITER';
  /** The `Amount` is always the amount that actively applies.  It may or may not match the `Original Amount` */
  costPerVolume: PotentiallyAdjustableAmount;
}

/** A `Rate` which applies on a per-unit-of-distance basis. */
export interface DistanceRate {
  type: RateType;
  unit: DistanceUnit;
  /** The `Amount` is always the amount that actively applies.  It may or may not match the `Original Amount` */
  costPerDistance: PotentiallyAdjustableAmount;
}

export type Rate = (PerRentalRate | SimplePeriodicRate | MaxPeriodicRate | VolumetricRate | DistanceRate) & {
  type?: RateType;
};

export type Equipment = EquipmentWithoutRate & {
  rate?: Rate;
};

/**
 * The universally unique name for a **Protection Type**
 * @format ehi-reference-urn
 * @pattern ^urn:com\.ehi:[A-Za-z0-9]+:reference:rental:protectionType:[A-Z]{3}:[A-Z]+([A-Z0-9&\.-_]+)*$
 * @example "urn:com.ehi:xqa:reference:rental:protectionType:USA:CDW1"
 */
export type ProtectionTypeURN = string;

/**
 * The universally unique name for a **Protection Category**
 * @format ehi-reference-urn
 * @pattern ^urn:com\.ehi:[A-Za-z0-9]+:reference:rental:protectionCategory:[A-Z]+(_[A-Z]+)*$
 * @example "urn:com.ehi:xqa:reference:rental:protectionCategory:PAI"
 */
export type ProtectionCategoryURN = string;

/** An add-on option offered to the renter to reduce the chance of unexpected costs to the renter during a vehicle rental. */
export type ProtectionWithoutRate = Addon & {
  /** The universally unique name for a **Protection Type** */
  type: ProtectionTypeURN;
  category?: ProtectionCategoryURN;
};

export type Protection = ProtectionWithoutRate & {
  rate?: Rate;
};

/**
 * The unique name for a type of `Ancillary Fee`
 * @format ehi-reference-urn
 * @pattern ^urn:com\.ehi:[a-z]+:reference:rental:ancillaryType:[a-zA-Z0-9_-]+$
 * @example "urn:com.ehi:xqa:reference:rental:ancillaryType:YOUNG_DRIVER"
 */
export type AncillaryFeeTypeURN = string;

export interface AncillaryFee {
  rate?: Rate;
  /** The unique name for a type of `Ancillary Fee` */
  type?: AncillaryFeeTypeURN;
  chargeType?: ChargeTypeURN;
}

export type SimpleAncillaryFee = AncillaryFee & object;

export type FuelAncillaryFee = AncillaryFee & object;

export type DriverAncillaryFee = AncillaryFee & object;

export type AdditionalDriverAncillaryFee = DriverAncillaryFee & {
  isIncludedInTimeAndMileage?: boolean;
};

export type YoungDriverAncillaryFee = DriverAncillaryFee & object;

export interface DistanceFee {
  /** Distance information */
  distance?: Distance;
  /** A Currency Amount that is not masked. */
  flatFee?: UnmaskedCurrencyAmount;
}

/**
 * A fee associated with delivering a vehicle to a location or collecting a vehicle from a location.
 * ## Application of Fee
 * The distance driven will be represented as `Pickup` or `Return`'s `Destination` `Distance`
 *
 * If the amount of distance driven is less than `freeDistance` then no charge will apply.
 *
 * If the amount of distance driven is more than `freeDistance`, then `freeDistance` will be subtracted from the total distance driven.
 * The resulting distance will be considered unaccounted for and used for subsequent calculations.
 *
 * If there is at least 1 `additionalDistanceFee` and there is still distance unaccounted for then the first `additionalDistanceFee`'s `flatFee` will be applied.
 * The first `additionalDistanceFee` distance will be subtracted from the unaccounted for distance.
 *
 * If there is a second `additionalDistanceFee` and there is still distance unaccounted for, then the second `additionalDistanceFee`'s `flatFee` will be applied.
 * The second `additionalDistanceFee` distance will be subtracted from the unaccounted for distance.
 *
 * If there is still distance unaccounted for, then the the rate will apply for the remaining distance.
 */
export type DeliveryOrCollectionAncillaryFee = AncillaryFee & {
  /** Distance information */
  freeDistance?: Distance;
  /** @maxLength 2 */
  additionalDistanceFee?: DistanceFee[];
  zone?: 'ONE' | 'TWO';
};

/** A list of applied fee types organized by their type. */
export interface AppliedAncillaryFees {
  /** A fee with an associated rate that is time based. */
  simple?: SimpleAncillaryFee[];
  fuelServiceOption?: FuelAncillaryFee;
  /** A fee with an associated rate that is based on the number of drivers. */
  additionalDriver?: AdditionalDriverAncillaryFee[];
  /** A fee with an associated rate that is based on the age of the driver. */
  youngDriver?: YoungDriverAncillaryFee[];
  /**
   * A fee associated with delivering a vehicle to a location or collecting a vehicle from a location.
   * ## Application of Fee
   * The distance driven will be represented as `Pickup` or `Return`'s `Destination` `Distance`
   *
   * If the amount of distance driven is less than `freeDistance` then no charge will apply.
   *
   * If the amount of distance driven is more than `freeDistance`, then `freeDistance` will be subtracted from the total distance driven.
   * The resulting distance will be considered unaccounted for and used for subsequent calculations.
   *
   * If there is at least 1 `additionalDistanceFee` and there is still distance unaccounted for then the first `additionalDistanceFee`'s `flatFee` will be applied.
   * The first `additionalDistanceFee` distance will be subtracted from the unaccounted for distance.
   *
   * If there is a second `additionalDistanceFee` and there is still distance unaccounted for, then the second `additionalDistanceFee`'s `flatFee` will be applied.
   * The second `additionalDistanceFee` distance will be subtracted from the unaccounted for distance.
   *
   * If there is still distance unaccounted for, then the the rate will apply for the remaining distance.
   */
  delivery?: DeliveryOrCollectionAncillaryFee;
  /**
   * A fee associated with delivering a vehicle to a location or collecting a vehicle from a location.
   * ## Application of Fee
   * The distance driven will be represented as `Pickup` or `Return`'s `Destination` `Distance`
   *
   * If the amount of distance driven is less than `freeDistance` then no charge will apply.
   *
   * If the amount of distance driven is more than `freeDistance`, then `freeDistance` will be subtracted from the total distance driven.
   * The resulting distance will be considered unaccounted for and used for subsequent calculations.
   *
   * If there is at least 1 `additionalDistanceFee` and there is still distance unaccounted for then the first `additionalDistanceFee`'s `flatFee` will be applied.
   * The first `additionalDistanceFee` distance will be subtracted from the unaccounted for distance.
   *
   * If there is a second `additionalDistanceFee` and there is still distance unaccounted for, then the second `additionalDistanceFee`'s `flatFee` will be applied.
   * The second `additionalDistanceFee` distance will be subtracted from the unaccounted for distance.
   *
   * If there is still distance unaccounted for, then the the rate will apply for the remaining distance.
   */
  collection?: DeliveryOrCollectionAncillaryFee;
}

/** A fee associated with the presence of an Equipment or Protection addon. */
export type ContingentFee = AncillaryFee;

/** A collection of ancillary fee add on types. */
export interface AncillaryFees {
  /** A list of applied fee types organized by their type. */
  applied?: AppliedAncillaryFees;
  contingent?: ContingentFee[];
}

/** Indicates whether the user wishes to add fuel service or not. */
export type FuelService = boolean;

/** Additional products added to the reservation. */
export interface AddOns {
  equipment?: Equipment[];
  /** List of protection add-ons tied to the reservation. */
  protections?: Protection[];
  /** A collection of ancillary fee add on types. */
  ancillaryFees?: AncillaryFees;
  /** Indicates whether the user wishes to add fuel service or not. */
  fuelService?: FuelService;
}

/**
 * The unique identifier for a reason an action is not permitted.
 * @format ehi-reference-urn
 * @pattern ^urn:com\.ehi:[a-zA-Z0-9]+:reference:rental:booking:reservation:action:notPermittedReason:[A-Z]+(_[A-Z]+)*$
 * @example "urn:com.ehi:xqa:reference:rental:booking:reservation:action:notPermittedReason:INVALID_STATUS"
 */
export type NotPermittedReasonURN = string;

/** Describes an action that can or cannot be taken on a reservation and a reason as to why. */
export interface Action {
  /** Value denoting whether the action is permitted or not. */
  permitted?: boolean;
  /**
   * Array containing the list of reasons an action would not be permitted.
   * If the action is permitted this list will be empty
   */
  reasons?: NotPermittedReasonURN[];
}

/** Contains the actions that could possibly be taken on a reservation. */
export interface Actions {
  /** Describes an action that can or cannot be taken on a reservation and a reason as to why. */
  cancel?: Action;
}

/** Response containing retrieved reservation information. */
export type Reservation = ReservationBase & {
  charges?: Charges;
  vehicleClassSelection?: VehicleClassSelection;
  /** Additional products added to the reservation. */
  addOns?: AddOns;
  /** Contains the actions that could possibly be taken on a reservation. */
  actions?: Actions;
};

/** Additional products added to the reservation. */
export interface AddOnsWithoutRates {
  equipment?: EquipmentWithoutRate[];
  /** List of protection add-ons tied to the reservation. */
  protections?: ProtectionWithoutRate[];
  /** Indicates whether the user wishes to add fuel service or not. */
  fuelService?: FuelService;
}

/** Information required to re-create pricing quote with rate adjustments */
export interface RateAdjustment {
  /**
   * Charge Activity Code
   * @maxLength 2
   * @example "AS"
   */
  chargeActivityCode?: string;
  /** The unique name for a type of `Charge`. */
  chargeType?: ChargeTypeURN;
  /**
   * auxiliaryKey needed to re-create pricing quote
   * @example "02D"
   */
  auxiliaryKey?: string;
  /**
   * The unit type.
   * @maxLength 1
   * @example "H"
   */
  rateUnitType?: string;
  /**
   * The rate amount that has been adjusted.
   * @format currency
   * @example 112.25
   */
  rateAmount?: number;
  /**
   * The rate amount before it had been adjusted.
   * @format currency
   * @example 123.25
   */
  originalRateAmount?: number;
}

export interface BaseIncludedDistanceAdjustment {
  /** Different included distance adjustment types */
  type?: IncludedDistanceType;
  unit?: DistanceUnit;
}

export type LimitedDistanceAdjustment = BaseIncludedDistanceAdjustment & {
  /**
   * The saved adjustment to the distance per hour allotted before excess distance charges are applied.
   * @format int32
   * @min 0
   * @max 9999
   */
  hourly?: number;
  /**
   * The saved adjustment to the distance per day allotted before excess distance charges are applied.
   * @format int32
   * @min 0
   * @max 9999
   */
  daily?: number;
  /**
   * The saved adjustment to the distance per week allotted before excess distance charges are applied.
   * @format int32
   * @min 0
   * @max 9999
   */
  weekly?: number;
  /**
   * The saved adjustment to the distance per month allotted before excess distance charges are applied.
   * @format int32
   * @min 0
   * @max 9999
   */
  monthly?: number;
};

export type UnlimitedDistanceAdjustment = BaseIncludedDistanceAdjustment;

export type RentalDistanceAdjustment = BaseIncludedDistanceAdjustment & {
  /**
   * The saved adjustment to the distance allotted for the entire rental.
   * @format int32
   * @min 0
   * @max 9999
   */
  rental?: number;
};

/**
 * The saved included distance adjustment made to the reservation.
 * ## LIMITED
 * Distance per hour, day, week, or month allotted before excess distance charges are applied.
 *
 * ## UNLIMITED
 * Unlimited distance is allotted for the rental with no Excess Distance Charges.
 *
 * ## RENTAL
 * The distance allotted for the entire rental. Any distance driven beyond this will result in Excess Distance Charges.
 */
export type IncludedDistanceAdjustment = (
  | LimitedDistanceAdjustment
  | UnlimitedDistanceAdjustment
  | RentalDistanceAdjustment
) & {
  /** Different included distance adjustment types */
  type?: IncludedDistanceType;
};

/** Response containing retrieved reservation information. Will not contain any rates or charges. */
export type ReservationWithoutRatesOrCharges = ReservationBase & {
  vehicleClassSelection?: VehicleClassSelectionWithoutRate;
  /** Additional products added to the reservation. */
  addOns?: AddOnsWithoutRates;
  rateAdjustment?: RateAdjustment[];
  /** Contains the actions that could possibly be taken on a reservation. */
  actions?: Actions;
  /**
   * The saved included distance adjustment made to the reservation.
   * ## LIMITED
   * Distance per hour, day, week, or month allotted before excess distance charges are applied.
   *
   * ## UNLIMITED
   * Unlimited distance is allotted for the rental with no Excess Distance Charges.
   *
   * ## RENTAL
   * The distance allotted for the entire rental. Any distance driven beyond this will result in Excess Distance Charges.
   */
  includedDistanceAdjustment?: IncludedDistanceAdjustment;
};

/** Free form notes created by users or system generated notes */
export type ReservationNotes = Note[];

export interface BillingReferenceRequestBody {
  /**
   * The external billing reference
   * @minLength 1
   * @maxLength 25
   * @example "Company ABC Billing Ref"
   */
  billingReference?: string;
}

export type EditorRetrieveVehicleRatesRequest = VehicleClassURN[];

/** A single available vehicle rate containing a Total value and a `VehicleRate`. */
export interface VehicleRateAndTotal {
  /** A Currency Amount that is not masked. */
  total?: UnmaskedCurrencyAmount;
  vehicleRate?: VehicleRate;
}

/** A containing object that holds a single Paylater `VehicleRateAndTotal`, a `VehicleClassURN`, a `RateProduct`, and the availability of the car class. */
export interface VehicleRates {
  /** The vehicle class to be reserved by the `Renter`, in the form of a SIPP code. */
  vehicleClassToReserve?: VehicleClassURN;
  /** A single available vehicle rate containing a Total value and a `VehicleRate`. */
  paylater?: VehicleRateAndTotal;
  /** @example "AVAILABLE" */
  availability?: 'AVAILABLE' | 'UNAVAILABLE' | 'ON_REQUEST' | 'VERIFY';
  /** The unique name for a `Rate Product` */
  rateProduct?: RateProductURN;
}

/** Response containing a list of `VehicleRates` and related rental information. */
export interface RetrieveVehicleRatesResponse {
  /** The universally unique name for a **Rental Day Cycle**. */
  rentalDayCycle?: RentalDayCycleURN;
  /** Indicates whether there exists more than one rate plan. */
  multipleRatePlansAvailable?: boolean;
  /** The unique name for a `Rate Plan` */
  ratePlan?: RatePlanURN;
  /**
   * The freely given period of time before additional hourly charges are incurred for daily rentals.
   * @example 29
   */
  graceMinutes?: number;
  /** The requested list of `VehicleRates`. */
  vehicleRates?: VehicleRates[];
}

export interface EhiMessage {
  /**
   * A unique id for the particular message.
   * @example "BOOK1234"
   */
  code?: string;
  /** The locale in [ISO 639-3](https://iso639-3.sil.org/) for language and  [ISO 3166-1 alpha-3](https://www.iso.org/iso-3166-country-codes.html) for country code */
  locale?: Locale;
  /**
   * A human readable string describing the message which can be shown to an end-user.
   * @example "Too many pieces of equipment type added"
   */
  localizedMessage?: string;
  /**
   * A list of references to the specific element/field related to the message.
   * @example ["requestBody:/0/type"]
   */
  paths?: string[];
  /**
   * The severity of the message.
   * @example "ERROR"
   */
  severity?: 'INFO' | 'WARNING' | 'ERROR';
}

export type EditorIssue = EhiMessage & {
  /**
   * A reference to an object external to the Reservation Editor that is related to the issue.
   * @format urn
   * @pattern ^urn:com\.ehi:[a-zA-Z0-9]+:([A-Za-z0-9-._~@!$&'()*+,;=\/:]|[A-Za-z0-9-_:])+$
   */
  externalReference?: string;
  /** Indicates whether it is possible to override the issue and save the reservation. */
  canOverride?: boolean;
};

export type EditorPayers = Payers & {
  /** @maxItems 1 */
  business?: BusinessPayer[];
};

export interface EditorEquipment {
  selected?: Equipment[];
  available?: Equipment[];
}

/** Available types for a given category for protections that may be associated with a Reservation. */
export type ProtectionsForCategory = Protection[];

/**
 * Available categories for protections that may be associated with a Reservation.
 * Keys for this map are limited to all available protection categories.
 *
 * NOTE: Only one protection within a given category can be selected.
 * @example {"urn:com.ehi:xqa:reference:rental:protectionCategory:CDW":[{"type":"urn:com.ehi:xqa:reference:rental:protectionType:USA:CDW1","category":"urn:com.ehi:xqa:reference:rental:protectionCategory:CDW","selectability":"OPTIONAL","rate":{"type":"PER_RENTAL","costPerRental":180}},{"type":"urn:com.ehi:xqa:reference:rental:protectionType:USA:CDW2","category":"urn:com.ehi:xqa:reference:rental:protectionCategory:CDW","selectability":"OPTIONAL","rate":{"type":"PER_RENTAL","costPerRental":120.8}}],"urn:com.ehi:xqa:reference:rental:protectionCategory:RAP":[{"type":"urn:com.ehi:xqa:reference:rental:protectionType:USA:RAP1","category":"urn:com.ehi:xqa:reference:rental:protectionCategory:RAP","selectability":"OPTIONAL","rate":{"type":"PER_RENTAL","costPerRental":85}},{"type":"urn:com.ehi:xqa:reference:rental:protectionType:USA:RAP2","category":"urn:com.ehi:xqa:reference:rental:protectionCategory:RAP","selectability":"OPTIONAL","rate":{"type":"PER_RENTAL","costPerRental":67}}]}
 */
export type AvailableProtection = Record<string, ProtectionsForCategory>;

/** Protections tied to a `ReservationEditor`, such as Collision Damage Waiver, Roadside Assistance, Personal Accident Insurance, Excess Protection, etc. */
export interface EditorProtection {
  /** List of chosen available protections for the `ReservationEditor`. */
  selected?: Protection[];
  /**
   * Available categories for protections that may be associated with a Reservation.
   * Keys for this map are limited to all available protection categories.
   *
   * NOTE: Only one protection within a given category can be selected.
   */
  available?: AvailableProtection;
}

export type SimpleAncillaryFeeType = AncillaryFee & object;

export type FuelAncillaryFeeType = AncillaryFee & {
  fuelType?: 'UNLEADED' | 'DIESEL';
};

/** The minimum and maximum ages for a fee. */
export interface AgeRange {
  /** @example 24 */
  startingAge?: number;
  /** @example 24 */
  endingAge?: number;
}

export type YoungDriverAncillaryFeeType = DriverAncillaryFee & {
  /** The minimum and maximum ages for a fee. */
  ageRange?: AgeRange;
};

/**
 * A fee associated with delivering a vehicle to a location or collecting a vehicle from a location.
 * ## Application of Fee
 * The distance driven will be represented as `Pickup` or `Return`'s `Destination` `Distance`
 *
 * If the amount of distance driven is less than `freeDistance` then no charge will apply.
 *
 * If the amount of distance driven is more than `freeDistance`, then `freeDistance` will be subtracted from the total distance driven.
 * The resulting distance will be considered unaccounted for and used for subsequent calculations.
 *
 * If there is at least 1 `additionalDistanceFee` and there is still distance unaccounted for then the first `additionalDistanceFee`'s `flatFee` will be applied.
 * The first `additionalDistanceFee` distance will be subtracted from the unaccounted for distance.
 *
 * If there is a second `additionalDistanceFee` and there is still distance unaccounted for, then the second `additionalDistanceFee`'s `flatFee` will be applied.
 * The second `additionalDistanceFee` distance will be subtracted from the unaccounted for distance.
 *
 * If there is still distance unaccounted for, then the the rate will apply for the remaining distance.
 */
export type DeliveryOrCollectionAncillaryFeeType = AncillaryFee & {
  /** Distance information */
  freeDistance?: Distance;
  /** @maxLength 2 */
  additionalDistanceFee?: DistanceFee[];
  zone?: 'ONE' | 'TWO';
};

/** Ancillary fees that could be applied to a Reservation. */
export interface PotentialAncillaryFees {
  /** A fee with an associated rate that is time based. */
  simple?: SimpleAncillaryFeeType[];
  /** A fee with an associated rate that is based on the number of drivers. */
  additionalDriver?: SimpleAncillaryFeeType[];
  fuelServiceOption?: FuelAncillaryFeeType;
  /** A fee with an associated rate that is based on the age of the driver. */
  youngDriver?: YoungDriverAncillaryFeeType[];
  delivery?: DeliveryOrCollectionAncillaryFeeType[];
  collection?: DeliveryOrCollectionAncillaryFeeType[];
}

/** A collection of ancillary fees associated with an Editor. */
export interface EditorAncillaryFees {
  /** A list of applied fee types organized by their type. */
  applied?: AppliedAncillaryFees;
  contingent?: ContingentFee[];
  /** Ancillary fees that could be applied to a Reservation. */
  potential?: PotentialAncillaryFees;
}

/** Optional additional products that have been added by the renter to the `ReservationEditor`. Some additional products can be added automatically based on an associated contract, when applicable. */
export interface EditorAddOns {
  equipment?: EditorEquipment;
  /** Protections tied to a `ReservationEditor`, such as Collision Damage Waiver, Roadside Assistance, Personal Accident Insurance, Excess Protection, etc. */
  protections?: EditorProtection;
  /** A collection of ancillary fees associated with an Editor. */
  ancillaryFees?: EditorAncillaryFees;
  /** Indicates whether the user wishes to add fuel service or not. */
  fuelService: FuelService;
}

/** Information describing the the current state of the reservation being edited. */
export interface ReservationInfo {
  /**
   * The status of a `Reservation` or the status that a `Reservation` would be if a `Reservation Editor` is saved.
   * * AWAITING_CONFIRMATION -  Awaiting for confirmation from a branch agent that the reservation can be fulfilled.
   * * INCOMPLETE - Reservation is missing data required or has data that is incompatible such that the reservation cannot be fulfilled in its current state.
   * * CONFIRMED - Reservation that was previously AWAITING_CONFIRMATION has been confirmed by a branch agent that the reservation can be fulfilled.
   * * SOLD - Reservation is in a state such that it can be fulfilled. No Branch Agent confirmation was required.
   * * FULFILLED – Reservation has had a rental agreement created from it. A reservation with this status cannot be modified or cancelled.
   * * MATCHED   – Reservation is matched to a ticket.
   * * DENIED    – Reservation has been denied, most likely because the renter was marked as do-not-rent.
   * * ABANDONED – Reservation was abandoned, not completed during the create process.
   * * NO_SHOW   – Reservation was not fulfilled, well past when it should have been.
   * * CANCELED  – Reservation was canceled by the renter.
   * * TRANSFERRED - Reservation has been transferred to another group.
   */
  status?: StatusUrn;
  /** The unique name of the `Lifecycle` current for the `Reservation`. */
  lifecycle?: LifecycleUrn;
}

/** A Reservation Editor that can be interacted with to create or update a Reservation. */
export interface ReservationEditor {
  /** The unique name of a `Reservation` */
  reservation?: ReservationURN;
  /** The unique name of a `Brand` */
  brand?: BrandURN;
  /** The unique name of the `Lifecycle` current for the `Reservation`. */
  lifecycle?: LifecycleUrn;
  /**
   * The status of a `Reservation` or the status that a `Reservation` would be if a `Reservation Editor` is saved.
   * * AWAITING_CONFIRMATION -  Awaiting for confirmation from a branch agent that the reservation can be fulfilled.
   * * INCOMPLETE - Reservation is missing data required or has data that is incompatible such that the reservation cannot be fulfilled in its current state.
   * * CONFIRMED - Reservation that was previously AWAITING_CONFIRMATION has been confirmed by a branch agent that the reservation can be fulfilled.
   * * SOLD - Reservation is in a state such that it can be fulfilled. No Branch Agent confirmation was required.
   * * FULFILLED – Reservation has had a rental agreement created from it. A reservation with this status cannot be modified or cancelled.
   * * MATCHED   – Reservation is matched to a ticket.
   * * DENIED    – Reservation has been denied, most likely because the renter was marked as do-not-rent.
   * * ABANDONED – Reservation was abandoned, not completed during the create process.
   * * NO_SHOW   – Reservation was not fulfilled, well past when it should have been.
   * * CANCELED  – Reservation was canceled by the renter.
   * * TRANSFERRED - Reservation has been transferred to another group.
   */
  status?: StatusUrn;
  charges?: Charges;
  pickup?: Pickup;
  return?: Return;
  vehicleClassSelection?: VehicleClassSelection;
  /**
   * ---
   *  `Protected Data` may be masked under certain circumstance based on EHI's data masking rules.
   *
   *  Protected Data Identifier: `contact`
   */
  contact?: Contact;
  /** A list of all issues that need to be resolved before being able save the editor to a reservation */
  issue?: EditorIssue[];
  rateSource?: RateSource;
  /** Remark is added to a reservation to pass along additional information that the branch might need to know. */
  remark?: Remark;
  payers?: EditorPayers;
  /** The main driver associated with a `Reservation` or `Reservation Editor`. The `Renter` is legally responsible for any damage or incidentals incurred during the rental. */
  renter?: Renter;
  additionalDriver?: AdditionalDriver[];
  /** Optional additional products that have been added by the renter to the `ReservationEditor`. Some additional products can be added automatically based on an associated contract, when applicable. */
  addOns?: EditorAddOns;
  pointOfSale?: PointOfSale;
  source?: Source;
  note?: Note[];
  /** Alternate reservation source and identifier. */
  alternateReferences?: AlternateReferences;
  /** Travel Partner Program on a `Reservation` or `Reservation Editor` which allows the `Renter` to earn rewards with a qualifying partner for rentals, instead of loyalty points.  A fee may apply. */
  travelPartnerProgram?: TravelPartnerProgram;
  cancellationInformation?: CancellationInformation;
  /**
   * Indicates that some rates and charge amounts should be hidden from the customer.
   * This only occurs in certain situations, and always when the customer is not paying
   * for the rental directly.
   */
  secretRates?: SecretRates;
  onRequest?: OnRequest[];
  currentReservationInfo?: ReservationInfo;
  /** List of conditions/provisions set up on the contract or product */
  generalConditions?: GeneralCondition[];
  /** The unique name of a `Reservation` */
  priorReservationNumber?: ReservationURN;
  /** Array of carrier safety identifiers associated with driver or company that is renting vehicle */
  carrierSafetyIdentifiers?: CarrierSafetyIdentifiers;
  /** The `Renter's` vehicle repair and shop information. */
  renterRepair?: Repair;
}

/**
 * A list of `Issue` codes to be overridden. This will result in a reservation being created that needs adjustment in the future, either at the time of Reservation or when a Rental Agreement is opened.
 * The resulting reservation will have a status of Incomplete if missing key data, as indicated by the Reservation Editor's Status.
 * A single value of "ALL" may be provided to override all issues.
 * Clients must have a `booking.reservation:overrideIssue` claim in order to use this.
 * @example ["BOOK0000","BOOK0001"]
 */
export type OverrideIssue = string[];

/**
 * If the request body will result in an incomplete `Reservation`, or otherwise has data that is in conflict
 * with itself a 400 response will be returned.
 */
export interface SaveToReservation {
  /**
   * A list of `Issue` codes to be overridden. This will result in a reservation being created that needs adjustment in the future, either at the time of Reservation or when a Rental Agreement is opened.
   * The resulting reservation will have a status of Incomplete if missing key data, as indicated by the Reservation Editor's Status.
   * A single value of "ALL" may be provided to override all issues.
   * Clients must have a `booking.reservation:overrideIssue` claim in order to use this.
   */
  overrideIssue?: OverrideIssue;
}

/** Properties needed to create a Reservation Editor from scratch. This will happen when a customer creates a new reservation. */
export interface ReservationEditorCreator {
  /** The unique name of a `Reservation` */
  reservation?: ReservationURN;
  /** The unique identifier for a client application through which requests are sent to Booking. */
  bookingChannel?: BookingChannelUrn;
}

export interface SelectVehicleClass {
  /** The rate type */
  type: 'PAYLATER';
  /**
   * A vehicle class is a code used to sort and/or group vehicles.
   *
   * Vehicle classes consist of four letters, each representing one or more major features of a given vehicle.
   *
   * Here are how the different letters are interpreted:
   *   - First letter:     Size of the Vehicle
   *   - Second Letter:    Number of Doors
   *   - Third Letter:     Transmission & Drive
   *   - Fourth Letter:    Fuel & A/C
   */
  vehicleClass: VehicleClassURN;
}

/** A container for a single `OnRequestReasonType` and associated `OnRequestComment`. */
export interface OnRequestReason {
  /** Specifies the type of reason for the `OnRequest`. */
  reason?: OnRequestReasonType;
  /** The universally unique name for a type of **Equipment** */
  equipmentType?: EquipmentTypeURN;
  /** Description providing any special instructions supporting `OnRequest.Reason` */
  comment?: OnRequestComment;
}

export interface UpdateOnRequestRequest {
  /** Indicates whether the `OnRequest` is confirmed or failed, which indicates whether or not the `OnRequest` can be fulfilled or not. */
  status?: 'CONFIRMED' | 'FAILED';
  /** @minItems 1 */
  reasons?: OnRequestReason[];
}

/** Add Ons for create or modify reservation request. */
export interface ReservationAddOns {
  equipment?: EquipmentTypeURN[];
  protections?: ProtectionTypeURN[];
  /** Indicates whether the user wishes to add fuel service or not. */
  fuelService?: FuelService;
}

export interface CreateModifyReservationBase {
  pickup: Pickup;
  return?: Return;
  rateSource?: RateSource;
  /** The main driver associated with a `Reservation` or `Reservation Editor`. The `Renter` is legally responsible for any damage or incidentals incurred during the rental. */
  renter: Renter;
  /** A collection of Business Payers. */
  businessPayer?: BusinessPayer[];
  vehicleClassSelection?: SelectVehicleClass;
  /** Remark is added to a reservation to pass along additional information that the branch might need to know. */
  remark?: Remark;
  /** Add Ons for create or modify reservation request. */
  addOns?: ReservationAddOns;
  /**
   * ---
   *  `Protected Data` may be masked under certain circumstance based on EHI's data masking rules.
   *
   *  Protected Data Identifier: `contact`
   */
  contact?: Contact;
  /**
   * A list of `Issue` codes to be overridden. This will result in a reservation being created that needs adjustment in the future, either at the time of Reservation or when a Rental Agreement is opened.
   * The resulting reservation will have a status of Incomplete if missing key data, as indicated by the Reservation Editor's Status.
   * A single value of "ALL" may be provided to override all issues.
   * Clients must have a `booking.reservation:overrideIssue` claim in order to use this.
   */
  overrideIssue?: OverrideIssue;
  /** Alternate reservation source and identifier. */
  alternateReferences?: AlternateReferences;
  /** The `Renter's` vehicle repair and shop information. */
  renterRepair?: Repair;
  /** The unique identifier for a client application through which requests are sent to Booking. */
  bookingChannel?: BookingChannelUrn;
}

export type CreateReservationRequest = UtilRequiredKeys<CreateModifyReservationBase, 'pickup' | 'renter'> & {
  pointOfSale?: PointOfSale;
};

export type ModifyReservationRequest = CreateModifyReservationBase & {
  /** The unique name of a `Reservation` */
  reservation?: ReservationURN;
};

export interface ReservationData {
  urn?: ReservationURN;
  /**
   * A sequential number identifying the revision, starting with 1.
   * @format int32
   * @min 1
   * @example 123
   */
  revisionNumber?: number;
  /**
   * @format date-time
   * @example "2022-08-01T20:16:44Z"
   */
  lastUpdatedDateTime?: string;
  /**
   * @format date-time
   * @example "2022-08-01T20:16:44Z"
   */
  createdDateTime?: string;
}

/** Information on the result of a data transaction */
export interface TransactionResult {
  /** @example "SUCCESS" */
  status: 'SUCCESS' | 'FAILED';
  reservationData?: ReservationData;
  /**
   * @format date-time
   * @example "2022-08-01T20:16:44Z"
   */
  createdDateTime?: string;
}

/**
 * The unique name for a country.
 * @format ehi-reference-urn
 * @pattern ^urn:com\.ehi:[a-zA-Z0-9]+:reference:location:country:[A-Z]{3}$
 * @example "urn:com.ehi:xqa:reference:location:country:USA"
 */
export type CountryURN = string;

/**
 * The unique name for a **Country Subdivision**.
 * @format ehi-reference-urn
 * @pattern ^urn:com\.ehi:[a-zA-Z0-9]+:reference:location:countrySubdivision:[A-Z]{1,3}$
 * @example "urn:com.ehi:xqa:reference:location:countrySubdivision:ARM"
 */
export type CountrySubdivisionURN = string;

export interface Address {
  /**
   * @maxLength 35
   * @example "600 Corporate Park Dr"
   */
  line1?: string;
  /**
   * @maxLength 35
   * @example "Suite 200"
   */
  line2?: string;
  /**
   * @maxLength 25
   * @example "St. Louis"
   */
  city?: string;
  /** The unique name for a **Country Subdivision**. */
  countrySubdivision?: CountrySubdivisionURN;
  /**
   * @maxLength 10
   * @example "63105"
   */
  postalCode?: string;
  /** The unique name for a country. */
  country?: CountryURN;
}

export type DriverProfile = DriverProfileURN;

/**
 * A uniform resource name for a coupon applied to a `Reservation`.
 * @format ehi-subresource-urn
 * @pattern ^urn:com\.ehi:[a-zA-Z0-9]+:rental\.booking:reservation:[0-9]{9,}:coupon:[1-9][0-9]*$
 * @example "urn:com.ehi:xqa:rental.booking:reservation:4378295891:coupon:1"
 */
export type ReservationCouponURN = string;

export type CancelReasonURN = CancellationReasonURN;

/** The rate duration applied to a given day of the week within a VariableDuration */
export interface VariableDayDuration {
  /** They day of the week to which the rate duration is applicable */
  dayOfWeek?: 'SUNDAY' | 'MONDAY' | 'TUESDAY' | 'WEDNESDAY' | 'THURSDAY' | 'FRIDAY' | 'SATURDAY';
  /**
   * The number of hours for which the rate will be applied
   * @format int32
   * @min 0
   * @example 18
   */
  hours?: number;
  /**
   * The number of days for which the rate will be applied
   * @format int32
   * @min 0
   * @example 2
   */
  days?: number;
}

export type QueryParamsType = Record<string | number, any>;
export type ResponseFormat = keyof Omit<Body, 'body' | 'bodyUsed'>;

export interface FullRequestParams extends Omit<RequestInit, 'body'> {
  /** set parameter to `true` for call `securityWorker` for this request */
  secure?: boolean;
  /** request path */
  path: string;
  /** content type of request body */
  type?: ContentType;
  /** query params */
  query?: QueryParamsType;
  /** format of response (i.e. response.json() -> format: "json") */
  format?: ResponseFormat;
  /** request body */
  body?: unknown;
  /** base url */
  baseUrl?: string;
  /** request cancellation token */
  cancelToken?: CancelToken;
}

export type RequestParams = Omit<FullRequestParams, 'body' | 'method' | 'query' | 'path'>;

export interface ApiConfig<SecurityDataType = unknown> {
  baseUrl?: string;
  baseApiParams?: Omit<RequestParams, 'baseUrl' | 'cancelToken' | 'signal'>;
  securityWorker?: (securityData: SecurityDataType | null) => Promise<RequestParams | void> | RequestParams | void;
  customFetch?: typeof fetch;
}

export interface HttpResponse<D extends unknown, E extends unknown = unknown> extends Response {
  data: D;
  error: E;
}

type CancelToken = Symbol | string | number;

export enum ContentType {
  Json = 'application/json',
  FormData = 'multipart/form-data',
  UrlEncoded = 'application/x-www-form-urlencoded',
  Text = 'text/plain',
}

export class HttpClient<SecurityDataType = unknown> {
  public baseUrl: string = 'https://{environment}.api.ehi.dev';
  private securityData: SecurityDataType | null = null;
  private securityWorker?: ApiConfig<SecurityDataType>['securityWorker'];
  private abortControllers = new Map<CancelToken, AbortController>();
  private customFetch = (...fetchParams: Parameters<typeof fetch>) => fetch(...fetchParams);

  private baseApiParams: RequestParams = {
    credentials: 'same-origin',
    headers: {},
    redirect: 'follow',
    referrerPolicy: 'no-referrer',
  };

  constructor(apiConfig: ApiConfig<SecurityDataType> = {}) {
    Object.assign(this, apiConfig);
  }

  public setSecurityData = (data: SecurityDataType | null) => {
    this.securityData = data;
  };

  protected encodeQueryParam(key: string, value: any) {
    const encodedKey = encodeURIComponent(key);
    return `${encodedKey}=${encodeURIComponent(typeof value === 'number' ? value : `${value}`)}`;
  }

  protected addQueryParam(query: QueryParamsType, key: string) {
    return this.encodeQueryParam(key, query[key]);
  }

  protected addArrayQueryParam(query: QueryParamsType, key: string) {
    const value = query[key];
    return value.map((v: any) => this.encodeQueryParam(key, v)).join('&');
  }

  protected toQueryString(rawQuery?: QueryParamsType): string {
    const query = rawQuery || {};
    const keys = Object.keys(query).filter((key) => 'undefined' !== typeof query[key]);
    return keys
      .map((key) => (Array.isArray(query[key]) ? this.addArrayQueryParam(query, key) : this.addQueryParam(query, key)))
      .join('&');
  }

  protected addQueryParams(rawQuery?: QueryParamsType): string {
    const queryString = this.toQueryString(rawQuery);
    return queryString ? `?${queryString}` : '';
  }

  private contentFormatters: Record<ContentType, (input: any) => any> = {
    [ContentType.Json]: (input: any) =>
      input !== null && (typeof input === 'object' || typeof input === 'string') ? JSON.stringify(input) : input,
    [ContentType.Text]: (input: any) => (input !== null && typeof input !== 'string' ? JSON.stringify(input) : input),
    [ContentType.FormData]: (input: any) =>
      Object.keys(input || {}).reduce((formData, key) => {
        const property = input[key];
        formData.append(
          key,
          property instanceof Blob
            ? property
            : typeof property === 'object' && property !== null
            ? JSON.stringify(property)
            : `${property}`
        );
        return formData;
      }, new FormData()),
    [ContentType.UrlEncoded]: (input: any) => this.toQueryString(input),
  };

  protected mergeRequestParams(params1: RequestParams, params2?: RequestParams): RequestParams {
    return {
      ...this.baseApiParams,
      ...params1,
      ...(params2 || {}),
      headers: {
        ...(this.baseApiParams.headers || {}),
        ...(params1.headers || {}),
        ...((params2 && params2.headers) || {}),
      },
    };
  }

  protected createAbortSignal = (cancelToken: CancelToken): AbortSignal | undefined => {
    if (this.abortControllers.has(cancelToken)) {
      const abortController = this.abortControllers.get(cancelToken);
      if (abortController) {
        return abortController.signal;
      }
      return void 0;
    }

    const abortController = new AbortController();
    this.abortControllers.set(cancelToken, abortController);
    return abortController.signal;
  };

  public abortRequest = (cancelToken: CancelToken) => {
    const abortController = this.abortControllers.get(cancelToken);

    if (abortController) {
      abortController.abort();
      this.abortControllers.delete(cancelToken);
    }
  };

  public request = async <T = any, E = any>({
    body,
    secure,
    path,
    type,
    query,
    format,
    baseUrl,
    cancelToken,
    ...params
  }: FullRequestParams): Promise<HttpResponse<T, E>> => {
    const secureParams =
      ((typeof secure === 'boolean' ? secure : this.baseApiParams.secure) &&
        this.securityWorker &&
        (await this.securityWorker(this.securityData))) ||
      {};
    const requestParams = this.mergeRequestParams(params, secureParams);
    const queryString = query && this.toQueryString(query);
    const payloadFormatter = this.contentFormatters[type || ContentType.Json];
    const responseFormat = format || requestParams.format;

    return this.customFetch(`${baseUrl || this.baseUrl || ''}${path}${queryString ? `?${queryString}` : ''}`, {
      ...requestParams,
      headers: {
        ...(requestParams.headers || {}),
        ...(type && type !== ContentType.FormData ? { 'Content-Type': type } : {}),
      },
      signal: cancelToken ? this.createAbortSignal(cancelToken) : requestParams.signal,
      body: typeof body === 'undefined' || body === null ? null : payloadFormatter(body),
    }).then(async (response) => {
      const r = response as HttpResponse<T, E>;
      r.data = null as unknown as T;
      r.error = null as unknown as E;

      const data = !responseFormat
        ? r
        : await response[responseFormat]()
            .then((data) => {
              if (r.ok) {
                r.data = data;
              } else {
                r.error = data;
              }
              return r;
            })
            .catch((e) => {
              r.error = e;
              return r;
            });

      if (cancelToken) {
        this.abortControllers.delete(cancelToken);
      }

      if (!response.ok) throw data;
      return data;
    });
  };
}

/**
 * @title Booking Process Service
 * @version 2.0.0-beta.3
 * @license Enterprise Holdings, Inc. API Marketplace Terms of Service
 * @baseUrl https://{environment}.api.ehi.dev
 * @contact Booking Team <BookingSupport@ehi.com>
 *
 * # Overview
 * This API provides capabilities to manage Reservations across the full Booking lifecycle.
 *
 * ## Booking Lifecycle
 * Booking is composed of a lifecycle, with the following phases:
 *
 * * PENDING - Reservation is created but pending acceptance by the branch before it can be used for fulfillment.
 * * RESERVED - Reservation is open and valid to be used for fulfillment.
 * * FULFILLED - Reservation has been used for a fulfillment.
 * * CANCELED - Reservation is no longer valid for fulfillment. This also includes reservations which were never valid for fulfillment, such as abandoned.
 *
 * A booking can start in either the PENDING status or the RESERVED status and move through the lifecycle to either FULFILLED or CANCELED.
 *
 * ## Reservation Editors for Creating and Changing Reservations
 * To avoid a muddied interaction model which makes it hard to know if one is looking at the current state of a Reservation or a collection of unsaved changes to one, this API leverages a separate, short-lived resource to support the change cycle used to create or modify a reservation. This type of resource is provided by the Reservation Editor endpoints. A Reservation Editor can be used to start a brand new reservation from scratch or to make changes to an existing reservation.
 *
 * The data model of a Reservation Editor is identical to that of a Reservation except for a few attributes specific to supporting iterative and "what if" changes. The Reservation Editor then adds on all the needed endpoints and interactions to support those iterative and "what if" changes to a reservation which may or may not be saved.
 * Reservation Editors are intended to be relatively short-lived while still supporting  a variety of workflow needs. To facilitate this, an editor persists while it is in  use, being deleted after one hour of inactivity.
 *
 * When created from an existing reservation, a Reservation Editor is "working copy" of the reservation, initially matching the current state of the reservation exactly. As changes are made, the Reservation Editor tracks its state against the rules for reservations (some of which are highlighted above). When it identifies inconsistencies, it captures and surfaces them as issues.
 *
 * ## Issues
 * Issues are generated when there are unresolved problems in a Reservation Editor. These issues can prevent a Reservation Editor from being saved to a reservation. If an issue has a true value for `canOverride`, a client with the appropriate permissions can bypass the issue via `overrideIssue` and save to a reservation regardless  of its state. If an issue cannot be overridden, the API consumer must address it through additional compensating changes before they can save to a reservation. `overrideIssue` is available in an Editor's save-to-reservation request, as well as in  Booking Behaviors' create-reservation  and modify-reservation endpoints.
 *
 * ## Vehicle Rates
 *
 *   There are several kinds of rates that may apply to a vehicle, described below. All rates apply to how long the vehicle is used (time) and the distance driven (distance).
 *   All rates may have some amount of included distance (in either miles or kilometers) which is specified in the rate structure, and a rate per mile/kilometer beyond the included distance.
 *   A rate may have a time and distance discount, which is a percentage discount that will be applied when calculating charges.
 *
 *   - PeriodicVehicleRate: A rate that charges based upon length of rental, using one or more time-based rates for hourly, daily, weekly, and monthly usage.
 *     A vehicle periodic rate will have a rate for at least one time period, but could have any combination of them.
 *     Rates for longer durations are applied before rates for shorter durations.
 *
 *   - VariableVehicleRate: A rate that has specific hourly and daily rates for each day of the week, and may also have a weekly and/or monthly rate.
 *
 *   - PackageVehicleRate:
 *       A Vehicle Package Rate specifies a per package cost that covers the vehicle rental and any included items for the number of rental days in the package.
 *       1. Rental days beyond the length of the package will be charged using either the extra time or extension time rates.
 *       2. If a voucher is being used to pay for the rental, the extra time rates will be used to cover the extra days beyond the package days that the voucher covers, and then the renter will pay for days beyond that at the extension time rate.
 *       3. If a voucher is not being used to pay for the rental, the renter will pay for days beyond the package days at the extra time rate.
 */
export class Api<SecurityDataType extends unknown> extends HttpClient<SecurityDataType> {
  rental = {
    /**
     * @description Mark the `Reservation` as cancelled. Refund loyalty redemption, if present. Refund payments collected, withholding penalties.
     *
     * @tags Reservation Cancel
     * @name CancelReservation
     * @summary Cancel a Reservation
     * @request POST:/rental/booking/reservation/{reservationId}/cancel-reservation
     * @secure
     */
    cancelReservation: (reservationId: string, data: CancelReservationRequest, params: RequestParams = {}) =>
      this.request<any, Errors>({
        path: `/rental/booking/reservation/${reservationId}/cancel-reservation`,
        method: 'POST',
        body: data,
        secure: true,
        ...params,
      }),

    /**
     * @description Retrieve the specified `Reservation`.
     *
     * @tags Reservation
     * @name GetReservation
     * @summary Retrieve a Reservation
     * @request GET:/rental/booking/reservation/{reservationId}
     * @secure
     */
    getReservation: (reservationId: string, params: RequestParams = {}) =>
      this.request<Reservation, Errors>({
        path: `/rental/booking/reservation/${reservationId}`,
        method: 'GET',
        secure: true,
        ...params,
      }),

    /**
     * @description Fulfills a reservation The status of the reservation will be updated to FULFILLED, if the reservation is currently in one of the following statuses it will not be updated NO_SHOW CANCELED DENIED ABANDONED FULFILLED
     *
     * @tags Reservation
     * @name FulfillReservation
     * @summary Fulfills a reservation
     * @request POST:/rental/booking/reservation/{reservationId}/fulfill-reservation
     * @secure
     */
    fulfillReservation: (reservationId: string, params: RequestParams = {}) =>
      this.request<void, Errors>({
        path: `/rental/booking/reservation/${reservationId}/fulfill-reservation`,
        method: 'POST',
        secure: true,
        ...params,
      }),

    /**
     * @description Reverts the fufillment of a reservation The status of the reservation will be updated to the previous status it had before being fufilled
     *
     * @tags Reservation
     * @name RevertFufillment
     * @summary Reverts the Fulfillment of a reservation
     * @request POST:/rental/booking/reservation/{reservationId}/revert-fulfillment
     * @secure
     */
    revertFufillment: (reservationId: string, params: RequestParams = {}) =>
      this.request<void, Errors>({
        path: `/rental/booking/reservation/${reservationId}/revert-fulfillment`,
        method: 'POST',
        secure: true,
        ...params,
      }),

    /**
     * @description Accept a local-addendum attestation
     *
     * @tags Reservation
     * @name AcceptLocalAddendum
     * @summary Accept a local addendum attestation
     * @request POST:/rental/booking/reservation/{reservationId}/accept-local-addendum
     * @secure
     */
    acceptLocalAddendum: (reservationId: string, params: RequestParams = {}) =>
      this.request<void, Errors>({
        path: `/rental/booking/reservation/${reservationId}/accept-local-addendum`,
        method: 'POST',
        secure: true,
        ...params,
      }),

    /**
     * @description Retrieve the Notes attached to the specified `Reservation`.
     *
     * @tags Reservation
     * @name GetReservationNotes
     * @summary Retrieve the Notes attached to a Reservation
     * @request GET:/rental/booking/reservation/{reservationId}/note
     * @secure
     */
    getReservationNotes: (reservationId: string, params: RequestParams = {}) =>
      this.request<ReservationNotes, Errors>({
        path: `/rental/booking/reservation/${reservationId}/note`,
        method: 'GET',
        secure: true,
        ...params,
      }),

    /**
     * @description Add or update the billing reference on a 'Reservation Editor' #### Restrictions The billing reference must not be more than 25 characters long.
     *
     * @tags Reservation Editor
     * @name AddUpdateBillingReference
     * @summary Add or update the billing reference for a Reservation Editor
     * @request PUT:/rental/booking/reservationEditor/{reservationEditorId}/payers/billingReference
     * @secure
     */
    addUpdateBillingReference: (
      reservationEditorId: string,
      data: BillingReferenceRequestBody,
      params: RequestParams = {}
    ) =>
      this.request<void, Errors>({
        path: `/rental/booking/reservationEditor/${reservationEditorId}/payers/billingReference`,
        method: 'PUT',
        body: data,
        secure: true,
        type: ContentType.Json,
        ...params,
      }),

    /**
     * @description Add or update the list of business payers on a `Reservation Editor`. #### Restrictions A `Reservation` and `Reservation Editor` can have only one Business Payer at this time.
     *
     * @tags Reservation Editor
     * @name AddUpdatePayers
     * @summary Add or update the business payers for a Reservation Editor
     * @request PUT:/rental/booking/reservationEditor/{reservationEditorId}/payers/business
     * @secure
     */
    addUpdatePayers: (reservationEditorId: string, data: BusinessPayer[], params: RequestParams = {}) =>
      this.request<void, Errors>({
        path: `/rental/booking/reservationEditor/${reservationEditorId}/payers/business`,
        method: 'PUT',
        body: data,
        secure: true,
        type: ContentType.Json,
        ...params,
      }),

    /**
     * @description Add or update the person payer on a `Reservation Editor`. #### Restrictions A `Reservation` and `Reservation Editor` can have only one Person Payer.
     *
     * @tags Reservation Editor
     * @name AddUpdatePersonPayer
     * @summary Add or update the person payer for a Reservation Editor
     * @request PUT:/rental/booking/reservationEditor/{reservationEditorId}/payers/person
     * @secure
     */
    addUpdatePersonPayer: (reservationEditorId: string, data: PersonPayer, params: RequestParams = {}) =>
      this.request<void, Errors>({
        path: `/rental/booking/reservationEditor/${reservationEditorId}/payers/person`,
        method: 'PUT',
        body: data,
        secure: true,
        type: ContentType.Json,
        ...params,
      }),

    /**
     * @description Retrieve vehicle rates for the provided list of vehicle classes. If no list is provided, then retrieve rates for classes configured for the pickup location.
     *
     * @tags Reservation Editor
     * @name EditorRetrieveVehicleRates
     * @summary Retrieve vehicle rates
     * @request POST:/rental/booking/reservationEditor/{reservationEditorId}/retrieve-vehicle-rates
     * @secure
     */
    editorRetrieveVehicleRates: (
      reservationEditorId: string,
      data: EditorRetrieveVehicleRatesRequest,
      params: RequestParams = {}
    ) =>
      this.request<RetrieveVehicleRatesResponse, Errors>({
        path: `/rental/booking/reservationEditor/${reservationEditorId}/retrieve-vehicle-rates`,
        method: 'POST',
        body: data,
        secure: true,
        type: ContentType.Json,
        format: 'json',
        ...params,
      }),

    /**
     * @description Retrieve the specified `Reservation Editor`.
     *
     * @tags Reservation Editor
     * @name GetReservationEditor
     * @summary Retrieve a Reservation Editor
     * @request GET:/rental/booking/reservationEditor/{reservationEditorId}
     * @secure
     */
    getReservationEditor: (reservationEditorId: string, params: RequestParams = {}) =>
      this.request<ReservationEditor, Errors>({
        path: `/rental/booking/reservationEditor/${reservationEditorId}`,
        method: 'GET',
        secure: true,
        ...params,
      }),

    /**
     * @description Save a `Reservation Editor` to the underlying `Reservation`. If there is no underlying `Reservation`, create one. Return a reference to it in the Location header.
     *
     * @tags Reservation Editor
     * @name SaveToReservation
     * @summary Save a Reservation Editor to a Reservation
     * @request POST:/rental/booking/reservationEditor/{reservationEditorId}/save-to-reservation
     * @secure
     */
    saveToReservation: (reservationEditorId: string, data?: SaveToReservation, params: RequestParams = {}) =>
      this.request<void, Errors>({
        path: `/rental/booking/reservationEditor/${reservationEditorId}/save-to-reservation`,
        method: 'POST',
        body: data,
        secure: true,
        type: ContentType.Json,
        ...params,
      }),

    /**
     * @description If there is no reservation number tied to the reservation editor session already, assign a new reservation number to the reservation editor.
     *
     * @tags Reservation Editor
     * @name AssignReservationNumber
     * @summary Assign a reservation number to a Reservation Editor
     * @request POST:/rental/booking/reservationEditor/{reservationEditorId}/assign-reservation-number
     * @secure
     */
    assignReservationNumber: (reservationEditorId: string, params: RequestParams = {}) =>
      this.request<ReservationURN, Errors>({
        path: `/rental/booking/reservationEditor/${reservationEditorId}/assign-reservation-number`,
        method: 'POST',
        secure: true,
        format: 'json',
        ...params,
      }),

    /**
     * @description **NOTE: UPDATING THE RATE SOURCE MAY HAVE WIDESPREAD IMPACTS TO A RESERVATION EDITOR THAT CANNOT BE UNDONE!** Modify the `Rate Source` on a `Reservation Editor`. There will always be a rate source associated with a reservation editor. The default rate source is retail. Modifying the `Rate Source` affects `Selectability` of equipment and protections, potential ancillary fees, rates and charges. Equipment and protections may be automatically selected or unselected. 'Rental Reason` is optional. For a retail rate source, the default value is Leisure. For a negotiated rate source, the default value is set by the negotiated account.
     *
     * @tags Reservation Editor
     * @name CreateModifyRateSource
     * @summary Modify rate source for a Reservation Editor
     * @request POST:/rental/booking/reservationEditor/{reservationEditorId}/modify-rate-source
     * @secure
     */
    createModifyRateSource: (reservationEditorId: string, data: RateSource, params: RequestParams = {}) =>
      this.request<void, Errors>({
        path: `/rental/booking/reservationEditor/${reservationEditorId}/modify-rate-source`,
        method: 'POST',
        body: data,
        secure: true,
        type: ContentType.Json,
        ...params,
      }),

    /**
     * @description Reservation Editors are intended to be relatively short-lived while still supporting a variety of workflow needs. To facilitate this, an editor persists in cache while it is in use, being deleted after one hour of inactivity. Create a new `Reservation Editor` to create a new `Reservation` or provide a reservation number in the request to modify an existing one.
     *
     * @tags Reservation Editor Create
     * @name CreateReservationEditor
     * @summary Create a new Reservation Editor
     * @request POST:/rental/booking/reservationEditor
     * @secure
     */
    createReservationEditor: (data: ReservationEditorCreator, params: RequestParams = {}) =>
      this.request<void, Errors>({
        path: `/rental/booking/reservationEditor`,
        method: 'POST',
        body: data,
        secure: true,
        type: ContentType.Json,
        ...params,
      }),

    /**
     * @description Add or update the `Pickup` information on a `Reservation Editor`. `Pickup` can include the date and time of vehicle pickup, branch where the vehicle will be picked up and how the vehicle will be picked up. Changing the pickup information on the reservation may result in updates to the rates and charges. When a date-time is provided and the timezone does not match the timezone of the `branch`, the date-time's timezone will be adjusted, without shifting the local time.  An ehi-message warning will be returned.
     *
     * @tags Reservation Editor
     * @name AddUpdatePickup
     * @summary Add or update the Pickup information for a Reservation Editor
     * @request PUT:/rental/booking/reservationEditor/{reservationEditorId}/pickup
     * @secure
     */
    addUpdatePickup: (reservationEditorId: string, data: Pickup, params: RequestParams = {}) =>
      this.request<void, Errors>({
        path: `/rental/booking/reservationEditor/${reservationEditorId}/pickup`,
        method: 'PUT',
        body: data,
        secure: true,
        type: ContentType.Json,
        ...params,
      }),

    /**
     * @description Add or update the `Return` information on a `Reservation Editor`. `Return` can include the date and time of vehicle return, branch where the vehicle will be returned and how the vehicle will be returned. Changing the return information on the reservation may result in updates to the rates and charges. When a date-time is provided and the timezone does not match the timezone of the `branch`, the date-time's timezone will be adjusted, without shifting the local time.  An ehi-message warning will be returned.
     *
     * @tags Reservation Editor
     * @name AddUpdateReturn
     * @summary Add or update the Return information for a Reservation Editor
     * @request PUT:/rental/booking/reservationEditor/{reservationEditorId}/return
     * @secure
     */
    addUpdateReturn: (reservationEditorId: string, data: Return, params: RequestParams = {}) =>
      this.request<void, Errors>({
        path: `/rental/booking/reservationEditor/${reservationEditorId}/return`,
        method: 'PUT',
        body: data,
        secure: true,
        type: ContentType.Json,
        ...params,
      }),

    /**
     * @description Add or update the `Remark` on a `Reservation Editor`. `Remark` is added to a reservation to pass along a message that a branch agent might need to know. There can be only one `Remark` added for a  reservation anytime. Update will override the existing remark.
     *
     * @tags Reservation Editor
     * @name AddUpdateRemark
     * @summary Add or update the Remark for a Reservation Editor
     * @request PUT:/rental/booking/reservationEditor/{reservationEditorId}/remark
     * @secure
     */
    addUpdateRemark: (reservationEditorId: string, data: Remark, params: RequestParams = {}) =>
      this.request<void, Errors>({
        path: `/rental/booking/reservationEditor/${reservationEditorId}/remark`,
        method: 'PUT',
        body: data,
        secure: true,
        type: ContentType.Text,
        ...params,
      }),

    /**
     * @description Remove the `Remark` from a `Reservation Editor`.
     *
     * @tags Reservation Editor
     * @name RemoveRemark
     * @summary Remove Remark from a Reservation Editor
     * @request DELETE:/rental/booking/reservationEditor/{reservationEditorId}/remark
     * @secure
     */
    removeRemark: (reservationEditorId: string, params: RequestParams = {}) =>
      this.request<void, Errors>({
        path: `/rental/booking/reservationEditor/${reservationEditorId}/remark`,
        method: 'DELETE',
        secure: true,
        ...params,
      }),

    /**
     * @description Add or update the `Additional Driver` list on a `Reservation Editor`. `Additional Driver` is added to a reservation with the profile of the driver if it exists.  If there is no profile it adds the name of the driver. #### Restrictions Either profile or name must be not null and only one of them can be non-null.
     *
     * @tags Reservation Editor
     * @name AddUpdateAdditionalDriver
     * @summary Add or update the Additional Driver for a Reservation Editor
     * @request PUT:/rental/booking/reservationEditor/{reservationEditorId}/additionalDriver
     * @secure
     */
    addUpdateAdditionalDriver: (reservationEditorId: string, data: AdditionalDriver[], params: RequestParams = {}) =>
      this.request<void, Errors>({
        path: `/rental/booking/reservationEditor/${reservationEditorId}/additionalDriver`,
        method: 'PUT',
        body: data,
        secure: true,
        type: ContentType.Json,
        ...params,
      }),

    /**
     * @description Add or update vehicle class selection on a `Reservation Editor`.
     *
     * @tags Reservation Editor
     * @name AddUpdateVehicleClassSelection
     * @summary Add or update vehicle class selection on a Reservation Editor
     * @request PUT:/rental/booking/reservationEditor/{reservationEditorId}/vehicleClassSelection
     * @secure
     */
    addUpdateVehicleClassSelection: (
      reservationEditorId: string,
      data: SelectVehicleClass,
      params: RequestParams = {}
    ) =>
      this.request<void, Errors>({
        path: `/rental/booking/reservationEditor/${reservationEditorId}/vehicleClassSelection`,
        method: 'PUT',
        body: data,
        secure: true,
        type: ContentType.Json,
        ...params,
      }),

    /**
     * @description Remove vehicle class selection on a `Reservation Editor`.
     *
     * @tags Reservation Editor
     * @name RemoveVehicleClassSelection
     * @summary Remove vehicle class selection on a Reservation Editor
     * @request DELETE:/rental/booking/reservationEditor/{reservationEditorId}/vehicleClassSelection
     * @secure
     */
    removeVehicleClassSelection: (reservationEditorId: string, params: RequestParams = {}) =>
      this.request<void, Errors>({
        path: `/rental/booking/reservationEditor/${reservationEditorId}/vehicleClassSelection`,
        method: 'DELETE',
        secure: true,
        ...params,
      }),

    /**
     * @description Update the On-Request on a `Reservation Editor`.
     *
     * @tags Reservation Editor
     * @name UpdateOnRequest
     * @summary Update the On-Request on a Reservation Editor
     * @request POST:/rental/booking/reservationEditor/{reservationEditorId}/update-on-request
     * @secure
     */
    updateOnRequest: (reservationEditorId: string, data: UpdateOnRequestRequest, params: RequestParams = {}) =>
      this.request<void, Errors>({
        path: `/rental/booking/reservationEditor/${reservationEditorId}/update-on-request`,
        method: 'POST',
        body: data,
        secure: true,
        type: ContentType.Json,
        ...params,
      }),

    /**
     * @description Remove the renter information on a `Reservation Editor`.
     *
     * @tags Reservation Editor
     * @name RemoveRenter
     * @summary Remove Renter information on a Reservation Editor
     * @request DELETE:/rental/booking/reservationEditor/{reservationEditorId}/renter
     * @secure
     */
    removeRenter: (reservationEditorId: string, params: RequestParams = {}) =>
      this.request<void, Errors>({
        path: `/rental/booking/reservationEditor/${reservationEditorId}/renter`,
        method: 'DELETE',
        secure: true,
        ...params,
      }),

    /**
     * @description Update `Walk In` for the renter on a `Reservation Editor`. #### Restrictions Cannot change value if 'Can Set Walk In' is false. This information can not be updated if a `Reservation Editor` was created from an existing `Reservation`.
     *
     * @tags Reservation Editor
     * @name UpdateRenterWalkIn
     * @summary Update Walk In for the renter on a Reservation Editor
     * @request PUT:/rental/booking/reservationEditor/{reservationEditorId}/renter/walkIn
     * @secure
     */
    updateRenterWalkIn: (reservationEditorId: string, data: WalkIn, params: RequestParams = {}) =>
      this.request<void, Errors>({
        path: `/rental/booking/reservationEditor/${reservationEditorId}/renter/walkIn`,
        method: 'PUT',
        body: data,
        secure: true,
        type: ContentType.Json,
        ...params,
      }),

    /**
     * @description Add or update Arrival Travel Information for the `Renter` on a `Reservation Editor`.
     *
     * @tags Reservation Editor
     * @name AddUpdateRenterArrivalTravelInformation
     * @summary Add or update Arrival Travel Information for the renter on a Reservation Editor
     * @request PUT:/rental/booking/reservationEditor/{reservationEditorId}/renter/arrivalTravelInformation
     * @secure
     */
    addUpdateRenterArrivalTravelInformation: (
      reservationEditorId: string,
      data: TravelInformation,
      params: RequestParams = {}
    ) =>
      this.request<void, Errors>({
        path: `/rental/booking/reservationEditor/${reservationEditorId}/renter/arrivalTravelInformation`,
        method: 'PUT',
        body: data,
        secure: true,
        type: ContentType.Json,
        ...params,
      }),

    /**
     * @description Delete Arrival Travel Information for the `Renter` on a `Reservation Editor`.
     *
     * @tags Reservation Editor
     * @name DeleteRenterArrivalTravelInformation
     * @summary Delete the Arrival Travel Information for the renter on a Reservation Editor
     * @request DELETE:/rental/booking/reservationEditor/{reservationEditorId}/renter/arrivalTravelInformation
     * @secure
     */
    deleteRenterArrivalTravelInformation: (reservationEditorId: string, params: RequestParams = {}) =>
      this.request<void, Errors>({
        path: `/rental/booking/reservationEditor/${reservationEditorId}/renter/arrivalTravelInformation`,
        method: 'DELETE',
        secure: true,
        ...params,
      }),

    /**
     * @description Associate a renter to a `Reservation Editor`. Loyalty Program must be provided in order for a `DriverProfileRenter`'s loyalty benefits to apply. A young driver fee will be automatically added based on the renter's age.
     *
     * @tags Reservation Editor
     * @name CreateModifyRenter
     * @summary Associate a Renter to a Reservation Editor
     * @request POST:/rental/booking/reservationEditor/{reservationEditorId}/associate-renter
     * @secure
     */
    createModifyRenter: (reservationEditorId: string, data: Renter, params: RequestParams = {}) =>
      this.request<void, Errors>({
        path: `/rental/booking/reservationEditor/${reservationEditorId}/associate-renter`,
        method: 'POST',
        body: data,
        secure: true,
        type: ContentType.Json,
        ...params,
      }),

    /**
     * @description Add or update `Point of Sale` information on a `Reservation Editor`. #### Restrictions The Location for a Point of Sale cannot be updated if a `Reservation Editor` was created from an existing `Reservation`.
     *
     * @tags Reservation Editor
     * @name AddUpdatePointOfSale
     * @summary Add or update Point of Sale information on a Reservation Editor
     * @request PUT:/rental/booking/reservationEditor/{reservationEditorId}/pointOfSale
     * @secure
     */
    addUpdatePointOfSale: (reservationEditorId: string, data: PointOfSale, params: RequestParams = {}) =>
      this.request<void, Errors>({
        path: `/rental/booking/reservationEditor/${reservationEditorId}/pointOfSale`,
        method: 'PUT',
        body: data,
        secure: true,
        type: ContentType.Json,
        ...params,
      }),

    /**
     * @description Fuel Service boolean selection is required in all requests to this endpoint. Any equipment or protections that do not appear in their respective request lists will be removed from the selected lists. Any equipment or protections that are included in the respective request lists that are not currently in the selected lists will be added.
     *
     * @tags Reservation Editor
     * @name UpdateAllAddOns
     * @summary Add, remove or update currently selected Add Ons
     * @request PUT:/rental/booking/reservationEditor/{reservationEditorId}/addOns
     * @secure
     */
    updateAllAddOns: (reservationEditorId: string, data: EditorAddOns, params: RequestParams = {}) =>
      this.request<void, Errors>({
        path: `/rental/booking/reservationEditor/${reservationEditorId}/addOns`,
        method: 'PUT',
        body: data,
        secure: true,
        type: ContentType.Json,
        ...params,
      }),

    /**
     * @description Replace all selected equipment with the equipment in the request. #### Remove from Selected Equipment Any equipment that does not appear in the request list will be removed from the selected list. #### Add to Selected Equipment Any equipment that is in the request list that is not currently in the selected list will be added. #### Update Equipment Quantity If more than one equipment of the same type is desired, it should be added to the selected list multiple times. #### Restrictions Mandatory equipment must be included in the request list.  Included equipment does not have to be selected and can be removed from the selected list.
     *
     * @tags Reservation Editor
     * @name ReplaceSelectedEquipment
     * @summary Replace all selected equipment on a Reservation Editor
     * @request PUT:/rental/booking/reservationEditor/{reservationEditorId}/addOns/equipment/selected
     * @secure
     */
    replaceSelectedEquipment: (reservationEditorId: string, data: Equipment[], params: RequestParams = {}) =>
      this.request<void, Errors>({
        path: `/rental/booking/reservationEditor/${reservationEditorId}/addOns/equipment/selected`,
        method: 'PUT',
        body: data,
        secure: true,
        type: ContentType.Json,
        ...params,
      }),

    /**
     * @description Replace all selected protections with the protections in the request. Selected protections must already be selected or available. #### Remove from Selected Protections Any protection that does not appear in the request list will be removed from the selected list. #### Add to Selected Protections Any protection that is in the request list that is not currently in the selected list will be added. #### Restrictions Protections within the same category cannot be selected at the same time. Mandatory and Included Protections must be included in the request list.
     *
     * @tags Reservation Editor
     * @name ReplaceSelectedProtections
     * @summary Replace all selected protections on a Reservation Editor
     * @request PUT:/rental/booking/reservationEditor/{reservationEditorId}/addOns/protections/selected
     * @secure
     */
    replaceSelectedProtections: (
      reservationEditorId: string,
      data: ProtectionsForCategory,
      params: RequestParams = {}
    ) =>
      this.request<void, Errors>({
        path: `/rental/booking/reservationEditor/${reservationEditorId}/addOns/protections/selected`,
        method: 'PUT',
        body: data,
        secure: true,
        type: ContentType.Json,
        ...params,
      }),

    /**
     * @description Add or update contact information on a `Reservation Editor`. The `Contact` may or may not be the same individual as the `Renter`.
     *
     * @tags Reservation Editor
     * @name AddUpdateContact
     * @summary Add or update Contact information on a Reservation Editor
     * @request PUT:/rental/booking/reservationEditor/{reservationEditorId}/contact
     * @secure
     */
    addUpdateContact: (reservationEditorId: string, data: Contact, params: RequestParams = {}) =>
      this.request<void, Errors>({
        path: `/rental/booking/reservationEditor/${reservationEditorId}/contact`,
        method: 'PUT',
        body: data,
        secure: true,
        type: ContentType.Json,
        ...params,
      }),

    /**
     * @description Add or update repair information on a `Reservation Editor`.
     *
     * @tags Reservation Editor
     * @name AddUpdateRepair
     * @summary Add or update Repair information on a Reservation Editor
     * @request PUT:/rental/booking/reservationEditor/{reservationEditorId}/repair
     * @secure
     */
    addUpdateRepair: (reservationEditorId: string, data: Repair, params: RequestParams = {}) =>
      this.request<void, Errors>({
        path: `/rental/booking/reservationEditor/${reservationEditorId}/repair`,
        method: 'PUT',
        body: data,
        secure: true,
        type: ContentType.Json,
        ...params,
      }),

    /**
     * @description Adjust the vehicle rate on a `Reservation Editor`.
     *
     * @tags Reservation Editor
     * @name AdjustVehicleRate
     * @summary Adjust vehicle rate on a Reservation Editor
     * @request POST:/rental/booking/reservationEditor/{reservationEditorId}/vehicleClassSelection/adjust-vehicle-rate
     * @secure
     */
    adjustVehicleRate: (reservationEditorId: string, data: BaseVehicleRate, params: RequestParams = {}) =>
      this.request<void, Errors>({
        path: `/rental/booking/reservationEditor/${reservationEditorId}/vehicleClassSelection/adjust-vehicle-rate`,
        method: 'POST',
        body: data,
        secure: true,
        type: ContentType.Json,
        ...params,
      }),

    /**
     * @description Add fuel service and associated ancillary fee to a `Reservation Editor`. #### Restrictions Fuel must be present under potential ancillary fees in order to be added.
     *
     * @tags Reservation Editor
     * @name AddFuelService
     * @summary Add Fuel Service on a Reservation Editor
     * @request POST:/rental/booking/reservationEditor/{reservationEditorId}/addOns/add-fuel-service
     * @secure
     */
    addFuelService: (reservationEditorId: string, params: RequestParams = {}) =>
      this.request<void, Errors>({
        path: `/rental/booking/reservationEditor/${reservationEditorId}/addOns/add-fuel-service`,
        method: 'POST',
        secure: true,
        ...params,
      }),

    /**
     * @description Remove fuel service and associated ancillary fee from a `Reservation Editor`.
     *
     * @tags Reservation Editor
     * @name RemoveFuelService
     * @summary Remove Fuel Service on a Reservation Editor
     * @request POST:/rental/booking/reservationEditor/{reservationEditorId}/addOns/remove-fuel-service
     * @secure
     */
    removeFuelService: (reservationEditorId: string, params: RequestParams = {}) =>
      this.request<void, Errors>({
        path: `/rental/booking/reservationEditor/${reservationEditorId}/addOns/remove-fuel-service`,
        method: 'POST',
        secure: true,
        ...params,
      }),

    /**
     * @description Replace the list of notes on the Reservation Editor
     *
     * @tags Reservation Editor
     * @name ReservationEditorAddNote
     * @summary Replace the list of notes on the Reservation Editor
     * @request PUT:/rental/booking/reservationEditor/{reservationEditorId}/note
     * @secure
     */
    reservationEditorAddNote: (reservationEditorId: string, data: ReservationNotes, params: RequestParams = {}) =>
      this.request<void, Errors>({
        path: `/rental/booking/reservationEditor/${reservationEditorId}/note`,
        method: 'PUT',
        body: data,
        secure: true,
        type: ContentType.Json,
        ...params,
      }),

    /**
     * @description Creates or replaces Alternate References.  Once saved to a reservation, a reference cannot be updated or removed.
     *
     * @tags Reservation Editor
     * @name AddUpdateAlternateReferences
     * @summary Creates or replaces the AlternateReferences object on the Editor
     * @request PUT:/rental/booking/reservationEditor/{reservationEditorId}/alternateReferences
     * @secure
     */
    addUpdateAlternateReferences: (
      reservationEditorId: string,
      data: AlternateReferences,
      params: RequestParams = {}
    ) =>
      this.request<void, Errors>({
        path: `/rental/booking/reservationEditor/${reservationEditorId}/alternateReferences`,
        method: 'PUT',
        body: data,
        secure: true,
        type: ContentType.Json,
        ...params,
      }),

    /**
     * @description Add or replace all carrier safety identifiers on a Reservation editor associated with driver or company that is renting vehicle. Editor can have a maximum of 15 carrier safety identifiers
     *
     * @tags Reservation Editor
     * @name UpdateCarrierSafetyIdentifiers
     * @summary Add or replace all carrier safety identifiers on a Reservation editor
     * @request PUT:/rental/booking/reservationEditor/{reservationEditorId}/carrierSafetyIdentifiers
     * @secure
     */
    updateCarrierSafetyIdentifiers: (
      reservationEditorId: string,
      data: CarrierSafetyIdentifiers,
      params: RequestParams = {}
    ) =>
      this.request<void, Errors>({
        path: `/rental/booking/reservationEditor/${reservationEditorId}/carrierSafetyIdentifiers`,
        method: 'PUT',
        body: data,
        secure: true,
        type: ContentType.Json,
        ...params,
      }),

    /**
     * @description Creates a `Reservation` using the data provided. Protections that are mandatory or included and are missing from the request will be automatically added. Equipment that are mandatory and are missing from the request will be automatically added. Return a reference to the `Reservation` in the Location header.
     *
     * @tags Booking Behaviors
     * @name CreateReservation
     * @summary Create Reservation
     * @request POST:/rental/booking/create-reservation
     * @secure
     */
    createReservation: (data: CreateReservationRequest, params: RequestParams = {}) =>
      this.request<void, Errors>({
        path: `/rental/booking/create-reservation`,
        method: 'POST',
        body: data,
        secure: true,
        type: ContentType.Json,
        ...params,
      }),

    /**
     * @description Modify a `Reservation` using the data provided. Optionally include the If-Match header to ensure the reservation has not already been modified since last retrieved. Protections that are mandatory or included and are missing from the request will be automatically added. Equipment that are mandatory and are missing from the request will be automatically added. Return a reference to the `Reservation` in the Location header.
     *
     * @tags Booking Behaviors
     * @name ModifyReservation
     * @summary Modify Reservation
     * @request POST:/rental/booking/modify-reservation
     * @secure
     */
    modifyReservation: (data: ModifyReservationRequest, params: RequestParams = {}) =>
      this.request<void, Errors>({
        path: `/rental/booking/modify-reservation`,
        method: 'POST',
        body: data,
        secure: true,
        type: ContentType.Json,
        ...params,
      }),

    /**
     * @description Retrieve the result of a data transaction.
     *
     * @tags Transaction Result
     * @name RetrieveTransactionResult
     * @summary Retrieve a transaction result
     * @request GET:/rental/booking/transactionResult/{transactionId}
     * @secure
     */
    retrieveTransactionResult: (transactionId: string, params: RequestParams = {}) =>
      this.request<TransactionResult, Errors>({
        path: `/rental/booking/transactionResult/${transactionId}`,
        method: 'GET',
        secure: true,
        ...params,
      }),
  };
}
