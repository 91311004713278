import { DOUBLE_DASH, EMPTY_VALUE } from 'utils/constants';
import { ConsentFields, ConsentFieldValues, ConsentStatus } from 'components/flexFlow/driver/consent/ConsentFormTypes';
import { isCorporateCountry } from 'utils/locationUtils';
import { AddressType, BranchV2 } from 'services/location/locationTypes';
import { ConsentSms } from 'services/consent/consentCommunicationTypes';
import { TFunction } from 'i18next';
import * as Yup from 'yup';
import { ConsentState } from 'redux/slices/consent/consentSliceTypes';

export const consentInitialValues: ConsentFieldValues = {
  [ConsentFields.Country]: EMPTY_VALUE,
  [ConsentFields.PhoneNumber]: EMPTY_VALUE,
  [ConsentFields.Status]: EMPTY_VALUE,
  [ConsentFields.UpdateCheckbox]: false,
};

export const displayConsentSection = (
  isAdditionalDriver: boolean | undefined,
  pickup: BranchV2 | undefined
): boolean => {
  return !isAdditionalDriver && isCorporateCountry(pickup?.addresses ?? [], AddressType.PHYSICAL);
};

export const hasPreviousConsentData = (consentData: ConsentSms | undefined): boolean => {
  return !!consentData?.status;
};

export const createOrUpdateConsent = (selectedConsentData: ConsentState | undefined): boolean | undefined => {
  return (
    !!selectedConsentData &&
    (selectedConsentData?.retrievedConsentData?.status !== selectedConsentData?.selectedStatus ||
      (selectedConsentData?.selectedStatus === ConsentStatus.Accepted && selectedConsentData?.consentCheckBox))
  );
};

export const consentFormValidationSchema = (t: TFunction<'translation'>, isModifyFlow: boolean) =>
  Yup.object().shape({
    country: Yup.string().when([ConsentFields.Status, ConsentFields.UpdateCheckbox], {
      is: (status: ConsentStatus, checkBoxFlag: boolean) => {
        return status === ConsentStatus.Accepted && (!checkBoxFlag || (isModifyFlow && checkBoxFlag));
      },
      then: () => Yup.string().required(t('validation.requiredField')),
      otherwise: () => Yup.string(),
    }),

    phoneNumber: Yup.string()
      .nullable()
      .transform((curr, orig) => (orig === '' ? null : curr))
      .when([ConsentFields.Status, ConsentFields.UpdateCheckbox], {
        is: (status: ConsentStatus, checkBoxFlag: boolean) => {
          return status === ConsentStatus.Accepted && (!checkBoxFlag || (isModifyFlow && checkBoxFlag));
        },
        then: () =>
          Yup.string()
            .required(t('validation.requiredField'))
            .test('length', t('validation.phoneNumberValidation'), (val) => (val && val.length == 10) || !val),
        otherwise: () => Yup.string(),
      }),
  });

export const getReadOnlyConsent = (
  t: TFunction<'translation'>,
  consentData?: ConsentSms
): { dataTestId: string; value: string }[] => {
  const consentStatus = consentData?.status ?? undefined;
  const statusDataTestId = 'consent-status';
  const phoneDataTestId = 'consent-phone';

  switch (consentStatus) {
    case ConsentStatus.Accepted:
      return [
        { dataTestId: statusDataTestId, value: t('consent.accepted') },
        { dataTestId: phoneDataTestId, value: consentData ? consentData?.phoneNumber : DOUBLE_DASH },
      ];
    case ConsentStatus.Declined:
      return [{ dataTestId: statusDataTestId, value: t('consent.declined') }];
    case ConsentStatus.Revoked:
      return [{ dataTestId: statusDataTestId, value: t('consent.revoked') }];
    default:
      return [{ dataTestId: statusDataTestId, value: DOUBLE_DASH }];
  }
};
