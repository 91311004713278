import useMediaQuery from '@mui/material/useMediaQuery';
import { Drawer } from '@mui/material';
import { FC, useContext } from 'react';
import { ResSummaryContext } from 'context/resSummary/ResSummaryContext';
import { DrawerContainer, SummaryContent } from 'components/resSummary/ReservationSummaryDrawer.styles';
import { InlineSummaryProps, SummaryDrawerProps } from 'components/resSummary/ReservationSummaryTypes';

export const InlineSummaryContainer: FC<InlineSummaryProps> = ({ children }) => {
  return (
    <InlineSummaryWrapper>
      <DrawerContainer data-testid='inlineSummaryContainerDrawerContainer'>{children}</DrawerContainer>
    </InlineSummaryWrapper>
  );
};

const InlineSummaryWrapper: FC<SummaryDrawerProps> = ({ children }) => {
  const { openDrawer, setOpenDrawer } = useContext(ResSummaryContext);
  const isMobileOrTablet = useMediaQuery('(max-width:800px)');
  return isMobileOrTablet ? (
    <Drawer
      style={{ zIndex: 2001 }}
      anchor={'left'}
      open={openDrawer}
      onClose={(): void => setOpenDrawer(false)}
      data-testid='inlineSummaryWrapperMobileTabletDrawer'>
      {children}
    </Drawer>
  ) : (
    <>{openDrawer && <SummaryContent data-testid='inlineSummaryWrapperContent'>{children}</SummaryContent>}</>
  );
};
