import { styled } from '@mui/material';
import { DefaultPageContainer } from 'components/shared/ui/styles/Global.styles';
import { ehiTheme } from '@ehi/ui';
import Card from '@mui/material/Card';

export const HistoryPageContainer = styled(DefaultPageContainer)`
  align-items: center;
  align-self: stretch;
  display: flex;
  flex-direction: column;
  gap: ${ehiTheme.spacing(3)};
  overflow-y: scroll;
  padding: ${ehiTheme.spacing(3)};
  height: 0;
  flex-grow: 1;
`;

export const HistoryCard = styled(Card)`
  min-height: fit-content;
  padding: 0;
`;
