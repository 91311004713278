import { FC, memo, useContext } from 'react';
import { DynamicBox, WrapperBox } from 'components/shared/ui/card/CardNavigation.styles';
import { CardNavigation } from 'components/shared/ui/card/CardNavigation';
import Divider from '@mui/material/Divider';
import { ResSummaryContext } from 'context/resSummary/ResSummaryContext';
import { CardNavigationProps } from './CardTypes';

const STICKY_WRAPPER_ID = 'card-nav-sticky-wrapper';
const PLACEHOLDER_WRAPPER_ID = 'card-nav-placeholder';

export const StickyCardNavigation: FC<CardNavigationProps> = memo(({ ...props }) => {
  const { openDrawer } = useContext(ResSummaryContext);

  return (
    <WrapperBox id={PLACEHOLDER_WRAPPER_ID} className={openDrawer ? 'drawer-open' : ''}>
      {/*
        card-nav-placeholder "holds the space" for when card-nav-sticky-wrapper becomes sticky to prevent page jumping
       */}
      <DynamicBox id={STICKY_WRAPPER_ID}>
        <CardNavigation {...props} />
      </DynamicBox>
      <Divider />
    </WrapperBox>
  );
});
