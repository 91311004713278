/* eslint-disable */
/* tslint:disable */
/*
 * ---------------------------------------------------------------
 * ## THIS FILE WAS GENERATED VIA SWAGGER-TYPESCRIPT-API        ##
 * ##                                                           ##
 * ## AUTHOR: acacode                                           ##
 * ## SOURCE: https://github.com/acacode/swagger-typescript-api ##
 * ---------------------------------------------------------------
 */

type UtilRequiredKeys<T, K extends keyof T> = Omit<T, K> & Required<Pick<T, K>>;

/**
 * A unique reference to a Business Account
 * @format urn
 * @pattern ^urn:com\.ehi:[a-zA-Z0-9]+:businessAccount:account:[a-zA-Z0-9]{3,8}$
 * @example "urn:com.ehi:xqa:businessAccount:account:XJ12345"
 */
export type BusinessAccountURN = string;

/**
 * A unique reference to a Billing Account
 * @format urn
 * @pattern ^urn:com\.ehi:[a-zA-Z0-9]+:businessAccount:account:[a-zA-Z0-9]{3,8}:billingAccount:[1-9][0-9]{2,14}$
 * @example "urn:com.ehi:xqa:businessAccount:account:XJ12345:billingAccount:539852572"
 */
export type BillingAccountURN = string;

/**
 * Business Account number associated with billing account.  This can either be the owning Business Account or a linked Business Account.
 * @minLength 3
 * @maxLength 8
 * @pattern ^[a-zA-Z0-9]{3,8}
 * @example "XJ12345"
 */
export type BusinessAccountNumber = string;

/**
 * @minLength 3
 * @maxLength 15
 * @pattern ^[1-9][0-9]{2,14}$
 * @example "102450461"
 */
export type BillingNumber = string;

export interface PaginationRequest {
  /**
   * The index of the page of results
   * @format int32
   * @min 1
   */
  pageIndex: number;
  /**
   * The number of results included in a page
   * @format int32
   * @min 1
   * @max 50
   * @default 50
   */
  pageSize?: number;
}

export type PaginationResponse = UtilRequiredKeys<PaginationRequest, 'pageIndex'> & {
  /**
   * The total number of results found in the search
   * @format int32
   * @min 0
   */
  totalResults: number;
  /**
   * The total number of pages found in the search
   * @format integer
   * @min 0
   */
  totalPages: number;
};

/** A single field by which to sort results, and a sort direction. */
export interface SortRequest {
  /** The name of the field which should be used to sort returned records. */
  field:
    | 'ACCOUNT_NAME'
    | 'ACCOUNT_NUMBER'
    | 'ANALYSIS_TYPE'
    | 'ADDRESS_LINE_1'
    | 'ADDRESS_CITY'
    | 'ADDRESS_COUNTRY_SUBDIVISION'
    | 'GROUP_BRANCH';
  /**
   * Sort direction specifies which direction results should be sorted for this field. Ascending order means smallest first,
   * while Descending means largest first. Alpha characters are smallest at the beginning of the alphabet (A), and largest
   * at the end (Z). If not specified, the default Sort Direction is Ascending.
   */
  direction?: 'ASCENDING' | 'DESCENDING';
}

export interface ChargeAuthorizationCriteria {
  /** @minItems 1 */
  chargeTypeCategories?: ChargeTypeCategory[];
  /** @minItems 1 */
  billingAvailabilities: BillingAvailability[];
  /**
   * @maxItems 50
   * @minItems 1
   */
  chargeTypes?: string[];
}

export interface ChargeTypeChargeAuthorizationRequest {
  pagination?: PaginationRequest;
  criteria?: ChargeAuthorizationCriteria;
}

/** Billing availability criteria to retrieve billing account. */
export interface ValidationCriteria {
  /** Business Account number associated with billing account.  This can either be the owning Business Account or a linked Business Account. */
  accountNumber: BusinessAccountNumber;
  billingNumber: BillingNumber;
  billingAvailability?: BillingAvailability;
}

/** Provide the Business Account Number and or a Billing Account Number to retrieve the appropriate Billing Account */
export interface AccountNumberCriteria {
  /** Business Account number associated with billing account.  This can either be the owning Business Account or a linked Business Account. */
  accountNumber?: BusinessAccountNumber;
  billingNumber?: BillingNumber;
}

export interface SearchAccountRequest {
  pagination?: PaginationRequest;
  /** A single field by which to sort results, and a sort direction. */
  sorting?: SortRequest;
  criteria: SearchAccountCriteria;
}

export interface SearchAccountCriteria {
  /**
   * List of account types. Accounts must belong to one of the provided types to be included in results.
   * @uniqueItems true
   */
  accountTypes?: BusinessAccountTypeURN[];
  /**
   * Partial or full name of the account. Accounts will be included in results if the account name exactly matches
   * the searched account name, or if the searched account name matches the beginning of the account name. This
   * matching is case insensitive.
   *
   * For example, searching for "ABC" would return accounts with names "ABC", "ABCD", and "abcxyz", but would not
   * return an account with the name "XYZABC" because the searched name is not found at the beginning of the account
   * name.
   *
   * At least three characters must be provided when searching by partial account name.
   * @minLength 3
   * @maxLength 80
   * @example "PROGRESSIVE INS-LOUISVILLE"
   */
  accountName?: string;
  brand?: Brand;
  /**
   * Whether only active accounts should be included in results, excluding all inactive accounts. If false
   * or not provided, both active and inactive accounts will be included.
   * @default false
   * @example true
   */
  activeAccountsOnly?: boolean;
  owningLocation?: {
    /**
     * List of owning groups. Accounts must have an owning location in one of the provided groups to be
     * included in results.
     * @uniqueItems true
     */
    groups?: string[];
  };
  /**
   * Optional filter to include or exclude Car Share accounts from results.
   *
   * If true, *only* Car Share accounts will be included in results.
   * If false, Car Share accounts will not be included in results.
   * If not provided, all accounts will be included regardless of whether each is a Car Share account.
   * @example true
   */
  includeCarShareAccounts?: boolean | null;
  /**
   * Optional filter to include or exclude Commute accounts from results.
   *
   * If true, *only* Commute accounts will be included in results.
   * If false, Commute accounts will not be included in results.
   * If not provided, all accounts will be included regardless of whether each is a Commute account.
   * @example true
   */
  includeCommuteAccounts?: boolean | null;
  mainPhoneNumber?: PhoneNumber;
  /** A code of letters and digits added to a postal address to aid in the sorting of mail. */
  postalCode?: PostalCode;
  /** The country code in [ISO 3166-1 alpha-3](https://www.iso.org/iso-3166-country-codes.html) format. */
  country?: Country;
}

export interface BusinessAccountSearchResult {
  /** Unique reference to a Business Account */
  urn?: AccountURN;
  /** Business Account Type */
  type?: BusinessAccountTypeURN;
  /** Name of the Business Account */
  name?: BusinessAccountName;
  /** Represents all Brands that this Business Account can interact with. */
  brands?: Brand[];
  /** Status of a Business Account */
  status?: BusinessAccountStatusURN;
  owningLocation?: Location;
  /**
   * A universal resource name for analysis type reference data. Analysis type is a means to further refine
   * the account type, which provides additional information relevant in reporting.
   */
  analysisType?: AnalysisTypeURN;
  /**
   * Whether this account is a Car Share account.
   * @example false
   */
  isCarShareAccount?: boolean;
  /**
   * Whether this account is a Commute account. Commute accounts are used for ride-sharing business.
   * @example false
   */
  isCommuteAccount?: boolean;
  mainAddress?: Address;
  /** Phone Point of Contact */
  mainPhone?: Phone;
}

/**
 * A universal resource name for analysis type reference data. Analysis type is a means to further refine
 * the account type, which provides additional information relevant in reporting.
 * @format urn
 * @pattern ^urn:com\.ehi:[a-zA-Z0-9]+:reference:businessAccount:analysisType:[A-Z_]+$
 * @example "urn:com.ehi:xqa:reference:businessAccount:analysisType:ENTERPRISE_LOCAL_SINGLE"
 */
export type AnalysisTypeURN = string;

/**
 * Name of the Business Account
 * @maxLength 80
 * @example "PROGRESSIVE INS-LOUISVILLE"
 */
export type BusinessAccountName = string;

/**
 * Status of a Business Account
 * @format urn
 * @pattern ^urn:com\.ehi:[a-zA-Z0-9]+:reference:businessAccount:accountStatus:[A-Z_]+$
 * @example "urn:com.ehi:xqa:reference:businessAccount:accountStatus:ACTIVE"
 */
export type BusinessAccountStatusURN = string;

/**
 * Business Account Type
 * @format urn
 * @pattern ^urn:com\.ehi:[a-zA-Z0-9]+:reference:businessAccount:accountType:[A-Z_]+$
 * @example "urn:com.ehi:xqa:reference:businessAccount:accountType:DEALERSHIP"
 */
export type BusinessAccountTypeURN = string;

export type AdditionalInformation =
  | AdditionalInformationAlgorithm
  | AdditionalInformationDropDown
  | AdditionalInformationExactValue
  | AdditionalInformationPattern
  | AdditionalInformationText;

/** A specific type of Additional Information for capturing input which must matching a specific algorithm. */
export type AdditionalInformationAlgorithm = {
  /**
   * The algorithm which is applied to the input for additional validation. Only used in specific circumstances
   * when customized logic must be used to check the validity of the input.
   */
  algorithm: 'HALCON_VIAJES';
};

export type AdditionalInformationBase = BaseAdditionalInformationBase &
  (
    | BaseAdditionalInformationBaseTypeMapping<'ALGORITHM', AdditionalInformationAlgorithm>
    | BaseAdditionalInformationBaseTypeMapping<'DROP_DOWN', AdditionalInformationDropDown>
    | BaseAdditionalInformationBaseTypeMapping<'EXACT_VALUE', AdditionalInformationExactValue>
    | BaseAdditionalInformationBaseTypeMapping<'PATTERN', AdditionalInformationPattern>
    | BaseAdditionalInformationBaseTypeMapping<'TEXT', AdditionalInformationText>
  );

/**
 * A specific type of Additional Information for capturing input from a set of pre-defined values. The list of
 * possible values is displayed to the user in a drop-down menu.
 */
export type AdditionalInformationDropDown = BaseAdditionalInformationBase & {
  /** A list of values from which the user can choose. */
  values: {
    /**
     * A single list item for a drop-down menu.
     * @example "OPTION1"
     */
    value?: string;
    /**
     * The sequence number used for relative ordering of list items. Items with smaller ordinals should be displayed
     * before items with larger ordinals.
     * @min 1
     * @example 1
     */
    ordinal?: number;
  }[];
};

/**
 * A specific type of Additional Information for capturing input from a pre-defined list of specific values.
 * The list of allowed values is not exposed to end users. Users must know one of the values in order to
 * successfully pass validation.
 */
export type AdditionalInformationExactValue = BaseAdditionalInformationBase & {
  /**
   * A list of values against which the user input will be validated. Users must provide a value which
   * matches one of the items in the list.
   */
  values: string[];
};

/** A specific type of Additional Information for capturing input with a specific format. */
export type AdditionalInformationPattern = BaseAdditionalInformationBase & {
  /**
   * A specific format that the value must match. The following characters are used to define the format:
   *
   * * \? - any alpha character (Aa-Zz)
   * * \# - any numeric character (0-9)
   * * \% - special characters
   * * \* - any character except blanks
   * * \& - blank
   * * \+ - any alpha-numeric character
   * @example "###%###%####"
   */
  format: string;
};

/** A specific type of Additional Information for capturing textual, numeric, or date input. */
export type AdditionalInformationText = BaseAdditionalInformationBase & {
  /**
   * The data type for the text field to control which characters are allowed.
   * @example "ALPHA_NUMERIC"
   */
  dataType: 'ALL' | 'ALPHA_NUMERIC' | 'ALPHA' | 'NUMERIC' | 'DATE';
  /**
   * The maximum number of characters allowed for the field.
   * @example 50
   */
  maxLength?: number;
};

/** Localized name and helper text for Additional Information in a specific language. */
export interface AdditionalInformationTranslation {
  /**
   * The localized name of the field.
   * @maxLength 70
   * @example "Employee ID"
   */
  name: string;
  /**
   * The localized helper text of the field.
   * @example "The ID of the employee."
   */
  helperText?: string;
  /**
   * A combination of language code and country where the country is optional. Country can be omitted to represent a default
   * locale for the language, which is used as a fallback for translations of the language when a more specific combination
   * of country and language code may not exist.
   *
   * For example, a user may want to set the default English text for some value, so they would create a language with
   * language code "eng" and no country. This language could be used as a fallback when users attempt to find English
   * translations for GBR, CAN, or any other country.
   *
   * Users may then want to set up more specific translations, like a slightly different text for British Engligh, and would
   * need to create a language with language code "eng" and country "GBR". This language would be used when requesting
   * translations in British English, but the default would be used for all other countries.
   */
  language: Language;
}

/**
 * A unique identifier for Additional Information.
 * @format urn
 * @pattern ^urn:com\.ehi:[a-zA-Z0-9]+:businessAccount:account:[a-zA-Z0-9]{3,8}:additionalInformation:[0-9]+$
 * @example "urn:com.ehi:xqa:businessAccount:account:XJ12345:additionalInformation:8324967211"
 */
export type AdditionalInformationURN = string;

/**
 * A stage in the rental process during which some piece of additional information must be validated.
 *
 * For example, if a piece of Additional Information must be validated at RENTAL_AGREEMENT_OPEN, that means the
 * field must be captured and validated before a Rental Agreement can be successfully opened.
 */
export enum RentalStage {
  ARMS_RESERVATION = 'ARMS_RESERVATION',
  RESERVATION = 'RESERVATION',
  RENTAL_AGREEMENT_OPEN = 'RENTAL_AGREEMENT_OPEN',
  RENTAL_AGREEMENT_CLOSE = 'RENTAL_AGREEMENT_CLOSE',
}

/** Governs how this field behaves on Retail Web applications. */
export interface WebDisplay {
  /**
   * Whether this field can be displayed on Retail Web applications.
   * @example true
   */
  isAllowed?: boolean;
  /**
   * Whether this field must be populated before the user can see a rate through Retail Web applications.
   * @example false
   */
  enforcePreRate?: boolean;
}

/** The user input validation request with optional current rental stage. */
export interface ValidateAdditionalInformationRequest {
  /**
   * A stage in the rental process during which some piece of additional information must be validated.
   *
   * For example, if a piece of Additional Information must be validated at RENTAL_AGREEMENT_OPEN, that means the
   * field must be captured and validated before a Rental Agreement can be successfully opened.
   */
  currentRentalStage?: RentalStage;
  /**
   * Whether the current validation fields are collected from a Retail Web application. If true, only the
   * web-allowed fields for the current rental stage are required, while all other fields are optional. In
   * the case where no rental stage is provided, web-allowed fields across all stages are required, while
   * all others are optional. If false or not provided, all fields will be validated for the current stage,
   * regardless of whether the fields are web-approved.
   * @default false
   * @example false
   */
  isWebApplication?: boolean;
  /**
   * Whether to ignore any fields that are not provided for validation. If true, only the provided fields will
   * be validated. If false or not provided, all required fields will be validated, and any missing required
   * fields will cause validation to fail.
   * @default false
   * @example false
   */
  ignoreMissingRequiredFields?: boolean;
  /** The list of Additional Information fields and user values to be validated. */
  additionalInformationValues: CapturedAdditionalInformation[];
}

/** A user-provided value and a reference to the specific Additional Information field for which it was captured. */
export interface CapturedAdditionalInformation {
  /** A unique identifier for Additional Information. */
  additionalInformation: AdditionalInformationURN;
  /**
   * The user-provided value for this piece of Additional Information.
   * @example "User Text"
   */
  value: string;
}

export type PaymentMethod = BasePaymentMethod &
  (
    | BasePaymentMethodTypeMapping<'CARD', PaymentMethodCard>
    | BasePaymentMethodTypeMapping<'AIR_PLUS', PaymentMethodAirPlus>
    | BasePaymentMethodTypeMapping<'COLLECTIONS', PaymentMethodCollections>
    | BasePaymentMethodTypeMapping<'CENTRAL_BILL_CARD', PaymentMethodCentralBillCard>
    | BasePaymentMethodTypeMapping<'DIRECT_DEBIT', PaymentMethodDirectDebit>
    | BasePaymentMethodTypeMapping<'INTERNAL_DIRECT_BILL', PaymentMethodInternalDirectBill>
    | BasePaymentMethodTypeMapping<'VOUCHER', PaymentMethodVoucher>
    | BasePaymentMethodTypeMapping<'PURCHASE_ORDER', PaymentMethodPurchaseOrder>
  );

export interface PaymentMethodBase {
  /** The type of payment method. */
  type:
    | 'CARD'
    | 'AIR_PLUS'
    | 'COLLECTIONS'
    | 'CENTRAL_BILL_CARD'
    | 'DIRECT_DEBIT'
    | 'INTERNAL_DIRECT_BILL'
    | 'VOUCHER'
    | 'PURCHASE_ORDER';
  /**
   * The intended use for the payment method.
   *
   * * RENTAL - The payment method is intended to cover the cost of rentals.
   * * VOUCHER - The payment method is intended to cover the cost of any vouchers used by the account.
   */
  intendedUse: 'RENTAL' | 'VOUCHER';
  /**
   * The stage at which the payment method is settled. Payment methods intended for use on vouchers will
   * always have a settlement stage of DIRECT_BILL_ISSUED.
   */
  settlementStage: 'RENTAL_AGREEMENT_CLOSE' | 'DIRECT_BILL_ISSUED';
  /**
   * Whether this payment method allows coupons. Coupons are not allowed for vouchers, or for any payment
   * method intended to be used for vouchers.
   * @example false
   */
  allowCoupons: boolean;
}

/**
 * A payment method for charging a billing account's Air Plus credit card on file.
 * @example {"type":"AIR_PLUS","intendedUse":"RENTAL","settlementStage":"DIRECT_BILL_ISSUED","allowCoupons":false,"referenceToken":"363977459454471"}
 */
export type PaymentMethodAirPlus = PaymentMethodBase & AirPlusCard;

/**
 * A payment method for charging a billing account's credit or debit card on file.
 * @example {"type":"CARD","intendedUse":"VOUCHER","settlementStage":"DIRECT_BILL_ISSUED","allowCoupons":false,"allowDebitCard":true,"referenceToken":"363977459454471"}
 */
export type PaymentMethodCard = PaymentMethodBase & CreditCard;

/**
 * A payment method for charging the billing account and settling using a Central Bill Card.
 * @example {"type":"CENTRAL_BILL_CARD","intendedUse":"RENTAL","settlementStage":"DIRECT_BILL_ISSUED","allowCoupons":false}
 */
export type PaymentMethodCentralBillCard = PaymentMethodBase;

/**
 * A payment method for charging the billing account and settling through an invoice.
 * @example {"type":"COLLECTIONS","intendedUse":"RENTAL","settlementStage":"DIRECT_BILL_ISSUED","allowCoupons":false}
 */
export type PaymentMethodCollections = PaymentMethodBase;

/**
 * A payment method for directly transferring funds into one of multiple EHI accounts.
 * @example {"type":"DIRECT_DEBIT","intendedUse":"RENTAL","settlementStage":"DIRECT_BILL_ISSUED","allowCoupons":false,"directDebitProfile":"urn:com.ehi:xqa:reference:businessAccount:billing:directDebitProfile:USCST"}
 */
export type PaymentMethodDirectDebit = PaymentMethodBase & DirectDebit;

/**
 * A payment method used for intracompany transactions for licensees.
 * @example {"type":"INTERNAL_DIRECT_BILL","intendedUse":"RENTAL","settlementStage":"DIRECT_BILL_ISSUED","allowCoupons":false}
 */
export type PaymentMethodInternalDirectBill = PaymentMethodBase;

/**
 * A payment method for charging the billing account through a purchase order.
 * @example {"type":"PURCHASE_ORDER","intendedUse":"RENTAL","settlementStage":"DIRECT_BILL_ISSUED","allowCoupons":false}
 */
export type PaymentMethodPurchaseOrder = PaymentMethodBase;

export type PaymentMethods = PaymentMethod[];

/**
 * A payment method for using a voucher to cover a portion or all of a rental.
 * @example {"type":"VOUCHER","intendedUse":"RENTAL","settlementStage":"DIRECT_BILL_ISSUED","allowCoupons":false,"voucherType":"urn:com.ehi:xqa:reference:businessAccount:billing:voucherType:XOTTO","isElectronic":true}
 */
export type PaymentMethodVoucher = PaymentMethodBase & Voucher;

export interface AirPlusCard {
  /** A reference token from the Payment system. */
  referenceToken?: PaymentReferenceToken;
}

export interface CreditCard {
  /**
   * Whether this payment method allows a debit card. If false, only credit cards can be used. If true, both
   * debit and credit cards can be used.
   * @example true
   */
  allowDebitCard: boolean;
  /** A reference token from the Payment system. */
  referenceToken: PaymentReferenceToken;
}

export interface DirectDebit {
  /**
   * A reference to the direct debit profile which can be used for payment.
   * @format urn
   * @pattern ^urn:com\.ehi:[a-zA-Z0-9]+:reference:businessAccount:billing:directDebitProfile:[A-Z]+(_[A-Z]+)*$
   * @example "urn:com.ehi:xqa:reference:businessAccount:billing:directDebitProfile:USCST"
   */
  directDebitProfile: string;
}

/**
 * A reference token from the Payment system.
 * @pattern ^[0-9]+$
 * @example "363977459454471"
 */
export type PaymentReferenceToken = string;

export interface Voucher {
  /**
   * A universal resource name for a type of payment voucher.
   * @format urn
   * @pattern ^urn:com\.ehi:[a-zA-Z0-9]+:reference:businessAccount:billing:voucherType:[A-Z_]+$
   * @example "urn:com.ehi:xqa:reference:businessAccount:billing:voucherType:XOTTO"
   */
  voucherType: string;
  /**
   * Whether this voucher is electronic.
   * @example true
   */
  isElectronic: boolean;
}

/** Provide the Business Account Number and Billing Availability information to retrieve the appropriate Billing Account */
export interface BillingAvailabilityCriteria {
  /** Business Account number associated with billing account.  This can either be the owning Business Account or a linked Business Account. */
  accountNumber: BusinessAccountNumber;
  billingAvailability: BillingAvailability;
}

export interface BillingAvailability {
  /**
   * A Country where this Billing Account is authorized for use.
   * @format urn
   * @pattern ^urn:com\.ehi:[a-zA-Z0-9]+:reference:businessAccount:country:[A-Z_]+$
   * @example "urn:com.ehi:xqa:reference:businessAccount:country:USA"
   */
  country: string;
  /**
   * A Brand where this Billing Account is authorized for use.
   * @format urn
   * @pattern ^urn:com\.ehi:[a-zA-Z0-9]+:reference:businessAccount:brand:[A-Z_]+$
   * @example "urn:com.ehi:xqa:reference:businessAccount:brand:ENTERPRISE"
   */
  brand: string;
  /**
   * A Line of Business where this Billing Account is authorized for use.
   * @format urn
   * @pattern ^urn:com\.ehi:[a-zA-Z0-9]+:reference:businessAccount:lineOfBusiness:[A-Z_]+$
   * @example "urn:com.ehi:xqa:reference:businessAccount:lineOfBusiness:CAR"
   */
  lineOfBusiness: string;
  chargeAuthorizations?: ChargeAuthorization[];
}

/**
 * @format urn
 * @pattern ^urn:com\.ehi:[a-zA-Z0-9]+:reference:billing:chargeType:[A-Z]{2}\d{5}$
 * @example "urn:com.ehi:xqa:reference:billing:chargeType:ET00051"
 */
export type ChargeType = string;

/**
 * This field is an Account Maintenance defined element and is used for grouping on the Account Maintenance GUI application.
 *
 * Renters may add additional items to their rental contracts, such as: ski rack, insurance, a GPS unit, etc. Those items are grouped into 4 charge authorization category types:
 *
 * * **PROTECTIONS** - DW/CDW/LDW/; Mexico Insurance; PAI; PAI/PEC; Roadside Assistance Protection; SLP
 * * **ADDITIONAL_PRODUCTS** - Child Seat; GPS Unit; Infant Seat; Luggage Rack; Hands Free Mount; Mobile Accessories - Ear Buds; etc.
 * * **ANCILLARY_CHARGES** - Additional Driver Fee; Delivery Charge; Drop Fee; Fuel Service Option; CO2 Offset (ECARS 2.0 only); etc.
 * * **MISCELLANEOUS_CHARGES** - Damage Admin Fee; CB Stand Alone; Collection & Delivery; Credit Report Charge; Congestion Zone Charge; etc
 * @format urn
 * @pattern ^urn:com\.ehi:[a-zA-Z0-9]+:reference:businessAccount:billing:chargeTypeCategory:[A-Z_]+$
 * @example "urn:com.ehi:xqa:reference:businessAccount:billing:chargeTypeCategory:ADDITIONAL_PRODUCTS"
 */
export type ChargeTypeCategory = string;

export interface ChargeAuthorization {
  chargeType?: ChargeType;
  /**
   * This field is an Account Maintenance defined element and is used for grouping on the Account Maintenance GUI application.
   *
   * Renters may add additional items to their rental contracts, such as: ski rack, insurance, a GPS unit, etc. Those items are grouped into 4 charge authorization category types:
   *
   * * **PROTECTIONS** - DW/CDW/LDW/; Mexico Insurance; PAI; PAI/PEC; Roadside Assistance Protection; SLP
   * * **ADDITIONAL_PRODUCTS** - Child Seat; GPS Unit; Infant Seat; Luggage Rack; Hands Free Mount; Mobile Accessories - Ear Buds; etc.
   * * **ANCILLARY_CHARGES** - Additional Driver Fee; Delivery Charge; Drop Fee; Fuel Service Option; CO2 Offset (ECARS 2.0 only); etc.
   * * **MISCELLANEOUS_CHARGES** - Damage Admin Fee; CB Stand Alone; Collection & Delivery; Credit Report Charge; Congestion Zone Charge; etc
   */
  chargeTypeCategory?: ChargeTypeCategory;
  /**
   * @format urn
   * @pattern ^urn:com\.ehi:[a-zA-Z0-9]+:reference:businessAccount:billing:authorizationValueType:[A-Z_]+$
   * @example "urn:com.ehi:xqa:reference:businessAccount:billing:authorizationValueType:OPTIONAL"
   */
  authorizationValueType?: string;
}

export interface BillingAccount {
  /** A unique reference to a Billing Account */
  urn: BillingAccountURN;
  /**
   * Billing Number from an External Partner's billing system.
   * @pattern ^[1-9][0-9]{2,14}$
   * @example "102453468"
   */
  externalNumber: string;
  /**
   * An External Partner that this Billing Account represents
   * @format urn
   * @pattern ^urn:com\.ehi:[a-zA-Z0-9]+:reference:businessAccount:billing:externalPartner:[A-Z_]+$
   * @example "urn:com.ehi:xqa:reference:businessAccount:billing:externalPartner:EXAMPLE"
   */
  externalPartner?: string;
  /** @example "Service 029" */
  name: string;
  /**
   * The legal name associated with the Billing Account.
   * @example "ENTERPRISE LEASING COMPANY OF STL, LLC"
   */
  legalName: string;
  /**
   * The status of the Billing Account:
   *
   * * Approved
   * * Unapproved
   * * Canceled
   * * Suspended
   * @format urn
   * @pattern ^urn:com\.ehi:[a-zA-Z0-9]+:reference:businessAccount:billing:billingAccountStatus:[A-Z_]+$
   * @example "urn:com.ehi:xqa:reference:businessAccount:billing:billingAccountStatus:APPROVED"
   */
  status: string;
  /** Additional Business Accounts that this Billing Account is Authorized for use with. */
  linkedBusinessAccounts?: BusinessAccountURN[];
}

export type BillingAccountWithPaymentMethods = BillingAccount & {
  paymentMethods?: PaymentMethods;
};

/**
 * The Business Function of this Contact Information
 * @format urn
 * @pattern ^urn:com\.ehi:[a-zA-Z0-9]+:reference:businessAccount:businessFunction:[A-Z_]+$
 * @example "urn:com.ehi:xqa:reference:businessAccount:businessFunction:MAIN"
 */
export type ContactInformationURN = string;

/** Contact Information associated with a Business Account */
export type ContactInformation = OrganizationContactInformation & {
  /** The Business Function of this Contact Information */
  urn?: ContactInformationURN;
  /** A collection of Contacts associated with this Business Function */
  contacts?: Contacts;
};

/** Organization level Contact Information */
export interface OrganizationContactInformation {
  /** A collection of the Points of Contact */
  contactPoints?: ContactPoint[];
  address?: Address;
}

/** A collection of Contacts associated with this Business Function */
export type Contacts = Contact[];

/** An Individual's Contact Information */
export interface Contact {
  /**
   * Contact's Title
   * @maxLength 20
   * @example "CEO"
   */
  businessTitle?: string;
  name?: Name;
  /** A collection of the Points of Contact */
  contactPoints?: ContactPoint[];
  /**
   * Indicates that this is the primary Contact
   * @default false
   * @example true
   */
  isPrimary?: boolean;
}

export type ContactPoint =
  | ({
      type: 'EMAIL';
    } & Email)
  | ({
      type: 'PHONE';
    } & Phone)
  | ({
      type: 'FAX';
    } & Fax);

/** A Point of Contact */
export type ContactPointBase = BaseContactPointBase &
  (
    | BaseContactPointBaseTypeMapping<'EMAIL', Email>
    | BaseContactPointBaseTypeMapping<'PHONE', Phone>
    | BaseContactPointBaseTypeMapping<'FAX', Fax>
  );

export interface Address {
  /**
   * Attention line of an Address
   * @maxLength 80
   * @example "Mr. Smith"
   */
  attention?: string;
  /**
   * Address line 1
   * @maxLength 80
   * @example "555 1st Avenue"
   */
  line1?: string;
  /**
   * Address line 2
   * @maxLength 80
   * @example "Manhattan"
   */
  line2?: string;
  /**
   * Address line 3
   * @maxLength 80
   */
  line3?: string;
  /**
   * Address line 4
   * @maxLength 80
   */
  line4?: string;
  /**
   * @maxLength 80
   * @example "New York"
   */
  city?: string;
  /**
   * The Country Subdivision
   * @format urn
   * @pattern ^urn:com\.ehi:[a-zA-Z0-9]+:reference:businessAccount:countrySubdivision:[A-Z_]+$
   * @example "urn:com.ehi:xqa:reference:businessAccount:countrySubdivision:NY"
   */
  countrySubdivision?: string;
  /**
   * The type of Country Subdivision such as State or Province
   * @format urn
   * @pattern ^urn:com\.ehi:[a-zA-Z0-9]+:reference:businessAccount:countrySubdivisionType:[A-Z_]+$
   * @example "urn:com.ehi:xqa:reference:businessAccount:countrySubdivisionType:STATE"
   */
  countrySubdivisionType?: string;
  /** A code of letters and digits added to a postal address to aid in the sorting of mail. */
  postalCode?: PostalCode;
  /**
   * @format urn
   * @pattern ^urn:com\.ehi:[a-zA-Z0-9]+:reference:businessAccount:country:[A-Z_]+$
   * @example "urn:com.ehi:xqa:reference:businessAccount:country:USA"
   */
  country?: string;
}

export interface WebExperience {
  /**
   * The customized policies for this account, which may include overriding certain policy types with
   * custom policy messages, or hiding certain policies altogether. Any policy types not explicitly
   * included in this list should be displayed normally per the location-based rules.
   *
   * If all of the policies are default, no policies will be returned in this list.
   */
  policies?: Policy[];
  /**
   * Whether marketing messages should be displayed on Retail Web applications for this account.
   * @example false
   */
  displayMarketingMessages: boolean;
  /**
   * Whether this account can be removed from the session or reservation. Accounts who refer customers
   * to the application and want to retain the benefits of the referral may disable account removal.
   * @example true
   */
  allowAccountRemoval: boolean;
  /**
   * Whether this account is eligible for upgrade offers through Retail Web applications.
   * @example true
   */
  allowUpgradeOffer: boolean;
  /** A list of email addresses to which notifications can be sent. */
  notificationEmailAddresses?: EmailAddress[];
  /** A list of the preferred vehicle classes for the account. */
  preferredVehicleClasses?: VehicleClass[];
  /**
   * A list of custom links to be displayed on Retail Web applications for the account. Links may be
   * set up for different locales, but only the links matching the provided Ehi-Locale header will be
   * returned upon retrieval.
   */
  customLinks?: CustomLink[];
  /** The locale in [ISO 639-3](https://iso639-3.sil.org/) for language and [ISO 3166-1 alpha-3](https://www.iso.org/iso-3166-country-codes.html) for country code */
  defaultLocale: Locale;
  /**
   * A custom message to be displayed on Retail Web applications for the account. Various messages may be
   * set up for different locales, but only the message matching the provided Ehi-Locale header will be
   * returned upon retrieval.
   *
   * If any messages are defined, one must be set up for the default locale. If a message has not been set
   * up for the requested Ehi-Locale, the message for the default locale will be returned instead.
   */
  customMessage?: CustomMessage;
}

/**
 * A custom link to be displayed on Retail Web applications for the account. Links may be set up for
 * different locales, but only the links matching the provided Ehi-Locale header will be returned upon
 * retrieval.
 */
export interface CustomLink {
  /** The locale in [ISO 639-3](https://iso639-3.sil.org/) for language and [ISO 3166-1 alpha-3](https://www.iso.org/iso-3166-country-codes.html) for country code */
  locale?: Locale;
  /**
   * The label for the custom link.
   * @maxLength 255
   * @example "Customized Link"
   */
  label?: string;
  /**
   * The location of the linked item.
   * @format uri
   * @maxLength 1000
   * @example "https://example.com/"
   */
  uri?: string;
}

/**
 * A custom message to be displayed on Retail Web applications for the account. Various messages may be
 * set up for different locales, but only the message matching the provided Ehi-Locale header will be
 * returned upon retrieval.
 *
 * If any messages are defined, one must be set up for the default locale. If a message has not been set
 * up for the requested Ehi-Locale, the message for the default locale will be returned instead.
 */
export interface CustomMessage {
  /** The locale in [ISO 639-3](https://iso639-3.sil.org/) for language and [ISO 3166-1 alpha-3](https://www.iso.org/iso-3166-country-codes.html) for country code */
  locale?: Locale;
  /**
   * The text of the custom message for the associated locale.
   * @maxLength 2000
   * @example "Customized message text."
   */
  value?: string;
}

/**
 * A custom value which overrides the default policy text of a certain policy type for the account.
 *
 * If any custom values are defined, one must be set up for the default locale.
 */
export interface CustomValue {
  /** The locale in [ISO 639-3](https://iso639-3.sil.org/) for language and [ISO 3166-1 alpha-3](https://www.iso.org/iso-3166-country-codes.html) for country code */
  locale?: Locale;
  /**
   * The text of the custom value for the associated locale.
   * @maxLength 2000
   * @example "Customized policy text for the account."
   */
  value?: string;
}

export type Policy = BasePolicy &
  (BasePolicyDisplayMapping<'CUSTOM', CustomPolicy> | BasePolicyDisplayMapping<'HIDDEN', HiddenPolicy>);

type BasePolicy = object;

/**
 * A custom policy indicates that the specified policy type should be displayed with a customized message
 * for this account.
 *
 * The custom value will be returned in the locale matching the provided Ehi-Locale header. If no custom
 * value has been set up for the provided Ehi-Locale, the custom value for the default locale will be
 * returned instead.
 */
export type CustomPolicy = BasePolicy & {
  /**
   * A custom value which overrides the default policy text of a certain policy type for the account.
   *
   * If any custom values are defined, one must be set up for the default locale.
   */
  customValue: CustomValue;
};

/**
 * A hidden policy indicates that the specified policy type should not be displayed for this account.
 * @example {"type":"urn:com.ehi:xqa:reference:businessAccount:policyType:AFTER_HOURS","display":"HIDDEN"}
 */
export type HiddenPolicy = BasePolicy;

/**
 * A vehicle classification code used to sort/group vehicles. Vehicle classes are four characters long and are abbreviations
 * for the major features of a given vehicle.
 *
 * Here are how the different characters are interpreted:
 *   - First letter: Size of the Vehicle
 *   - Second Letter: Number of Doors
 *   - Third Letter: Transmission & Drive
 *   - Fourth Letter: Fuel & A/C
 *
 * _Example:_ FVAV => Full Size, Passenger Van, Automatic, Petrol & A/C
 * @format urn
 * @pattern ^urn:com\.ehi:[a-zA-Z0-9]+:reference:vehicle:vehicleClass:[CDEFGHIJLMNOPRSUWX]{1}[BCDEFGHIJKLMNOPQRSTVWXYZ]{1}[ABCDMN]{1}[ABCDEFHILMNQRSUVXZ]{1}$
 * @example "urn:com.ehi:xqa:reference:vehicle:vehicleClass:ICAR"
 */
export type VehicleClass = string;

/**
 * Unique reference to a Business Account
 * @format urn
 * @pattern ^urn:com\.ehi:[a-zA-Z0-9]+:businessAccount:account:[a-zA-Z0-9]{3,8}$
 * @example "urn:com.ehi:xqa:businessAccount:account:XJ12345"
 */
export type AccountURN = string;

/**
 * The IATA (International Air Travel Association) Number represents an authorized travel agent. An IATA Number may only be associated with one Business Account.
 * @pattern ^[A-Z0-9]{1,8}$
 * @example "01519000"
 */
export type IATANumber = string;

export interface BaseAccount {
  /** Unique reference to a Business Account */
  urn?: AccountURN;
  /**
   * Business Account Type
   * @format urn
   * @pattern ^urn:com\.ehi:[a-zA-Z0-9]+:reference:businessAccount:accountType:[A-Z_]+$
   * @example "urn:com.ehi:xqa:reference:businessAccount:accountType:DEALERSHIP"
   */
  type?: string;
  /**
   * Name of the Business Account
   * @maxLength 80
   * @example "PROGRESSIVE INS-LOUISVILLE"
   */
  name?: string;
  /**
   * The legal name may be different than the name given to a Business Account
   * @maxLength 120
   * @example "ENTERPRISE LEASING COMPANY OF ORLANDO, LLC"
   */
  legalName?: string;
  /** Represents all Brands that this Business Account can interact with */
  brands?: Brand[];
  /**
   * Status of a Business Account
   * @format urn
   * @pattern ^urn:com\.ehi:[a-zA-Z0-9]+:reference:businessAccount:accountStatus:[A-Z_]+$
   * @example "urn:com.ehi:xqa:reference:businessAccount:accountStatus:ACTIVE"
   */
  status?: string;
  owningLocation?: Location;
  /** The IATA (International Air Travel Association) Number represents an authorized travel agent. An IATA Number may only be associated with one Business Account. */
  iataNumber?: IATANumber;
  /**
   * @format urn
   * @pattern ^urn:com\.ehi:[a-zA-Z0-9]+:reference:businessAccount:analysisType:[A-Z_]+$
   * @example "urn:com.ehi:xqa:reference:businessAccount:analysisType:ENTERPRISE_LOCAL_SINGLE"
   */
  analysisType?: string;
  /**
   * @format urn
   * @pattern ^urn:com\.ehi:[a-zA-Z0-9]+:reference:businessAccount:country:[A-Z_]+$
   * @example "urn:com.ehi:xqa:reference:businessAccount:country:USA"
   */
  registrationCountry?: string;
  /**
   * Indicates if the company has the ability to assume liability for the rental vs the actual Renter being liable. Formerly known as companyAsDriverName.
   * @example false
   */
  companyCanAssumeLiability?: boolean;
  /**
   * This flag is used to validate use of contract ID on our branded websites. User will be asked to validate by either logging in to a loyalty profile or a pin number.
   * @example "PIN"
   */
  webAuthenticationMethod?: 'NONE' | 'LOYALTY' | 'PIN';
  /**
   * This flag determines whether the telematics information for truck accounts is collected for each individual driver, or if the telematics data applies for the account as a whole.  This can be used to determine which login to use for the telematics portal.
   * @default false
   * @example true
   */
  truckTelematicsByDriver?: boolean;
  /** A list of the Carrier Safety Identifiers associated with the account. */
  carrierSafetyIdentifiers?: string[];
}

/** Email Point of Contact */
export type Email = BaseContactPointBase & {
  emailAddress: EmailAddress;
};

/**
 * @maxLength 80
 * @pattern ^[\w-_\.+]*[\w-_\.]\@([\w]+\.)+[\w]+[\w]$
 * @example "email@address.com"
 */
export type EmailAddress = string;

/**
 * A code of letters and digits added to a postal address to aid in the sorting of mail.
 * @maxLength 10
 * @example "63141"
 */
export type PostalCode = string;

/**
 * @maxLength 20
 * @pattern ^[\d\s]{1,20}$
 * @example "3145121111"
 */
export type PhoneNumber = string;

/**
 * Phone Point of Contact
 * @example {"type":"PHONE","priority":1,"phoneNumber":"3145121111","extension":"2","countryCode":"1"}
 */
export type Phone = BaseContactPointBase & {
  phoneNumber: PhoneNumber;
  /**
   * @maxLength 10
   * @pattern ^\d{1,10}$
   * @example "2"
   */
  extension?: string;
  /**
   * @maxLength 4
   * @pattern ^\d{1,4}$
   * @example "1"
   */
  countryCode?: string;
};

/** Fax Point of Contact */
export type Fax = BaseContactPointBase & {
  phoneNumber: PhoneNumber;
};

export interface Name {
  /**
   * @format urn
   * @pattern ^urn:com\.ehi:[a-zA-Z0-9]+:reference:businessAccount:contactTitle:[A-Z_]+$
   * @example "urn:com.ehi:xqa:reference:businessAccount:contactTitle:MR"
   */
  title?: string;
  /**
   * @maxLength 30
   * @example "Mark"
   */
  givenName?: string;
  /**
   * @maxLength 31
   * @example "Jacob"
   */
  surname?: string;
  /**
   * @maxLength 80
   * @example "Mark Jacob"
   */
  fullName?: string;
}

export type ARMSAccount = BaseAccount & {
  /** Details related to ARMS configuration. */
  armsConfiguration?: ARMSConfiguration;
};

export type ReservationAccount = BaseAccount & {
  /** Details related to reservation configuration. */
  reservationConfiguration?: ReservationConfiguration;
  loyaltyProgram?: LoyaltyProgram;
};

export type RentalAgreementAccount = BaseAccount & {
  /** Details related to rental agreement configuration. */
  rentalAgreementConfiguration?: RentalAgreementConfiguration;
  loyaltyProgram?: LoyaltyProgram;
};

/** Details related to rental agreement configuration. */
export interface RentalAgreementConfiguration {
  /** Details of delivery */
  deliveryCollection?: DeliveryCollection;
  /**
   * Determines whether one-way Rentals are allowed for this Business Account
   * @format urn
   * @pattern ^urn:com\.ehi:[a-zA-Z0-9]+:reference:businessAccount:oneWayRentalType:[A-Z_]+$
   * @example "urn:com.ehi:xqa:reference:businessAccount:oneWayRentalType:NO_ONE_WAY_ALLOWED"
   */
  oneWayRentalType?: string;
  /**
   * Determines whether this Business Account is configured to allow a Credit Card on the Rental Agreement that was accepted during reservation.
   * @example true
   */
  allowReservationCreditCard?: boolean;
  /**
   * Determines whether this Business Account is configured to allow Credit Authorizations to exceed the maximum amount of the rental.
   * @example true
   */
  allowCreditAuthorizationOverRentalMaximum?: boolean;
  /**
   * Determines whether this Business Account is configured to allow upgrading vehicle classes
   * @example true
   */
  allowUpgrade?: boolean;
  /**
   * Defines whether or not this Business Account can be used for leisure use rentals. If not allowed, a separate Business Account for Leisure Use can be specified in the leisureAcctCustNbr field.
   * @example true
   */
  leisureAllowedInd?: boolean;
  /**
   * Uniquely identifies the account used for leisure rentals (when leisureAllowedInd is true this field will be empty)
   * @example "XJ12345"
   */
  leisureAcctCustNbr?: string;
  /**
   * Determines whether this Business Account allows Rate Editing
   * @format urn
   * @pattern ^urn:com\.ehi:[a-zA-Z0-9]+:reference:businessAccount:rateEdit:[A-Z_]+$
   * @example "urn:com.ehi:xqa:reference:businessAccount:rateEdit:NO_HARD_EDIT"
   */
  rateEdit?: string;
  /**
   * Uniquely identifies the vehicle class used to determine the rate representing either Corporate or Group rates.
   * @format urn
   * @pattern ^urn:com\.ehi:[a-zA-Z0-9]+:reference:businessAccount:vehicleClassRate:[A-Z_]+$
   * @example "urn:com.ehi:xqa:reference:businessAccount:vehicleClassRate:CORPORATE"
   */
  vehicleClassRate?: string;
  /** Determines when and how a ticket will be re-written. */
  rewriteInformation?: RewriteInformation;
  /**
   * This flag indicates whether or not a rental is eligible for frequent flyer points when the account is used as a contract ID.
   * @example true
   */
  allowFrequentFlyerEarnings?: boolean;
  /**
   * This flag indicates if rental invoices can be retrieved online by renter when the account is used as the contract ID.
   * @example true
   */
  allowOnlineReceipts?: boolean;
}

/** Details of delivery */
export interface DeliveryCollection {
  /**
   * @default false
   * @example true
   */
  isRestricted?: boolean;
  sites?: DeliveryCollectionSite[];
  branch?: Location;
}

export interface DeliveryCollectionSite {
  /**
   * A description of the site where the delivery/collection will take place.
   * @maxLength 256
   */
  description?: string;
  address?: {
    /**
     * Address line 1
     * @maxLength 80
     * @example "555 1st Avenue"
     */
    line1?: string;
    /**
     * Address line 2
     * @maxLength 80
     * @example "Manhattan"
     */
    line2?: string;
    /**
     * @maxLength 80
     * @example "New York"
     */
    city?: string;
    /**
     * The Country Subdivision
     * @format urn
     * @pattern ^urn:com\.ehi:[a-zA-Z0-9]+:reference:businessAccount:countrySubdivision:[A-Z_]+$
     * @example "urn:com.ehi:xqa:reference:businessAccount:countrySubdivision:NY"
     */
    countrySubdivision?: string;
    /**
     * The type of Country Subdivision such as State or Province
     * @format urn
     * @pattern ^urn:com\.ehi:[a-zA-Z0-9]+:reference:businessAccount:countrySubdivisionType:[A-Z_]+$
     * @example "urn:com.ehi:xqa:reference:businessAccount:countrySubdivisionType:STATE"
     */
    countrySubdivisionType?: string;
    /** A code of letters and digits added to a postal address to aid in the sorting of mail. */
    postalCode?: PostalCode;
    /**
     * @format urn
     * @pattern ^urn:com\.ehi:[a-zA-Z0-9]+:reference:businessAccount:country:[A-Z_]+$
     * @example "urn:com.ehi:xqa:reference:businessAccount:country:USA"
     */
    country?: string;
  };
}

/** Details related to reservation configuration. */
export interface ReservationConfiguration {
  /**
   * Determines whether this Business Account is configured to create a specific type of Reservation
   * @format urn
   * @pattern ^urn:com\.ehi:[a-zA-Z0-9]+:reference:businessAccount:reservationType:[A-Z_]+$
   * @example "urn:com.ehi:xqa:reference:businessAccount:reservationType:RETAIL"
   */
  reservationType?: string;
  /**
   * Determines whether this Business Account is configured to allow modifying a Reservation after it has been created.
   * @example true
   */
  allowModification?: boolean;
  /**
   * Determines whether this Business Account is configured to allow cancellation of a Reservation
   * @example true
   */
  allowCancel?: boolean;
  /**
   * Out-of-Hours booking allows an account to book a reservation at any location with Out-of-Hours loaded in LRD.
   * @example true
   */
  allowOutOfHoursBooking?: boolean;
  /**
   * This flag determines if this account can be used for booking via one of our corporate websites.
   * @example true
   */
  allowWebBooking?: boolean;
}

/** Details related to ARMS configuration. */
export interface ARMSConfiguration {
  /**
   * Determines whether this Business Account only uses ARMS® to create Reservations and not to manage the entire Rental
   * @example true
   */
  isReservationOnly?: boolean;
  /**
   * Determines whether this Business Account is configured to allow electronic communications with a partner
   * @example true
   */
  allowElectronicCommunications?: boolean;
  /**
   * Determines whether this Business Account is configured to allow voiding a Reservation.
   * @example true
   */
  allowVoidReservation?: boolean;
  /**
   * Determines whether this Business Account will incur additional charges.
   * @example true
   */
  incursAdditionalCharges?: boolean;
  /**
   * Determines Whether this Business account allows for re-writes to be performed by the rental branch.
   * @example true
   */
  allowRewrites?: boolean;
  /** Determines when and how a ticket will be re-written. */
  rewriteInformation?: RewriteInformation;
  /**
   * Determines  account type in ARMSConfiguration for ARMS Lob Default value which is same as Account field in ARMS.
   * @example "INSURANCE"
   */
  accountType?:
    | 'INSURANCE'
    | 'BODY_SHOP'
    | 'DEALERSHIP'
    | 'RETAIL'
    | 'CORPORATE'
    | 'FLEET'
    | 'EMPLOYEE'
    | 'GOVERNMENT'
    | 'CAR_SALE'
    | 'LEASING'
    | 'INSURANCE_ROOT'
    | 'OTHER'
    | 'AUTO_SUPERSTORE'
    | 'CREDIT_UNION'
    | 'VENDOR'
    | 'CREDIT_CARD'
    | 'ENTERPRISE_GROUP';
}

/**
 * @format urn
 * @pattern ^urn:com\.ehi:[a-zA-Z0-9]+:reference:businessAccount:brand:[A-Z_]+$
 * @example "urn:com.ehi:xqa:reference:businessAccount:brand:ALAMO"
 */
export type Brand = string;

/**
 * The country code in [ISO 3166-1 alpha-3](https://www.iso.org/iso-3166-country-codes.html) format.
 * @pattern ^[A-Z]{3}$
 * @example "USA"
 */
export type Country = string;

export interface LoyaltyProgram {
  /**
   * Determines whether this Business Account allows participation in Rewards accrual
   * @example true
   */
  isRewardEligible?: boolean;
  /**
   * @format urn
   * @pattern ^urn:com\.ehi:[a-zA-Z0-9]+:reference:businessAccount:loyaltySignupEligibility:[A-Z_]+$
   * @example "urn:com.ehi:xqa:reference:businessAccount:loyaltySignupEligibility:RETAIL_ONLY"
   */
  loyaltySignupEligibility?: string;
  /**
   * Determines whether this Business Account allows participation in the Travel Partner Program
   * @example true
   */
  allowTravelPartnerProgram?: boolean;
}

export interface Location {
  /**
   * @format urn
   * @pattern ^urn:com\.ehi:[a-zA-Z]+:location:branch:[0-9]{7}$
   * @example "urn:com.ehi:xqa:location:branch:1000001"
   */
  branch?: string;
  /**
   * Legacy Group/Branch Id
   * @minLength 4
   * @maxLength 4
   * @example "5811"
   */
  legacyGroupBranch?: string;
}

/** Determines when and how a ticket will be re-written. */
export interface RewriteInformation {
  /**
   * Determines the rewrite logic used by the rental system for this account.
   * @format urn
   * @pattern ^urn:com\.ehi:[a-zA-Z0-9]+:reference:businessAccount:rewriteType:[A-Z_]+$
   * @example "urn:com.ehi:xqa:reference:businessAccount:rewriteType:DEFAULT"
   */
  rewriteDayType?: string;
  /**
   * Determines the number of days to re-write a rental for this account if the Rewrite Day Type is custom.
   * @example 14
   */
  rewriteDayAmount?: number;
}

/**
 * The locale in [ISO 639-3](https://iso639-3.sil.org/) for language and [ISO 3166-1 alpha-3](https://www.iso.org/iso-3166-country-codes.html) for country code
 * @pattern ^[a-z]{3}-[A-Z]{3}$
 * @example "eng-USA"
 */
export type Locale = string;

/**
 * A combination of language code and country where the country is optional. Country can be omitted to represent a default
 * locale for the language, which is used as a fallback for translations of the language when a more specific combination
 * of country and language code may not exist.
 *
 * For example, a user may want to set the default English text for some value, so they would create a language with
 * language code "eng" and no country. This language could be used as a fallback when users attempt to find English
 * translations for GBR, CAN, or any other country.
 *
 * Users may then want to set up more specific translations, like a slightly different text for British Engligh, and would
 * need to create a language with language code "eng" and country "GBR". This language would be used when requesting
 * translations in British English, but the default would be used for all other countries.
 */
export interface Language {
  /**
   * The language code in [ISO 639-3](https://iso639-3.sil.org/) format.
   * @pattern ^[a-z]{3}$
   * @example "eng"
   */
  code: string;
  /** The country code in [ISO 3166-1 alpha-3](https://www.iso.org/iso-3166-country-codes.html) format. */
  country?: Country;
}

export interface Errors {
  errors: Error[];
}

export interface Error {
  /** A unique ID/Code for a particular validation failure. */
  code: string;
  /** The locale in [ISO 639-3](https://iso639-3.sil.org/) for language and [ISO 3166-1 alpha-3](https://www.iso.org/iso-3166-country-codes.html) for country code */
  locale: Locale;
  /** A human readable string describing the error which can be shown to an end-user. */
  localizedMessage: string;
  /**
   * A list of references to the specific element/field related to the error. This may be useful to distinguish to which field(s) an error applies, as a single code could be used for multiple fields.
   * @example []
   */
  paths?: string[];
  severity: 'INFO' | 'WARNING' | 'ERROR';
  /** Any additional debugging details which could be useful by a developer. */
  supportInformation?: string;
}

interface BaseAdditionalInformationBase {
  /** A unique identifier for Additional Information. */
  urn: AdditionalInformationURN;
  /**
   * The type of the Additional Information.
   * @example "TEXT"
   */
  type: 'TEXT' | 'DROP_DOWN' | 'EXACT_VALUE' | 'PATTERN' | 'ALGORITHM';
  /**
   * The sequence number used for relative ordering of fields. Fields with smaller ordinals should be displayed
   * before fields with larger ordinals.
   * @min 1
   * @example 1
   */
  ordinal?: number;
  /** Localized name and helper text for Additional Information in a specific language. */
  translation?: AdditionalInformationTranslation;
  /**
   * Whether this field applies only for business payers, or for all payers.
   * @example false
   */
  onlyForBusinessPayer: boolean;
  /**
   * The rental stages at which this information will be validated.
   *
   * If ARMS_RESERVATION is included in the list, it must be the only rental stage in the list; no other rental
   * stages are allowed.
   */
  requiredRentalStages?: RentalStage[];
  /** Governs how this field behaves on Retail Web applications. */
  webDisplay?: WebDisplay;
  /**
   * The list of places that this field should be printed.
   * @uniqueItems true
   */
  printedOn?: ('RENTAL_AGREEMENT' | 'INVOICE')[];
  /**
   * A universal resource name for the associated mapping field in the Odyssey system.
   * @format urn
   * @pattern ^urn:com\.ehi:[a-zA-Z0-9]+:reference:businessAccount:odysseyMapping:[A-Z0-9_]+$
   * @example "urn:com.ehi:xqa:reference:businessAccount:odysseyMapping:BILLING_REFERENCE"
   */
  odysseyMapping?: string;
  /**
   * A universal resource name for the associated mapping field in the ECARS system.
   * @format urn
   * @pattern ^urn:com\.ehi:[a-zA-Z0-9]+:reference:businessAccount:ecarsMapping:[A-Z0-9_]+$
   * @example "urn:com.ehi:xqa:reference:businessAccount:ecarsMapping:CLAIM_NUMBER"
   */
  ecarsMapping?: string;
  /**
   * Whether this field is currently active. If not active, it will not be displayed to a user to be captured,
   * and will not be validated.
   * @example true
   */
  isActive?: boolean;
}

type BaseAdditionalInformationBaseTypeMapping<Key, Type> = {
  type: Key;
} & Type;

type BasePaymentMethod = object;

type BasePaymentMethodTypeMapping<Key, Type> = {
  type: Key;
} & Type;

/** A Point of Contact */
interface BaseContactPointBase {
  type: 'EMAIL' | 'PHONE' | 'FAX';
  /**
   * The Priority of this Point of Contact within the collection
   * @format int32
   * @example 1
   */
  priority?: number;
}

type BaseContactPointBaseTypeMapping<Key, Type> = {
  type: Key;
} & Type;

type BasePolicyDisplayMapping<Key, Type> = {
  display: Key;
} & Type;

export type QueryParamsType = Record<string | number, any>;
export type ResponseFormat = keyof Omit<Body, 'body' | 'bodyUsed'>;

export interface FullRequestParams extends Omit<RequestInit, 'body'> {
  /** set parameter to `true` for call `securityWorker` for this request */
  secure?: boolean;
  /** request path */
  path: string;
  /** content type of request body */
  type?: ContentType;
  /** query params */
  query?: QueryParamsType;
  /** format of response (i.e. response.json() -> format: "json") */
  format?: ResponseFormat;
  /** request body */
  body?: unknown;
  /** base url */
  baseUrl?: string;
  /** request cancellation token */
  cancelToken?: CancelToken;
}

export type RequestParams = Omit<FullRequestParams, 'body' | 'method' | 'query' | 'path'>;

export interface ApiConfig<SecurityDataType = unknown> {
  baseUrl?: string;
  baseApiParams?: Omit<RequestParams, 'baseUrl' | 'cancelToken' | 'signal'>;
  securityWorker?: (securityData: SecurityDataType | null) => Promise<RequestParams | void> | RequestParams | void;
  customFetch?: typeof fetch;
}

export interface HttpResponse<D extends unknown, E extends unknown = unknown> extends Response {
  data: D;
  error: E;
}

type CancelToken = Symbol | string | number;

export enum ContentType {
  Json = 'application/json',
  FormData = 'multipart/form-data',
  UrlEncoded = 'application/x-www-form-urlencoded',
  Text = 'text/plain',
}

export class HttpClient<SecurityDataType = unknown> {
  public baseUrl: string = 'https://{environment}.api.ehi.dev';
  private securityData: SecurityDataType | null = null;
  private securityWorker?: ApiConfig<SecurityDataType>['securityWorker'];
  private abortControllers = new Map<CancelToken, AbortController>();
  private customFetch = (...fetchParams: Parameters<typeof fetch>) => fetch(...fetchParams);

  private baseApiParams: RequestParams = {
    credentials: 'same-origin',
    headers: {},
    redirect: 'follow',
    referrerPolicy: 'no-referrer',
  };

  constructor(apiConfig: ApiConfig<SecurityDataType> = {}) {
    Object.assign(this, apiConfig);
  }

  public setSecurityData = (data: SecurityDataType | null) => {
    this.securityData = data;
  };

  protected encodeQueryParam(key: string, value: any) {
    const encodedKey = encodeURIComponent(key);
    return `${encodedKey}=${encodeURIComponent(typeof value === 'number' ? value : `${value}`)}`;
  }

  protected addQueryParam(query: QueryParamsType, key: string) {
    return this.encodeQueryParam(key, query[key]);
  }

  protected addArrayQueryParam(query: QueryParamsType, key: string) {
    const value = query[key];
    return value.map((v: any) => this.encodeQueryParam(key, v)).join('&');
  }

  protected toQueryString(rawQuery?: QueryParamsType): string {
    const query = rawQuery || {};
    const keys = Object.keys(query).filter((key) => 'undefined' !== typeof query[key]);
    return keys
      .map((key) => (Array.isArray(query[key]) ? this.addArrayQueryParam(query, key) : this.addQueryParam(query, key)))
      .join('&');
  }

  protected addQueryParams(rawQuery?: QueryParamsType): string {
    const queryString = this.toQueryString(rawQuery);
    return queryString ? `?${queryString}` : '';
  }

  private contentFormatters: Record<ContentType, (input: any) => any> = {
    [ContentType.Json]: (input: any) =>
      input !== null && (typeof input === 'object' || typeof input === 'string') ? JSON.stringify(input) : input,
    [ContentType.Text]: (input: any) => (input !== null && typeof input !== 'string' ? JSON.stringify(input) : input),
    [ContentType.FormData]: (input: any) =>
      Object.keys(input || {}).reduce((formData, key) => {
        const property = input[key];
        formData.append(
          key,
          property instanceof Blob
            ? property
            : typeof property === 'object' && property !== null
            ? JSON.stringify(property)
            : `${property}`
        );
        return formData;
      }, new FormData()),
    [ContentType.UrlEncoded]: (input: any) => this.toQueryString(input),
  };

  protected mergeRequestParams(params1: RequestParams, params2?: RequestParams): RequestParams {
    return {
      ...this.baseApiParams,
      ...params1,
      ...(params2 || {}),
      headers: {
        ...(this.baseApiParams.headers || {}),
        ...(params1.headers || {}),
        ...((params2 && params2.headers) || {}),
      },
    };
  }

  protected createAbortSignal = (cancelToken: CancelToken): AbortSignal | undefined => {
    if (this.abortControllers.has(cancelToken)) {
      const abortController = this.abortControllers.get(cancelToken);
      if (abortController) {
        return abortController.signal;
      }
      return void 0;
    }

    const abortController = new AbortController();
    this.abortControllers.set(cancelToken, abortController);
    return abortController.signal;
  };

  public abortRequest = (cancelToken: CancelToken) => {
    const abortController = this.abortControllers.get(cancelToken);

    if (abortController) {
      abortController.abort();
      this.abortControllers.delete(cancelToken);
    }
  };

  public request = async <T = any, E = any>({
    body,
    secure,
    path,
    type,
    query,
    format,
    baseUrl,
    cancelToken,
    ...params
  }: FullRequestParams): Promise<HttpResponse<T, E>> => {
    const secureParams =
      ((typeof secure === 'boolean' ? secure : this.baseApiParams.secure) &&
        this.securityWorker &&
        (await this.securityWorker(this.securityData))) ||
      {};
    const requestParams = this.mergeRequestParams(params, secureParams);
    const queryString = query && this.toQueryString(query);
    const payloadFormatter = this.contentFormatters[type || ContentType.Json];
    const responseFormat = format || requestParams.format;

    return this.customFetch(`${baseUrl || this.baseUrl || ''}${path}${queryString ? `?${queryString}` : ''}`, {
      ...requestParams,
      headers: {
        ...(requestParams.headers || {}),
        ...(type && type !== ContentType.FormData ? { 'Content-Type': type } : {}),
      },
      signal: (cancelToken ? this.createAbortSignal(cancelToken) : requestParams.signal) || null,
      body: typeof body === 'undefined' || body === null ? null : payloadFormatter(body),
    }).then(async (response) => {
      const r = response.clone() as HttpResponse<T, E>;
      r.data = null as unknown as T;
      r.error = null as unknown as E;

      const data = !responseFormat
        ? r
        : await response[responseFormat]()
            .then((data) => {
              if (r.ok) {
                r.data = data;
              } else {
                r.error = data;
              }
              return r;
            })
            .catch((e) => {
              r.error = e;
              return r;
            });

      if (cancelToken) {
        this.abortControllers.delete(cancelToken);
      }

      if (!response.ok) throw data;
      return data;
    });
  };
}

/**
 * @title Business Account
 * @version 3.5.0
 * @baseUrl https://{environment}.api.ehi.dev
 * @contact AM Team
 *
 * # Overview
 *
 * The Business Account API is responsible for the configuration and management of Business Accounts and Billing Accounts.
 *
 * ## Business Account
 * Drives business operations for all of our Enterprise Holdings business partners. Business Accounts are allowed to have any number of child Business Accounts and may have any depth of hierarchy allowing for complex configurations that accommodate many scenarios.
 *
 * Each Business Account may have any number of Billing Accounts associated.
 *
 * ## Billing Account
 * Describes how Business Accounts will be billed for services rendered. Billing Accounts may have child Billing Accounts, but are not allowed to have any deeper hierarchy.
 *
 * Billing Accounts are allowed for use with any of the Linked Business Accounts that are associated to the Billing Account in addition to the Business Account that defines the Billing Account.
 *
 * ## Additional Information
 * The Account Maintenance system allows its business users to create additional information fields to collect various
 * information from customers. Additional information can be any information that a particular Business Account wants its
 * customers to provide at the time of reservation or during the rental process. Depending on the configuration, additional
 * information fields may be required at different stages of the rental.
 *
 * While defining additional information fields, business users are required to provide a field name with a maximum of 70
 * characters and select one of the applicable field types:
 *
 * * **Text** - define alpha/numeric text or date field for user input
 * * **Drop Down** - create a list of acceptable values which will be displayed to users
 * * **Exact Value** - define a set of specific allowed values which are hidden from users
 * * **Pattern** - define a format which the user input must match
 * * **Algorithm** - choose an algorithm used to validate the user input
 *
 * When retrieving additional information fields for a Business Account, a query parameter can optionally be provided to
 * specify that Inactive fields should be included in results. By default, only Active additional information fields are
 * included in results.
 *
 * ## Validate Additional Information
 * Validating user input for additional information can be done by providing a list of the user-entered values and fields,
 * and some additional fields to control how the validation works.
 *
 * The validation happens at certain stages in the rental lifecycle, and the current rental stage can be provided as an input
 * to trigger validation only for the fields which are required at the current stage.
 *
 * If the current rental stage is not provided, all active Additional Information fields must be provided in order to pass
 * validation. Otherwise, active Additional Information fields must be provided if their required rental stage matches the
 * provided current rental stage. Failing to provide the required Additional Information fields will cause validation to fail.
 * Providing extra Additional Information fields which are not required by the current rental stage will cause the provided
 * fields to be validated as well, which may lead to unintended validation failures.
 *
 * The validation also supports a flag to indicate whether the current validation fields are collected from a Retail Web
 * application. If the flag is set to true, only the web-allowed fields for the current rental stage will be required, while
 * all other fields will become optional. In the case where no rental stage is provided, web-allowed fields across all stages
 * will be required, while all others become optional. If false or not provided, all fields will be validated for the current
 * stage, regardless of whether the fields are web-approved.
 *
 * Another optional flag allows ignoring missing required fields. When this flag is set to true, omitted fields will not be
 * validated, even if they would be required based on the current rental stage. When this flag is not provided, or is set to
 * false, fields are required based on their rental stage as explained above.
 *
 * ## Web Experience
 * The Web Experience represents the account-specific preferences, policies, and messages which drive the user experience
 * for Retail Web applications. Retail Web applications can use this information to control what is displayed to users.
 *
 * The account preferences include the default language, communication email addresses, preferred vehicle classes, whether
 * to allow upgrade offers, and whether to show marketing messages.
 *
 * Local policies that are normally shown may be overridden to display custom policy messages, and may even be removed
 * entirely.
 *
 * ## Payment Methods
 * Payment methods are the ways in which billing accounts can be charged. Each billing account has one of several payment
 * methods:
 *
 * * **Card** - Payment by a credit or debit card on file.
 * * **Air Plus** - Payment by an Air Plus card.
 * * **Collections** - Payment of an invoice as reconciled with the Billing Department.
 * * **Central Bill Card** - Payment by a billing account's Central Bill Card.
 * * **Direct Debit** - Payment by direct transfer into one of multiple EHI accounts.
 * * **Internal Direct Bill** - Internal payment for intracompany transactions (used for licensees).
 * * **Voucher** - Payment by a voucher that covers part or all of the transaction.
 * * **Purchase Order** - Payment by a purchase order.
 *
 * Some of the payment methods include a reference token to the Payment system where payment details are stored. This allows
 * the Business Account domain to avoid PCI concerns.
 */
export class Api<SecurityDataType extends unknown> extends HttpClient<SecurityDataType> {
  businessAccount = {
    /**
     * @description Retrieves an approved Billing Account for the provided criteria. ## Criteria * Business Account Number (`accountNumber`) - The Number of a Business Account that either owns a Billing Account or that the Billing Account is authorized to bill on-behalf-of * Billing Account Number (`billingNumber`) - The Number of a Billing Account * Billing Availability * Country - The Country that a Billing Account is authorized for use in * Brand - The Brand that a Billing Account is authorized for use with * Line of Business - The Line of Business that a Billing Account is authorized for use with ### Business Account Only Use the `application/prs.com-ehi.accountNumbers+json` `media-type` and provide the required `accountNumber`. If there is only one configured Billing Account and Billing Account Enforcement is not set you will retrieve the Billing Account. ### Billing Account Only Use the `application/prs.com-ehi.accountNumbers+json` `media-type` and provide the required `billingNumber`. ### Business Account and Billing Account Use the `application/prs.com-ehi.accountNumbers+json` `media-type` and provide the required `accountNumber` and a `billingAccount` to retrieve the Billing Account. ### Billing Availability Use the `application/prs.com-ehi.availability+json` `media-type` and provide the required `accountNumber` and the Country, Brand and Line of Business.  If a Billing Account has been authorized for use it will be returned. ## Response Structures Two response media types are supported for different levels of detail on the returned Billing Account. Use the Accept header to specify the appropriate response media type. * `application/json` provides the basic Billing Account details. * `application/prs.com-ehi.billingAccountPaymentMethods+json` provides the basic Billing Account details with the addition of payment methods.
     *
     * @tags Billing Accounts
     * @name RetrieveBillingAccount
     * @summary Retrieve Billing Account
     * @request POST:/businessAccount/retrieve-billing-account
     * @secure
     */
    retrieveBillingAccount: (data: AccountNumberCriteria, params: RequestParams = {}) =>
      this.request<BillingAccount, Errors>({
        path: `/businessAccount/retrieve-billing-account`,
        method: 'POST',
        body: data,
        secure: true,
        ...params,
      }),

    /**
     * @description Validates that the provided Business Account is able to use the provided Billing Account for a given Brand, Country and Line of Business. For the combination to be valid, The Business Account must be either the owning Business Account or a linked Business Account and the Billing Account must be available for the given combination of Brand, Country and Line of Business.
     *
     * @tags Billing Accounts
     * @name ValidateBillingAccount
     * @summary Validate Billing Account
     * @request POST:/businessAccount/validate-billing-account
     * @secure
     */
    validateBillingAccount: (data: ValidationCriteria, params: RequestParams = {}) =>
      this.request<boolean, Errors>({
        path: `/businessAccount/validate-billing-account`,
        method: 'POST',
        body: data,
        secure: true,
        format: 'json',
        ...params,
      }),

    /**
     * @description Retrieve an active Business Account with the provided Account Number
     *
     * @tags Business Accounts
     * @name RetrieveBusinessAccount
     * @summary Retrieve Business Account
     * @request GET:/businessAccount/account/{accountNumber}
     * @secure
     */
    retrieveBusinessAccount: (accountNumber: BusinessAccountNumber, params: RequestParams = {}) =>
      this.request<ReservationAccount, Errors>({
        path: `/businessAccount/account/${accountNumber}`,
        method: 'GET',
        secure: true,
        ...params,
      }),

    /**
     * @description Redirects to the Business Account associated with the provided IATA Number
     *
     * @tags Business Accounts
     * @name RetrieveAccountByIataNumber
     * @summary Retrieve an active Business Account by IATA Number
     * @request GET:/businessAccount/iataAccount/{iataNumber}
     * @secure
     */
    retrieveAccountByIataNumber: (iataNumber: IATANumber, params: RequestParams = {}) =>
      this.request<any, Errors>({
        path: `/businessAccount/iataAccount/${iataNumber}`,
        method: 'GET',
        secure: true,
        ...params,
      }),

    /**
     * @description Search for Business Accounts by providing criteria.
     *
     * @tags Business Accounts
     * @name SearchBusinessAccount
     * @summary Search for Business Accounts.
     * @request POST:/businessAccount/search-account
     * @secure
     */
    searchBusinessAccount: (data: SearchAccountRequest, params: RequestParams = {}) =>
      this.request<
        {
          pagination?: PaginationResponse;
          businessAccounts?: BusinessAccountSearchResult[];
        },
        Errors
      >({
        path: `/businessAccount/search-account`,
        method: 'POST',
        body: data,
        secure: true,
        ...params,
      }),

    /**
     * @description Retrieve the list of Additional Information fields defined for an account. Supports including inactive fields using an optional query parameter. By default, only active fields will be included in the response.
     *
     * @tags Business Accounts
     * @name RetrieveAdditionalInformation
     * @summary Retrieve the list of Additional Information fields defined for an account.
     * @request GET:/businessAccount/account/{accountNumber}/additionalInformation
     * @secure
     */
    retrieveAdditionalInformation: (
      accountNumber: BusinessAccountNumber,
      query?: {
        /**
         * Whether to include inactive Additional Information records. If true, both active and inactive records will be included
         * in results. If false or not provided, only active records will be included in results.
         */
        includeInactive?: boolean;
      },
      params: RequestParams = {}
    ) =>
      this.request<AdditionalInformation[], Errors>({
        path: `/businessAccount/account/${accountNumber}/additionalInformation`,
        method: 'GET',
        query: query,
        secure: true,
        ...params,
      }),

    /**
     * @description Validate the user input for Additional Information fields on an account. If the current rental stage is not provided, all active Additional Information fields must be provided in order to pass validation. Otherwise, active Additional Information fields must be provided if their required rental stage matches the provided current rental stage. All provided fields will be validated, even if not required by the current rental stage. If a field is not required at the current rental stage, and the user has not provided a value, it should not be provided in the validate request, or the validation will fail. Failing to include fields required at the current rental stage will cause validation to fail.
     *
     * @tags Business Accounts
     * @name ValidateAdditionalInformation
     * @summary Validate the user input for Additional Information fields on an account.
     * @request POST:/businessAccount/account/{accountNumber}/validate-additional-information
     * @secure
     */
    validateAdditionalInformation: (
      accountNumber: BusinessAccountNumber,
      data: ValidateAdditionalInformationRequest,
      params: RequestParams = {}
    ) =>
      this.request<boolean, Errors>({
        path: `/businessAccount/account/${accountNumber}/validate-additional-information`,
        method: 'POST',
        body: data,
        secure: true,
        ...params,
      }),

    /**
     * @description Contact Information contains organization level contact information as well as a list of Contacts for the provided Business Function. Business Functions: * Main * Billing * Mailing * Shipping
     *
     * @tags Business Accounts
     * @name RetrieveContactInformation
     * @summary Retrieve Contact Information by Business Function
     * @request GET:/businessAccount/account/{accountNumber}/contactInformation/{businessFunction}
     * @secure
     */
    retrieveContactInformation: (
      accountNumber: BusinessAccountNumber,
      businessFunction: 'MAIN' | 'BILLING' | 'MAILING' | 'SHIPPING',
      params: RequestParams = {}
    ) =>
      this.request<ContactInformation, Errors>({
        path: `/businessAccount/account/${accountNumber}/contactInformation/${businessFunction}`,
        method: 'GET',
        secure: true,
        ...params,
      }),

    /**
     * @description Find Charge Authorizations by providing one or more Billing Availabilities, optional Charge Type Categories and optionally a collection of Charge Types. The Charge Authorizations found for the criteria are grouped by their appropriate Billing Availabilities. ***Note:  Supports Pagination*** ### Available Content-Types * `application/json` - Provides the option of searching by Charge Types
     *
     * @tags Billing Accounts
     * @name FindChargeAuthorizations
     * @summary Find Charge Authorizations
     * @request POST:/businessAccount/account/{accountNumber}/billingAccount/{billingNumber}/find-charge-authorizations
     * @secure
     */
    findChargeAuthorizations: (
      accountNumber: BusinessAccountNumber,
      billingNumber: BillingNumber,
      data: ChargeTypeChargeAuthorizationRequest,
      params: RequestParams = {}
    ) =>
      this.request<
        {
          pagination?: PaginationResponse;
          billingAvailabilities?: BillingAvailability[];
        },
        Errors
      >({
        path: `/businessAccount/account/${accountNumber}/billingAccount/${billingNumber}/find-charge-authorizations`,
        method: 'POST',
        body: data,
        secure: true,
        ...params,
      }),

    /**
     * @description Retrieve the Web Experience information for an account. The Web Experience represents the account-specific preferences, policies, and messages which drive the user experience for Retail Web applications.
     *
     * @tags Business Accounts
     * @name RetrieveWebExperience
     * @summary Retrieve the Web Experience information for an account.
     * @request GET:/businessAccount/account/{accountNumber}/webExperience
     * @secure
     */
    retrieveWebExperience: (accountNumber: BusinessAccountNumber, params: RequestParams = {}) =>
      this.request<WebExperience, Errors>({
        path: `/businessAccount/account/${accountNumber}/webExperience`,
        method: 'GET',
        secure: true,
        ...params,
      }),
  };
}
