import { createContext, FC, ReactNode, useState } from 'react';
import { useMediaQuery } from '@mui/system';
import { useGetIdentityVerification } from 'services/identityVerification/useGetIdentityVerification';
import { useAppSelector } from 'redux/hooks';
import { selectPickup, selectReservationUrn } from 'redux/selectors/bookingEditor';
import { useBranchInfoByUrnQuery } from 'services/location/locationQueries';
import { isVerificationEnabled } from 'utils/locationUtils';
import { useEffectWhen } from 'hooks/useEffectWhen';
import { ResSummaryContextProps } from 'context/resSummary/ResSummaryContextTypes';

export const ResSummaryContext = createContext<ResSummaryContextProps>({
  openDrawer: false,
  setOpenDrawer: () => undefined,
});

export const ResSummaryProvider: FC<{ children: ReactNode }> = ({ children }) => {
  const isMobileOrTablet = useMediaQuery('(max-width:800px)');
  const [openDrawer, setOpenDrawer] = useState(!isMobileOrTablet);

  const { getVerificationHistory } = useGetIdentityVerification();
  const reservationUrn = useAppSelector(selectReservationUrn);
  const pickupData = useAppSelector(selectPickup);
  const { data: pickupBranchData } = useBranchInfoByUrnQuery(pickupData?.branch ?? '');

  useEffectWhen(() => {
    if (reservationUrn && pickupBranchData && isVerificationEnabled(pickupBranchData)) {
      getVerificationHistory(reservationUrn);
    }
  }, !!reservationUrn && !!pickupBranchData?.capabilityList);

  return <ResSummaryContext.Provider value={{ openDrawer, setOpenDrawer }}>{children}</ResSummaryContext.Provider>;
};
