import { Body1, Caption, H6 } from '@ehi/ui';
import { Grid, Box } from '@mui/material';
import { useTranslations } from 'components/shared/i18n';
import { useAppSelector } from 'redux/hooks';
import {
  selectEnterpriseBrandAndHasDateTime,
  selectIsReadOnlyFlow,
  selectRateSource,
  selectCarrierSafetyIdentifiers,
  selectPickup,
} from 'redux/selectors/bookingEditor';
import { parseUrn } from 'utils/urnUtils';
import {
  CarrierSafetyContentBox,
  CarrierSafetyTitleBox,
  RateAndBillingLabel,
  RateAndBillingSubSectionBox,
  RateAndBillingText,
  RateAndBillingTitleBox,
  StyledEhiEditButton,
} from 'components/flexFlow/rateAndBilling/RateAndBilling.styles';
import { RateSourceInfo } from 'components/flexFlow/rateAndBilling/RateSourceInfo';
import { FC, useState } from 'react';
import { EditRateSourceDialog } from 'components/flexFlow/rateAndBilling/editDialogs/rateSource/EditRateSourceDialog';
import { ProgressOverlay } from 'components/shared/ui/spinner/ProgressOverlay';
import { useRateSourceAccountDetails } from 'services/businessAccount/useRateSourceAccountDetails';
import { useBranchInfoByUrnQuery } from 'services/location/locationQueries';
import { EditCarrierSafetyNumberDialog } from 'components/flexFlow/rateAndBilling/editDialogs/rateSource/carrierSafetyNumber/EditCarrierSafetyNumberDialog';
import { isTruckLocation } from 'utils/locationUtils';
import { secondaryText } from 'components/shared/ui/styles/Global.styles';
import { AccountSearchProvider } from 'context/accountSearch/AccountSearchContext';
import { NegotiatedRateSource, RateSource } from 'services/booking/bookingTypes';
import { useGetRatePlanById } from 'services/ratePlan/ratePlanQueries';
import { EMPTY_VALUE } from 'utils/constants';
import { parseRateProduct } from 'utils/rateAndBillingUtils';

export const RateSourceSection: FC = () => {
  const { t } = useTranslations();
  const [showRateSourceModal, setShowRateSourceModal] = useState(false);
  const isEnterpriseBrandAndHasDateTime = useAppSelector(selectEnterpriseBrandAndHasDateTime);
  const rateSource: RateSource | undefined = useAppSelector(selectRateSource);
  const negotiatedRateSource = rateSource as NegotiatedRateSource;
  const { data: ratePlanDetails, isFetching: isRatePlanFetching } = useGetRatePlanById(
    parseUrn(negotiatedRateSource?.ratePlan)
  );
  const [showCarrierSafetyNumberModal, setShowCarrierSafetyNumberModal] = useState(false);
  const pickupData = useAppSelector(selectPickup);
  const { data: pickupBranch, isFetching: isFetchingPickupBranch } = useBranchInfoByUrnQuery(pickupData?.branch ?? '');

  const isReadOnly = useAppSelector(selectIsReadOnlyFlow);
  const {
    rateSourceInfo,
    rateSourceAccountNumber,
    editorRateSource,
    isLoading: isRateSourceInfoLoading,
  } = useRateSourceAccountDetails();
  const rateProduct = parseRateProduct(editorRateSource);
  const carrierSafetyIdentifiers = useAppSelector(selectCarrierSafetyIdentifiers);

  return (
    <RateAndBillingSubSectionBox data-testid='rateSourceSection'>
      <RateAndBillingTitleBox>
        <H6>{t('rateAndBilling.rateSource')}</H6>
        {!isReadOnly && (
          <StyledEhiEditButton onClick={(): void => setShowRateSourceModal(true)}>
            {t('common.edit')}
          </StyledEhiEditButton>
        )}
      </RateAndBillingTitleBox>
      <Grid container>
        <Grid item xs={6} sm={6} data-testid='accountNumber'>
          <RateAndBillingLabel data-testid='label'>{t('rateAndBilling.accountNumber')}</RateAndBillingLabel>
          {rateSourceAccountNumber && (
            <RateAndBillingText data-testid='textBody'>{rateSourceAccountNumber}</RateAndBillingText>
          )}
        </Grid>
        <Grid item xs={6} sm={6} data-testid='rateProduct'>
          {isEnterpriseBrandAndHasDateTime && ratePlanDetails?.name ? (
            <>
              <RateAndBillingLabel data-testid='label'>{t('rateAndBilling.rateSourceRatePlan')}</RateAndBillingLabel>
              <RateAndBillingText data-testid='textBody'>{ratePlanDetails?.name ?? EMPTY_VALUE}</RateAndBillingText>
            </>
          ) : (
            <>
              <RateAndBillingLabel data-testid='label'>{t('rateAndBilling.rateProduct')}</RateAndBillingLabel>
              {rateProduct && <RateAndBillingText data-testid='textBody'>{rateProduct}</RateAndBillingText>}
            </>
          )}
        </Grid>
        <RateSourceInfo
          accountName={rateSourceInfo?.name}
          accountType={rateSourceInfo?.type}
          isTruckLocation={isTruckLocation(pickupBranch?.type)}
        />
      </Grid>
      {isTruckLocation(pickupBranch?.type) && rateSourceInfo && (
        <Box data-testid={'carrierSafetyNumberSection'}>
          <CarrierSafetyTitleBox>
            <H6>{t('rateAndBilling.carrierSafetyNumbers.title')}</H6>
            {!isReadOnly && (
              <StyledEhiEditButton onClick={(): void => setShowCarrierSafetyNumberModal(true)}>
                {t('common.edit')}
              </StyledEhiEditButton>
            )}
          </CarrierSafetyTitleBox>
          <CarrierSafetyContentBox>
            <Caption color={secondaryText} display={'inline'}>
              {t('rateAndBilling.carrierSafetyNumbers.label')}
            </Caption>
            <Body1 display={'inline'} bold>
              {carrierSafetyIdentifiers?.join(', ')}
            </Body1>
          </CarrierSafetyContentBox>
        </Box>
      )}
      {showRateSourceModal && (
        <AccountSearchProvider>
          <EditRateSourceDialog closeModal={(): void => setShowRateSourceModal(false)} />
        </AccountSearchProvider>
      )}
      {showCarrierSafetyNumberModal && (
        <EditCarrierSafetyNumberDialog closeModal={(): void => setShowCarrierSafetyNumberModal(false)} />
      )}
      <ProgressOverlay
        inProgress={(isRateSourceInfoLoading || isFetchingPickupBranch || isRatePlanFetching) ?? false}
      />
    </RateAndBillingSubSectionBox>
  );
};
