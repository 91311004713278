import { useAppSelector } from 'redux/hooks';
import { selectBillingAccountPayer } from 'redux/selectors/bookingEditor';
import { parseBillingAccountNumber } from 'utils/rateAndBillingUtils';
import { parseUrn } from 'utils/urnUtils';
import { useMemo } from 'react';
import { useBillingAccountByAccountQuery, useBusinessAccountQuery } from 'services/businessAccount/accountQueries';
import { ResponseMessage } from 'services/types/ResponseMessageTypes';
import {
  RateAndBillingInfo,
  transformRateSourceInfoFromApi,
} from 'components/shared/uiModels/rateAndBilling/rateAndBillingTransformer';

export type RetrieveBillingAccountHookReturn = {
  billingAccountNumber: string;
  billingNumber: string;
  billingAccountInfo: RateAndBillingInfo | undefined;
  isLoading: boolean;
  accountErrors: ResponseMessage[];
};

export const useRetrieveBillingAccount = (): RetrieveBillingAccountHookReturn => {
  const billingAccountPayer = useAppSelector(selectBillingAccountPayer);
  const billingAccountNumber = parseBillingAccountNumber(billingAccountPayer);
  const billingNumber = parseUrn(billingAccountPayer);

  const {
    data: businessAccount,
    isFetching: isBusinessAccountLoading,
    error: businessAccountErrors,
  } = useBusinessAccountQuery(billingAccountNumber);

  const {
    data: billingAccount,
    isFetching: isBillingAccountLoading,
    error: billingAccountErrors,
  } = useBillingAccountByAccountQuery(billingAccountNumber);

  const billingAccountInfo: RateAndBillingInfo | undefined = useMemo(() => {
    return transformRateSourceInfoFromApi(businessAccount, billingAccount, billingAccountErrors);
  }, [billingAccount, billingAccountErrors, businessAccount]);

  const combinedErrors: ResponseMessage[] = [];
  if (businessAccountErrors?.errors && businessAccountErrors?.errors?.length > 0) {
    combinedErrors.push(...businessAccountErrors.errors);
  }

  if (billingAccountErrors?.errors?.length) {
    combinedErrors.push(...billingAccountErrors.errors);
  }

  return {
    billingAccountNumber: billingAccountNumber,
    billingNumber: billingNumber,
    billingAccountInfo: billingAccountInfo,
    isLoading: isBusinessAccountLoading || isBillingAccountLoading,
    accountErrors: combinedErrors,
  };
};
