import {
  CarClassText,
  DistanceAllotmentText,
  StyledLuggageIcon,
  StyledPassengerIcon,
  VehicleDescriptionText,
  VehicleSpecText,
  VehicleSpecTextContainer,
  VehicleTotalRateText,
} from 'components/flexFlow/vehicle/VehiclePage.styles';
import { FC, useMemo, useState } from 'react';
import AutoTransmissionDarkIcon from 'images/autoTransmissionDarkIcon.svg';
import AutoTransmissionLightIcon from 'images/autoTransmissionLightIcon.svg';
import { Grid } from '@mui/material';
import { useLocale, useTranslations } from 'components/shared/i18n';
import { GridVerticalContainer } from 'components/shared/ui/styles/Grid.styles';
import { ehiTheme } from '@ehi/ui';
import { ListItemCard } from 'components/shared/ui/card/Card';
import {
  ExpandLessButton,
  ExpandMoreButton,
  pageContainerBackground,
  RadioButton,
} from 'components/shared/ui/styles/Global.styles';
import { useAppSelector } from 'redux/hooks';
import {
  selectBookingEditorId,
  selectPayers,
  selectPickup,
  selectVehicleClassSelection,
} from 'redux/selectors/bookingEditor';
import { deleteVehicleClassSelection, updateVehicleClassSelection } from 'services/booking/bookingService';
import { EHI_DOMAINS, generateReferenceUrn, parseUrn } from 'utils/urnUtils';
import { getAppConfigCache } from 'services/appConfig/appConfigService';
import { useUpdateAndRefreshEditor } from 'hooks/bookingEditor/useUpdateAndRefreshEditor';
import { AdditionalVehicleInfo } from 'components/flexFlow/vehicle/additionalVehicleInfo/AdditionalVehicleInfo';
import { VehicleCardProps } from 'components/flexFlow/vehicle/VehicleTypes';
import { useAlert } from 'components/shared/alert/AlertContext';
import { formatCurrency } from 'utils/currencyUtils';
import { getAutoTransmissionDescription, getDistanceAllotmentText } from 'utils/vehicleUtils';
import { ProgressOverlay } from 'components/shared/ui/spinner/ProgressOverlay';
import { SelectedAction } from 'components/shared/alert/AlertDialogTypes';
import { useGetPaymentMethodsByLocation } from 'services/location/locationQueries';
import { BANK_CARD, DEBIT_CARD, PAYLATER } from 'utils/constants';
import { useBookingIssue } from 'services/booking/useBookingIssue';
import { PeoBookingIssues } from 'utils/bookingUtils';

const IS_SELECTED_CLASS_NAME = 'isSelected';

export const VehicleCard: FC<VehicleCardProps> = ({ vehicle }) => {
  const { t } = useTranslations();
  const { locale } = useLocale();
  const appConfig = getAppConfigCache();
  const defaultEhiDatabase = appConfig?.defaultEhiDatabase ?? '';
  const { updateAndRefresh } = useUpdateAndRefreshEditor();
  const { handleBookingIssues } = useBookingIssue();
  const { showAlert } = useAlert();

  const bookingEditorId = useAppSelector(selectBookingEditorId);
  const vehicleClassSelection = useAppSelector(selectVehicleClassSelection);
  const payers = useAppSelector(selectPayers);

  const pickupData = useAppSelector(selectPickup);
  const { data: activePaymentMethods } = useGetPaymentMethodsByLocation(parseUrn(pickupData?.branch));

  const isDebitCardPayer = useMemo(() => {
    return parseUrn(payers?.person?.paymentMethod) === DEBIT_CARD;
  }, [payers?.person?.paymentMethod]);

  const isVehicleClassAllowedForDebit = useMemo(() => {
    if (!activePaymentMethods) {
      return true;
    } else {
      const debitCardType = activePaymentMethods?.filter(
        (paymentMethod) => parseUrn(paymentMethod.urn) === BANK_CARD
      )[0];
      const allowedSippCodes = debitCardType?.vehicleClasses?.map((vehicleClass) => parseUrn(vehicleClass));
      return allowedSippCodes?.includes(vehicle.vehicleClass);
    }
  }, [activePaymentMethods, vehicle.vehicleClass]);

  const isSelected = useMemo(() => {
    return parseUrn(vehicleClassSelection?.preferred) === vehicle.vehicleClass;
  }, [vehicleClassSelection?.preferred, vehicle]);

  const [isVehicleUpdating, setIsVehicleUpdating] = useState(false);
  const [additionalInfoExpanded, setAdditionalInfoExpanded] = useState(vehicle.isExpanded);

  const updateVehicleClassToEditor = async (): Promise<void> => {
    if (isDebitCardPayer && !isVehicleClassAllowedForDebit) {
      setIsVehicleUpdating(false);
      const selectedAction = await showAlert({
        title: t('common.confirm'),
        description: t('vehicle.debitCardConfirmation'),
        secondaryActionText: t('common.cancel'),
        primaryActionText: t('common.proceed'),
      });

      if (selectedAction === SelectedAction.Primary) {
        setIsVehicleUpdating(true);
        await updateVehicleClassSelection(bookingEditorId, {
          type: PAYLATER,
          vehicleClass: generateReferenceUrn(
            EHI_DOMAINS.vehicle.name,
            EHI_DOMAINS.vehicle.vehicleClass,
            vehicle.vehicleClass,
            defaultEhiDatabase
          ),
        });
      }
    } else {
      await updateVehicleClassSelection(bookingEditorId, {
        type: PAYLATER,
        vehicleClass: generateReferenceUrn(
          EHI_DOMAINS.vehicle.name,
          EHI_DOMAINS.vehicle.vehicleClass,
          vehicle.vehicleClass,
          defaultEhiDatabase
        ),
      });
    }
  };

  const updateVehicleClass = async (): Promise<void> => {
    if (!vehicle.vehicleClass) {
      return;
    }
    setIsVehicleUpdating(true);
    const callToUpdateVehicle = isSelected
      ? await deleteVehicleClassSelection(bookingEditorId)
      : await updateVehicleClassToEditor();
    const { errors, data } = await updateAndRefresh(async () => callToUpdateVehicle);
    setIsVehicleUpdating(false);

    if (errors) {
      await showAlert({ responseMessages: errors });
    }
    if (data) {
      const availableBookingIssues = !isSelected ? Object.values(PeoBookingIssues) : [];
      await handleBookingIssues(
        data.issue ?? [],
        data.ehiMessages ?? [],
        availableBookingIssues,
        t('snackbarMessages.additionalInfo.vehicle', { sippCode: vehicle.vehicleClass })
      );
    }
  };

  return (
    <ListItemCard
      className={isSelected ? IS_SELECTED_CLASS_NAME : ''}
      data-testid={'vehicleCard'}
      sx={{ paddingTop: ehiTheme.spacing(1.5) }}>
      <GridVerticalContainer>
        <Grid container padding={ehiTheme.spacing(0, 3, 1.5, 3)} gap={ehiTheme.spacing(2)}>
          <Grid item display={'flex'} justifyContent={'center'} alignItems={'center'}>
            <RadioButton
              sx={{ padding: 0 }}
              checked={isSelected}
              onClick={updateVehicleClass}
              name={'vehicleRadioButton'}
              data-testid={vehicle.vehicleClass}
            />
          </Grid>
          <Grid item sm xs>
            <Grid container display={'flex'} gap={ehiTheme.spacing(2)}>
              <Grid item sm xs paddingTop={ehiTheme.spacing(0)}>
                <Grid item>
                  <CarClassText
                    selected={isSelected}>{`${vehicle.description} (${vehicle.vehicleClass})`}</CarClassText>
                </Grid>
                <Grid item>
                  <VehicleDescriptionText selected={isSelected}>
                    {vehicle.similarModels + ' ' + t('vehicle.similarModels')}
                  </VehicleDescriptionText>
                </Grid>
                <Grid item>
                  <Grid container gap={ehiTheme.spacing(2)}>
                    <VehicleSpecTextContainer data-testid={'autoTransContainer'}>
                      {isSelected ? (
                        <img src={AutoTransmissionLightIcon} alt={vehicle.transmissionType} />
                      ) : (
                        <img src={AutoTransmissionDarkIcon} alt={vehicle.transmissionType} />
                      )}
                      <VehicleSpecText selected={isSelected}>
                        {getAutoTransmissionDescription(t, vehicle.transmissionType)}
                      </VehicleSpecText>
                    </VehicleSpecTextContainer>
                    <Grid item>
                      <VehicleSpecTextContainer data-testid={'passengerContainer'}>
                        <StyledPassengerIcon selected={isSelected} />
                        <VehicleSpecText selected={isSelected}>{vehicle.passengers}</VehicleSpecText>
                      </VehicleSpecTextContainer>
                    </Grid>
                    <Grid item>
                      <VehicleSpecTextContainer data-testid={'luggageContainer'}>
                        <StyledLuggageIcon selected={isSelected} />
                        <VehicleSpecText selected={isSelected}>{vehicle.luggageSpace}</VehicleSpecText>
                      </VehicleSpecTextContainer>
                    </Grid>
                  </Grid>
                </Grid>
              </Grid>
              <Grid item>
                <GridVerticalContainer>
                  <VehicleTotalRateText>
                    {vehicle.vehicleRates?.paylater?.total?.amount
                      ? `${formatCurrency(
                          vehicle.vehicleRates?.paylater?.total?.amount,
                          vehicle.vehicleRates?.paylater?.total?.currencyCode,
                          locale
                        )} ${t('rates.estTotal')}`
                      : `-- ${t('rates.estTotal')}`}
                  </VehicleTotalRateText>
                  {vehicle.vehicleRates && (
                    <Grid item data-testid={'distanceAllotmentContainer'}>
                      <DistanceAllotmentText data-testid={'distanceAllotmentText'} selected={isSelected}>
                        {t(getDistanceAllotmentText(vehicle))}
                      </DistanceAllotmentText>
                    </Grid>
                  )}
                </GridVerticalContainer>
              </Grid>
            </Grid>
          </Grid>
          <Grid item textAlign={'center'} alignSelf={'center'}>
            {additionalInfoExpanded ? (
              <ExpandLessButton
                data-testid={'expandLessButton'}
                className={isSelected ? IS_SELECTED_CLASS_NAME : ''}
                onClick={(): void => {
                  vehicle.isExpanded = false;
                  setAdditionalInfoExpanded(false);
                }}
              />
            ) : (
              <ExpandMoreButton
                data-testid={'expandMoreButton'}
                className={isSelected ? IS_SELECTED_CLASS_NAME : ''}
                onClick={(): void => {
                  vehicle.isExpanded = true;
                  setAdditionalInfoExpanded(true);
                }}
              />
            )}
          </Grid>
        </Grid>
        {additionalInfoExpanded && (
          <Grid item sm bgcolor={pageContainerBackground} height={'100%'} width={'100%'}>
            <AdditionalVehicleInfo vehicle={vehicle} isSelected={isSelected} />
          </Grid>
        )}
      </GridVerticalContainer>
      <ProgressOverlay inProgress={isVehicleUpdating} />
    </ListItemCard>
  );
};
