import { DriverData } from 'components/shared/uiModels/driver/driverDataTypes';
import { DateTime } from 'luxon';
import {
  FullHumanAndElectronicVerification,
  FullIndividual,
} from 'services/identityVerification/identityVerificationTypes';
import { parseUrn } from 'utils/urnUtils';
import {
  DriverForVerification,
  transformToElectronicIndividual,
} from 'components/shared/uiModels/identityVerification/identityVerificationTransformer';
import { isEqual } from 'lodash';

export enum VerificationStatus {
  Success = 'SUCCESS',
  Failed = 'FAILED',
  NeedsUpdate = 'NEEDS_UPDATE',
  NotRun = 'NOT_RUN',
}

export const verifyRequiredFields = (driver: DriverData): boolean => {
  const nameVerified = !!driver.firstName && !!driver.lastName;
  const driverDOBFormat = 'yyyy-MM-dd';
  const DOB = driver.dob && DateTime.fromFormat(driver.dob, driverDOBFormat);
  const DOBVerified = !!DOB && !!DOB.year && !!DOB.month && !!DOB.day;

  const addressVerified =
    !!driver?.address?.lines.length &&
    !!driver.address.city &&
    !!driver.address.countrySubdivisionCode &&
    !!driver.address.countryCode &&
    !!driver.address.postalCode;

  return nameVerified && addressVerified && DOBVerified;
};

export const getDriverVerificationStatus = (
  driverDetails?: DriverData,
  verificationHistory?: FullHumanAndElectronicVerification[]
): VerificationStatus => {
  const verification = verificationHistory?.find(
    (item) => parseUrn(item?.individual?.urn) === driverDetails?.profileId
  );
  const validationNeedsUpdate =
    driverDetails && verification?.individual && getDriverHasBeenModified(driverDetails, verification?.individual);
  let verificationStatus: VerificationStatus;
  if (verification?.isVerified === undefined) {
    verificationStatus = VerificationStatus.NotRun;
  } else if (validationNeedsUpdate) {
    verificationStatus = VerificationStatus.NeedsUpdate;
  } else {
    verificationStatus = verification?.isVerified ? VerificationStatus.Success : VerificationStatus.Failed;
  }

  return verificationStatus;
};

export const getDriverHasBeenModified = (driverDetails: DriverData, verification?: FullIndividual): boolean => {
  const currentDriverDetails = transformToElectronicIndividual(driverDetails as DriverForVerification);
  const nameChanged = !isEqual(currentDriverDetails?.name, verification?.name);
  const dobChanged: boolean = currentDriverDetails?.dateOfBirth !== verification?.dateOfBirth;
  const addressChanged = !isEqual(currentDriverDetails?.address, verification?.address);

  return nameChanged || dobChanged || addressChanged;
};
