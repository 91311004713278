import { FC, useCallback, useEffect, useMemo } from 'react';
import { Box, Grid } from '@mui/material';
import {
  AccountDetailsContainer,
  RateAndBillingSearchContainer,
  RateAndBillingSearchLabel,
  StyledFlexGrid,
} from 'components/flexFlow/rateAndBilling/RateAndBilling.styles';
import { Body2, EhiButton, ehiTheme } from '@ehi/ui';
import { FormTextField } from 'components/shared/forms/FormTextField';
import { InputIconButton } from 'components/shared/ui/InputIconButton/InputIconButton';
import { FieldClearIcon } from 'components/shared/ui/FieldClearIcon';
import { EMPTY_VALUE, ENTER_KEY_CODE } from 'utils/constants';
import { useTranslations } from 'components/shared/i18n';
import {
  BillToFormFields,
  BillToFormValues,
} from 'components/flexFlow/rateAndBilling/editDialogs/billTo/EditBillToDialogTypes';
import { FieldValues, FormProvider, useForm, useFormContext } from 'react-hook-form';
import { useAlert } from 'components/shared/alert/AlertContext';
import {
  useAccountContactInfoQuery,
  useBillingAccountByAccountQuery,
  useBusinessAccountQuery,
} from 'services/businessAccount/accountQueries';
import { useBillTo } from 'components/flexFlow/rateAndBilling/editDialogs/billTo/useBillTo';
import { parseUrn } from 'utils/urnUtils';
import { useEffectWhen } from 'hooks/useEffectWhen';
import { HttpStatusCode } from 'services/types/EhiErrorsTypes';
import { MaskedTextField } from 'components/shared/forms/MaskedTextField';
import { ProgressOverlay } from 'components/shared/ui/spinner/ProgressOverlay';
import { transformAccountDetailsFromRetrieve } from 'components/shared/uiModels/rateAndBilling/rateAndBillingTransformer';
import { BusinessAccountCard } from 'components/flexFlow/rateAndBilling/editDialogs/BusinessAccountCard';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import { useAccountSearchContext } from 'context/accountSearch/AccountSearchContext';
import * as Yup from 'yup';
import { useYupValidationResolver } from 'components/shared/forms/useYupValidationResolver';
import { useRetrieveBillingAccount } from 'services/businessAccount/useRetrieveBillingAccount';

export type BillingAccountNumberSearchProps = {
  handleCloseModal: () => void;
  onBackClicked?: () => void;
};

export const BillToAccountNumberSearch: FC<BillingAccountNumberSearchProps> = ({ handleCloseModal, onBackClicked }) => {
  const { t } = useTranslations();
  const { isAccountNumberFieldHidden, selectedAccountUrn } = useAccountSearchContext();
  const { billingAccountNumber, billingNumber } = useRetrieveBillingAccount();

  const initialValues: BillToFormValues = useMemo(() => {
    return {
      [BillToFormFields.AccountNumber]: selectedAccountUrn ?? billingAccountNumber ?? EMPTY_VALUE,
      [BillToFormFields.BillingNumber]: billingNumber ?? EMPTY_VALUE,
      [BillToFormFields.HiddenAccountNumber]: selectedAccountUrn ?? billingAccountNumber ?? EMPTY_VALUE,
    };
  }, [billingAccountNumber, billingNumber, selectedAccountUrn]);
  const validationSchema = useMemo(() => {
    return Yup.object().shape({
      [BillToFormFields.AccountNumber]: Yup.string()
        .trim()
        .required(t('validation.requiredField'))
        .min(5, t('validation.minFieldLength', { fieldLength: 3 }))
        .matches(/^[a-z0-9]*$/i, t('validation.invalidField')),
      [BillToFormFields.BillingNumber]: Yup.string()
        .trim()
        .required(t('validation.requiredField'))
        .min(5, t('validation.minFieldLength', { fieldLength: 5 }))
        .max(10, t('validation.minFieldLength', { fieldLength: 10 }))
        .matches(/^[1-9]\d{4,9}$/, t('validation.invalidField')),
    });
  }, [t]);
  const resolver = useYupValidationResolver(validationSchema);
  const formMethods = useForm({
    resolver: resolver,
    defaultValues: initialValues,
  });
  const accountNumber = formMethods.watch(BillToFormFields.AccountNumber);

  const handleSubmitForBillingAccountName = async (): Promise<void> => {
    // Note: Hidden account number is used so that we can trigger the query without searching on every keystroke
    const previousAccountNumber = formMethods.getValues(BillToFormFields.HiddenAccountNumber);
    const currentAccountNumber = formMethods.getValues(BillToFormFields.AccountNumber);
    // Note: ignore validation if the account number is not changed
    if (previousAccountNumber !== currentAccountNumber) {
      formMethods.clearErrors();
      // Note: manually trigger validation as form submit validation doesn't happen for AccountNumber
      // If account number is invalid, there is no reason to make the API call triggered by setting these values
      const isValid = await formMethods.trigger([BillToFormFields.AccountNumber]);
      if (isValid) {
        formMethods.setValue(BillToFormFields.BillingNumber, EMPTY_VALUE);
        formMethods.setValue(
          BillToFormFields.HiddenAccountNumber,
          formMethods.getValues(BillToFormFields.AccountNumber)
        );
      } else {
        formMethods.setError(BillToFormFields.AccountNumber, {
          message: t('rateAndBilling.invalidAccountNumber'),
        });
      }
    }
  };

  const handleKeyPress = async (e: any): Promise<void> => {
    if (e.code === 'Enter' || e.keycode === ENTER_KEY_CODE) {
      await handleSubmitForBillingAccountName();
    }
  };

  const isAccountNumberApplyButtonDisabled = useCallback((): boolean => {
    return (
      !!(
        formMethods.formState.defaultValues &&
        formMethods.formState.defaultValues[BillToFormFields.AccountNumber] === accountNumber
      ) || accountNumber?.length === 0
    );
  }, [accountNumber, formMethods.formState.defaultValues]);

  return (
    <FormProvider {...formMethods}>
      <Box>
        {isAccountNumberFieldHidden ? (
          <EhiButton variant={'text'} onClick={onBackClicked} startIcon={<ArrowBackIcon />}>
            <Body2>{t('common.back')}</Body2>
          </EhiButton>
        ) : (
          <RateAndBillingSearchContainer margin={ehiTheme.spacing(2, 0)} data-testid='billingAccountNumberContainer'>
            <Grid item>
              <RateAndBillingSearchLabel>{t('rateAndBilling.enterAccountNumber')}</RateAndBillingSearchLabel>
              <StyledFlexGrid data-testid='searchField' spacing={1}>
                <Grid item xs={6} sm={6}>
                  <FormTextField
                    name={BillToFormFields.AccountNumber}
                    autoFocus={accountNumber?.length === 0 && !billingNumber}
                    label={t('rateAndBilling.accountNumber')}
                    data-testid='accountNumber'
                    fullWidth
                    required
                    onKeyDown={handleKeyPress}
                    InputProps={{
                      endAdornment: (
                        <InputIconButton
                          icon={<FieldClearIcon />}
                          label={t('common.clear')}
                          onClick={(): void => {
                            formMethods.setValue(BillToFormFields.AccountNumber, EMPTY_VALUE);
                            formMethods.setValue(BillToFormFields.HiddenAccountNumber, EMPTY_VALUE);
                            formMethods.clearErrors();
                          }}
                          disabled={accountNumber?.length === 0}
                        />
                      ),
                    }}
                  />
                </Grid>
                <Grid item xs={3} sm={3}>
                  <EhiButton
                    data-testid='account-applyButton'
                    variant='contained'
                    style={{ marginTop: 0 }}
                    disabled={isAccountNumberApplyButtonDisabled()}
                    onClick={handleSubmitForBillingAccountName}>
                    {t('common.apply')}
                  </EhiButton>
                </Grid>
              </StyledFlexGrid>
            </Grid>
          </RateAndBillingSearchContainer>
        )}
        <BillingAccountInfo handleCloseModal={handleCloseModal} />
        <ProgressOverlay inProgress={formMethods.formState.isSubmitting} />
      </Box>
    </FormProvider>
  );
};

const BillingAccountInfo: FC<{
  handleCloseModal: () => void;
}> = ({ handleCloseModal }) => {
  const { t } = useTranslations();
  const { showAlert } = useAlert();
  const { addOrModifyBillTo } = useBillTo();
  const { watch, setValue, clearErrors, setError, formState, handleSubmit } = useFormContext();
  const billingNumberHasError = formState.errors[BillToFormFields.BillingNumber] !== undefined;
  const billingNumber = watch(BillToFormFields.BillingNumber);
  const accountNumber = watch(BillToFormFields.HiddenAccountNumber);
  const { data: account, error: accountErrors, isFetching: accountLoading } = useBusinessAccountQuery(accountNumber);
  const {
    data: billingAccount,
    error: billingAccountErrors,
    isFetching: billingAccountLoading,
  } = useBillingAccountByAccountQuery(accountNumber);
  const { data: contact } = useAccountContactInfoQuery(accountNumber);
  const { checkBillingAccountErrors } = useBillTo();

  const accountDetails = useMemo(() => {
    return transformAccountDetailsFromRetrieve(account, contact);
  }, [account, contact]);

  useEffect(() => {
    if (billingAccount && billingAccount.urn) {
      setValue(BillToFormFields.BillingNumber, parseUrn(billingAccount.urn), { shouldValidate: true });
    } else if (!billingAccount && billingAccountErrors) {
      const { errorMessage } = checkBillingAccountErrors(billingAccountErrors);
      errorMessage && setError(BillToFormFields.AccountNumber, { message: errorMessage });
    }
  }, [billingAccountErrors, billingAccount, setValue, checkBillingAccountErrors, setError]);

  useEffectWhen(() => {
    // if not billing account errors, then show account errors
    if (!billingAccountErrors) {
      if (accountErrors?.status === HttpStatusCode.NotFound) {
        setError(BillToFormFields.AccountNumber, { message: accountErrors?.errors?.[0].localizedMessage });
      } else {
        void showAlert({
          variant: 'error',
          responseMessages: accountErrors?.errors,
        });
      }
    }
  }, !!accountErrors);

  const handleSubmitForBillingNumber = async (values: FieldValues): Promise<void> => {
    const accountNumber = values[BillToFormFields.AccountNumber];
    const billingNumber = values[BillToFormFields.BillingNumber];
    const { errorMessage } = await addOrModifyBillTo({
      accountNumber,
      billingNumber,
      handleCloseModal,
    });
    if (errorMessage) {
      setError(BillToFormFields.BillingNumber, { message: errorMessage });
    }
  };

  const handleKeyPress = async (e: any): Promise<void> => {
    if (e.code === 'Enter' || e.keycode === ENTER_KEY_CODE) {
      const onFormSubmit = handleSubmit(handleSubmitForBillingNumber);
      await onFormSubmit();
    }
  };

  return billingAccount || account ? (
    <>
      {accountDetails && (
        <AccountDetailsContainer>
          <BusinessAccountCard account={accountDetails} titleColor={'black'} />
        </AccountDetailsContainer>
      )}
      {billingAccountErrors?.status !== HttpStatusCode.NotFound && (
        <RateAndBillingSearchContainer margin={ehiTheme.spacing(2, 3)} data-testid='billingNumberContainer'>
          <RateAndBillingSearchLabel>{t('rateAndBilling.billTo.enterBillingNumber')}</RateAndBillingSearchLabel>
          <StyledFlexGrid spacing={1}>
            <Grid item xs={6} sm={6}>
              <MaskedTextField
                name={BillToFormFields.BillingNumber}
                label={t('rateAndBilling.billTo.billingNumber')}
                required
                autoFocus={!!account}
                exceptLast={4}
                onKeyDown={handleKeyPress}
                inputProps={{
                  minLength: 5,
                  maxLength: 10,
                }}
                InputProps={{
                  endAdornment: (
                    <InputIconButton
                      icon={<FieldClearIcon />}
                      label={t('common.clear')}
                      onClick={(): void => {
                        setValue(BillToFormFields.BillingNumber, EMPTY_VALUE);
                        clearErrors(BillToFormFields.BillingNumber);
                      }}
                      disabled={billingNumber?.length === 0}
                    />
                  ),
                }}
              />
            </Grid>
            <Grid item xs={3} sm={3}>
              <EhiButton
                data-testid='billingNumber-applyButton'
                variant='contained'
                style={{ marginTop: 0 }}
                disabled={billingNumberHasError || !billingNumber}
                onClick={handleSubmit(handleSubmitForBillingNumber)}>
                {t('common.apply')}
              </EhiButton>
            </Grid>
          </StyledFlexGrid>
        </RateAndBillingSearchContainer>
      )}
      <ProgressOverlay inProgress={accountLoading || billingAccountLoading} />
    </>
  ) : (
    <></>
  );
};
