import { Dispatch, FC, SetStateAction, useCallback, useMemo, useRef, useState } from 'react';
import { DriverData } from 'components/shared/uiModels/driver/driverDataTypes';
import { useTranslations } from 'components/shared/i18n';
import { DriverForm } from 'components/flexFlow/driver/driverForm/DriverForm';
import { EhiButton } from '@ehi/ui';
import { ContentContainer } from 'components/flexFlow/driver/Driver.styles';
import { Dialog } from 'components/shared/ui/dialogs/Dialog';
import { useNavigate } from 'react-router-dom';
import { HashPaths } from 'app/router/RouterPaths';
import { ConfirmationDialog } from 'components/flexFlow/driver/editDriver/ConfirmationDialog';
import { ADD, REMOVE } from 'components/flexFlow/driver/driverForm/driverFormUtils';
import { ProgressOverlay } from 'components/shared/ui/spinner/ProgressOverlay';
import { useAlert } from 'components/shared/alert/AlertContext';
import { SelectedAction } from 'components/shared/alert/AlertDialogTypes';
import { useSaveDriver } from 'components/flexFlow/driver/useSaveDriver';
import { DriverFormRefReturn } from 'components/flexFlow/driver/driverForm/DriverFormTypes';

export type EditDriverDialogProps = {
  driver: DriverData | undefined;
  onClose: (driverModified: boolean) => void;
  changePrimaryDriverDialogIsOpen: boolean;
  setChangePrimaryDriverDialogIsOpen: Dispatch<SetStateAction<boolean>>;
};

export const EditDriverDialog: FC<EditDriverDialogProps> = ({
  onClose,
  driver,
  changePrimaryDriverDialogIsOpen,
  setChangePrimaryDriverDialogIsOpen,
}) => {
  const { t } = useTranslations();
  const { showAlert } = useAlert();
  const { removeAdditionalDriver, changePrimaryDriverFromAdditionalDriver, isAdditionalDriver } = useSaveDriver();
  const formRef = useRef<DriverFormRefReturn>(null);
  const navigate = useNavigate();

  const [primaryDriverConfirmationOpen, setPrimaryDriverConfirmationOpen] = useState<boolean>(false);
  const [loading, setLoading] = useState<boolean>(false);

  const isDriverAnAdditionalDriver = useMemo(
    () => (driver ? isAdditionalDriver(driver.type, driver.urn) : false),
    [driver, isAdditionalDriver]
  );

  const submitForm = async (): Promise<void> => {
    if (formRef.current) {
      await formRef.current.handleSubmit();
    }
  };

  const handleClose = useCallback(
    (isUpdatingExistingDriver: boolean) => {
      onClose(isUpdatingExistingDriver);
    },
    [onClose]
  );

  const handleChangePrimaryDriverClick = async (): Promise<void> => {
    if (isDriverAnAdditionalDriver) {
      setPrimaryDriverConfirmationOpen(true);
    } else {
      navigate({ hash: HashPaths.ReplaceDriver }, { replace: true });
      handleClose(false);
    }
  };

  // What triggers this function -> "Make Primary Driver" button clicked when editing additional driver
  const handleConfirmChangePrimaryDriver = async (selection: string): Promise<void> => {
    setPrimaryDriverConfirmationOpen(false);
    setLoading(true);

    const { errors } = await changePrimaryDriverFromAdditionalDriver(
      selection,
      driver ? { driverInfo: driver } : undefined
    ).finally(() => setLoading(false));

    if (!errors) {
      onClose(true);
    }
  };

  const handleCancelChangePrimaryDriver = (): void => {
    setPrimaryDriverConfirmationOpen(false);
  };

  const handleRemoveDriverClick = useCallback(async () => {
    const selectionAction = await showAlert({
      variant: 'destructive',
      title: t('common.confirm'),
      description: t('driver.additionalDriverRemovalConfirmation'),
      primaryActionText: t('common.remove'),
      secondaryActionText: t('common.cancel'),
    });

    if (selectionAction === SelectedAction.Primary) {
      setLoading(true);
      const { errors } = await removeAdditionalDriver(driver).finally(() => setLoading(false));
      if (!errors) {
        onClose(true);
      }
    }
  }, [driver, onClose, removeAdditionalDriver, showAlert, t]);

  return (
    <>
      <Dialog
        title={isDriverAnAdditionalDriver ? t('driver.editAdditionalDriver') : t('driver.editPrimaryDriver')}
        titleActions={[
          <EhiButton
            key={'changePrimaryDriver'}
            disabled={!(driver?.firstName && driver.lastName)}
            variant='text'
            onClick={handleChangePrimaryDriverClick}>
            {isDriverAnAdditionalDriver ? t('driver.makePrimaryDriver') : t('driver.changePrimaryDriver')}
          </EhiButton>,
        ]}
        open={true}
        onClose={(): void => handleClose(false)}
        contentPadding={0}
        a11yKey='content'
        showDividers
        maxWidth={'md'}
        fullWidth
        actions={{
          primaryAction: {
            label: t('common.update'),
            onClick: submitForm,
          },
          secondaryAction: {
            label: t('common.cancel'),
            onClick: () => handleClose(false),
          },
          ...(isDriverAnAdditionalDriver
            ? {
                tertiaryAction: {
                  label: t('driver.removeDriver'),
                  type: 'error',
                  onClick: () => handleRemoveDriverClick(),
                },
              }
            : {}),
        }}>
        <ContentContainer>
          <DriverForm
            onClose={handleClose}
            formRef={formRef}
            driverToUpdate={driver}
            data-testId={'editDriverDialog'}
            changePrimaryDriverDialogIsOpen={changePrimaryDriverDialogIsOpen}
            setChangePrimaryDriverDialogIsOpen={setChangePrimaryDriverDialogIsOpen}
          />
        </ContentContainer>
        <ProgressOverlay inProgress={loading} />
      </Dialog>
      <ConfirmationDialog
        open={primaryDriverConfirmationOpen}
        onConfirm={handleConfirmChangePrimaryDriver}
        onCancel={handleCancelChangePrimaryDriver}
        title={t('driver.changePrimaryDriver')}
        description={t('driver.additionalDrivers.changePrimaryPrompt')}
        options={[
          { label: t('driver.additionalDrivers.makeAdditionalDriver'), value: ADD },
          { label: t('driver.removeFromReservation'), value: REMOVE },
        ]}
        defaultSelection={undefined}
      />
    </>
  );
};
