import { RootState } from 'redux/store';
import { ConsentState } from 'redux/slices/consent/consentSliceTypes';
import { EMPTY_VALUE } from 'utils/constants';
import { ConsentSms } from 'services/consent/consentCommunicationTypes';

export const selectConsentData = ({ consent }: RootState): ConsentState | undefined => {
  return consent;
};

export const selectConsentCountry = ({ consent }: RootState): string => {
  return consent?.selectedCountry ?? EMPTY_VALUE;
};

export const selectConsentPhoneNumber = ({ consent }: RootState): string => {
  return consent?.phoneNumber ?? EMPTY_VALUE;
};

export const selectConsentStatus = ({ consent }: RootState): string => {
  return consent?.selectedStatus ?? EMPTY_VALUE;
};

export const selectConsentUid = ({ consent }: RootState): string => {
  return consent?.consentUid ?? EMPTY_VALUE;
};

export const selectRetrievedConsentData = ({ consent }: RootState): ConsentSms | undefined => {
  return consent?.retrievedConsentData ?? undefined;
};

export const selectUpdateCheckBox = ({ consent }: RootState): boolean => {
  return consent?.consentCheckBox ?? false;
};

export const selectConsentEdited = ({ consent }: RootState): boolean => {
  return consent?.consentEdited ?? false;
};
