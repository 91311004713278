import { Box, styled } from '@mui/material';
import { Body2, ehiTheme } from '@ehi/ui';

export const NotesContainer = styled(Box)`
  display: flex;
  flex-direction: column;
  margin-top: ${ehiTheme.spacing(0.25)};
  height: 0;
  flex-grow: 1;
`;

export const NotesList = styled(Box)`
  display: flex;
  flex-direction: column;
  align-items: center;
  overflow-y: scroll;
  padding: ${ehiTheme.spacing(3)};
`;

export const PreferencesSection = styled(Box)`
  width: 75%;
  padding-bottom: ${ehiTheme.spacing(2.5)};
`;

export const NotesSection = styled(Box)`
  width: 75%;
  padding: ${ehiTheme.spacing(2, 0)};
`;

export const DateHeader = styled(Box)`
  margin: ${ehiTheme.spacing(1, 0)};
`;

export const StyledHeaderText = styled(Body2)`
  color: rgba(0, 0, 0, 0.54);
`;

export const CardContainer = styled(Box, {
  shouldForwardProp: (prop) => prop !== 'isPinnedTab',
})<{ isPinnedTab?: boolean }>(({ isPinnedTab }) => ({
  display: 'flex',
  alignItems: 'flex-start',
  gap: '8px',
  alignSelf: 'stretch',
  margin: ehiTheme.spacing(isPinnedTab ? 2 : 1, 0),
}));
