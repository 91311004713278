import {
  AdditionalDriver,
  DriverProfileRenter,
  RateSource,
  RateSourceType,
  Renter,
  RenterType,
} from 'services/booking/bookingTypes';
import { useAlert } from 'components/shared/alert/AlertContext';
import { useTranslations } from 'components/shared/i18n';
import {
  associateRenterToReservationEditor,
  modifyRateSource,
  updateAdditionalDrivers,
  updatePayersPerson,
} from 'services/booking/bookingService';
import {
  PeoBookingIssues,
  RateProductIsModifiedBookingIssue,
  VehicleNotValidForYoungDriverBookingIssue,
} from 'utils/bookingUtils';
import { parseUrn } from 'utils/urnUtils';
import { EMPTY_VALUE } from 'utils/constants';
import { useUpdateAndRefreshEditor } from 'hooks/bookingEditor/useUpdateAndRefreshEditor';
import { useAppSelector } from 'redux/hooks';
import {
  selectAdditionalDrivers,
  selectBookingEditorId,
  selectContact,
  selectDriverProfileRenter,
  selectPayers,
} from 'redux/selectors/bookingEditor';
import { useBookingIssue } from 'services/booking/useBookingIssue';
import { useRateSourceAccountDetails } from 'services/businessAccount/useRateSourceAccountDetails';
import {
  ADDITIONAL_DRIVER_GIVEN_NAME,
  ADDITIONAL_DRIVER_SURNAME,
  parseContactName,
  REMOVE,
} from './driverForm/driverFormUtils';
import { transformToRenterFromProfile } from 'services/renter/renterTransformer';
import { DriverData, NewDriver, SaveDriverReturn } from 'components/shared/uiModels/driver/driverDataTypes';
import { useDriverLicenseValidation } from 'hooks/driverLicenseValidation/useDriverLicenseValidation';
import { ServiceResultType } from 'services/types/ServiceResultTypes';
import { ReservationAccount } from 'services/businessAccount/businessAccountTypes';
import { addPaymentMethodByLoyaltyMembership } from 'components/flexFlow/driver/driverSearch/driverSearchUtils';
import { getAppConfigCache } from 'services/appConfig/appConfigService';
import { UnifiedLoyaltyMembership } from 'services/renter/driverProfile/driverProfileTypes';

export const useSaveDriver = (): SaveDriverReturn => {
  const appConfig = getAppConfigCache();
  const { t } = useTranslations();
  const { showAlert } = useAlert();
  const { updateAndRefresh } = useUpdateAndRefreshEditor();
  const { handleBookingIssues } = useBookingIssue();
  const { rateSourceInfo } = useRateSourceAccountDetails();
  const { validateExpirationDateAgainstRentalDates } = useDriverLicenseValidation();

  const bookingEditorId = useAppSelector(selectBookingEditorId);
  const driverProfileRenter = useAppSelector(selectDriverProfileRenter);
  const additionalDrivers = useAppSelector(selectAdditionalDrivers);
  const contact = useAppSelector(selectContact);
  const payers = useAppSelector(selectPayers);

  /**
   * Saves the list of additional drivers to the editor
   * @param additionalDriversToUpdate - the list of additional drivers to save
   */
  const saveAdditionalDriversToEditor = async (
    additionalDriversToUpdate: AdditionalDriver[]
  ): Promise<ServiceResultType> => {
    const result = await updateAndRefresh(() => updateAdditionalDrivers(bookingEditorId, additionalDriversToUpdate));

    if (result.errors) {
      await showAlert({
        description: `${t('driver.addAdditionalDriverError')}: ${result.errors?.[0]?.localizedMessage || EMPTY_VALUE}`,
      });
    }
    return result;
  };

  /**
   * Updates the editor with new list of additional drivers once driver is removed
   * @param driverToRemove - The driver to remove from additional drivers
   */
  const removeDriver = (driverToRemove: DriverData | undefined): AdditionalDriver[] => {
    const currentAdditionalDrivers = additionalDrivers && additionalDrivers.length > 0 ? additionalDrivers : [];
    if (driverToRemove?.urn) {
      return currentAdditionalDrivers.filter((additionalDriver) => additionalDriver.profile !== driverToRemove.urn);
    } else {
      const additionalDrivers = [...currentAdditionalDrivers];
      const index = additionalDrivers?.findIndex(
        // Note: given name as Add and surname as Driver are added by editor for additional driver, without driver data
        (item) =>
          item?.name?.given === ADDITIONAL_DRIVER_GIVEN_NAME && item?.name?.surname === ADDITIONAL_DRIVER_SURNAME
      );
      if (index !== -1) {
        additionalDrivers.splice(index, 1);
      }
      return additionalDrivers;
    }
  };

  /**
   * Updates the editor with new list of additional drivers once new driver is added and old driver is removed
   * @param driver - The new driver to add in to additional drivers
   * @param driverToRemove - The driver to remove from additional drivers. This happens when either updating a quick add
   * driver or moving additional driver to primary driver
   */
  const updateAdditionalDriverOnEditor = async (
    driver: DriverData,
    driverToRemove?: DriverData
  ): Promise<ServiceResultType> => {
    const currentAdditionalDrivers = additionalDrivers && additionalDrivers.length > 0 ? additionalDrivers : [];
    const updatedAdditionalDriverList = driverToRemove ? removeDriver(driverToRemove) : currentAdditionalDrivers;
    const additionalDriverToAdd: AdditionalDriver = {
      name: { given: driver.firstName ?? EMPTY_VALUE, surname: driver.lastName ?? EMPTY_VALUE },
      profile: driver.urn,
    };
    return saveAdditionalDriversToEditor([...updatedAdditionalDriverList, additionalDriverToAdd]);
  };

  /**
   * Updates the editor with new list quick add additional drivers
   * @param numOfProfiles - The number of quick add drivers to save to editor
   */
  const updateNoProfileAdditionalDriversOnEditor = (numOfProfiles: number): Promise<ServiceResultType> => {
    const currentAdditionalDrivers = additionalDrivers && additionalDrivers.length > 0 ? additionalDrivers : [];
    const noProfileDrivers: AdditionalDriver[] = [];
    for (let i = 1; i <= Number(numOfProfiles); i++) {
      noProfileDrivers.push({});
    }
    const additionalDriversToUpdate = [...currentAdditionalDrivers, ...noProfileDrivers];
    return saveAdditionalDriversToEditor(additionalDriversToUpdate);
  };

  /**
   * Updates the editor with new primary driver
   * @param renter - The renter to save to editor as primary driver
   * @param accountDetails - This contains the rate source account to update in editor. Pertains to only loyalty driver.
   * @param loyaltyMembership - This is the loyalty membership attached to loyalty driver.
   */
  const updatePrimaryDriverOnEditor = async (
    renter: Renter | undefined,
    accountDetails?: ReservationAccount,
    loyaltyMembership?: UnifiedLoyaltyMembership
  ): Promise<ServiceResultType> => {
    if (!renter) {
      const translatedMessage = t('driver.addDriverError');
      await showAlert({
        description: translatedMessage,
      });
      return { errors: [{ localizedMessage: translatedMessage, severity: 'ERROR', code: EMPTY_VALUE }] };
    }

    let rateSourcePayload: RateSource | undefined;

    const isLoyaltyDriver = !!(renter as DriverProfileRenter)?.membership;
    if (accountDetails) {
      rateSourcePayload = {
        type: RateSourceType.NEGOTIATED,
        account: accountDetails?.urn ?? EMPTY_VALUE,
      };
    } else if (!isLoyaltyDriver && rateSourceInfo) {
      rateSourcePayload = {
        type: RateSourceType.RETAIL,
      };
    }

    // Update the editor when payment method is not selected and
    // Driver loyalty profile has a payment method as credit card,
    const existingPaymentMethod = parseUrn(payers?.person?.paymentMethod);
    const defaultEhiDatabase = appConfig?.defaultEhiDatabase ?? EMPTY_VALUE;
    const paymentMethodPayload = addPaymentMethodByLoyaltyMembership(
      existingPaymentMethod,
      defaultEhiDatabase,
      loyaltyMembership
    );

    const result = await updateAndRefresh(async () => {
      await associateRenterToReservationEditor(bookingEditorId, renter);
      if (rateSourcePayload) await modifyRateSource(bookingEditorId, rateSourcePayload);
      if (paymentMethodPayload) await updatePayersPerson(bookingEditorId, paymentMethodPayload);
    });

    if (result.errors) {
      await showAlert({
        description: `${t('driver.addDriverError')}: ${result.errors?.[0]?.localizedMessage || EMPTY_VALUE}`,
      });
    }
    if (result.data) {
      const availableBookingIssues = [...Object.values(PeoBookingIssues), VehicleNotValidForYoungDriverBookingIssue];
      await handleBookingIssues(
        result.data.issue ?? [],
        result.data.ehiMessages ?? [],
        availableBookingIssues,
        t('snackbarMessages.additionalInfo.reviewSelections'),
        accountDetails ? [RateProductIsModifiedBookingIssue] : []
      );
    }

    return result;
  };

  /**
   * Determines whether driver profile driver needs to be saved as a primary or additional driver
   * @param newDriver - The driver profile driver to save to editor
   */
  const updateDriverProfileOnEditor = (newDriver: NewDriver): Promise<ServiceResultType> => {
    if (!driverProfileRenter) {
      return updatePrimaryDriverOnEditor(
        transformToRenterFromProfile(
          RenterType.DRIVER_PROFILE,
          newDriver.driverInfo.urn,
          newDriver.driverInfo?.loyaltyProgram?.urn
        ),
        newDriver.accountDetails,
        newDriver.loyaltyMembership
      );
    } else {
      return updateAdditionalDriverOnEditor(newDriver.driverInfo, newDriver.quickDriverToRemove);
    }
  };

  /**
   * Determines whether transactional profile driver needs to be saved as a primary or additional driver
   * @param newDriver - The transactional profile driver to save to editor
   */
  const updateTransactionalProfileOnEditor = (newDriver: NewDriver): Promise<ServiceResultType> => {
    if (!driverProfileRenter) {
      return updatePrimaryDriverOnEditor(
        transformToRenterFromProfile(RenterType.TRANSACTIONAL_PROFILE, newDriver.driverInfo.urn)
      );
    } else {
      return updateAdditionalDriverOnEditor(newDriver.driverInfo, newDriver.quickDriverToRemove);
    }
  };

  /**
   * Returns the current primary driver that is saved on the editor
   */
  const getCurrentPrimaryDriver = (): DriverData => {
    const { given, surname } = parseContactName(contact?.name ?? EMPTY_VALUE);
    return {
      type: RenterType.DRIVER_PROFILE,
      urn: driverProfileRenter?.profile,
      firstName: given,
      lastName: surname,
    };
  };

  /**
   * Updates editor when user clicks button to change primary driver when editing primary driver
   * @param selection - determines if primary driver is made an additional driver or is removed from editor
   * @param newDriver - This is the driver to replace current primary driver. It can come from DriverForm or DriverSearch
   */
  const changePrimaryDriverFromNewDriver = async (
    selection: string,
    newDriver: NewDriver | undefined
  ): Promise<ServiceResultType> => {
    const driverInfo = newDriver?.driverInfo;
    if (!driverInfo?.urn) {
      const translatedMessage = t('driver.addDriverError');
      await showAlert({
        description: translatedMessage,
      });
      return { errors: [{ localizedMessage: translatedMessage, severity: 'ERROR', code: EMPTY_VALUE }] };
    }

    const primaryDriverToMove = getCurrentPrimaryDriver();
    return updatePrimaryDriverOnEditor(
      transformToRenterFromProfile(driverInfo.type, driverInfo.urn, driverInfo.loyaltyProgram?.urn),
      newDriver?.accountDetails,
      newDriver?.loyaltyMembership
    ).then(async (result) => {
      if (driverInfo.license?.expirationDate) {
        validateExpirationDateAgainstRentalDates(driverInfo.license?.expirationDate);
      }

      if (selection !== REMOVE) {
        return updateAdditionalDriverOnEditor(primaryDriverToMove);
      }
      return result;
    });
  };

  /**
   * Updates editor when user clicks button to make additional driver a primary driver
   * @param selection - determines if primary driver is made an additional driver or is removed from editor
   * @param newDriver - This is the driver to move that contains the edits from the form
   */
  const changePrimaryDriverFromAdditionalDriver = async (
    selection: string,
    newDriver: NewDriver | undefined
  ): Promise<ServiceResultType> => {
    const driverInfo = newDriver?.driverInfo;
    if (!driverInfo?.urn) {
      const translatedMessage = t('driver.updateDriverError');
      await showAlert({
        description: translatedMessage,
      });
      return { errors: [{ localizedMessage: translatedMessage, severity: 'ERROR', code: EMPTY_VALUE }] };
    }

    const additionalDriverToRemove = driverInfo;
    const primaryDriverToMove = getCurrentPrimaryDriver();
    return updatePrimaryDriverOnEditor(
      transformToRenterFromProfile(driverInfo.type, driverInfo.urn, driverInfo.loyaltyProgram?.urn)
    ).then(async () => {
      if (driverInfo.license?.expirationDate) {
        validateExpirationDateAgainstRentalDates(driverInfo.license.expirationDate);
      }

      if (selection !== REMOVE) {
        return updateAdditionalDriverOnEditor(primaryDriverToMove, additionalDriverToRemove);
      } else {
        return removeAdditionalDriver(additionalDriverToRemove);
      }
    });
  };

  /**
   * Updates editor when by removing the driver passed in
   * @param driverToRemove - The driver to remove from additional drivers
   */
  const removeAdditionalDriver = async (driverToRemove: DriverData | undefined): Promise<ServiceResultType> => {
    return saveAdditionalDriversToEditor(removeDriver(driverToRemove));
  };

  /**
   * Returns true if the driver info indicates driver is an additional driver
   * @param driverType - The type associated with the driver
   * @param driverUrn - The urn associated with the driver
   */
  const isAdditionalDriver = (driverType: RenterType, driverUrn: string | undefined): boolean => {
    return (
      driverType === RenterType.NO_PROFILE ||
      !!additionalDrivers?.find((additionalDriver) => additionalDriver.profile === driverUrn)
    );
  };

  return {
    updateNoProfileAdditionalDriversOnEditor,
    updateDriverProfileOnEditor,
    updateTransactionalProfileOnEditor,
    changePrimaryDriverFromNewDriver,
    changePrimaryDriverFromAdditionalDriver,
    removeAdditionalDriver,
    isAdditionalDriver,
  };
};
