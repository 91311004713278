import { getDefaultAxiosInstance } from 'components/shared/axios/axiosConfiguration';
import {
  ElectronicIdentityVerificationRequest,
  Error,
  FullHumanAndElectronicVerification,
  IdentityVerificationResponse,
} from 'services/identityVerification/identityVerificationTypes';
import {
  ACCEPT,
  ACCEPT_APPLICATION_FULL_VERIFICATION,
  EHI_ACCEPT_PROTECTED_DATA,
  EHI_BILLING_CONTEXT,
  VALIDATIONS_PROTECTED_DATA_VALUE,
} from 'services/headerConstants';

export const IDENTITY_VERIFICATION_SERVICE_NAME = 'Identity Verification';
const BASE_PATH = '/identityVerification';
const PERSONAL_INFORMATION_ENDPOINT = '/personalInformation';

// Note: Neither endpoint can contain version in the Accept header, we are skipping applying it in the ehiHeaderInterceptor

export const getElectronicIdentityVerification = async (
  request?: ElectronicIdentityVerificationRequest
): Promise<IdentityVerificationResponse | Error> => {
  const axiosInstance = getDefaultAxiosInstance();
  const response = await axiosInstance.post(`${BASE_PATH}${PERSONAL_INFORMATION_ENDPOINT}/electronic-verify`, request, {
    genericUrl: `${BASE_PATH}${PERSONAL_INFORMATION_ENDPOINT}/electronic-verify`,
    headers: {
      [ACCEPT]: 'application/json',
      [EHI_BILLING_CONTEXT]: request?.transactionLocation,
    },
  });

  return response.data;
};

export const retrieveVerificationHistory = async (
  context: string
): Promise<FullHumanAndElectronicVerification[] | Error> => {
  const axiosInstance = getDefaultAxiosInstance();
  const response = await axiosInstance.get(
    `${BASE_PATH}${PERSONAL_INFORMATION_ENDPOINT}?context=${context}&returnLatest=true&verificationType=ELECTRONIC`,
    {
      genericUrl: `identityVerification/personalInformation?context={context}&returnLatest=true&verificationType=ELECTRONIC`,
      headers: {
        [ACCEPT]: ACCEPT_APPLICATION_FULL_VERIFICATION,
        [EHI_ACCEPT_PROTECTED_DATA]: VALIDATIONS_PROTECTED_DATA_VALUE,
      },
    }
  );

  return response?.data;
};
