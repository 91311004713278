import { ChangeEvent, FC, useMemo } from 'react';
import {
  PeoAmount,
  PeoAmountAndSelectability,
  PeoCheckboxContainer,
  PeoCode,
  PeoListItemContainer,
  PeoQuantityAndRate,
  PeoSelectability,
  PeoTitle,
  PeoTitleAndCode,
  StyledCheckbox,
} from './Peo.styles';
import { useAppSelector } from 'redux/hooks';
import { selectIsReadOnlyFlow } from 'redux/selectors/bookingEditor';
import { getCounterOptions, getItemRates, getSelectabilityDescription, Selectability } from 'utils/peoUtils';
import { useLocale, useTranslations } from 'components/shared/i18n';
import { Rate } from 'services/booking/bookingTypes';
import { SelectField } from 'components/shared/forms/SelectField';
import { useFormContext } from 'react-hook-form';
import { PeoListItemReadOnly } from './PeoListItemReadOnly';

export type PeoListItemProps = {
  checkBoxFieldName: string;
  title: string;
  code: string;
  rate: Rate | undefined;
  selectability: string;
  index: number;
  showCountDropdown?: boolean;
  quantityName?: string;
  isQuantityDisabled?: boolean;
};

const DISPLAY_COUNTER_CLASS_NAME = 'displayQuantity';

export const PeoListItem: FC<PeoListItemProps> = ({
  index,
  title,
  code,
  rate,
  selectability,
  checkBoxFieldName,
  showCountDropdown,
  quantityName,
  isQuantityDisabled,
}: PeoListItemProps) => {
  const isReadOnly = useAppSelector(selectIsReadOnlyFlow);
  const { t } = useTranslations();
  const { locale } = useLocale();
  const { setValue, watch } = useFormContext();

  const isChecked = watch(checkBoxFieldName);
  const handlePeoChange = (value: number): void => {
    setValue(checkBoxFieldName, value > 0, { shouldDirty: true });
    if (quantityName) setValue(quantityName, value, { shouldDirty: true });
  };

  const showQuantity = showCountDropdown && !isReadOnly && !isQuantityDisabled;

  const getItemDisabledState = useMemo(() => {
    return (selectability: string) => {
      switch (selectability) {
        case Selectability.OPTIONAL:
          return false;
        case Selectability.NOT_OFFERED:
        case Selectability.INCLUDED:
        case Selectability.MANDATORY:
          return true;
        default:
          return false;
      }
    };
  }, []);

  const disabled = getItemDisabledState(selectability);
  const isNoOffered = selectability === Selectability.NOT_OFFERED;

  if (isReadOnly) {
    return <PeoListItemReadOnly index={index} title={title} code={code} rate={rate} selectability={selectability} />;
  }

  return (
    <PeoListItemContainer
      key={`peo-item-${index}`}
      data-testid={`peo-item-${index}`}
      isSelected={isChecked}
      isDisabled={disabled}>
      <PeoCheckboxContainer>
        <StyledCheckbox
          name={checkBoxFieldName}
          data-testid={checkBoxFieldName}
          isChecked={isChecked}
          disabled={disabled}
          isDisabled={disabled}
          checked={isChecked}
          onChange={(e: ChangeEvent<HTMLInputElement>): void => {
            handlePeoChange(e.target.checked ? 1 : 0);
          }}
        />
        <PeoTitleAndCode>
          <PeoTitle data-testid='peoTitle'>{title}</PeoTitle>
          <PeoCode data-testid='peoCode' isSelected={isChecked} isDisabled={disabled}>
            {code}
          </PeoCode>
        </PeoTitleAndCode>
      </PeoCheckboxContainer>
      <PeoQuantityAndRate className={showQuantity ? DISPLAY_COUNTER_CLASS_NAME : ''} isDisabled={disabled}>
        {quantityName && (
          <SelectField
            style={{ visibility: showQuantity ? 'visible' : 'hidden' }}
            data-testid={quantityName}
            name={quantityName}
            options={getCounterOptions()}
            hasNoneOption={false}
            disabled={false}
            onChange={(event): void => {
              handlePeoChange(Number.parseInt(event.target.value, 10));
            }}
            variant={'outlined'}
          />
        )}
        <PeoAmountAndSelectability>
          {selectability === Selectability.OPTIONAL && (
            <PeoAmount data-testid='peoAmount' isSelected={isChecked}>
              {rate && getItemRates(rate, t, locale)}
            </PeoAmount>
          )}
          <PeoSelectability
            data-testid={`peoSelectability-${index}`}
            isSelected={isChecked}
            isDisabled={disabled}
            doNotOffer={isNoOffered}>
            {getSelectabilityDescription(selectability, t)}
          </PeoSelectability>
        </PeoAmountAndSelectability>
      </PeoQuantityAndRate>
    </PeoListItemContainer>
  );
};
