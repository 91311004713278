import { useCallback, useMemo } from 'react';
import { useRateSourceAccountDetails } from 'services/businessAccount/useRateSourceAccountDetails';
import { useTranslations } from 'components/shared/i18n';
import { useAppSelector } from 'redux/hooks';
import { selectIsReadOnlyFlow, selectRemarks, selectReservationData } from 'redux/selectors/bookingEditor';
import { ReservationAlert, ReservationAlertsHook } from 'components/shared/reservationAlerts/ReservationAlertsTypes';
import { useReservationSessionHelper } from 'components/shared/preprocessor/useReservationSessionHelper';
import { TransactionTypes } from 'utils/routing/TransactionTypes';
import { LEGACY_APP_CODE_ARMS, LEGACY_APP_CODE_GREENLIGHT } from 'utils/constants';

export const useReservationAlerts = (): ReservationAlertsHook => {
  const { t } = useTranslations();
  const { rateSourceInfo } = useRateSourceAccountDetails();
  const isViewFlow = useAppSelector(selectIsReadOnlyFlow);
  const { determineTransactionType } = useReservationSessionHelper();
  const reservationData = useAppSelector(selectReservationData);
  const preferences = useAppSelector(selectRemarks);

  const isReservationSupportedForModify: boolean = useMemo(() => {
    const validCodes: string[] = [LEGACY_APP_CODE_GREENLIGHT, LEGACY_APP_CODE_ARMS];
    return (
      reservationData?.source?.legacyApplication === undefined ||
      validCodes.includes(reservationData?.source?.legacyApplication)
    );
  }, [reservationData?.source?.legacyApplication]);

  const rateSourceAllowsModification: boolean = useMemo(() => {
    return (
      rateSourceInfo?.reservationConfiguration?.allowModification ||
      rateSourceInfo?.reservationConfiguration?.allowModification === undefined
    );
  }, [rateSourceInfo?.reservationConfiguration?.allowModification]);

  const transactionType = useMemo(() => {
    return (
      reservationData?.lifecycle &&
      reservationData?.status &&
      determineTransactionType(reservationData.lifecycle, reservationData.status)
    );
  }, [determineTransactionType, reservationData?.lifecycle, reservationData?.status]);

  const modifyAllowed: boolean = useMemo(() => {
    return (
      transactionType === TransactionTypes.Modify && rateSourceAllowsModification && isReservationSupportedForModify
    );
  }, [isReservationSupportedForModify, rateSourceAllowsModification, transactionType]);

  const cancelAllowed: boolean = useMemo(() => {
    return (
      rateSourceInfo?.reservationConfiguration?.allowCancel ||
      rateSourceInfo?.reservationConfiguration?.allowCancel === undefined
    );
  }, [rateSourceInfo]);

  const alertMessageVerbiage: string = useMemo(() => {
    if (!cancelAllowed) {
      return t('reservationAlerts.unableToModifyOrCancel.message');
    } else if (!rateSourceAllowsModification) {
      return t('reservationAlerts.unableToModify.message');
    } else {
      return t('reservationAlerts.unableToModifyNatRes.message');
    }
  }, [cancelAllowed, rateSourceAllowsModification, t]);

  const alertTitleVerbiage: string = useMemo(() => {
    if (!cancelAllowed) {
      return t('reservationAlerts.unableToModifyOrCancel.title');
    } else if (!rateSourceAllowsModification) {
      return t('reservationAlerts.unableToModify.title');
    } else {
      return t('reservationAlerts.unableToModifyNatRes.title');
    }
  }, [cancelAllowed, rateSourceAllowsModification, t]);

  const getAlerts: () => Array<ReservationAlert> | [] = useCallback(() => {
    const alerts = [];
    if (!modifyAllowed && isViewFlow && transactionType !== TransactionTypes.View) {
      alerts.push({
        title: alertTitleVerbiage,
        message: alertMessageVerbiage,
        hasNavigation: !rateSourceAllowsModification ? false : !isReservationSupportedForModify,
      });
    }

    if (preferences) {
      alerts.push({
        title: t('notes.preferences'),
        message: preferences,
      });
    }

    return alerts;
  }, [
    modifyAllowed,
    isViewFlow,
    transactionType,
    preferences,
    alertTitleVerbiage,
    alertMessageVerbiage,
    rateSourceAllowsModification,
    isReservationSupportedForModify,
    t,
  ]);

  return {
    alerts: getAlerts(),
    cancelAllowed,
    modifyAllowed,
  };
};
