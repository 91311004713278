import { useAppDispatch, useAppSelector } from 'redux/hooks';
import { selectBookingEditorId } from 'redux/selectors/bookingEditor';
import { useCallback } from 'react';
import { retrieveEditor } from 'services/booking/bookingService';
import { setReservation } from 'redux/slices/booking/bookingEditorSlice';
import { safelyCatchError } from 'utils/errorUtils';
import { ReservationEditor } from 'services/booking/bookingTypes';
import { ServiceResultType } from 'services/types/ServiceResultTypes';
import { useAlert } from 'components/shared/alert/AlertContext';
import { useTranslations } from 'components/shared/i18n';
import { useNavigate } from 'react-router-dom';
import { useReservationSessionHelper } from 'components/shared/preprocessor/useReservationSessionHelper';
import { SelectedAction } from 'components/shared/alert/AlertDialogTypes';
import { RouterPaths } from 'app/router/RouterPaths';
import { InvalidRateProductBookingIssue } from 'utils/bookingUtils';
import { ResponseMessage } from 'services/types/ResponseMessageTypes';

export type ReservationEditorWithMessages = ReservationEditor & {
  ehiMessages?: ResponseMessage[];
};

export type RefreshEditorHook = {
  /**
   *  Retrieves Reservation Editor based on current session editorId, and updates redux with the updated editor.
   *   (returning service result so save and navigate works appropriately, don't want to continue to navigate on errors)
   *
   *  @return Promise<ServiceResultType<ReservationEditor>>
   */
  refreshEditor: (editorId?: string) => Promise<ServiceResultType<ReservationEditorWithMessages>>;
};

export const useRefreshEditor = (): RefreshEditorHook => {
  const { t } = useTranslations();
  const bookingEditorId = useAppSelector(selectBookingEditorId);
  const dispatch = useAppDispatch();
  const { showAlert } = useAlert();
  const navigate = useNavigate();
  const { clearEditorSession } = useReservationSessionHelper();

  const refreshEditor = useCallback(
    async (editorId = bookingEditorId): Promise<ServiceResultType<ReservationEditorWithMessages>> => {
      let editor;
      let errors;

      try {
        editor = await retrieveEditor(editorId);

        const invalidRateProductError = editor?.ehiMessages?.find(
          (message) => InvalidRateProductBookingIssue === message.code
        );
        if (invalidRateProductError) {
          return { errors: [invalidRateProductError] };
        }
      } catch (error) {
        const ehiErrorsResponse = safelyCatchError(error);
        errors = ehiErrorsResponse.errors;

        const selection = await showAlert({
          variant: 'error',
          description: t('error.editorError', {
            error: errors?.[0].localizedMessage || '',
          }),
          primaryActionText: t('error.reload'),
          secondaryActionText: t('error.home'),
        });

        if (selection === SelectedAction.Primary) {
          return refreshEditor(editorId);
        } else if (selection === SelectedAction.Secondary) {
          clearEditorSession();
          navigate(RouterPaths.Search, {
            replace: true,
          });
          // Note: React Router's navigation is asynchronous. This means that the navigation process starts,
          // but the code continues to execute without waiting for the navigation to finish.
          // be sure to send back errors to prevent unwanted behavior
          return { errors: undefined };
        }
      }

      if (editor) {
        dispatch(setReservation({ editor: editor.data }));
      }

      return {
        data: { ...editor?.data, ehiMessages: editor?.ehiMessages } as ReservationEditorWithMessages,
        success: !!editor,
        errors,
      };
    },
    [bookingEditorId, clearEditorSession, dispatch, navigate, showAlert, t]
  );

  return { refreshEditor };
};
