import { getDefaultAxiosInstance } from 'components/shared/axios/axiosConfiguration';
import { ConsentSms } from 'services/consent/consentCommunicationTypes';
import { generateUrnForReservation } from 'utils/urnUtils';
import { getAppConfigCache } from 'services/appConfig/appConfigService';

const ROOT_PATH = '/communication/consent';
const CONSENT_SEARCH_PATH = '/search';
const REVOKE_CONSENT_PATH = '/revoke';
const CHANNEL = 'SMS';

export const retrieveConsentCommunication = async (reservationNumber: string): Promise<Array<ConsentSms>> => {
  const axiosInstance = getDefaultAxiosInstance();
  const appConfig = getAppConfigCache();

  const response = await axiosInstance.post(`${ROOT_PATH}${CONSENT_SEARCH_PATH}`, {
    genericUrl: `${ROOT_PATH}${CONSENT_SEARCH_PATH}`,
    context: generateUrnForReservation(reservationNumber, appConfig?.defaultEhiDatabase ?? ''),
    channel: CHANNEL,
  });
  return response.data;
};

export const createConsentCommunication = async (
  reservationNumber: string,
  isDeclined: boolean,
  phoneNumber?: string
): Promise<ConsentSms> => {
  const axiosInstance = getDefaultAxiosInstance();
  const appConfig = getAppConfigCache();

  const response = await axiosInstance.post(
    `${ROOT_PATH}`,
    {
      context: generateUrnForReservation(reservationNumber, appConfig?.defaultEhiDatabase ?? ''),
      channel: CHANNEL,
      isDeclined: isDeclined,
      phoneNumber: phoneNumber,
    },
    { genericUrl: `${ROOT_PATH}` }
  );
  return response.data;
};

export const revokeConsentCommunication = async (consentUUID: string): Promise<ConsentSms> => {
  const axiosInstance = getDefaultAxiosInstance();
  const response = await axiosInstance.post(`${ROOT_PATH}/${consentUUID}${REVOKE_CONSENT_PATH}`, {
    genericUrl: `${ROOT_PATH}/${consentUUID}${REVOKE_CONSENT_PATH}`,
  });
  return response.data;
};
