import { FlexiFunctionComponent } from 'components/shared/flexiFlow/FlexFlowTypes';
import { StickyCardNavigation } from 'components/shared/ui/card/StickyCardNavigation';
import { useTranslations } from 'components/shared/i18n';
import { useAppSelector } from 'redux/hooks';
import { selectBookingEditorId, selectPickup, selectReturn } from 'redux/selectors/bookingEditor';
import { useBranchInfoByUrnQuery } from 'services/location/locationQueries';
import { mapLoadingState } from 'components/shared/ui/spinner/loadableView/LoadableViewUtils';
import { WhenAndWhereFields, WhenAndWhereValues } from 'components/flexFlow/whenAndWhere/WhenAndWhereTypes';
import { useForm } from 'react-hook-form';
import { RentalStartSection } from 'components/flexFlow/whenAndWhere/RentalStartSection';
import { RentalReturnSection } from 'components/flexFlow/whenAndWhere/RentalReturnSection';
import { compareDateTime, toDateTime } from 'utils/dateUtils';
import { useMemo, useState } from 'react';
import { useUpdateAndRefreshEditor } from 'hooks/bookingEditor/useUpdateAndRefreshEditor';
import { updatePickupInformation, updateReturnInformation } from 'services/booking/bookingService';
import { DateTime } from 'luxon';
import { useDateTimeFormater } from 'utils/routing/useDatetimeFormater';
import { useYupValidationResolver } from 'components/shared/forms/useYupValidationResolver';
import { whenAndWhereValidationSchema } from 'components/flexFlow/whenAndWhere/whenAndWhereValidationSchema';
import { SavingFormProvider } from 'context/saveAction/SavingFormProvider';
import { EhiErrors } from 'services/types/EhiErrorsTypes';
import { useSaveWhenAndWhere } from 'components/flexFlow/whenAndWhere/useSaveWhenAndWhere';
import { ErrorBanner } from 'components/shared/errors/ErrorBanner';
import { Grid } from '@mui/material';
import { ehiTheme } from '@ehi/ui';
import {
  RentalCard,
  WhenAndWhereFlexFlowCard,
  WhenAndWhereGrid,
  WhenAndWherePage,
  WhenAndWhereScrollContainer,
} from 'components/flexFlow/whenAndWhere/WhenAndWhere.styles';
import { useBookingIssue } from 'services/booking/useBookingIssue';
import { ServiceResultType } from 'services/types/ServiceResultTypes';
import { useRateSourceAccountDetails } from 'services/businessAccount/useRateSourceAccountDetails';
import { BranchType } from 'services/location/locationTypes';
import { useCarrierSafetyNumberUtility } from 'components/flexFlow/rateAndBilling/editDialogs/rateSource/carrierSafetyNumber/useCarrierSafetyNumberUtility';
import {
  BillToBookingIssues,
  DriverNotValidForBrandBookingIssue,
  PeoBookingIssues,
  VehicleAvailabilityBookingIssues,
  VehicleNotValidForYoungDriverBookingIssue,
} from 'utils/bookingUtils';

export const WhenAndWhere: FlexiFunctionComponent = ({ onNext, onPrevious }) => {
  const { t } = useTranslations();
  const { getLocalizedDateTime } = useDateTimeFormater();
  const { save, handleErrors } = useSaveWhenAndWhere();
  const { handleBookingIssues } = useBookingIssue();
  const { updateAndRefresh } = useUpdateAndRefreshEditor();
  const { updateDefaultCarrierSafetyIdentifier } = useCarrierSafetyNumberUtility();

  const pickupData = useAppSelector(selectPickup);
  const returnData = useAppSelector(selectReturn);
  const bookingEditorId = useAppSelector(selectBookingEditorId);

  const [loading, setLoading] = useState(false);
  const [editorError, setEditorError] = useState(false);

  const {
    data: pickupBranch,
    isFetching: isFetchingPickupBranch,
    isError: isPickupBranchError,
  } = useBranchInfoByUrnQuery(pickupData?.branch ?? '');
  const {
    data: returnBranch,
    isFetching: isFetchingReturnBranch,
    isError: isReturnBranchError,
  } = useBranchInfoByUrnQuery(returnData?.branch ?? '');
  const { rateSourceInfo, isLoading: isLoadingRateSourceInfo } = useRateSourceAccountDetails();

  const isFetching = isFetchingPickupBranch || isFetchingReturnBranch || isLoadingRateSourceInfo || loading;
  const isError = isPickupBranchError || isReturnBranchError || editorError;

  const whenWhereInitialValues: WhenAndWhereValues = useMemo(() => {
    return {
      startDate: toDateTime(pickupData?.dateTime, pickupBranch?.timezone) ?? '',
      startTime: toDateTime(pickupData?.dateTime, pickupBranch?.timezone) ?? '',
      returnDate: toDateTime(returnData?.dateTime, returnBranch?.timezone) ?? '',
      returnTime: toDateTime(returnData?.dateTime, returnBranch?.timezone) ?? '',
      startLocation: pickupBranch?.stationId ?? '',
      returnLocation: returnBranch?.stationId ?? '',
      startLocationCurrentTime: pickupBranch?.timezone
        ? (getLocalizedDateTime(pickupBranch.timezone, DateTime.now()) as DateTime)
        : '',
      returnLocationCurrentTime: returnBranch?.timezone
        ? (getLocalizedDateTime(returnBranch?.timezone, DateTime.now()) as DateTime)
        : '',
      startLocationUrn: pickupBranch?.urn ?? '',
      returnLocationUrn: returnBranch?.urn ?? '',
      startDateTime: pickupData?.dateTime ?? '',
      returnDateTime: returnData?.dateTime ?? '',
      startLocationTimezone: pickupBranch?.timezone ?? undefined,
      returnLocationTimezone: returnBranch?.timezone ?? undefined,
    };
  }, [pickupData, returnData, pickupBranch, returnBranch, getLocalizedDateTime]);

  const getValidationSchema = useMemo(
    () =>
      whenAndWhereValidationSchema(
        t,
        pickupBranch?.timezone ? (getLocalizedDateTime(pickupBranch.timezone, DateTime.now()) as DateTime) : ''
      ),
    [t, pickupBranch, getLocalizedDateTime]
  );

  const resolver = useYupValidationResolver(getValidationSchema);

  const formMethods = useForm<WhenAndWhereValues>({
    defaultValues: whenWhereInitialValues,
    resolver: resolver,
  });

  const [startDate, startLocation, returnLocation, startDateTime, returnDateTime] = formMethods.watch([
    WhenAndWhereFields.StartDate,
    WhenAndWhereFields.StartLocation,
    WhenAndWhereFields.ReturnLocation,
    WhenAndWhereFields.StartDateTime,
    WhenAndWhereFields.ReturnDateTime,
  ]);
  const startDateTimeError = formMethods.formState.errors.startDate || formMethods.formState.errors.startTime;
  const showBannerMessage = useMemo(() => {
    if (startDateTime && returnDateTime) {
      if (compareDateTime(startDateTime, returnDateTime)) {
        formMethods.setError(WhenAndWhereFields.ReturnDate, {});
        formMethods.setError(WhenAndWhereFields.ReturnTime, {});

        return true;
      }
    }
    return false;
  }, [startDateTime, returnDateTime, formMethods]);

  const rentalCardClassName = useMemo(() => {
    const isRentalLocation =
      startLocation || returnLocation || whenWhereInitialValues.startLocation || whenWhereInitialValues.returnLocation;
    if (startDateTimeError && isRentalLocation) {
      return 'dateTimeRentalLocation';
    } else if (isRentalLocation) {
      return 'rentalLocation';
    } else if (startDateTimeError) {
      return 'dateTimeError';
    }
    return '';
  }, [startDateTimeError, startLocation, returnLocation, whenWhereInitialValues]);

  const getAvailableBookingIssuesForStartLocation = (): string[] => {
    return [
      ...Object.values(VehicleAvailabilityBookingIssues),
      ...Object.values(PeoBookingIssues),
      ...Object.values(BillToBookingIssues),
      DriverNotValidForBrandBookingIssue,
      VehicleNotValidForYoungDriverBookingIssue,
    ];
  };

  const handleResultForStartLocation = async (result: ServiceResultType): Promise<void> => {
    if (result.errors) {
      handleErrors(result.errors);
    }

    if (result.data) {
      await handleBookingIssues(
        result.data.issue ?? [],
        result.data.ehiMessages ?? [],
        getAvailableBookingIssuesForStartLocation(),
        t('snackbarMessages.additionalInfo.location')
      );
    }
  };

  const handleRentalStartLocation = async (
    branchUrn: string,
    stationId: string,
    locationCurrentTime: DateTime | undefined,
    timezone?: string,
    branchType?: BranchType
  ): Promise<void> => {
    setLoading(true);
    formMethods.setValue(WhenAndWhereFields.StartLocationUrn, branchUrn);
    formMethods.setValue(WhenAndWhereFields.StartLocationCurrentTime, locationCurrentTime ?? '');
    formMethods.setValue(WhenAndWhereFields.StartLocationTimezone, timezone ?? undefined);
    formMethods.clearErrors();
    if (pickupData && returnData) {
      let pickUpInfo = {
        branch: branchUrn,
      };
      const startDateTime = formMethods.getValues(WhenAndWhereFields.StartDateTime);
      pickUpInfo = { ...pickUpInfo, ...(startDateTime ? { dateTime: startDateTime } : {}) };

      await updateAndRefresh(async () => {
        if (branchType !== pickupBranch?.type && rateSourceInfo) {
          await updateDefaultCarrierSafetyIdentifier(branchType, rateSourceInfo?.carrierSafetyIdentifiers ?? []);
        }
        await updatePickupInformation(bookingEditorId, pickUpInfo);
      })
        .then(async (result) => {
          await handleResultForStartLocation(result);
        })
        .finally(() => {
          setLoading(false);
        });
    } else {
      try {
        [
          { field: WhenAndWhereFields.StartLocationCurrentTime, value: locationCurrentTime ?? '' },
          { field: WhenAndWhereFields.StartLocationTimezone, value: timezone ?? undefined },
          { field: WhenAndWhereFields.ReturnLocationCurrentTime, value: locationCurrentTime ?? '' },
          { field: WhenAndWhereFields.ReturnLocation, value: stationId },
          { field: WhenAndWhereFields.StartLocationUrn, value: branchUrn ?? '' },
          { field: WhenAndWhereFields.ReturnLocationUrn, value: branchUrn ?? '' },
          { field: WhenAndWhereFields.ReturnLocationTimezone, value: locationCurrentTime?.zone.name ?? '' },
        ].forEach((item) => formMethods.setValue(item.field, item.value));

        await updateAndRefresh(async () => {
          if (branchType !== pickupBranch?.type) {
            await updateDefaultCarrierSafetyIdentifier(branchType, rateSourceInfo?.carrierSafetyIdentifiers ?? []);
          }
          await updatePickupInformation(bookingEditorId, {
            branch: branchUrn,
          });
          await updateReturnInformation(bookingEditorId, {
            branch: branchUrn,
          });
        })
          .then(async (result) => {
            await handleResultForStartLocation(result);
          })
          .finally(() => {
            setLoading(false);
          });
      } catch (error) {
        setLoading(false);
        setEditorError(true);
        formMethods.setError(WhenAndWhereFields.StartLocation, {
          message: (error as EhiErrors)?.errors?.[0]?.localizedMessage,
        });
      }
    }
  };

  const handleRentalReturnLocation = async (
    branchUrn: string,
    stationId: string,
    locationCurrentTime: DateTime | undefined,
    timezone?: string
  ): Promise<void> => {
    // Clear Errors when we change the location.
    formMethods.clearErrors();
    [
      { field: WhenAndWhereFields.ReturnLocationUrn, value: branchUrn ?? '' },
      { field: WhenAndWhereFields.ReturnLocation, value: stationId },
      { field: WhenAndWhereFields.ReturnLocationTimezone, value: timezone ?? undefined },
      { field: WhenAndWhereFields.ReturnLocationCurrentTime, value: locationCurrentTime ?? '' },
    ].forEach((item) => formMethods.setValue(item.field, item.value));
    let returnInfo = {
      branch: branchUrn,
    };
    const returnDateTime = formMethods.getValues(WhenAndWhereFields.ReturnDateTime);
    returnInfo = { ...returnInfo, ...(returnDateTime ? { dateTime: returnDateTime } : {}) };
    setLoading(true);
    await updateAndRefresh(() => updateReturnInformation(bookingEditorId, returnInfo))
      .then(async (result) => {
        if (result.errors) {
          handleErrors(result.errors);
        }
        if (result.data) {
          const availableBookingIssues = [
            ...Object.values(VehicleAvailabilityBookingIssues),
            ...Object.values(BillToBookingIssues),
          ];
          await handleBookingIssues(
            result.data.issue ?? [],
            result.data.ehiMessages ?? [],
            availableBookingIssues,
            t('snackbarMessages.additionalInfo.location')
          );
        }
      })
      .finally(() => {
        setLoading(false);
      });
  };

  const handleSubmit = async (values: WhenAndWhereValues): Promise<ServiceResultType> => {
    setLoading(true);
    return save(values).finally(() => {
      setLoading(false);
    });
  };

  return (
    <WhenAndWherePage>
      <WhenAndWhereFlexFlowCard loadingState={mapLoadingState(isFetching, isError)}>
        <StickyCardNavigation onPrevious={onPrevious} onNext={onNext} showExitToRentalSummary={true} />
        <SavingFormProvider
          {...formMethods}
          submitFn={(values: WhenAndWhereValues): Promise<ServiceResultType> => handleSubmit(values)}>
          <WhenAndWhereScrollContainer>
            <WhenAndWhereGrid container>
              {showBannerMessage && (
                <Grid item sm={12}>
                  <ErrorBanner errorMessage={t('validation.returnDateTimeError')} />
                </Grid>
              )}
              <Grid item sm>
                <RentalCard className={rentalCardClassName} variant={'outlined'} sx={{ padding: ehiTheme.spacing(3) }}>
                  <RentalStartSection
                    pickupBranch={pickupBranch}
                    pickupDateTime={pickupData?.dateTime}
                    handleRentalStartLocation={handleRentalStartLocation}
                  />
                </RentalCard>
              </Grid>
              <Grid item sm>
                <RentalCard className={rentalCardClassName} variant={'outlined'} sx={{ padding: ehiTheme.spacing(3) }}>
                  <RentalReturnSection
                    returnBranch={returnBranch}
                    returnDateTime={returnData?.dateTime}
                    handleRentalReturnLocation={handleRentalReturnLocation}
                    startDate={startDate ? (startDate as DateTime) : undefined}
                  />
                </RentalCard>
              </Grid>
            </WhenAndWhereGrid>
          </WhenAndWhereScrollContainer>
        </SavingFormProvider>
      </WhenAndWhereFlexFlowCard>
    </WhenAndWherePage>
  );
};
