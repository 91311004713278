import { useCallback, useEffect, useRef, useState } from 'react';
import { useMap } from '@vis.gl/react-google-maps';
import { type Marker, MarkerClusterer } from '@googlemaps/markerclusterer';
import { BranchLocation } from 'components/flexFlow/whenAndWhere/branchLookup/BranchLookupMarker';

interface MarkerClustererHook {
  registerMarker: (marker: Marker | null, stationId: string) => void;
}

function useMarkerClusterer(branches: BranchLocation[]): MarkerClustererHook {
  const map = useMap();
  const [markers, setMarkers] = useState<{ [stationId: string]: Marker }>({});
  const clusterer = useRef<MarkerClusterer | null>(null);
  const prevBranchesRef = useRef<BranchLocation[]>(branches);

  const haveBranchesChanged = (prev: BranchLocation[], next: BranchLocation[]): boolean => {
    const prevIds = new Set(prev.map((branch) => branch.stationId));
    return next.some((branch) => !prevIds.has(branch.stationId));
  };

  useEffect(() => {
    if (!map) return;

    clusterer.current = new MarkerClusterer({ map });

    return () => {
      if (clusterer.current) {
        clusterer.current.clearMarkers();
      }
      clusterer.current = null;
    };
  }, [map]);

  useEffect(() => {
    if (haveBranchesChanged(prevBranchesRef.current, branches)) {
      prevBranchesRef.current = branches;
      setMarkers({});

      if (clusterer.current) {
        clusterer.current.clearMarkers();
      }
    }
  }, [branches]);

  useEffect(() => {
    if (!clusterer.current || !map) return;

    clusterer.current.clearMarkers();
    const markerArray = Object.values(markers);
    if (markerArray.length > 0) {
      clusterer.current.addMarkers(markerArray);
    }
  }, [markers, map]);

  const registerMarker = useCallback((marker: Marker | null, stationId: string) => {
    setMarkers((prevMarkers) => {
      if ((marker && prevMarkers[stationId]) || (!marker && !prevMarkers[stationId])) return prevMarkers;

      if (marker) {
        return { ...prevMarkers, [stationId]: marker };
      } else {
        const newMarkers = { ...prevMarkers };
        delete newMarkers[stationId];
        return newMarkers;
      }
    });
  }, []);

  return { registerMarker };
}

export default useMarkerClusterer;
