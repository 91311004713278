import { FC, ReactElement, useMemo } from 'react';
import { Box, Grid } from '@mui/material';
import { useAppSelector } from 'redux/hooks';
import { selectVehicleClassSelection } from 'redux/selectors/bookingEditor';
import { Body1, Caption, ehiTheme } from '@ehi/ui';
import { useLocale, useTranslations } from 'components/shared/i18n';
import { useVehicleContentQuery } from 'services/vehicleContent/vehicleContentQueries';
import { useVehicleRates } from 'services/booking/useVehicleRates';
import { transformVehicleListFromApiData } from 'components/shared/uiModels/vehicle/vehicleTransformer';
import { Vehicle } from 'components/shared/uiModels/vehicle/vehicleDataTypes';
import {
  StyledBox,
  StyledGridContainer,
  VehicleSpecGridItem,
  VehicleSpecText,
} from 'components/flexFlow/review/Review.styles';
import AutoTransmissionDarkIcon from 'images/autoTransmissionDarkIcon.svg';
import { getAutoTransmissionDescription, getCarClassAndDescription, getTotalDistance } from 'utils/vehicleUtils';
import { Person as PassengerIcon, WorkOutline as LuggageIcon } from '@mui/icons-material';
import { Caption2 } from 'components/shared/ui/styles/Typography.styles';
import { parseUrn } from 'utils/urnUtils';
import { Card } from 'components/shared/ui/card/Card';
import { ProgressOverlay } from 'components/shared/ui/spinner/ProgressOverlay';
import { VehicleFeatures } from 'components/flexFlow/vehicle/VehicleFeatures';
import {
  PeriodicDistance,
  PeriodicVehicleRate,
  PotentiallyAdjustableAmount,
  VehicleRate,
} from 'services/booking/bookingTypes';
import { RateCardType } from 'components/flexFlow/vehicle/VehicleTypes';
import { formatCurrency } from 'utils/currencyUtils';
import { pageContainerBackground } from 'components/shared/ui/styles/Global.styles';

export const VehicleSection: FC = () => {
  const { t } = useTranslations();
  const vehicleClassSelection = useAppSelector(selectVehicleClassSelection);
  const { data: vehicleContent, isFetching: isVehicleContentLoading } = useVehicleContentQuery();
  const { vehicleRates, isLoading: isVehicleRatesLoading } = useVehicleRates();

  const vehicles = useMemo(() => {
    if (!(isVehicleContentLoading || isVehicleRatesLoading)) {
      return transformVehicleListFromApiData(vehicleContent, vehicleRates);
    }
    return [];
  }, [isVehicleContentLoading, isVehicleRatesLoading, vehicleContent, vehicleRates]);

  const selectedVehicle = useMemo(() => {
    return vehicles.find((vehicle: Vehicle) => vehicle.vehicleClass === parseUrn(vehicleClassSelection?.preferred));
  }, [vehicleClassSelection?.preferred, vehicles]);

  /* Retrieve vehicle class if one is selected */
  const vehicleClass = useMemo(
    () => getCarClassAndDescription(parseUrn(vehicleClassSelection?.preferred), vehicles),
    [vehicleClassSelection?.preferred, vehicles]
  );
  const rate: VehicleRate | undefined = vehicleClassSelection?.rate;
  const vehiclePeriodRate: PeriodicVehicleRate | undefined = rate && (rate as PeriodicVehicleRate);
  const includedDistanceRates: PeriodicDistance | undefined = vehiclePeriodRate?.includedDistance;
  const amountPerExcessDistance: PotentiallyAdjustableAmount | undefined =
    vehicleClassSelection?.rate?.amountPerExcessDistance;

  const getDistanceAllotment = (): ReactElement => {
    if (rate?.totalIncludedDistance?.value && rate?.totalIncludedDistance?.value > 0) {
      return (
        <Box data-testid='textBody' style={{ display: 'flex', alignItems: 'center' }}>
          {rate?.totalIncludedDistance?.value !== undefined && (
            <Caption data-testid={'vehicleInfoTotalIncludedDistance'}>{`${rate?.totalIncludedDistance.value}${t(
              'rates.perRental'
            )}`}</Caption>
          )}
          {amountPerExcessDistance && amountPerExcessDistance.amount !== undefined && (
            <>
              <span>,&nbsp;</span>
              <RateLabel
                amount={amountPerExcessDistance.amount}
                currency={amountPerExcessDistance.currencyCode ?? ''}
                period={t('rates.perExcess')}
              />
            </>
          )}
        </Box>
      );
    } else if (includedDistanceRates && getTotalDistance(includedDistanceRates) > 0) {
      const lineItems = new Array<JSX.Element>();

      if (includedDistanceRates.distancePerDay && includedDistanceRates.distancePerDay > 0) {
        lineItems.push(
          <Box key={'vehicleInfoRatesPerDay'}>
            <Caption data-testid={'vehicleInfoRatesPerDay'}>{`${includedDistanceRates.distancePerDay}${t(
              'rates.perDay'
            )}`}</Caption>
          </Box>
        );
      }
      if (includedDistanceRates.distancePerWeek && includedDistanceRates.distancePerWeek > 0) {
        lineItems.push(
          <Box key={'vehicleInfoRatesPerWeek'}>
            <span>,&nbsp;</span>;
            <Caption data-testid={'vehicleInfoRatesPerWeek'}>{`${includedDistanceRates.distancePerWeek}${t(
              'rates.perWeek'
            )}`}</Caption>
          </Box>
        );
      }
      if (includedDistanceRates.distancePerMonth && includedDistanceRates.distancePerMonth > 0) {
        lineItems.push(
          <Box key={'vehicleInfoRatesPerMonth'}>
            <span>,&nbsp;</span>
            <Caption data-testid={'vehicleInfoRatesPerMonth'}>{`${includedDistanceRates.distancePerMonth}${t(
              'rates.perMonth'
            )}`}</Caption>
          </Box>
        );
      }
      return (
        <Box style={{ display: 'flex', alignItems: 'center' }}>
          {lineItems}
          {amountPerExcessDistance && amountPerExcessDistance.amount !== undefined && (
            <>
              <span>,&nbsp;</span>
              <RateLabel
                amount={amountPerExcessDistance.amount}
                currency={amountPerExcessDistance.currencyCode ?? ''}
                period={t('rates.perExcess')}
              />
            </>
          )}
        </Box>
      );
    } else {
      return (
        <Caption data-testid={'vehicleInfoUnlimitedLabel'}>{vehicleClass ? t('vehicle.unlimitedMileage') : ''}</Caption>
      );
    }
  };

  return (
    <Card data-testid='vehicleSection' style={{ margin: ehiTheme.spacing(2, 0, 2, 0), padding: 0 }}>
      {selectedVehicle ? (
        <StyledBox>
          <StyledGridContainer>
            <Grid item xs={2} sm={2} display={'flex'} justifyContent={'center'}>
              <img
                data-testid='vehicleImage'
                src={selectedVehicle.imageKey}
                alt={''}
                height={'100%'}
                width={'100%'}
                style={{ maxHeight: '140px', maxWidth: '192px' }}
              />
            </Grid>
            <Grid item xs={10} sm={10}>
              <StyledBox data-testid='vehicleInfo'>
                <Body1
                  data-testid='description'
                  bold>{`${selectedVehicle.description} (${selectedVehicle.vehicleClass})`}</Body1>
                <Caption data-testid='similarModels' color={'#0000008A'}>
                  {selectedVehicle.similarModels + ' ' + t('vehicle.similarModels')}
                </Caption>
                <Grid data-testid='vehicleSpecs' container spacing={2}>
                  <VehicleSpecGridItem data-testid='transmissionType'>
                    <img src={AutoTransmissionDarkIcon} alt={selectedVehicle.transmissionType} />
                    <VehicleSpecText>
                      {getAutoTransmissionDescription(t, selectedVehicle.transmissionType)}
                    </VehicleSpecText>
                  </VehicleSpecGridItem>
                  <VehicleSpecGridItem data-testid='passengers'>
                    <PassengerIcon fontSize={'small'} />
                    <VehicleSpecText>{selectedVehicle.passengers}</VehicleSpecText>
                  </VehicleSpecGridItem>
                  <VehicleSpecGridItem data-testid='luggageSpace'>
                    <LuggageIcon fontSize={'small'} />
                    <VehicleSpecText>{selectedVehicle.luggageSpace}</VehicleSpecText>
                  </VehicleSpecGridItem>
                </Grid>
              </StyledBox>
            </Grid>
          </StyledGridContainer>
          <StyledBox bgcolor={pageContainerBackground}>
            <StyledGridContainer>
              <Grid item xs={9} sm={9}>
                <StyledBox data-testid='vehicleFeaturesSection'>
                  <VehicleFeatures vehicle={selectedVehicle} />
                </StyledBox>
              </Grid>
              <Grid item xs={3} sm={3}>
                <StyledBox data-testid='vehicleDistanceAllotment' style={{ alignItems: 'end' }}>
                  <Caption2 data-testid='label' bold>
                    {t('vehicle.distanceAllotment')}
                  </Caption2>
                  {getDistanceAllotment()}
                </StyledBox>
              </Grid>
            </StyledGridContainer>
          </StyledBox>
        </StyledBox>
      ) : (
        <Body1 data-testid='noVehicleSelectedText' color={'#0000008A'} padding={ehiTheme.spacing(2)}>
          {t('vehicle.noVehicleHasBeenSelected')}
        </Body1>
      )}
      <ProgressOverlay inProgress={isVehicleContentLoading || isVehicleRatesLoading} />
    </Card>
  );
};

export const RateLabel: FC<RateCardType> = ({ amount, currency, period }) => {
  const { locale } = useLocale();
  return <Caption data-testid={'rateLabelText'}>{formatCurrency(amount, currency, locale) + period}</Caption>;
};
