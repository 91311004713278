import { FC, useMemo } from 'react';
import { useTranslations } from 'components/shared/i18n';
import { DriverFormFields, DriverType } from 'components/flexFlow/driver/driverForm/DriverFormTypes';
import { GridContainer, GridItem } from 'components/shared/ui/styles/Grid.styles';
import { useFormContext, useWatch } from 'react-hook-form';
import { SelectField } from 'components/shared/forms/SelectField';
import {
  AGE_OPTIONS,
  getRegionLabel,
  resetFieldsClearErrors,
} from 'components/flexFlow/driver/driverForm/driverFormUtils';
import { CountrySelectField } from 'components/shared/forms/CountrySelectField';
import { FormTextField } from 'components/shared/forms/FormTextField';
import { CountrySubDivisionSelectField } from 'components/shared/forms/CountrySubDivisionSelectField';
import { piiField } from '@ehi/support';
import { FormSectionHeader, Section } from 'components/flexFlow/driver/driverForm/DriverForm.styles';
import { Box } from '@mui/material';

export const DriverSection: FC = () => {
  const { t } = useTranslations();
  const { clearErrors, resetField, setValue } = useFormContext();
  const [driverCountry, driverType] = useWatch({
    name: [DriverFormFields.DriverCountry, DriverFormFields.DriverType],
  });

  const regionLabel = useMemo(() => {
    const regionLabelKey = getRegionLabel(driverCountry);
    if (regionLabelKey) {
      return t(regionLabelKey);
    }
    return '';
  }, [driverCountry, t]);

  return (
    <Section data-testid={'driverSection'}>
      <Box>
        <GridItem>
          <FormSectionHeader>{t('driver.driver')}</FormSectionHeader>
        </GridItem>
        <GridContainer>
          <GridItem sm={driverType === undefined ? 4 : 6}>
            <FormTextField
              name={DriverFormFields.LastName}
              label={t('driver.lastName')}
              data-testid={'lastNameField'}
              inputProps={{ maxLength: 50 }}
              disabled={driverType !== undefined}
              required
            />
          </GridItem>
          <GridItem sm={driverType === undefined ? 4 : 6}>
            <FormTextField
              name={DriverFormFields.FirstName}
              label={t('driver.firstName')}
              data-testid={'firstNameField'}
              inputProps={{ maxLength: 50 }}
              disabled={driverType !== undefined}
              required
            />
          </GridItem>
          {driverType === undefined && (
            <GridItem sm={4}>
              <SelectField
                name={DriverFormFields.Age}
                label={t('driver.age')}
                data-testid={'menu-age'}
                options={AGE_OPTIONS}
                hasNoneOption={false}
                disabled={driverType === DriverType.LOYALTY_DRIVER_PROFILE}
              />
            </GridItem>
          )}
          <GridItem sm={driverType === undefined ? 6 : 12}>
            <CountrySelectField
              name={DriverFormFields.DriverCountry}
              label={t('common.country')}
              data-testid={'driverCountry'}
              disabled={driverType === DriverType.LOYALTY_DRIVER_PROFILE}
              onChange={(event): void => {
                setValue(DriverFormFields.DriverCountry, event.target.value);
                resetFieldsClearErrors(
                  [
                    DriverFormFields.StreetAddress1,
                    DriverFormFields.StreetAddress2,
                    DriverFormFields.City,
                    DriverFormFields.SubRegion,
                    DriverFormFields.PostalCode,
                  ],
                  resetField,
                  clearErrors
                );
              }}
            />
          </GridItem>
        </GridContainer>
        <GridContainer className={piiField} data-testid={'address-container'}>
          {driverCountry && (
            <>
              <GridItem sm={6}>
                <FormTextField
                  name={DriverFormFields.StreetAddress1}
                  label={t('common.address.streetAddress1')}
                  data-testid={'streetAddress1'}
                  disabled={driverType === DriverType.LOYALTY_DRIVER_PROFILE}
                  required
                />
              </GridItem>
              <GridItem sm={6}>
                <FormTextField
                  name={DriverFormFields.StreetAddress2}
                  label={t('common.address.streetAddress2')}
                  disabled={driverType === DriverType.LOYALTY_DRIVER_PROFILE}
                  data-testid={'streetAddress2'}
                />
              </GridItem>
              <GridItem sm={4}>
                <FormTextField
                  name={DriverFormFields.City}
                  label={t('common.address.city')}
                  data-testid={'city'}
                  disabled={driverType === DriverType.LOYALTY_DRIVER_PROFILE}
                  required
                />
              </GridItem>
              {regionLabel && (
                <GridItem sm={4}>
                  <CountrySubDivisionSelectField
                    name={DriverFormFields.SubRegion}
                    label={regionLabel}
                    data-testid={'subRegion'}
                    country={driverCountry}
                    disabled={driverType === DriverType.LOYALTY_DRIVER_PROFILE}
                    required
                  />
                </GridItem>
              )}
              <GridItem sm={4}>
                <FormTextField
                  name={DriverFormFields.PostalCode}
                  label={t('common.address.postalCode')}
                  data-testid={'postalCode'}
                  disabled={driverType === DriverType.LOYALTY_DRIVER_PROFILE}
                  required
                />
              </GridItem>
            </>
          )}
        </GridContainer>
      </Box>
    </Section>
  );
};
