import { FC, ReactElement } from 'react';
import { useTranslations } from 'components/shared/i18n';
import { FormProvider, useForm } from 'react-hook-form';
import { Dialog } from 'components/shared/ui/dialogs/Dialog';
import {
  ReferenceInformationFields,
  ReferenceInformationValues,
} from 'components/flexFlow/rateAndBilling/editDialogs/referenceInformation/EditReferenceInformationTypes';
import { ehiTheme } from '@ehi/ui';
import { Box } from '@mui/material';
import { InputIconButton } from 'components/shared/ui/InputIconButton/InputIconButton';
import { FieldClearIcon } from 'components/shared/ui/FieldClearIcon';
import { EMPTY_VALUE } from 'utils/constants';
import { useAppSelector } from 'redux/hooks';
import { selectPointOfSale } from 'redux/selectors/bookingEditor';
import { MaxLengthTextField } from 'components/shared/forms/MaxLengthTextField';
import { useReservationFlow } from 'hooks/useReservationFlow';
import { ProgressOverlay } from 'components/shared/ui/spinner/ProgressOverlay';
import { useSaveReferenceInfo } from 'components/flexFlow/rateAndBilling/editDialogs/referenceInformation/useSaveReferenceInfo';
import Error from '@mui/icons-material/Error';

interface ReferenceInfoDialogProps {
  onClose: () => void;
}

export const EditReferenceInformationModal: FC<ReferenceInfoDialogProps> = ({ onClose }) => {
  const { t } = useTranslations();
  const pointOfSale = useAppSelector(selectPointOfSale);
  const { isModifyFlow } = useReservationFlow();
  const savedIataNumber = pointOfSale?.bookingAgent?.agencyIdentifier ?? '';
  const { submitReferenceInfo } = useSaveReferenceInfo();

  const formMethods = useForm<ReferenceInformationValues>({
    defaultValues: { iataNumber: savedIataNumber },
  });

  const handleSubmit = async ({ iataNumber }: ReferenceInformationValues): Promise<void> => {
    const trimmedIata = iataNumber.trim();

    if (savedIataNumber === trimmedIata) {
      onClose();
      return;
    }

    await submitReferenceInfo(trimmedIata, formMethods.setError, onClose);
  };

  const getHelperText = (errorMessage: string): ReactElement => {
    return (
      <span style={{ display: 'flex' }}>
        <Error fontSize='small' sx={{ paddingRight: '6px', display: 'flex' }} />
        {errorMessage}
      </span>
    );
  };

  const iataNumber = formMethods.watch(ReferenceInformationFields.IataNumber);
  return (
    <Dialog
      data-testid='editReferenceInformationDialog'
      id='editReferenceInformationDialog'
      contentPadding={ehiTheme.spacing(3)}
      open={true}
      a11yKey='content'
      title={t('rateAndBilling.referenceInformation')}
      maxWidth={'sm'}
      fullWidth
      actions={{
        secondaryAction: {
          label: t('common.cancel'),
          onClick: onClose,
        },
        primaryAction: {
          label: t('common.save'),
          onClick: formMethods.handleSubmit(handleSubmit),
        },
      }}>
      <FormProvider {...formMethods}>
        <Box data-testid={'referenceInfo-dialog-body'}>
          <MaxLengthTextField
            name={ReferenceInformationFields.IataNumber}
            label={t('rateAndBilling.iataArcClia')}
            data-testid={'iataArcCliaField'}
            fullWidth
            disabled={isModifyFlow}
            maxLength={12}
            error={!!formMethods.formState.errors.iataNumber}
            helperText={
              formMethods.formState.errors.iataNumber?.message &&
              getHelperText(formMethods.formState.errors.iataNumber.message.toString())
            }
            InputProps={{
              endAdornment: (
                <InputIconButton
                  icon={<FieldClearIcon />}
                  label={t('common.clear')}
                  onClick={(): void => {
                    formMethods.setValue(ReferenceInformationFields.IataNumber, EMPTY_VALUE);
                  }}
                  disabled={!iataNumber || iataNumber.length === 0}
                />
              ),
            }}
          />
        </Box>
        <ProgressOverlay inProgress={formMethods.formState.isSubmitting} />
      </FormProvider>
    </Dialog>
  );
};
