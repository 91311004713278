import { Dispatch, FC, MutableRefObject, SetStateAction, useCallback, useMemo, useState } from 'react';
import {
  DriverFormFields,
  DriverFormRefReturn,
  DriverFormValues,
  DriverType,
} from 'components/flexFlow/driver/driverForm/DriverFormTypes';
import { FormProvider, useForm } from 'react-hook-form';
import { DriverSection } from 'components/flexFlow/driver/driverForm/formSection/DriverSection';
import { LicenseSection } from 'components/flexFlow/driver/driverForm/formSection/LicenseSection';
import { ContactSection } from 'components/flexFlow/driver/driverForm/formSection/ContactSection';
import { MarginWrapper } from 'components/shared/ui/styles/Global.styles';
import { DividerWithFullWidth } from 'components/shared/ui/styles/Divider.styles';
import { ehiTheme } from '@ehi/ui';
import { ProgressOverlay } from 'components/shared/ui/spinner/ProgressOverlay';
import {
  ADD,
  driverFormInitialValues,
  driverFormValidationSchema,
  isValidFullProfile,
  REMOVE,
} from 'components/flexFlow/driver/driverForm/driverFormUtils';
import {
  createDriverProfile,
  modifyContactInformation,
  modifyDriverProfile,
} from 'services/renter/driverProfile/driverProfileService';
import { safelyCatchError } from 'utils/errorUtils';
import { getAppConfigCache } from 'services/appConfig/appConfigService';
import {
  createTransactionalProfile,
  getTransactionalProfile,
  updateTransactionalProfile,
} from 'services/renter/transactionalProfile/transactionalProfileService';
import { DriverData } from 'components/shared/uiModels/driver/driverDataTypes';
import { useAlert } from 'components/shared/alert/AlertContext';
import { useTranslations } from 'components/shared/i18n';
import { useYupValidationResolver } from 'components/shared/forms/useYupValidationResolver';
import { Alert } from '@mui/material';
import InfoIcon from '@mui/icons-material/Info';
import { StyledDriverBanner } from 'components/flexFlow/driver/driverForm/DriverForm.styles';
import { parseUrn } from 'utils/urnUtils';
import { EMPTY_VALUE } from 'utils/constants';
import { ConfirmationDialog } from 'components/flexFlow/driver/editDriver/ConfirmationDialog';
import {
  transformToCreateDriverProfileRequest,
  transformToCreateTransactionalProfileRequest,
  transformToDriverFromDriverProfile,
  transformToDriverFromTransactionalProfile,
  transformToModifyContactInfoRequest,
  transformToModifyDriverProfileRequest,
} from 'services/renter/renterTransformer';
import { useSaveDriver } from 'components/flexFlow/driver/useSaveDriver';
import { HashPaths } from 'app/router/RouterPaths';
import { useLocation } from 'react-router-dom';
import { DriverProfile } from 'services/renter/driverProfile/driverProfileTypes';
import { TransactionalProfileResponseContent } from 'services/renter/transactionalProfile/transactionalProfileTypes';
import { RenterType } from 'services/booking/bookingTypes';

export type CreateDriverFormProps = {
  onClose: (driverModified: boolean) => void;
  formRef?: MutableRefObject<DriverFormRefReturn | null>;
  driverToUpdate?: DriverData;
  changePrimaryDriverDialogIsOpen: boolean;
  setChangePrimaryDriverDialogIsOpen: Dispatch<SetStateAction<boolean>>;
};

export const DriverForm: FC<CreateDriverFormProps> = ({
  onClose,
  formRef,
  driverToUpdate,
  changePrimaryDriverDialogIsOpen,
  setChangePrimaryDriverDialogIsOpen,
}) => {
  const { t } = useTranslations();
  const appConfig = getAppConfigCache();
  const { showAlert } = useAlert();
  const { hash } = useLocation();

  const [newDriver, setNewDriver] = useState<DriverData | undefined>(undefined);
  const [loading, setLoading] = useState(false);

  const {
    updateDriverProfileOnEditor,
    updateTransactionalProfileOnEditor,
    changePrimaryDriverFromNewDriver,
    isAdditionalDriver,
  } = useSaveDriver();

  const resolver = useYupValidationResolver(driverFormValidationSchema(t));
  const formMethods = useForm<DriverFormValues>({
    resolver: resolver,
    defaultValues: driverFormInitialValues(driverToUpdate),
  });

  const isUpdatingAdditionalDriver = useMemo(
    () => (driverToUpdate ? isAdditionalDriver(driverToUpdate.type, driverToUpdate.urn) : false),
    [driverToUpdate, isAdditionalDriver]
  );
  const isReplacingPrimaryDriver = useMemo(
    () => hash === HashPaths.ReplaceDriver && !isUpdatingAdditionalDriver,
    [hash, isUpdatingAdditionalDriver]
  );

  // What triggers this function -> "Change Primary Driver" button clicked then add new driver through DriverForm
  const handleConfirmChangePrimaryDriver = async (selection: string): Promise<void> => {
    setChangePrimaryDriverDialogIsOpen(false);
    setLoading(true);

    const { errors } = await changePrimaryDriverFromNewDriver(
      selection,
      newDriver ? { driverInfo: newDriver } : undefined
    ).finally(() => setLoading(false));
    if (!errors) {
      onClose(false);
    }
  };

  const handleCancelChangePrimaryDriver = (): void => {
    setChangePrimaryDriverDialogIsOpen(false);
  };

  const getDriverDataFromDriverForm = useCallback(
    async (values: DriverFormValues): Promise<DriverData> => {
      if (isValidFullProfile(values)) {
        const driverProfile: DriverProfile = await createDriverProfile(
          transformToCreateDriverProfileRequest(values, appConfig?.defaultEhiDatabase ?? EMPTY_VALUE, t)
        );
        return driverProfile ? transformToDriverFromDriverProfile(driverProfile, []) : { type: RenterType.NO_PROFILE };
      } else {
        const transactionalProfile: TransactionalProfileResponseContent = await createTransactionalProfile(
          transformToCreateTransactionalProfileRequest(values, appConfig?.defaultEhiDatabase ?? EMPTY_VALUE, t)
        );
        return transactionalProfile
          ? transformToDriverFromTransactionalProfile(transactionalProfile)
          : { type: RenterType.NO_PROFILE };
      }
    },
    [appConfig?.defaultEhiDatabase, t]
  );

  const handleCreatingProfile = useCallback(
    async (values: DriverFormValues) => {
      const newDriver = await getDriverDataFromDriverForm(values);

      if (isReplacingPrimaryDriver) {
        setNewDriver(newDriver);
        setChangePrimaryDriverDialogIsOpen(true);
      } else {
        setLoading(true);
        const isQuickAddDriver = !driverToUpdate?.urn && isUpdatingAdditionalDriver;

        const { errors } = await (newDriver.type === RenterType.DRIVER_PROFILE
          ? updateDriverProfileOnEditor({
              driverInfo: newDriver,
              quickDriverToRemove: isQuickAddDriver ? driverToUpdate : undefined,
            })
          : updateTransactionalProfileOnEditor({
              driverInfo: newDriver,
              quickDriverToRemove: isQuickAddDriver ? driverToUpdate : undefined,
            })
        ).finally(() => setLoading(false));

        if (!errors) {
          onClose(isQuickAddDriver);
        }
      }
    },
    [
      driverToUpdate,
      getDriverDataFromDriverForm,
      isReplacingPrimaryDriver,
      isUpdatingAdditionalDriver,
      onClose,
      setChangePrimaryDriverDialogIsOpen,
      updateDriverProfileOnEditor,
      updateTransactionalProfileOnEditor,
    ]
  );

  const handleModifyingProfile = useCallback(
    async (values: DriverFormValues) => {
      if (Object.keys(formMethods.formState.dirtyFields).length) {
        try {
          setLoading(true);
          if (values[DriverFormFields.DriverType] === DriverType.LOYALTY_DRIVER_PROFILE) {
            await modifyContactInformation(
              parseUrn(driverToUpdate?.urn),
              transformToModifyContactInfoRequest(values, appConfig?.defaultEhiDatabase ?? EMPTY_VALUE)
            );
            onClose(true);
          } else if (
            values[DriverFormFields.DriverType] === DriverType.DRIVER_PROFILE ||
            values[DriverFormFields.DriverType] === DriverType.DRIVER_PROFILE_DNR
          ) {
            await modifyDriverProfile(
              parseUrn(driverToUpdate?.urn),
              transformToModifyDriverProfileRequest(values, appConfig?.defaultEhiDatabase ?? EMPTY_VALUE, t)
            );
            onClose(true);
          } else if (driverToUpdate?.urn) {
            await getTransactionalProfile(parseUrn(driverToUpdate.urn)).then(async (profileData) => {
              const { data: profile, etag } = profileData;

              await updateTransactionalProfile(
                profile.uuid ?? EMPTY_VALUE,
                transformToCreateTransactionalProfileRequest(values, appConfig?.defaultEhiDatabase ?? EMPTY_VALUE, t),
                etag ?? EMPTY_VALUE
              )
                .then(() => {
                  onClose(true);
                })
                .catch(async (error) => {
                  const ehiErrorsResponse = safelyCatchError(error);
                  if (ehiErrorsResponse?.errors) {
                    await showAlert({
                      responseMessages: ehiErrorsResponse?.errors,
                    });
                  }
                });
            });
          }
        } catch (error) {
          const ehiErrorsResponse = safelyCatchError(error);
          if (ehiErrorsResponse?.errors) {
            await showAlert({
              responseMessages: ehiErrorsResponse?.errors,
            });
          }
        } finally {
          setLoading(false);
        }
      } else {
        onClose(false);
      }
    },
    [appConfig?.defaultEhiDatabase, driverToUpdate?.urn, formMethods.formState.dirtyFields, onClose, showAlert, t]
  );

  if (formRef) {
    formRef.current = {
      handleSubmit: !driverToUpdate?.urn
        ? formMethods.handleSubmit(handleCreatingProfile)
        : formMethods.handleSubmit(handleModifyingProfile),
    };
  }

  const getBannerMessage = (driverType: DriverType | undefined): string | undefined => {
    if (driverType === DriverType.DRIVER_PROFILE || driverType === DriverType.DRIVER_PROFILE_DNR) {
      return t('driver.driverProfileBanner');
    } else if (driverType === DriverType.LOYALTY_DRIVER_PROFILE) {
      return t('driver.loyaltyDriverBanner');
    }
    return undefined;
  };

  return (
    <MarginWrapper data-testid={'driverForm'}>
      <FormProvider {...formMethods}>
        {getBannerMessage(formMethods.getValues(DriverFormFields.DriverType)) && (
          <StyledDriverBanner data-testid={'modifyDriverBanner'}>
            <Alert
              severity='info'
              sx={{
                border: `1px solid #6A3E9D80`,
                display: 'flex',
                alignItems: 'center',
              }}
              icon={<InfoIcon />}>
              {getBannerMessage(formMethods.getValues(DriverFormFields.DriverType))}
            </Alert>
          </StyledDriverBanner>
        )}
        <DriverSection />
        <DividerWithFullWidth style={{ borderColor: ehiTheme.palette.divider }} />
        <LicenseSection />
        <DividerWithFullWidth style={{ borderColor: ehiTheme.palette.divider }} />
        <ContactSection />
      </FormProvider>
      <ProgressOverlay inProgress={loading} />
      {changePrimaryDriverDialogIsOpen && (
        <ConfirmationDialog
          open={changePrimaryDriverDialogIsOpen}
          onConfirm={handleConfirmChangePrimaryDriver}
          onCancel={handleCancelChangePrimaryDriver}
          title={!isUpdatingAdditionalDriver ? t('driver.changePrimaryDriver') : t('driver.makePrimaryDriver')}
          description={t('driver.additionalDrivers.changePrimaryPrompt')}
          options={[
            { label: t('driver.additionalDrivers.makeAdditionalDriver'), value: ADD },
            { label: t('driver.removeFromReservation'), value: REMOVE },
          ]}
        />
      )}
    </MarginWrapper>
  );
};
