import { Box, styled } from '@mui/material';
import CircleOutlinedIcon from '@mui/icons-material/CircleOutlined';
import { EhiButton, ehiTheme, Subtitle2 } from '@ehi/ui';
import { disabledText, secondaryText, StyledLink } from 'components/shared/ui/styles/Global.styles';
import ContrastIcon from '@mui/icons-material/Contrast';
import CircleIcon from '@mui/icons-material/Circle';

export const VerificationItems = styled(Box)`
  display: flex;
  align-items: center;
`;

export const StyledResultsLink = styled(StyledLink, {
  shouldForwardProp: (prop) => prop !== 'disabled',
})<{
  disabled: boolean;
}>(({ disabled = false }) => {
  const color = disabled ? disabledText : ehiTheme.palette.primary.main;
  const cursor = disabled ? 'default' : 'pointer';
  return {
    textDecoration: 'underline',
    cursor,
    color,
  };
});

export const StyledRunButton = styled(EhiButton)`
  padding: ${ehiTheme.spacing(0.5, 0, 0.5, 0)};
  margin: 0;
  min-width: 4rem;
`;

export const VerificationFailIcon = styled(CircleOutlinedIcon)`
  color: ${secondaryText};
  margin-right: ${ehiTheme.spacing(1)};
`;

export const VerificationNeedUpdateIcon = styled(ContrastIcon)`
  color: ${secondaryText};
  margin-right: ${ehiTheme.spacing(1)};
`;

export const VerificationSuccessIcon = styled(CircleIcon)`
  color: ${ehiTheme.palette.success.main};
  margin-right: ${ehiTheme.spacing(1)};
`;

export const VerificationRunningItems = styled(Box)`
  display: flex;
  align-items: center;
  gap: ${ehiTheme.spacing(2)};
  padding: ${ehiTheme.spacing(0.5, 0)};
`;

export const StyledRunningSubtitle = styled(Subtitle2)`
  text-transform: uppercase;
  color: ${ehiTheme.palette.primary.main};
`;
