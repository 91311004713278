import { Checkbox, CheckboxProps, FormControlLabel, FormControlLabelProps } from '@mui/material';
import { FC, ReactElement } from 'react';
import { Controller, useFormContext } from 'react-hook-form';

export type CheckboxFieldProps = CheckboxProps &
  Pick<FormControlLabelProps, 'labelPlacement'> & {
    name: string;
    label?: string;
    dense?: boolean;
  };

export const CheckboxField: FC<CheckboxFieldProps> = ({ name, label, labelPlacement = 'end', dense, ...props }) => {
  const { control } = useFormContext();

  return (
    <Controller
      control={control}
      name={name}
      render={({ field }): ReactElement => {
        const { ref, ...fieldProps } = field;

        return (
          <FormControlLabel
            checked={field.value}
            sx={{ margin: 0 }}
            control={<Checkbox {...fieldProps} inputRef={ref} sx={dense ? { paddingY: 0 } : {}} {...props} />}
            label={label}
            labelPlacement={labelPlacement}
          />
        );
      }}
    />
  );
};
