import { useYupValidationResolver } from 'components/shared/forms/useYupValidationResolver';
import {
  SameAsRateSourceFormFields,
  SameAsRateSourceFormValues,
} from 'components/flexFlow/rateAndBilling/editDialogs/billTo/SameAsRateSourceDialogTypes';
import { FC, useMemo } from 'react';
import * as Yup from 'yup';
import { useTranslations } from 'components/shared/i18n';
import { FormProvider, useForm, useFormContext } from 'react-hook-form';
import { Dialog } from 'components/shared/ui/dialogs/Dialog';
import { GridContainer, GridItem } from 'components/shared/ui/styles/Grid.styles';
import { Body1, EhiButton, ehiTheme } from '@ehi/ui';
import { Box, Grid } from '@mui/material';
import { BusinessAccountCard } from 'components/flexFlow/rateAndBilling/editDialogs/BusinessAccountCard';
import { MaskedTextField } from 'components/shared/forms/MaskedTextField';
import { InputIconButton } from 'components/shared/ui/InputIconButton/InputIconButton';
import { FieldClearIcon } from 'components/shared/ui/FieldClearIcon';
import { useBillTo } from 'components/flexFlow/rateAndBilling/editDialogs/billTo/useBillTo';
import { useAccountContactInfoQuery } from 'services/businessAccount/accountQueries';
import { EMPTY_VALUE, ENTER_KEY_CODE } from 'utils/constants';
import { ProgressOverlay } from 'components/shared/ui/spinner/ProgressOverlay';
import { BillToFormFields } from 'components/flexFlow/rateAndBilling/editDialogs/billTo/EditBillToDialogTypes';
import { AccountDetailsType } from 'components/flexFlow/rateAndBilling/RateAndBillingTypes';
import { useRateSourceAccountDetails } from 'services/businessAccount/useRateSourceAccountDetails';
import { transformRateAndBillingInfo } from 'components/shared/uiModels/rateAndBilling/rateAndBillingTransformer';
import { pageContainerBackground } from 'components/shared/ui/styles/Global.styles';

type SameAsRateSourceProps = {
  billingNumber: string;
  onClose: () => void;
};

export const SameAsRateSourceDialog: FC<SameAsRateSourceProps> = ({ billingNumber, onClose }) => {
  const { t } = useTranslations();
  const { rateSourceInfo, rateSourceAccountNumber } = useRateSourceAccountDetails();
  const { addOrModifyBillTo } = useBillTo();
  const { data: contact } = useAccountContactInfoQuery(rateSourceAccountNumber);
  const accountDetails: AccountDetailsType = useMemo(() => {
    return transformRateAndBillingInfo(rateSourceInfo, contact);
  }, [rateSourceInfo, contact]);

  const validationSchema = useMemo(() => {
    return Yup.object().shape({
      [SameAsRateSourceFormFields.BillingNumber]: Yup.string()
        .trim()
        .required(t('validation.requiredField'))
        .min(5, t('validation.minFieldLength', { fieldLength: 5 }))
        .max(10, t('validation.minFieldLength', { fieldLength: 10 }))
        .matches(/^[1-9]\d{4,9}$/, t('validation.invalidField')),
    });
  }, [t]);

  const resolver = useYupValidationResolver(validationSchema);
  const initialValues: SameAsRateSourceFormValues = useMemo(() => {
    return {
      [SameAsRateSourceFormFields.BillingNumber]: billingNumber ? billingNumber : EMPTY_VALUE,
    };
  }, [billingNumber]);
  const formMethods = useForm({
    resolver: resolver,
    defaultValues: initialValues,
  });

  const handleSubmit = async (values: SameAsRateSourceFormValues): Promise<void> => {
    const billingNumber = values[SameAsRateSourceFormFields.BillingNumber];
    const { errorMessage } = await addOrModifyBillTo({
      accountNumber: rateSourceAccountNumber,
      billingNumber,
      handleCloseModal: onClose,
    });
    if (errorMessage) {
      formMethods.setError(BillToFormFields.BillingNumber, { message: errorMessage });
    }
  };

  const onFormSubmit = formMethods.handleSubmit(handleSubmit);

  return (
    <FormProvider {...formMethods}>
      <Dialog
        title={t('rateAndBilling.billTo.billToDialogTitle')}
        data-testid='addFromRateSourceDialog'
        a11yKey={'add-billing-account'}
        open={true}
        contentPadding={0}
        actions={{
          primaryAction: {
            label: t('common.cancel'),
            onClick: onClose,
          },
        }}>
        <BillingAccountInfo onFormSubmit={onFormSubmit} accountDetails={accountDetails} />
        <ProgressOverlay inProgress={formMethods.formState.isSubmitting} />
      </Dialog>
    </FormProvider>
  );
};

const BillingAccountInfo: FC<{
  onFormSubmit: () => void;
  accountDetails: AccountDetailsType;
}> = ({ onFormSubmit, accountDetails }) => {
  const { t } = useTranslations();
  const { clearErrors, formState, setValue, watch } = useFormContext<SameAsRateSourceFormValues>();
  const billingNumber = watch(SameAsRateSourceFormFields.BillingNumber);

  const handleKeyPress = (e: any): void => {
    if (e.code === 'Enter' || e.keycode === ENTER_KEY_CODE) {
      onFormSubmit();
    }
  };

  return (
    <>
      <GridContainer style={{ padding: ehiTheme.spacing(1, 3) }}>
        <Body1 data-testid={'enterBillingNumber'}>{t('rateAndBilling.billTo.enterBillingNumber')}</Body1>
      </GridContainer>
      <Box style={{ padding: ehiTheme.spacing(1, 3) }}>
        <GridContainer
          style={{ padding: ehiTheme.spacing(2) }}
          data-testid='billingNumberContainer'
          bgcolor={pageContainerBackground}
          paddingBottom={ehiTheme.spacing(4)}
          marginTop={'auto'}>
          <BusinessAccountCard account={accountDetails} titleColor={'black'} />
          <GridItem xs={6} sm={6} style={{ marginTop: ehiTheme.spacing(1), paddingLeft: ehiTheme.spacing(0) }}>
            <MaskedTextField
              name={SameAsRateSourceFormFields.BillingNumber}
              label={t('rateAndBilling.billTo.billingNumber')}
              required
              autoFocus={true}
              onKeyDown={handleKeyPress}
              exceptLast={4}
              InputProps={{
                endAdornment: (
                  <InputIconButton
                    icon={<FieldClearIcon />}
                    label={t('common.clear')}
                    onClick={(): void => {
                      setValue(SameAsRateSourceFormFields.BillingNumber, EMPTY_VALUE);
                      clearErrors(SameAsRateSourceFormFields.BillingNumber);
                    }}
                    disabled={billingNumber?.length === 0}
                  />
                ),
              }}
            />
          </GridItem>
          <Grid item xs={3} sm={3}>
            <EhiButton
              data-testid='billingNumber-applyButton'
              variant='contained'
              style={{ marginTop: 0 }}
              disabled={!formState.isValid || !billingNumber.length}
              onClick={onFormSubmit}>
              {t('common.apply')}
            </EhiButton>
          </Grid>
        </GridContainer>
      </Box>
    </>
  );
};
