import { Navigate, RouteObject } from 'react-router-dom';
import { IFRAME_ROUTES, RouterPaths, SIBLING_PREFIX } from 'app/router/RouterPaths';
import { AccessDeniedErrorPage } from 'components/setLocation/AccessDeniedErrorPage';
import { DeepLink } from 'components/deeplink/DeepLink';
import { FunctionComponentElement, lazy, ReactElement } from 'react';
import { LogoutPage } from 'components/shared/auth/LogoutPage';
import { FlexMenuWrapper } from 'components/shared/flexiFlow/FlexMenuWrapper';
import { getDefaultFlexRoutes } from 'app/router/FlexFlowRoutes';
import { FlexPage, FlexRoute, isFlexPage } from 'components/shared/flexiFlow/flexRoute';
import { FlexComponent } from 'components/shared/flexiFlow/FlexComponent';
import { ReservationErrorBoundary } from 'components/shared/errors/ReservationErrorBoundary';
import ReservationRouteWrapper from 'components/shared/routeWrapper/ReservationRouteWrapper';
import { ReservationPreProcessor } from 'components/shared/preprocessor/ReservationPreProcessor';
import { ViewRouter } from 'app/router/ViewRouter';
import ErrorWrapper from 'components/shared/errors/ErrorWrapper';
import NetworkError from 'components/shared/errors/NetworkError';
import { CreateRouter } from 'app/router/CreateRouter';
import { CreateRouteWrapper } from 'components/shared/routeWrapper/CreateRouteWrapper';
import { IFrameLayout } from 'components/shared/layouts/IFrameLayout';
import { QuickResIframePage } from 'components/quickRes/QuickResIframePage';
import { QuickResIframeContainer } from 'components/quickRes/QuickResIframeContainer';
import { ModifyRouter } from 'app/router/ModifyRouter';
import { TFunction } from 'i18next';
import { Notes } from 'components/notes/Notes';

const AppLayout = lazy(() => import('components/shared/layouts/AppLayout'));
const SetLocation = lazy(() => import('components/setLocation/SetLocation'));
const SupportTicket = lazy(() => import('components/support/SupportTicket'));
const NotFound = lazy(() => import('components/shared/errors/NotFound'));
const DevSearch = lazy(() => import('components/dev/DevSearch'));
const ReservationSearch = lazy(() => import('components/search/ReservationSearch'));

export const getAppRoutes = (t: TFunction): RouteObject[] => [
  { path: RouterPaths.Empty, element: <Navigate replace to={RouterPaths.Search} /> },
  { path: RouterPaths.SupportTicket, element: <SupportTicket /> },
  { path: RouterPaths.AccessDenied, element: <AccessDeniedErrorPage /> },
  { path: RouterPaths.DeepLink, element: <DeepLink /> },
  { path: RouterPaths.SetLocation, element: <SetLocation /> },
  { path: RouterPaths.Logout, element: <LogoutPage /> },
  { path: RouterPaths.Dev, element: <DevSearch /> },
  { path: RouterPaths.Error, element: <ErrorWrapper /> },
  { path: RouterPaths.NetworkError, element: <NetworkError /> },
  {
    path: 'res',
    element: <AppLayout />, // wraps global nav for all sub routes
    errorElement: <ReservationErrorBoundary />,
    children: [
      {
        path: RouterPaths.PreProcessor,
        element: <ReservationPreProcessor />, // starts a transaction process
      },
      {
        path: RouterPaths.Create,
        element: <CreateRouteWrapper />, // wraps rental header for create full res
        children: [
          {
            path: '*',
            element: <CreateRouter />, // wraps the browser tab name for child routes
            children: getFlexFlowRoutes(t),
          },
        ],
      },
      {
        path: RouterPaths.BasePath,
        element: <ReservationRouteWrapper />, // rehydrates session state and wraps rental header for view/modify sub routes
        children: [
          {
            path: RouterPaths.View,
            element: <ViewRouter />, // wraps the browser tab name for child routes
            children: getFlexFlowRoutes(t),
          },
          {
            path: RouterPaths.Modify,
            element: <ModifyRouter />,
            children: getFlexFlowRoutes(t),
          },
        ],
      },
      {
        path: RouterPaths.Search,
        element: <ReservationSearch />,
      },
    ],
  },
  {
    path: 'iframe',
    element: <IFrameLayout />, // wraps global nav for all sub routes
    errorElement: <ReservationErrorBoundary />,
    children: [
      {
        path: IFRAME_ROUTES.quickRes,
        element: <QuickResIframePage />, // starts a transaction process
      },
      {
        path: IFRAME_ROUTES.iframe,
        element: <QuickResIframeContainer />, // starts a transaction process
      },
    ],
  },
  { path: '*', element: <NotFound /> },
];

export const getFlexFlowRoutes = (t: TFunction): RouteObject[] => [
  {
    path: '*',
    element: <FlexMenuWrapper pages={getDefaultFlexRoutes(t)} />,
    children: buildFlexChildren(getDefaultFlexRoutes(t)),
  },
  { path: RouterPaths.Notes, element: <Notes /> },
];

// Filter out any routes we are using for only flex flow menu, or to high jack saveAndNavigate.
const realPagesOnly = (routeList: FlexRoute[]): FlexPage[] => {
  return routeList.filter((page: FlexRoute) => isFlexPage(page)) as FlexPage[];
};

const buildFlexChildren = (routeList: FlexRoute[]): RouteObject[] => [
  ...realPagesOnly(routeList).map((page: FlexPage) => ({
    path: page.path,
    handle: { flexDetails: page }, // Pass down page detail for easy reference with useMatch in components
    element: buildFlexComponent(page, routeList),
  })),
  { path: '*', element: <NotFound /> },
];

const buildFlexComponent = (page: FlexPage, routeList: FlexRoute[]): FunctionComponentElement<ReactElement> => {
  const previousRoute: FlexRoute = routeList[page.position - 1];
  const nextRoute: FlexRoute = routeList[page.position + 1];

  const previousNav =
    page.previous || (previousRoute && (previousRoute.to || `${SIBLING_PREFIX}${previousRoute.path}`)) || undefined;
  const nextNav = page.next || (nextRoute && (nextRoute.to || `${SIBLING_PREFIX}${nextRoute.path}`)) || undefined;

  return <FlexComponent component={page.component} previous={previousNav} next={nextNav} />;
};
