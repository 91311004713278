import { PaymentHistoryResponse } from 'services/payment/paymentHistoryTypes';
import { formatCurrency } from 'utils/currencyUtils';
import { DOUBLE_DASH, EMPTY_VALUE, FORWARD_SLASH } from 'utils/constants';
import { parseUrn } from 'utils/urnUtils';
import { toDateTimeString } from 'utils/dateUtils';

export type FormattedPaymentHistory = {
  amount: string;
  brand: string;
  cardNumber: string;
  date: string;
  expDate: string;
  name: string | undefined;
  saleType: string;
  status: string | undefined;
  time: string;
  transactionType: string;
};

export const transformToFormattedPaymentHistory = (
  paymentHistory: PaymentHistoryResponse,
  locale: string,
  dateFormat: string,
  timeFormat: string
): FormattedPaymentHistory[] => {
  return paymentHistory.map((history) => {
    const formattedDate = toDateTimeString(history.timestamp, dateFormat) ?? EMPTY_VALUE;
    const formattedTime = toDateTimeString(history.timestamp, timeFormat) ?? EMPTY_VALUE;
    const formattedCardNumber = history.cardDetails.maskedCardNumber.slice(-4);
    const formattedExpMonth = String(history.cardDetails.expiration.lastValidMonth).padStart(2, '0');
    const formattedExpYear = String(history.cardDetails.expiration.year).slice(-2);
    const formattedExpDate = formattedExpMonth + FORWARD_SLASH + formattedExpYear;
    const formattedAmount =
      history.amount && history.amount.value && history.amount.currencyCode
        ? formatCurrency(history.amount.value, history.amount.currencyCode, locale)
        : DOUBLE_DASH;

    return {
      amount: formattedAmount,
      brand: parseUrn(history.cardAttributes.cardBrand),
      cardNumber: formattedCardNumber,
      date: formattedDate,
      expDate: formattedExpDate,
      name: history.cardDetails.nameProvided,
      saleType: history.type,
      status: history.status,
      time: formattedTime,
      transactionType: history.cardTransactionType,
    };
  });
};
