import { isTruckLocation } from 'utils/locationUtils';
import { updateCarrierSafetyIdentifiers } from 'services/booking/bookingService';
import { BranchType } from 'services/location/locationTypes';
import { useCallback } from 'react';
import { useAppSelector } from 'redux/hooks';
import { selectBookingEditorId, selectCarrierSafetyIdentifiers } from 'redux/selectors/bookingEditor';

export const useCarrierSafetyNumberUtility = (): {
  updateCarrierSafetyIdentifier: (carrierSafetyNumbers?: string[]) => Promise<void>;
  updateDefaultCarrierSafetyIdentifier: (
    pickupBranchType: BranchType | undefined,
    carrierSafetyNumbers?: string[]
  ) => Promise<void>;
  removeCarrierSafetyIdentifier: () => Promise<void>;
} => {
  const bookingEditorId = useAppSelector(selectBookingEditorId);
  const currentCarrierSafetyIdentifiers = useAppSelector(selectCarrierSafetyIdentifiers);

  const updateCarrierSafetyIdentifier = useCallback(
    (carrierSafetyNumbers?: string[]) => {
      return updateCarrierSafetyIdentifiers(bookingEditorId, carrierSafetyNumbers ?? []);
    },
    [bookingEditorId]
  );

  const updateDefaultCarrierSafetyIdentifier = useCallback(
    async (pickupBranchType: BranchType | undefined, carrierSafetyNumbers?: string[]): Promise<void> => {
      if (
        (!currentCarrierSafetyIdentifiers || currentCarrierSafetyIdentifiers.length === 0) &&
        (!carrierSafetyNumbers || carrierSafetyNumbers.length === 0)
      ) {
        return;
      }

      const carrierSafetyNumbersToUpdate =
        isTruckLocation(pickupBranchType) && carrierSafetyNumbers ? carrierSafetyNumbers : [];
      return updateCarrierSafetyIdentifiers(bookingEditorId, carrierSafetyNumbersToUpdate);
    },
    [bookingEditorId, currentCarrierSafetyIdentifiers]
  );

  const removeCarrierSafetyIdentifier = useCallback(async (): Promise<void> => {
    if (!currentCarrierSafetyIdentifiers || currentCarrierSafetyIdentifiers.length === 0) {
      return;
    }
    return updateCarrierSafetyIdentifiers(bookingEditorId, []);
  }, [bookingEditorId, currentCarrierSafetyIdentifiers]);
  return {
    updateCarrierSafetyIdentifier,
    updateDefaultCarrierSafetyIdentifier,
    removeCarrierSafetyIdentifier,
  };
};
