import { FC, useState } from 'react';
import { useTranslations } from 'components/shared/i18n';
import { useAppSelector } from 'redux/hooks';
import { selectIsReadOnlyFlow, selectPointOfSale } from 'redux/selectors/bookingEditor';
import {
  RateAndBillingLabel,
  RateAndBillingSubSectionBox,
  RateAndBillingTitleBox,
  StyledEhiEditButton,
} from 'components/flexFlow/rateAndBilling/RateAndBilling.styles';
import { Box, Grid } from '@mui/material';
import { Body1, H6 } from '@ehi/ui';
import { EhiDivider } from 'components/shared/ui/styles/Divider.styles';
import { EditReferenceInformationModal } from 'components/flexFlow/rateAndBilling/editDialogs/referenceInformation/EditReferenceInformationModal';
import { useReservationFlow } from 'hooks/useReservationFlow';

export const ReferenceInformationSection: FC = () => {
  const { t } = useTranslations();
  const isReadOnly = useAppSelector(selectIsReadOnlyFlow);
  const pointOfSale = useAppSelector(selectPointOfSale);
  const { isModifyFlow } = useReservationFlow();
  const [showReferenceInfoModal, setShowReferenceInfoModal] = useState(false);

  return (
    <>
      <EhiDivider />
      <RateAndBillingSubSectionBox data-testid={'referenceInformationSection'}>
        <RateAndBillingTitleBox>
          <H6 bold data-testid='referenceInformation-title'>
            {t('rateAndBilling.referenceInformation')}
          </H6>
          {!isReadOnly && (
            <Box>
              <StyledEhiEditButton
                size='small'
                disabled={isModifyFlow}
                variant='text'
                onClick={(): void => setShowReferenceInfoModal(true)}
                data-testid='reference-information-edit-button'>
                {t('common.edit')}
              </StyledEhiEditButton>
            </Box>
          )}
        </RateAndBillingTitleBox>
        <Grid container>
          <Grid item xs={6} sm={6} data-testid='iataArcClia'>
            <RateAndBillingLabel data-testid='iataArcClia-label'>{t('rateAndBilling.iataArcClia')}</RateAndBillingLabel>
            <Body1 data-testid='iataArcClia-text'>{pointOfSale?.bookingAgent?.agencyIdentifier}</Body1>
          </Grid>
          <Grid item xs={6} sm={6} data-testid='billingReference'>
            <RateAndBillingLabel data-testid='label'>{t('rateAndBilling.billingReference')}</RateAndBillingLabel>
            <Body1 data-testid='textBody'>{''}</Body1>
          </Grid>
        </Grid>
      </RateAndBillingSubSectionBox>
      {showReferenceInfoModal && (
        <EditReferenceInformationModal onClose={(): void => setShowReferenceInfoModal(false)} />
      )}
    </>
  );
};
