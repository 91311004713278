import { Grid, styled, Theme } from '@mui/material';
import { GridItem } from 'components/shared/ui/styles/Grid.styles';
import { EhiDivider } from 'components/shared/ui/styles/Divider.styles';
import { ehiTheme } from '@ehi/ui';

export const StyledDivider = styled(EhiDivider)(({ theme }: { theme: Theme }) => ({
  margin: theme.spacing(0),
}));

export const SectionInfo = styled(Grid)`
  display: flex;
  justify-content: space-between;
  padding: ${ehiTheme.spacing(2.5)};
  background: #f5f5f5;
`;
SectionInfo.defaultProps = {
  container: true,
};

export const VehicleSearch = styled(GridItem)`
  padding: ${ehiTheme.spacing(2, 0)};
`;
VehicleSearch.defaultProps = {
  container: true,
};

export const VehicleErrorMessage = styled('div')`
  display: flex;
  align-items: center;
`;

export const HelperSectionInfo = styled(Grid)`
  display: flex;
  justify-content: space-between;
`;

const gridItemProps = {
  item: true,
  xs: 12,
  sm: true,
};
export const SectionGridItem = styled(Grid)(({ theme }) => ({
  '&&': {
    padding: theme.spacing(0),
  },
}));
SectionGridItem.defaultProps = gridItemProps;
