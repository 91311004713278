import { Box, styled } from '@mui/material';
import Tabs from '@mui/material/Tabs';

export const NotesPage = styled(Box)`
  display: flex;
  flex-direction: column;
  height: 100%;
`;

export const TabsBar = styled(Tabs)`
  position: sticky;
  top: 0;
  background: #fff;
  justify-content: space-between;
  box-shadow: 0 1px 1px 0 rgba(0, 0, 0, 0.14), 0 2px 1px 0 rgba(0, 0, 0, 0.12), 0 1px 3px 0 rgba(0, 0, 0, 0.2);
`;
