import { FC, Fragment, JSX } from 'react';
import { Body2, ehiTheme } from '@ehi/ui';
import { Box } from '@mui/material';
import { FormProvider, useForm } from 'react-hook-form';
import {
  AdditionalInfoData,
  DropDownOption,
  EditAdditionalInfoProps,
  FieldType,
  UIAdditionalInfoField,
} from 'components/flexFlow/rateAndBilling/additionalInformation/AdditionalInfoTypes';
import { useTranslations } from 'components/shared/i18n';
import { GridContainer, GridItem } from 'components/shared/ui/styles/Grid.styles';
import { AdditionalInfoHelperText } from 'components/flexFlow/rateAndBilling/additionalInformation/EditAdditionalInfoDialog.styles';
import { Dialog } from 'components/shared/ui/dialogs/Dialog';
import { useYupValidationResolver } from 'components/shared/forms/useYupValidationResolver';
import { Option } from 'components/shared/ui/OptionTypes';
import { SelectField } from 'components/shared/forms/SelectField';
import { ProgressOverlay } from 'components/shared/ui/spinner/ProgressOverlay';
import { parseUrn } from 'utils/urnUtils';
import { useSaveAdditionalInformation } from 'components/flexFlow/rateAndBilling/additionalInformation/useSaveAdditionalInformation';
import {
  generateSaveRequests,
  getActions,
  getInitialValues,
  validationSchema,
} from 'components/flexFlow/rateAndBilling/additionalInformation/additionalInformationUtils';
import { DateTime } from 'luxon';
import { SelectedAction } from 'components/shared/alert/AlertDialogTypes';
import { EhiDivider } from 'components/shared/ui/styles/Divider.styles';
import { MaxLengthTextField } from 'components/shared/forms/MaxLengthTextField';

export const EditAdditionalInfoDialog: FC<EditAdditionalInfoProps> = ({ additionalInfoData, open, onClose }) => {
  const { t } = useTranslations();
  const { saveAdditionalInfo } = useSaveAdditionalInformation();

  const initialValues = getInitialValues(additionalInfoData);
  const resolver = useYupValidationResolver(validationSchema(additionalInfoData, t));
  const formMethods = useForm({ resolver: resolver, defaultValues: initialValues });

  const handleSubmit = async (values: Record<string, string | DateTime<boolean> | undefined>): Promise<void> => {
    const requests = generateSaveRequests(values, additionalInfoData);

    if (requests?.length > 0) {
      const isValid = await saveAdditionalInfo(requests, formMethods.setError);

      if (isValid) {
        onClose(SelectedAction.Primary);
      }
    } else {
      // Note: Close dialog when there is no additional information to save
      onClose(SelectedAction.Primary);
    }
  };

  const onFormSubmit = formMethods.handleSubmit(handleSubmit);

  return (
    <Dialog
      data-testid='edit-additional-info-dialog'
      a11yKey={'edit-additional-info'}
      fullWidth={true}
      open={open}
      title={t('rateAndBilling.additionalInformation')}
      actions={getActions(onClose, onFormSubmit, t)}>
      <FormProvider {...formMethods}>
        <EditAdditionalInfoDialogContent additionalInfoByAccount={additionalInfoData} />
        <ProgressOverlay inProgress={formMethods.formState.isSubmitting} />
      </FormProvider>
    </Dialog>
  );
};

type ContentProps = {
  additionalInfoByAccount: AdditionalInfoData[];
};

const EditAdditionalInfoDialogContent: FC<ContentProps> = ({ additionalInfoByAccount }) => {
  const { t } = useTranslations();
  const formContent = additionalInfoByAccount.map((info, index) => {
    if (!info.additionalInfoFields || info.additionalInfoFields.length === 0) {
      return <></>;
    }
    return (
      <Fragment key={info.accountNumber}>
        {index !== 0 && <EhiDivider sx={{ margin: ehiTheme.spacing(1) }} />}
        <Box sx={{ maxWidth: '100%' }} key={index}>
          <GridContainer style={{ margin: ehiTheme.spacing(1) }} alignItems='center'>
            <Body2 style={{ marginRight: ehiTheme.spacing(0.5) }}>{t('rateAndBilling.forAccount')}</Body2>
            <Body2 bold data-testid={`additional-info-heading-${info.accountName}`}>
              {info.accountName}
            </Body2>
          </GridContainer>
          <GridContainer>
            {info.additionalInfoFields.map((field) => transformToFormField(info.accountNumber, field))}
          </GridContainer>
        </Box>
      </Fragment>
    );
  });
  return <>{formContent}</>;
};

const transformToFormField = (accountNumber: string, field: UIAdditionalInfoField): JSX.Element => {
  const fieldId = parseUrn(field.fieldId);
  const baseFieldProperties = {
    name: `${accountNumber}_${fieldId}`,
    label: field.fieldName,
    required: field.isRequiredAtReservation,
  };
  switch (field.fieldInfo.type) {
    case FieldType.TEXT: {
      return (
        <GridItem sm={6} key={field.fieldId} data-testid={`${accountNumber}-${field.fieldName}`}>
          <MaxLengthTextField {...baseFieldProperties} maxLength={field.fieldInfo.maxLength} />
          <AdditionalInfoHelperText>{field.helperText}</AdditionalInfoHelperText>
        </GridItem>
      );
    }
    case FieldType.DROP_DOWN: {
      const dropDownFieldInfo = field.fieldInfo;
      return (
        <GridItem sm={6} key={field.fieldId} data-testid={`${accountNumber}-${field.fieldName}`}>
          <SelectField
            {...baseFieldProperties}
            options={dropDownFieldInfo?.dropDownOption?.map((option: DropDownOption) => {
              return { label: option.text, value: option.text } as Option;
            })}
          />
          <AdditionalInfoHelperText>{field.helperText}</AdditionalInfoHelperText>
        </GridItem>
      );
    }
  }
};
