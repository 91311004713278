import { useLocation } from 'react-router-dom';
import { useMemo } from 'react';
import { CREATE, MODIFY } from 'utils/constants';
import { selectBookingEditorId } from 'redux/selectors/bookingEditor';
import { useAppSelector } from 'redux/hooks';

export type GetFlow = {
  isCreateFlow: boolean;
  isModifyFlow: boolean;
};

export const useReservationFlow = (): GetFlow => {
  const { pathname } = useLocation();
  const bookingEditorId = useAppSelector(selectBookingEditorId);

  const isCreateFlow = useMemo((): boolean => {
    return !!bookingEditorId && pathname.includes(CREATE);
  }, [bookingEditorId, pathname]);

  const isModifyFlow = useMemo((): boolean => {
    return !!bookingEditorId && pathname.includes(MODIFY);
  }, [bookingEditorId, pathname]);

  return {
    isCreateFlow,
    isModifyFlow,
  };
};
