import { FC, useMemo } from 'react';
import { formatPhoneNumber } from 'utils/phoneNumberUtils';
import { Body2, EhiButton, ehiTheme } from '@ehi/ui';
import ListItem from '@mui/material/ListItem';
import { piiField } from '@ehi/support';
import { parseUrn } from 'utils/urnUtils';
import { SearchResult } from 'services/renter/driverProfile/driverProfileTypes';
import { DriverListItem, DriverSearchName, FlexContainer } from './DriverSearch.styles';
import { useTranslations } from 'components/shared/i18n';
import { LoyaltyProgramLogo } from 'components/shared/ui/logos/LoyaltyProgramLogo';
import { toDateTimeString } from 'utils/dateUtils';
import { StyledWarningIcon } from 'components/shared/ui/styles/WarningIcon.styles';
import { useGetLoyaltyProgram } from 'services/renter/driverProfile/driverProfileQueries';
import { PhoneType } from 'services/renter/renterReferenceTypes';
import { Grid } from '@mui/material';
import { getCountry, getSubdivision } from 'utils/countryUtils';
import { useCountriesQuery, useSubdivisionsQuery } from 'services/location/locationQueries';
import { findDriverLicense } from 'services/renter/renterTransformer';
import { DriverGridItem } from 'components/flexFlow/driver/Driver.styles';

export interface DriverResultsParams {
  showRenterWarningDialog: (state: SearchResult | undefined) => void;
  searchResult: SearchResult;
  onAddDriver?: (driver: SearchResult) => void;
  testId?: string;
  phoneTypeDomain?: PhoneType[];
}

const DriverSearchResult: FC<DriverResultsParams> = ({
  showRenterWarningDialog,
  searchResult,
  onAddDriver,
  testId,
  phoneTypeDomain,
}: DriverResultsParams) => {
  const { t } = useTranslations();
  const { data: loyaltyPrograms } = useGetLoyaltyProgram();
  const driverLicenseInformation = findDriverLicense(searchResult.legalIdentifications);
  const { data: countries } = useCountriesQuery();
  const { data: subDivisions } = useSubdivisionsQuery(parseUrn(driverLicenseInformation?.country));
  const loyaltyProgramName = useMemo(() => {
    return loyaltyPrograms?.find((value) => value.urn === searchResult.loyaltyMembership?.loyaltyProgram)?.name;
  }, [loyaltyPrograms, searchResult.loyaltyMembership?.loyaltyProgram]);
  const phoneType = useMemo(() => {
    return phoneTypeDomain?.find((phone) => phone.urn === searchResult?.phoneNumbers?.[0]?.phoneType)?.name;
  }, [phoneTypeDomain, searchResult?.phoneNumbers]);

  return (
    <ListItem
      divider
      component={DriverListItem}
      data-testid={testId}
      style={{ padding: ehiTheme.spacing(3, 0), gap: ehiTheme.spacing(1) }}>
      <Grid container gap={ehiTheme.spacing(1)}>
        <FlexContainer>
          <Grid container>
            {searchResult.renterWarningFlag && (
              <StyledWarningIcon data-testid='renterWarning' sx={{ paddingTop: ehiTheme.spacing(0.2) }} />
            )}
            <DriverSearchName bold>
              {searchResult?.name?.surname}, {searchResult.name?.givenName}
            </DriverSearchName>
          </Grid>
        </FlexContainer>
        <Grid container className={piiField} gap={ehiTheme.spacing(3)}>
          <DriverGridItem>
            <Body2 color='textPrimary'>{searchResult.address?.addressLine1}</Body2>
            <Body2 color='textPrimary'>
              {searchResult?.address?.city}, {parseUrn(searchResult?.address?.countrySubdivision)}{' '}
              {searchResult?.address?.postalCode} {parseUrn(searchResult?.address?.country)}
            </Body2>
            {phoneType && (
              <Body2 color='textPrimary'>
                {`${phoneType}: ${formatPhoneNumber(searchResult?.phoneNumbers?.[0].number) ?? ''}`}
              </Body2>
            )}
            <Body2 color='textPrimary' style={{ wordBreak: 'break-all' }}>
              {`${t('driverSearch.email')} ${formatPhoneNumber(searchResult?.emailAddresses?.[0].emailAddress) ?? ''}`}
            </Body2>
          </DriverGridItem>
          <DriverGridItem>
            <Body2 display='inline' color='textPrimary'>
              {t('driverSearch.dlSuffix')}
            </Body2>{' '}
            <Body2 display='inline' color='textPrimary'>
              {driverLicenseInformation?.number}
            </Body2>
            <Body2 color='textPrimary'>
              {driverLicenseInformation?.countrySubdivision
                ? getSubdivision(subDivisions, driverLicenseInformation?.countrySubdivision)?.name
                : ''}
            </Body2>
            <Body2 color='textPrimary'>
              {getCountry(countries, parseUrn(driverLicenseInformation?.country))?.name}
            </Body2>
            <Body2 display='inline' color='textPrimary'>
              {t('driverSearch.dlExp')}
            </Body2>{' '}
            <Body2 display='inline' color='textPrimary'>
              {toDateTimeString(driverLicenseInformation?.expirationDate, t('format.date'))}
            </Body2>
            <br />
            <Body2 display='inline' color='textPrimary'>
              {t('driverSearch.dob')}
            </Body2>{' '}
            <Body2 display='inline' color='textPrimary'>
              {toDateTimeString(searchResult.birthInformation?.date, t('format.date'))}
            </Body2>
          </DriverGridItem>
          <DriverGridItem>
            <LoyaltyProgramLogo
              membershipProgram={parseUrn(searchResult.loyaltyMembership?.loyaltyProgram)}
              height={18}
              width={64}
            />
            <br />
            {loyaltyProgramName && <Body2 display='inline'>{loyaltyProgramName}</Body2>}
            <br />
            <Body2 display='inline'>{searchResult.loyaltyMembership?.number}</Body2>
          </DriverGridItem>
          <DriverGridItem sm={1} display={'flex'} justifyContent={'center'} alignItems={'end'}>
            <EhiButton
              style={{ margin: ehiTheme.spacing(0), padding: ehiTheme.spacing(0) }}
              onClick={(): void => {
                if (searchResult.renterWarningFlag) {
                  showRenterWarningDialog(searchResult);
                } else {
                  onAddDriver?.(searchResult);
                }
              }}>
              {t('common.add')}
            </EhiButton>
          </DriverGridItem>
        </Grid>
      </Grid>
    </ListItem>
  );
};

export default DriverSearchResult;
