import { styled } from '@mui/material';
import { Box } from '@mui/system';
import { ehiTheme, H6 } from '@ehi/ui';
import { secondaryText } from 'components/shared/ui/styles/Global.styles';

export const NoResultsContainer = styled(Box)`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
`;

export const StyledDescription = styled('span')`
  font-size: ${ehiTheme.typography.subtitle1.fontSize};
  font-weight: normal;
  line-height: ${ehiTheme.spacing(1.5)};
  color: ${secondaryText};
  word-wrap: break-word;
  max-width: 750px;
`;

export const StyledMessage = styled('div')`
  display: flex;
  flex-direction: column;
  text-align: left;
`;

export const SuggestionsHeader = styled(H6)`
  color: ${secondaryText};
`;

export const StyledLink = styled('a')`
  color: ${ehiTheme.palette.primary.main};
  text-decoration: underline;
`;

export const NoResultStyledLink = styled('a')`
  color: ${ehiTheme.palette.primary.main};
  text-decoration-line: underline;
  cursor: pointer;
  line-height: 24px;
  letter-spacing: 0.5px;
`;

export const TextWrapper = styled('div')`
  display: grid;
  justify-content: center;
  max-width: 750px;
  padding: ${ehiTheme.spacing(16, 12, 12, 12)};
`;
