import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { isDevCypressRun } from 'utils/buildInfoUtil';
import { DriverState } from 'redux/slices/driver/driverSliceTypes';
import { FullHumanAndElectronicVerification } from 'services/identityVerification/identityVerificationTypes';

const win = window as any;
const initialState = (isDevCypressRun() && win?.initialState?.bookingEditor) || ({} as DriverState);

const driverSlice = createSlice({
  name: 'driver',
  initialState: initialState as DriverState,
  reducers: {
    setVerificationData: (
      state: DriverState,
      action: PayloadAction<FullHumanAndElectronicVerification[] | undefined>
    ) => {
      const { payload } = action;
      state.verificationResults = payload;
    },
    resetDriverState: (state: DriverState) => {
      state.verificationResults = undefined;
    },
  },
});

export const { setVerificationData, resetDriverState } = driverSlice.actions;

export default driverSlice.reducer;
