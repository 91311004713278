import { UseQueryResult } from '@tanstack/react-query';
import { useResScopeQuery } from 'context/queryClient/queryUtil';
import { RatePlan } from './ratePlanTypes';
import { getRatePlanById } from './ratePlanService';
const RATE_PLAN = 'ratePlan';

export const useGetRatePlanById = (ratePlanId: string): UseQueryResult<RatePlan> => {
  return useResScopeQuery({
    queryKey: [RATE_PLAN, ratePlanId],
    queryFn: (): Promise<RatePlan> => getRatePlanById(ratePlanId),
    enabled: !!ratePlanId,
  });
};
