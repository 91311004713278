import { FC, useCallback, useEffect, useMemo } from 'react';
import { GridContainer } from 'components/shared/ui/styles/Grid.styles';
import { piiField } from '@ehi/support';
import { useTranslations } from 'components/shared/i18n';
import { DateTime } from 'luxon';
import { PrimaryButton, SecondaryButton } from 'components/shared/ui/styles/Global.styles';
import {
  DriverSearchFields,
  DriverSearchValues,
  SearchByType,
} from 'components/flexFlow/driver/driverSearch/DriverSearchTypes';
import * as Yup from 'yup';
import { AnyObject, StringSchema } from 'yup';
import { FormProvider, useForm, useFormContext } from 'react-hook-form';
import { useYupValidationResolver } from 'components/shared/forms/useYupValidationResolver';
import { CorporateCountry } from 'utils/countryUtils';
import {
  FlexContainer,
  SearchButtonWrapper,
  SearchContainer,
} from 'components/flexFlow/driver/driverSearch/DriverSearch.styles';
import { SelectField } from 'components/shared/forms/SelectField';
import { MaxLengthTextField } from 'components/shared/forms/MaxLengthTextField';
import { CountrySelectField } from 'components/shared/forms/CountrySelectField';
import { PhoneTextField } from 'components/shared/forms/PhoneTextField';
import { DatePickerField } from 'components/shared/forms/DatePickerField';
import { CountrySubDivisionSelectField } from 'components/shared/forms/CountrySubDivisionSelectField';
import { loadEhiLocationCookie } from '@ehi/location';
import { useBranchInfoByPeopleSoftIdQuery } from 'services/location/locationQueries';
import { extractCountryCode } from 'utils/locationUtils';
import { ehiTheme } from '@ehi/ui';
import { Grid } from '@mui/material';
import { DriverGridItem } from 'components/flexFlow/driver/Driver.styles';

export type DriverSearchFormProps = {
  search?: (values: DriverSearchValues) => void;
  loading?: boolean;
};
export const DriverSearchForm: FC<DriverSearchFormProps> = ({ search, loading = false }: DriverSearchFormProps) => {
  const { t } = useTranslations();

  const generateSearchByTypeRequiredValidation = (
    searchByType: SearchByType,
    minLength = 1
  ): {
    is: (searchBy: SearchByType) => boolean;
    then: () => StringSchema<NonNullable<string | undefined>, AnyObject, undefined, ''>;
  } => {
    return {
      is: (searchBy: SearchByType) => searchBy === searchByType,
      then: () =>
        Yup.string()
          .required(t('validation.requiredField'))
          .min(minLength, t('validation.minFieldLength', { fieldLength: 2 })),
    };
  };

  const validationSchema = Yup.object().shape(
    {
      lastName: Yup.string().when(
        DriverSearchFields.SearchBy,
        generateSearchByTypeRequiredValidation(SearchByType.RenterInformation, 2)
      ),
      firstName: Yup.string().when(
        DriverSearchFields.SearchBy,
        generateSearchByTypeRequiredValidation(SearchByType.RenterInformation, 2)
      ),
      phoneNumber: Yup.string().when([DriverSearchFields.SearchBy, DriverSearchFields.DOB], {
        is: (searchBy: SearchByType, dateOfBirth: DateTime | undefined) =>
          searchBy === SearchByType.RenterInformation && !dateOfBirth,
        then: () => Yup.string().required(t('validation.requiredField')).min(10, t('validation.phoneNumberValidation')),
        otherwise: () => Yup.string(),
      }),
      dateOfBirth: Yup.date().when([DriverSearchFields.SearchBy, DriverSearchFields.PhoneNumber], {
        is: (searchBy: SearchByType, phoneNumber: string) =>
          searchBy === SearchByType.RenterInformation && !phoneNumber?.length,
        then: () =>
          Yup.date().required(t('validation.requiredField')).nullable().typeError(t('validation.invalidDateFormat')),
        otherwise: () => Yup.date().nullable(),
      }),
      loyaltyNumber: Yup.string().when(
        DriverSearchFields.SearchBy,
        generateSearchByTypeRequiredValidation(SearchByType.LoyaltyNumber, 2)
      ),
      licenseNumber: Yup.string().when(
        DriverSearchFields.SearchBy,
        generateSearchByTypeRequiredValidation(SearchByType.DriversLicense, 2)
      ),
      country: Yup.string().when(
        DriverSearchFields.SearchBy,
        generateSearchByTypeRequiredValidation(SearchByType.DriversLicense)
      ),
      state: Yup.string().when([DriverSearchFields.SearchBy, DriverSearchFields.Country], {
        is: (searchBy: SearchByType, country: string) =>
          searchBy === SearchByType.DriversLicense &&
          (country === CorporateCountry.UnitedStates || country === CorporateCountry.Canada),
        then: () => Yup.string().required(t('validation.requiredField')),
        otherwise: () => Yup.string(),
      }),
    },
    [[DriverSearchFields.PhoneNumber, DriverSearchFields.DOB]]
  );

  const initialValues: DriverSearchValues = useMemo(
    () => ({
      searchBy: SearchByType.RenterInformation,
      loyaltyNumber: '',
      licenseNumber: '',
      country: '',
      state: '',
      lastName: '',
      firstName: '',
      dateOfBirth: undefined,
      phoneNumber: '',
    }),
    []
  );
  const resolver = useYupValidationResolver(validationSchema);
  const methods = useForm<DriverSearchValues>({ resolver: resolver, defaultValues: initialValues });

  const handleSubmit = useCallback(
    async (values: DriverSearchValues) => {
      return search?.(values);
    },
    [search]
  );

  const handleReset = useCallback(() => {
    methods.reset({ ...initialValues });
  }, [initialValues, methods]);

  const onFormSubmit = methods.handleSubmit(handleSubmit);

  return (
    <SearchContainer>
      <FormProvider {...methods}>
        <DriverSearchFormContainer loading={loading} onFormSubmit={onFormSubmit} handleReset={handleReset} />
      </FormProvider>
    </SearchContainer>
  );
};

type DriverSearchFormContainerProps = {
  loading?: boolean;
  onFormSubmit: () => void;
  handleReset: () => void;
};

const DriverSearchFormContainer: FC<DriverSearchFormContainerProps> = ({ onFormSubmit, loading, handleReset }) => {
  const { t } = useTranslations();
  const cookie = loadEhiLocationCookie();
  const { data: branchV2 } = useBranchInfoByPeopleSoftIdQuery(cookie?.peoplesoftId ?? '');
  const { setValue, reset, setFocus, watch } = useFormContext<DriverSearchValues>();
  const searchBy = watch('searchBy');
  const country = watch('country');
  useEffect(() => {
    if (searchBy === SearchByType.RenterInformation) {
      setFocus(DriverSearchFields.LastName);
    } else if (searchBy === SearchByType.LoyaltyNumber) {
      setFocus(DriverSearchFields.LoyaltyNumber);
    } else if (searchBy === SearchByType.DriversLicense) {
      setFocus(DriverSearchFields.LicenseNumber);
    }
  }, [searchBy, setFocus]);

  const options = useMemo(
    () =>
      [SearchByType.RenterInformation, SearchByType.LoyaltyNumber, SearchByType.DriversLicense].map((searchByType) => {
        return {
          label: t(searchByType.value),
          value: searchByType.toString(),
        };
      }),
    [t]
  );

  return (
    <FlexContainer sx={{ gap: ehiTheme.spacing(2) }}>
      <GridContainer gap={ehiTheme.spacing(3)}>
        <DriverGridItem>
          <SelectField
            name={DriverSearchFields.SearchBy}
            label={t('location.searchBy')}
            options={options}
            hasNoneOption={false}
            onChange={(event): void => {
              reset();
              setValue(DriverSearchFields.SearchBy, SearchByType[event.target.value as keyof typeof SearchByType]);
            }}
          />
        </DriverGridItem>
        {searchBy === SearchByType.RenterInformation && (
          <>
            <DriverGridItem display={{ xs: 'none', sm: 'block' }} />
            <GridContainer gap={ehiTheme.spacing(3)}>
              <DriverGridItem data-testid={'driver-lastName'}>
                <MaxLengthTextField
                  name={DriverSearchFields.LastName}
                  type='text'
                  label={t('driver.lastName')}
                  required
                  maxLength={60}
                  inputProps={{
                    minLength: 2,
                  }}
                />
              </DriverGridItem>
              <DriverGridItem data-testid={'driver-firstName'}>
                <MaxLengthTextField
                  name={DriverSearchFields.FirstName}
                  type='text'
                  label={t('driver.firstName')}
                  required
                  maxLength={35}
                  inputProps={{
                    minLength: 2,
                  }}
                />
              </DriverGridItem>
            </GridContainer>
            <GridContainer gap={ehiTheme.spacing(3)}>
              <DriverGridItem data-testid={'driver-dateOfBirth'}>
                <DatePickerField name={DriverSearchFields.DOB} label={t('driver.dateOfBirth')} className={piiField} />
              </DriverGridItem>
              <DriverGridItem data-testid={'driver-phoneNumber'}>
                <PhoneTextField
                  country={extractCountryCode(branchV2?.addresses)}
                  name={DriverSearchFields.PhoneNumber}
                  label={t('driver.phoneNumber')}
                  className={piiField}
                />
              </DriverGridItem>
            </GridContainer>
          </>
        )}
        {searchBy === SearchByType.LoyaltyNumber && (
          <DriverGridItem data-testid={'driver-loyaltyNumber'}>
            <MaxLengthTextField
              type='text'
              name={DriverSearchFields.LoyaltyNumber}
              label={t('driver.loyaltyNumber')}
              maxLength={25}
              required
              className={piiField}
            />
          </DriverGridItem>
        )}
        {searchBy === SearchByType.DriversLicense && (
          <>
            <DriverGridItem data-testid={'license-number'}>
              <MaxLengthTextField
                name={DriverSearchFields.LicenseNumber}
                type='text'
                label={t('driver.licenseNumber')}
                required
                maxLength={50}
                className={piiField}
              />
            </DriverGridItem>
            <GridContainer gap={ehiTheme.spacing(2, 3)}>
              <DriverGridItem data-testid={'driver-country'}>
                <CountrySelectField
                  sx={{ padding: 0 }}
                  name={DriverSearchFields.Country}
                  required
                  label={t('common.country')}
                  onChange={(event): void => {
                    setValue(DriverSearchFields.Country, event.target.value);
                    setValue(DriverSearchFields.State, '');
                  }}
                />
              </DriverGridItem>

              {country.length > 0 &&
              (country === CorporateCountry.UnitedStates || country === CorporateCountry.Canada) ? (
                <DriverGridItem data-testid={'driver-state'}>
                  <CountrySubDivisionSelectField
                    name={DriverSearchFields.State}
                    required
                    label={
                      country === CorporateCountry.UnitedStates
                        ? t('common.address.state')
                        : t('common.address.province')
                    }
                    country={country}
                    onChange={(event): void => {
                      setValue(DriverSearchFields.State, event.target.value);
                    }}
                    className={piiField}
                  />
                </DriverGridItem>
              ) : (
                <DriverGridItem />
              )}
            </GridContainer>
          </>
        )}
      </GridContainer>
      <SearchButtonWrapper>
        <Grid container style={{ padding: 0 }} gap={ehiTheme.spacing(1)} justifyContent={'flex-end'}>
          <Grid item>
            <SecondaryButton
              data-testid='branchLookupReset'
              disabled={loading}
              sx={{ margin: 0 }}
              onClick={(): void => {
                handleReset();
              }}>
              {t('common.reset')}
            </SecondaryButton>
          </Grid>
          <Grid item>
            <PrimaryButton
              sx={{ margin: 0 }}
              disabled={loading}
              data-testid='searchDriver'
              onClick={(): void => {
                onFormSubmit();
              }}>
              {t('common.search')}
            </PrimaryButton>
          </Grid>
        </Grid>
      </SearchButtonWrapper>
    </FlexContainer>
  );
};
