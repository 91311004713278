import { getDefaultAxiosInstance } from 'components/shared/axios/axiosConfiguration';
import { PaymentHistoryResponse } from 'services/payment/paymentHistoryTypes';

const PAYMENT_TRANSACTION_ENDPOINT = '/payment/transaction';

export const getPaymentHistory = async (resNumber: string): Promise<PaymentHistoryResponse> => {
  return (
    await getDefaultAxiosInstance().get(`${PAYMENT_TRANSACTION_ENDPOINT}?reservation=${resNumber}`, {
      genericUrl: `${PAYMENT_TRANSACTION_ENDPOINT}?reservation={resNumber}`,
    })
  ).data;
};
