import {
  Address,
  ElectronicIdentityVerificationRequest,
  ElectronicIndividual,
  SearchVerificationHistoryRequest,
} from 'services/identityVerification/identityVerificationTypes';
import { ELECTRONIC_TYPE_VERIFICATION } from 'utils/constants';
import { parseUrn } from 'utils/urnUtils';

export type DriverForVerification = {
  profileId?: string;
  firstName?: string;
  lastName?: string;
  address: DriverAddressForVerification;
  dob: string;
};

export interface DriverAddressForVerification {
  lines: string[];
  city: string;
  postalCode?: string;
  countryCode: string;
  countrySubdivisionCode?: string;
}

export const transformToElectronicVerificationRequest = (
  driver: DriverForVerification,
  pickupLocation: string,
  reservationUrn: string
): ElectronicIdentityVerificationRequest => {
  return {
    context: reservationUrn,
    transactionLocation: parseUrn(pickupLocation),
    individuals: [transformToElectronicIndividual(driver, reservationUrn)],
  };
};

export const transformToElectronicIndividual = (
  { address, dob, profileId, lastName, firstName }: DriverForVerification,
  reservationUrn?: string
): ElectronicIndividual => {
  return {
    dateOfBirth: dob,
    address: transformToAddress(address),
    ...(!!reservationUrn && !!profileId && { urn: `${reservationUrn}:individual:${profileId}` }),
    ...(!!firstName && !!lastName && { name: { givenName: firstName, surname: lastName } }),
  };
};

const transformToAddress = (address: DriverAddressForVerification): Address => {
  return {
    city: address.city,
    countryCode: address.countryCode,
    streetAddressLine1: address.lines[0],
    ...(!!address.lines[1] && { streetAddressLine2: address?.lines[1] }),
    ...(!!address.countrySubdivisionCode && { countrySubdivisionCode: address.countrySubdivisionCode }),
    ...(!!address.postalCode && { postalCode: address.postalCode }),
  } as Address;
};

export const transformToSearchVerificationHistoryRequest = (
  pickupLocation: string,
  context: string
): SearchVerificationHistoryRequest => {
  return {
    context: context,
    transactionLocation: parseUrn(pickupLocation),
    verificationType: ELECTRONIC_TYPE_VERIFICATION,
    returnLatest: true,
  };
};
