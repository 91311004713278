import { createContext, FC, ReactNode, useCallback, useContext, useRef, useState } from 'react';

import { AlertDialog } from 'components/shared/alert/AlertDialog';
import {
  AlertContextProps,
  AlertOptions,
  AlertWithActions,
  SelectedAction,
} from 'components/shared/alert/AlertDialogTypes';
import { AppErrorCode, SpecialErrorCodes } from 'utils/errorUtils';
import NetworkError from 'components/shared/errors/NetworkError';
import { ResponseMessageDialog } from 'components/shared/alert/ResponseMessageDialog';
import { RefreshEditorDialog } from 'components/shared/alert/RefreshEditorDialog';
import { AlertWithAction } from 'components/shared/alert/AlertWithAction';
import { EMPTY_VALUE } from 'utils/constants';
import { SupportingServiceBookingIssue } from 'utils/bookingUtils';

export const AlertContext = createContext<AlertContextProps>({
  showAlert: () => {
    return Promise.reject(new Error('Context is not setup'));
  },
  hideAlert: () => {
    // do nothing
  },
});

export const useAlert = (): AlertContextProps => useContext(AlertContext);

export const AlertProvider: FC<{ children?: ReactNode }> = ({ children }) => {
  const [alertState, setAlertState] = useState<AlertOptions | null>(null);
  const awaitingPromiseRef = useRef<{ resolve: (_selected?: SelectedAction) => void }>();

  const showAlert = useCallback((options: AlertOptions): Promise<SelectedAction | undefined> => {
    setAlertState(options);
    return new Promise<SelectedAction | undefined>((resolve) => {
      awaitingPromiseRef.current = { resolve };
    });
  }, []);

  const hideAlert = (selected?: SelectedAction): void => {
    setAlertState(null);
    awaitingPromiseRef.current?.resolve(selected);
  };

  const getAlertContent = (): JSX.Element | undefined => {
    if (alertState?.responseMessages) {
      const isNetworkTimeout = alertState.responseMessages.find(
        (error) => error.code === SpecialErrorCodes.NetworkTimeout || error.code === AppErrorCode.networkError
      );

      // Refresh editor only when backend service is down and editor returns 500 with error code = BOOK1000
      const refreshEditor = alertState.responseMessages.find((error) => error.code === SupportingServiceBookingIssue);

      if (isNetworkTimeout) {
        return <NetworkError />;
      } else if (refreshEditor) {
        return (
          <RefreshEditorDialog
            message={alertState?.responseMessages?.[0].localizedMessage || EMPTY_VALUE}
            onClose={hideAlert}
          />
        );
      } else {
        return <ResponseMessageDialog {...alertState} open={!!alertState} onClose={hideAlert} />;
      }
    } else if ((alertState as AlertWithActions)?.descriptions) {
      const description = (
        <>
          {(alertState as AlertWithActions).descriptions.map((description) => (
            <AlertWithAction alertVariant={alertState?.variant} key={description.message} description={description} />
          ))}
        </>
      );
      return <AlertDialog {...alertState} open={!!alertState} onClose={hideAlert} description={description} />;
    } else if (alertState?.description) {
      return <AlertDialog {...alertState} open={!!alertState} onClose={hideAlert} />;
    }

    return undefined;
  };

  return (
    <AlertContext.Provider value={{ showAlert, hideAlert }}>
      {children}
      {getAlertContent()}
    </AlertContext.Provider>
  );
};
