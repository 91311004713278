import { FC } from 'react';
import { INFO_ALERT_VARIANT } from 'components/shared/alert/AlertDialogTypes';
import InfoIcon from '@mui/icons-material/Info';
import { Trans } from 'react-i18next';
import { StyledLink } from 'components/shared/ui/styles/Global.styles';
import { Alert } from '@mui/material';
import { NoteBannerReturnType } from 'components/shared/banner/NoteBannerTypes';

export const NoteBanner: FC<NoteBannerReturnType> = ({ description, handleLinkClick }) => {
  return (
    <Alert
      severity={INFO_ALERT_VARIANT}
      sx={{
        border: '1px solid #6A3E9D80',
        display: 'flex',
        alignItems: 'center',
      }}
      icon={<InfoIcon />}>
      {handleLinkClick ? (
        <Trans
          i18nKey={'reservationAlerts.noteBannerWithLink'}
          values={{ description }}
          components={{
            Link: (
              <StyledLink
                data-testid='requiredFieldsDetailsLink'
                sx={{ textDecoration: 'underline' }}
                onClick={handleLinkClick}
              />
            ),
          }}
        />
      ) : (
        <Trans i18nKey={'reservationAlerts.noteBanner'} values={{ description }} />
      )}
    </Alert>
  );
};
