import { FC, useCallback, useEffect, useState } from 'react';
import { useTranslations } from 'components/shared/i18n';
import { QuickResFields } from 'components/quickRes/QuickResTypes';
import { DateTime } from 'luxon';
import { TimePickerField } from 'components/shared/forms/TimePickerField';
import { DatePickerField } from 'components/shared/forms/DatePickerField';
import { useAppSelector } from 'redux/hooks';
import { selectBookingEditorId, selectReturn } from 'redux/selectors/bookingEditor';
import { useUpdateAndRefreshEditor } from 'hooks/bookingEditor/useUpdateAndRefreshEditor';
import { useFormContext } from 'react-hook-form';
import { combineDateAndTime, isInvalidDateTime } from 'utils/dateUtils';
import { updateReturnInformation } from 'services/booking/bookingService';
import { ProgressOverlay } from 'components/shared/ui/spinner/ProgressOverlay';
import { WhenAndWhereFields } from 'components/flexFlow/whenAndWhere/WhenAndWhereTypes';
import { Box, Grid } from '@mui/material';
import { ehiTheme, Subtitle1 } from '@ehi/ui';
import { logDebug } from 'utils/logUtils';
import { useAlert } from 'components/shared/alert/AlertContext';
import { loadEhiLocationCookie } from '@ehi/location';
import { useSetLocationService } from 'services/location/useSetLocationService';
import { SectionGridItem } from 'components/quickRes/QuickRes.styles';

const RentalReturn: FC = () => {
  const { t } = useTranslations();
  const bookingEditorId = useAppSelector(selectBookingEditorId);
  const returnInfo = useAppSelector(selectReturn);
  const { updateAndRefresh } = useUpdateAndRefreshEditor();
  const { showAlert } = useAlert();
  const cookieLocation = loadEhiLocationCookie();
  const { queryLocation } = useSetLocationService();

  const [loading, setLoading] = useState(false);

  const {
    getValues,
    setValue,
    trigger,
    setError,
    getFieldState,
    formState: { errors },
  } = useFormContext();
  const {
    startDate,
    startTime,
    startDateTime,
    returnDate,
    returnTime,
    returnDateTime,
    currentLocationTimezone: timezone,
  } = getValues();

  const addReturnDateTime = useCallback(
    async (dateTime: DateTime | undefined) => {
      if (dateTime && dateTime.toISO()) {
        setValue(QuickResFields.ReturnDateTime, dateTime.toISO());
        if (
          !errors[QuickResFields.ReturnDate] &&
          !errors[QuickResFields.ReturnTime] &&
          dateTime.toISO({ suppressMilliseconds: true }) !== returnInfo?.dateTime
        ) {
          try {
            setLoading(true);
            const location = await queryLocation(cookieLocation?.peoplesoftId ?? '');
            const { errors: returnErrors } = await updateAndRefresh(() =>
              updateReturnInformation(bookingEditorId, {
                branch: location.location.urn,
                dateTime: dateTime?.toISO() ?? '',
              })
            );
            if (returnErrors) {
              await showAlert({
                variant: 'error',
                description: returnErrors?.[0]?.localizedMessage ?? '',
                primaryActionText: 'dismiss',
              });
            }
          } catch (error) {
            logDebug(error);
          } finally {
            setLoading(false);
          }
        }
      }
    },
    [
      bookingEditorId,
      cookieLocation?.peoplesoftId,
      errors,
      queryLocation,
      returnInfo?.dateTime,
      setValue,
      showAlert,
      updateAndRefresh,
    ]
  );

  const removeReturnDateTime = useCallback(async () => {
    setValue(QuickResFields.ReturnDateTime, '');
    try {
      setLoading(true);
      const location = await queryLocation(cookieLocation?.peoplesoftId ?? '');
      const { errors } = await updateAndRefresh(() =>
        updateReturnInformation(bookingEditorId, {
          branch: location.location.urn,
        })
      );
      if (errors) {
        await showAlert({
          variant: 'error',
          description: errors?.[0]?.localizedMessage ?? '',
          primaryActionText: 'dismiss',
        });
      }
    } catch (error) {
      logDebug(error);
    } finally {
      setLoading(false);
    }
  }, [bookingEditorId, cookieLocation?.peoplesoftId, queryLocation, setValue, showAlert, updateAndRefresh]);

  const updateReturnDateTime = useCallback(async () => {
    // Note: Set to default one day rental if no return date entered
    const combinedDateTime = startDateTime
      ? DateTime.fromISO(startDateTime)?.plus({ day: 1 }).setZone(timezone)
      : undefined;
    if (combinedDateTime) {
      await addReturnDateTime(combinedDateTime);
    } else if (!combinedDateTime && !!returnInfo?.dateTime) {
      await removeReturnDateTime();
    }
  }, [addReturnDateTime, removeReturnDateTime, returnInfo?.dateTime, startDateTime, timezone]);

  const returnFieldsHaveBeenTouched = useCallback(
    () => getFieldState(QuickResFields.ReturnDate).isDirty && getFieldState(QuickResFields.ReturnTime).isDirty,
    [getFieldState]
  );

  const handleReturnDateChange = useCallback(
    async (date: DateTime | '') => {
      await trigger([QuickResFields.ReturnTime]);
      setValue(QuickResFields.ReturnDate, date);
      if (!date && !returnTime) {
        await updateReturnDateTime();
      } else if (returnFieldsHaveBeenTouched()) {
        const combinedDateTime = date && returnTime ? combineDateAndTime(date, returnTime, timezone) : undefined;
        await addReturnDateTime(combinedDateTime);
      }
    },
    [addReturnDateTime, returnFieldsHaveBeenTouched, returnTime, setValue, timezone, trigger, updateReturnDateTime]
  );

  const handleReturnTimeChange = useCallback(
    async (time: DateTime | '', isOnBlurOrAccept: boolean) => {
      if (isOnBlurOrAccept) {
        if (isInvalidDateTime(time)) {
          setError(WhenAndWhereFields.ReturnTime, { message: t('validation.invalidTimeFormat') });
        } else if (!time && !returnDate) {
          await trigger([QuickResFields.ReturnDate]);
          await updateReturnDateTime();
        } else if (returnFieldsHaveBeenTouched()) {
          await trigger([QuickResFields.ReturnDate]);
          setValue(QuickResFields.ReturnTime, time);
          const combinedDateTime = returnDate && time ? combineDateAndTime(returnDate, time, timezone) : undefined;
          await addReturnDateTime(combinedDateTime);
        }
      }
    },
    [
      addReturnDateTime,
      returnDate,
      returnFieldsHaveBeenTouched,
      setError,
      setValue,
      t,
      timezone,
      trigger,
      updateReturnDateTime,
    ]
  );

  const validateReturnDateTime = useCallback(async () => {
    if (returnDateTime && startDateTime && returnDateTime <= startDateTime) {
      await trigger([QuickResFields.ReturnDate, QuickResFields.ReturnTime]);
    }
  }, [returnDateTime, startDateTime, trigger]);

  useEffect(() => {
    (async (): Promise<void> => {
      await validateReturnDateTime();
    })();
  }, [startDate, startTime, returnDate, returnTime, validateReturnDateTime]);

  return (
    <Box>
      <Grid container gap={ehiTheme.spacing(2)}>
        <Grid item>
          <Subtitle1 bold data-testid={'rental-return'}>
            {t('whenWhere.rentalReturn')}
          </Subtitle1>
        </Grid>
        <Grid container data-testid={'rentalReturnSection'} gap={ehiTheme.spacing(2)}>
          <SectionGridItem data-testid={'returnDateField'}>
            <DatePickerField
              name={QuickResFields.ReturnDate}
              label={t('whenWhere.date')}
              submitOnChange={handleReturnDateChange}
              sx={{ padding: ehiTheme.spacing(0) }}
              minDate={startDate || undefined}
              disablePast={startDate ? DateTime.now() < startDate : false}
            />
          </SectionGridItem>
          <SectionGridItem data-testid={'returnTimeField'}>
            <TimePickerField
              name={QuickResFields.ReturnTime}
              label={t('whenWhere.time')}
              timezone={timezone}
              sx={{ padding: ehiTheme.spacing(0) }}
              submitOnChange={(time: DateTime | ''): Promise<void> => handleReturnTimeChange(time, false)}
              onSubmitTime={(time: DateTime | ''): Promise<void> => handleReturnTimeChange(time, true)}
            />
          </SectionGridItem>
        </Grid>
        <ProgressOverlay inProgress={loading} />
      </Grid>
    </Box>
  );
};

export default RentalReturn;
