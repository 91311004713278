import { EditorAddOns } from 'services/booking/bookingTypes';

export const transformToEditorAddOnsRequestBody = (equipment: string[], protections: string[]): EditorAddOns => {
  return {
    fuelService: false,
    equipment:
      equipment.length > 0
        ? {
            selected: equipment.map((item) => ({ type: item })),
          }
        : undefined,
    protections: protections.length > 0 ? { selected: protections.map((item) => ({ type: item })) } : undefined,
  };
};
