import { getDefaultAxiosInstance } from 'components/shared/axios/axiosConfiguration';
import { VehicleContent } from './vehicleContentTypes';

const BASE_RES_ADAPTER_PATH = '/bff/rental/booking/reservation/greenlight/resAdapter';

export const getVehicleContent = async (countryCode: string): Promise<VehicleContent> => {
  return (
    await getDefaultAxiosInstance().get(
      `${BASE_RES_ADAPTER_PATH}?channelName=enterprise_cros&isoCountry=${countryCode}`,
      {
        genericUrl: `${BASE_RES_ADAPTER_PATH}?channelName=enterprise_cros&isoCountry={countryCode}`,
      }
    )
  ).data;
};
