import { useCallback } from 'react';
import {
  AdditionalInfoAccountType,
  SaveAdditionalInfoAccountData,
  SaveAdditionalInfoHookReturn,
} from 'components/flexFlow/rateAndBilling/additionalInformation/AdditionalInfoTypes';
import {
  BusinessPayer,
  BusinessPayerType,
  NegotiatedRateSource,
  RateSource,
  RateSourceType,
} from 'services/booking/bookingTypes';
import { modifyRateSource, updateBusinessPayers } from 'services/booking/bookingService';
import { useAppSelector } from 'redux/hooks';
import { selectBookingEditorId, selectRateSource } from 'redux/selectors/bookingEditor';
import { useUpdateAndRefreshEditor } from 'hooks/bookingEditor/useUpdateAndRefreshEditor';
import { generateUrnForBillingAccount, parseUrn } from 'utils/urnUtils';
import { getAppConfigCache } from 'services/appConfig/appConfigService';
import { validateAdditionalInformation } from 'services/businessAccount/businessAccountService';
import { RentalStage } from 'services/businessAccount/businessAccountTypes';
import { useAlert } from 'components/shared/alert/AlertContext';
import { EMPTY_VALUE } from 'utils/constants';
import { ErrorOption, FieldPath, FieldValues } from 'react-hook-form';
import { safelyCatchError } from 'utils/errorUtils';
import { ResponseMessage } from 'services/types/ResponseMessageTypes';
import { logDebug } from 'utils/logUtils';

export const useSaveAdditionalInformation = (): SaveAdditionalInfoHookReturn => {
  const bookingEditorId = useAppSelector(selectBookingEditorId);
  const { showAlert } = useAlert();
  const { updateAndRefresh } = useUpdateAndRefreshEditor();
  const appConfig = getAppConfigCache();
  const defaultEhiDatabase = appConfig?.defaultEhiDatabase ?? EMPTY_VALUE;
  const rateSource: RateSource | undefined = useAppSelector(selectRateSource);
  const negotiatedRateSource = rateSource as NegotiatedRateSource;

  const saveAdditionalInfo = useCallback(
    async (
      saveAdditionalInfoList: SaveAdditionalInfoAccountData[],
      setError: (
        name: FieldPath<FieldValues> | `root.${string}` | 'root',
        error: ErrorOption,
        options?: { shouldFocus: boolean }
      ) => void
    ): Promise<boolean> => {
      const responseMessages: ResponseMessage[] = [];
      for (const account of saveAdditionalInfoList) {
        // Generic validation payload for all account types
        const validateInputFieldsPayload = {
          ignoreMissingRequiredFields: true,
          currentRentalStage: RentalStage.RESERVATION,
          additionalInformationValues:
            account.fields.length > 0
              ? account.fields.map((info) => ({
                  additionalInformation: info.fieldId,
                  value: info.value,
                }))
              : [],
        };

        try {
          const { isValid, validationWarnings } = await validateAdditionalInformation(
            account.accountNumber,
            validateInputFieldsPayload
          );

          if (!isValid) {
            validationWarnings?.forEach((message) => {
              message.paths?.forEach((path) => {
                const issue = account.fields.find((info) => path === info.fieldId);
                issue?.fieldId &&
                  setError(`${account.accountNumber}_${parseUrn(issue.fieldId)}`, {
                    message: message.localizedMessage ?? EMPTY_VALUE,
                  });
              });
            });
            return false; // early exit if validation fails
          }

          switch (account.type) {
            case AdditionalInfoAccountType.SAME_AS_RATE_SOURCE: {
              const rateSourceRequestBody: RateSource = {
                type: RateSourceType.NEGOTIATED,
                account: account.accountUrn,
                ratePlan: negotiatedRateSource?.ratePlan ?? undefined,
                rateProduct: negotiatedRateSource?.rateProduct ?? undefined,
                additionalInformation: account.fields?.filter((item) => !item.isOnlyForBusinessPayer),
              };
              const { errors: rateSourceErrors } = await updateAndRefresh(() =>
                modifyRateSource(bookingEditorId, rateSourceRequestBody)
              );

              const requestBody: BusinessPayer[] = [
                {
                  type: BusinessPayerType.BILLING_ACCOUNT,
                  billingAccount: generateUrnForBillingAccount(
                    account.accountNumber,
                    account.billingNumber,
                    defaultEhiDatabase
                  ),
                  additionalInformation: account.fields?.filter((item) => item.isOnlyForBusinessPayer),
                } as BusinessPayer,
              ];

              const { errors: billingErrors } = await updateAndRefresh(() =>
                updateBusinessPayers(bookingEditorId, requestBody)
              );

              if (rateSourceErrors) {
                responseMessages.push(...rateSourceErrors);
              }

              if (billingErrors) {
                responseMessages.push(...billingErrors);
              }

              break;
            }
            case AdditionalInfoAccountType.RATE_SOURCE: {
              const rateSourceRequestBody: RateSource = {
                type: RateSourceType.NEGOTIATED,
                account: account.accountUrn,
                ratePlan: negotiatedRateSource?.ratePlan ?? undefined,
                rateProduct: negotiatedRateSource?.rateProduct ?? undefined,
                additionalInformation: account.fields?.filter((item) => !item.isOnlyForBusinessPayer),
              };
              const { errors } = await updateAndRefresh(() => modifyRateSource(bookingEditorId, rateSourceRequestBody));
              if (errors) {
                responseMessages.push(...errors);
              }
              break;
            }

            case AdditionalInfoAccountType.BILL_TO: {
              const requestBody: BusinessPayer[] = [
                {
                  type: BusinessPayerType.BILLING_ACCOUNT,
                  billingAccount: generateUrnForBillingAccount(
                    account.accountNumber,
                    account.billingNumber,
                    defaultEhiDatabase
                  ),
                  additionalInformation: account.fields,
                } as BusinessPayer,
              ];
              const { errors } = await updateAndRefresh(() => updateBusinessPayers(bookingEditorId, requestBody));
              if (errors) {
                responseMessages.push(...errors);
              }
              break;
            }

            default:
              // If account type is unexpected, return false
              logDebug('Unable to determine the account type');
              await showAlert({ description: 'Unable to save additional information' });
              return false;
          }
        } catch (error) {
          const ehiErrorsResponse = safelyCatchError(error);
          await showAlert({ description: ehiErrorsResponse.errors?.[0].localizedMessage ?? EMPTY_VALUE });
          return false;
        }
      }

      if (responseMessages?.length > 0) {
        await showAlert({ responseMessages: responseMessages });
      }
      return responseMessages?.length === 0;
    },
    [
      updateAndRefresh,
      bookingEditorId,
      showAlert,
      defaultEhiDatabase,
      negotiatedRateSource?.ratePlan,
      negotiatedRateSource?.rateProduct,
    ]
  );

  return {
    saveAdditionalInfo,
  };
};
