import { FlexiFunctionComponent } from 'components/shared/flexiFlow/FlexFlowTypes';
import { StickyCardNavigation } from 'components/shared/ui/card/StickyCardNavigation';
import { LoadingState } from 'components/shared/ui/spinner/loadableView/LoadableViewTypes';
import { useTranslations } from 'components/shared/i18n';
import { PoliciesSection } from 'components/flexFlow/review/sections/PoliciesSection';
import { EhiDivider } from 'components/shared/ui/styles/Divider.styles';
import { useContext, useEffect } from 'react';
import { ResSummaryContext } from 'context/resSummary/ResSummaryContext';
import { VehicleSection } from 'components/flexFlow/review/sections/VehicleSection';
import { EstimatedCharges } from 'components/flexFlow/review/sections/EstimatedCharges';
import { WhenAndWhereSection } from 'components/flexFlow/review/sections/WhenAndWhereSection';
import { ReviewFlexFlowCard, ReviewPage, ReviewScrollContainer } from 'components/flexFlow/review/Review.styles';

export const Review: FlexiFunctionComponent = ({ onPrevious }) => {
  const { t } = useTranslations();
  const { openDrawer, setOpenDrawer } = useContext(ResSummaryContext);

  useEffect(() => {
    if (openDrawer) {
      setOpenDrawer(false);
    }
  }, [openDrawer, setOpenDrawer]);

  return (
    <ReviewPage>
      <ReviewFlexFlowCard loadingState={LoadingState.SUCCESS}>
        <StickyCardNavigation onPrevious={onPrevious} showExitToRentalSummary={false} isReviewPage={true} />
        <ReviewScrollContainer data-testid='reviewPage' title={t('review.overView')}>
          <WhenAndWhereSection />
          <VehicleSection />
          <EhiDivider />
          <PoliciesSection />
          <EhiDivider />
          <EstimatedCharges />
        </ReviewScrollContainer>
      </ReviewFlexFlowCard>
    </ReviewPage>
  );
};
