import { styled } from '@mui/material/styles';
import { EhiButton, ehiTheme } from '@ehi/ui';
import { Box, ButtonGroup } from '@mui/material';
import { FlexFlowCard, FlexiFlowCardInnerContainer } from 'components/shared/ui/card/Card';
import Grid from '@mui/material/Grid';

export const StyledEhiButton = styled(EhiButton)`
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
  padding-left: ${ehiTheme.spacing(1)};
  padding-right: ${ehiTheme.spacing(1)};
`;

export const StyledButtonGroup = styled(ButtonGroup)`
  padding: ${ehiTheme.spacing(0)};

  & .MuiButtonGroup-grouped {
    margin: ${ehiTheme.spacing(0)};
  }
`;

export const ContentContainer = styled('div')`
  display: flex;
  flex-direction: column;
  overflow: hidden;
`;

export const StyledDialogContent = styled('div')({
  display: 'flex',
  flexDirection: 'column',
  gap: ehiTheme.spacing(3),
});

export const DriverPage = styled(Box)({
  height: '0px',
  width: '100%',
  flexGrow: '1',
});

export const DriverFlexFlowCard = styled(FlexFlowCard)({
  height: 'auto',
  maxHeight: '100%',
  display: 'flex',
  flexDirection: 'column',
});

export const DriverScrollContainer = styled(Box)({
  flexGrow: 1,
  height: 'auto',
  overflowY: 'scroll',
  padding: ehiTheme.spacing(3),
});

export const DriverContent = styled(Box)({
  display: 'flex',
  flexDirection: 'column',
  gap: ehiTheme.spacing(3),
});

export const PrimaryDriverContainer = styled(FlexiFlowCardInnerContainer)({
  margin: ehiTheme.spacing(0),
  padding: ehiTheme.spacing(3),
  width: '100%',
  boxSizing: 'border-box',
});

export const AdditionalDriverContainer = styled(FlexiFlowCardInnerContainer)({
  margin: ehiTheme.spacing(0),
  padding: ehiTheme.spacing(3),
  width: '100%',
  boxSizing: 'border-box',
});

export const CardActionsContainer = styled(FlexiFlowCardInnerContainer)({
  border: 'none',
  borderRadius: ehiTheme.spacing(0),
  padding: ehiTheme.spacing(0),
  margin: ehiTheme.spacing(0),
  width: '100%',
  boxSizing: 'border-box',
  display: 'flex',
  justifyContent: 'flex-end',
});

export const DriverPageButton = styled(StyledEhiButton)({
  margin: ehiTheme.spacing(0),
});

const gridItemProps = {
  item: true,
  sm: true,
  xs: 12,
};

export const DriverGridItem = styled(Grid)(({ theme }) => ({
  '&&': {
    padding: theme.spacing(0),
  },
}));
DriverGridItem.defaultProps = gridItemProps;
