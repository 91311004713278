import { useCallback } from 'react';
import { ReferenceInformationFields } from 'components/flexFlow/rateAndBilling/editDialogs/referenceInformation/EditReferenceInformationTypes';
import { validateIataNumber, retrieveBusinessAccount } from 'services/businessAccount/businessAccountService';
import { updatePointOfSale } from 'services/booking/bookingService';
import { INACTIVE } from 'utils/constants';
import { parseUrn } from 'utils/urnUtils';
import { loadEhiLocationCookie } from '@ehi/location';
import { useUpdateAndRefreshEditor } from 'hooks/bookingEditor/useUpdateAndRefreshEditor';
import { useAppSelector } from 'redux/hooks';
import { selectBookingEditorId, selectPointOfSale } from 'redux/selectors/bookingEditor';
import { useAlert } from 'components/shared/alert/AlertContext';
import { useTranslations } from 'components/shared/i18n';
import { getLocationHeaderFromUrl } from 'components/shared/preprocessor/ReservationSessionHelper';
import { useBranchInfoByPeopleSoftIdQuery } from 'services/location/locationQueries';

interface UseSaveReferenceInfoType {
  submitReferenceInfo: (
    iataNumber: string,
    setError: (field: ReferenceInformationFields, error: { message: string }) => void,
    onClose: () => void
  ) => Promise<void>;
}
export const useSaveReferenceInfo = (): UseSaveReferenceInfoType => {
  const { t } = useTranslations();
  const { updateAndRefresh } = useUpdateAndRefreshEditor();
  const { showAlert } = useAlert();
  const pointOfSale = useAppSelector(selectPointOfSale);
  const bookingEditorId = useAppSelector(selectBookingEditorId);
  const cookie = loadEhiLocationCookie();
  const { data: branchV2 } = useBranchInfoByPeopleSoftIdQuery(cookie?.peoplesoftId ?? '');
  const submitReferenceInfo = useCallback(
    async (
      iataNumber: string,
      setError: (field: ReferenceInformationFields, error: { message: string }) => void,
      onClose: () => void
    ): Promise<void> => {
      const trimmedIata = iataNumber.trim();
      try {
        if (trimmedIata) {
          const requestData = await validateIataNumber(trimmedIata);
          const responseURL = requestData?.data?.headers?.responseURL ?? '';
          const accountNumber = responseURL && getLocationHeaderFromUrl(responseURL);

          if (!accountNumber) {
            setError(ReferenceInformationFields.IataNumber, {
              message: t('rateAndBilling.invalidIataArcClia'),
            });
            return;
          }

          const { status } = await retrieveBusinessAccount(accountNumber);

          if (parseUrn(status) === INACTIVE) {
            setError(ReferenceInformationFields.IataNumber, {
              message: t('rateAndBilling.invalidIataArcClia'),
            });
            return;
          }
        }

        const updatedPointOfSale = {
          ...pointOfSale,
          location: branchV2?.urn ?? '',
          ...(trimmedIata
            ? {
                bookingAgent: {
                  ...pointOfSale?.bookingAgent,
                  agencyIdentifier: trimmedIata,
                },
              }
            : { bookingAgent: {} }),
        };

        const { errors } = await updateAndRefresh(async () => {
          await updatePointOfSale(bookingEditorId, updatedPointOfSale);
        });

        if (errors) {
          await showAlert({ responseMessages: errors });
        } else {
          onClose();
        }
      } catch {
        setError(ReferenceInformationFields.IataNumber, {
          message: t('rateAndBilling.invalidIataArcClia'),
        });
      }
    },
    [pointOfSale, branchV2?.urn, updateAndRefresh, t, bookingEditorId, showAlert]
  );

  return { submitReferenceInfo };
};
