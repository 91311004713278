import { Address, ContactInformation, ContactPoint, Phone } from 'services/businessAccount/businessAccountTypes';
import { EHI_DOMAINS, parseUrn, parseUrnItem } from 'utils/urnUtils';
import { formatPhoneNumber } from 'utils/phoneNumberUtils';
import { NegotiatedRateSource, RateSource } from 'services/booking/bookingTypes';
import { BillingAccountErrorCodes } from 'utils/errorUtils';
import { AccountDetailsType } from 'components/flexFlow/rateAndBilling/RateAndBillingTypes';
import { Option } from 'components/shared/ui/OptionTypes';
import { Brand, EMPTY_VALUE } from './constants';
import {
  RateSourceFields,
  RateSourceValues,
} from 'components/flexFlow/rateAndBilling/editDialogs/rateSource/EditRateSourceDialogTypes';

export const getAddressLines = (address: Address | undefined): string => {
  const addressLines = [];
  address?.line1 && addressLines.push(address.line1);
  address?.line2 && addressLines.push(address.line2);
  address?.line3 && addressLines.push(address.line3);
  address?.line4 && addressLines.push(address.line4);
  return addressLines.join(' ');
};

export const getPhoneNumber = (contact?: ContactInformation): string => {
  const phoneInfo = contact?.contactPoints?.find(
    (contact: ContactPoint) => contact.type === 'PHONE' && contact.priority === 1
  ) as Phone | undefined;
  return formatPhoneNumber(phoneInfo?.phoneNumber);
};

export const isNegotiatedRateSource = (rateSource?: RateSource): boolean => {
  return !!rateSource && (rateSource as NegotiatedRateSource).account !== undefined;
};

export const parseRateSourceAccountNumber = (rateSource?: RateSource): string => {
  return isNegotiatedRateSource(rateSource) ? parseUrn((rateSource as NegotiatedRateSource).account) : '';
};

export const parseRateProduct = (rateSource?: RateSource): string => {
  return parseUrn(rateSource?.rateProduct);
};

export const parseBillingAccountNumber = (billingAccountUrn?: string): string => {
  return billingAccountUrn ? parseUrnItem(billingAccountUrn, EHI_DOMAINS.businessAccount.account) : '';
};

/**
 * Error codes APE00197, APE00027, and APE00185 all indicate that the Billing Account Exists is “True” and BNR flag is ‘Yes’.
 * */
export const hasBillingNumberRequiredErrorCodes = (billingAccountErrors: string[] | undefined): boolean => {
  const requiredBillingErrorCodes = [
    BillingAccountErrorCodes.BillingNumberIsRequired,
    BillingAccountErrorCodes.BillingNumberAlwaysRequired,
    BillingAccountErrorCodes.MoreThanOneBillingAccountNumber,
  ];
  return requiredBillingErrorCodes.some((code) => billingAccountErrors?.includes(code));
};

export const hasRatePlans = (ratePlansOptions: Option[], brand?: string): boolean => {
  return !!(brand && parseUrn(brand) === Brand.ENTERPRISE && ratePlansOptions?.length > 0);
};

export const showAccountDetails = (
  ratePlansOptions: Option[],
  accountDetails?: AccountDetailsType,
  brand?: string,
  isOriginalAccount?: boolean
): boolean => {
  return !!(accountDetails?.accountNumber && (isOriginalAccount || hasRatePlans(ratePlansOptions, brand)));
};

export const showRateProduct = (
  isOriginalAccount: boolean,
  brand?: string,
  ratePlanOptionsLength?: number
): boolean => {
  return !!(isOriginalAccount && brand && ratePlanOptionsLength === 0);
};

export const rateSourceAccountNumberSearchInitialValues = (
  rateProduct?: string,
  editorAccountNumber?: string,
  ratePlan?: string,
  selectedAccount?: string
): Partial<RateSourceValues> => {
  const accountNumber = editorAccountNumber ? editorAccountNumber : selectedAccount;

  return {
    [RateSourceFields.AccountNumber]: accountNumber ?? EMPTY_VALUE,
    [RateSourceFields.PreviousAccountNumber]: accountNumber ?? EMPTY_VALUE,
    [RateSourceFields.RateProduct]: rateProduct ?? EMPTY_VALUE,
    [RateSourceFields.RatePlan]: ratePlan ?? EMPTY_VALUE,
  };
};
