import { Box, Card, Typography } from '@mui/material';
import { styled } from '@mui/system';
import { ehiTheme, Subtitle1 } from '@ehi/ui';
import AirplanemodeActiveIcon from '@mui/icons-material/AirplanemodeActive';
import StoreIcon from '@mui/icons-material/Store';
import { Caption2 } from 'components/shared/ui/styles/Typography.styles';
import { disabledText, secondaryText } from 'components/shared/ui/styles/Global.styles';

export const BranchListItem = styled('div')`
  padding: ${ehiTheme.spacing(1, 0)};
`;

export const CardContent = styled(Card, {
  shouldForwardProp: (prop) => prop !== 'selected' && prop !== 'isDisabled',
})<{ selected: boolean; isDisabled?: boolean }>(({ selected, isDisabled }) => {
  const styles: any = {
    display: 'flex',
    padding: ehiTheme.spacing(2),
    height: 'auto',
    backgroundColor: 'white',
    cursor: 'pointer',
  };

  if (selected) {
    styles.backgroundColor = '#3D6A99';
  }

  if (isDisabled) {
    styles.filter = 'grayscale(100%)';
    styles.height = '130px';
    styles.opacity = 0.5;
    styles.cursor = 'unset';
  }

  return styles;
});
export const CardImageContainer = styled(Box)({
  position: 'relative',
  width: '50px',
  height: '50px',
  flexShrink: 0,
});

export const CardIndexBox = styled(Box)({
  position: 'absolute',
  top: '30%',
  left: '40%',
  transform: 'translate(-50%, -50%)',
  backgroundColor: 'none',
  borderRadius: '50%',
  width: '24px',
  height: '24px',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
});

export const CardIndexTypography = styled(Typography)<{ selected: boolean; isDisabled?: boolean }>(
  ({ selected, isDisabled }) => {
    let color = 'white';

    if (isDisabled) {
      color = '#a0a0a0';
    } else if (selected) {
      color = '#4d789d';
    }

    return {
      fontWeight: 700,
      textAlign: 'center',
      color,
    };
  }
);

export const CardInfoContainer = styled(Box)({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'space-between',
});

export const CardInfoBox = styled(Box)({
  flex: 1,
  display: 'flex',
  flexDirection: 'column',
});

export const CardStationInfo = styled(Box)(() => ({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  height: '100%',
  gap: '5px',
}));

export const CardDescription = styled(Subtitle1)<{ selected: boolean; isDisabled?: boolean }>(
  ({ selected, isDisabled }) => {
    let color = '#4d789d';

    if (isDisabled) {
      color = disabledText;
    } else if (selected) {
      color = 'white';
    }

    return {
      color,
    };
  }
);

export const CardInfoItem = styled(Caption2)<{ selected: boolean; isDisabled?: boolean }>(
  ({ selected, isDisabled }) => {
    let color = secondaryText;

    if (isDisabled) {
      color = disabledText;
    } else if (selected) {
      color = 'white';
    }

    return {
      color,
    };
  }
);

export const CardStationText = styled(CardInfoItem)(() => ({
  textAlign: 'left',
}));

export const StyledAirplaneIcon = styled(AirplanemodeActiveIcon)<{ selected: boolean; isDisabled?: boolean }>(
  ({ selected, isDisabled }) => {
    let color = '#0000008A';

    if (isDisabled) {
      color = disabledText;
    } else if (selected) {
      color = 'white';
    }

    return {
      color,
    };
  }
);

export const StyledStoreIcon = styled(StoreIcon)<{ selected: boolean; isDisabled?: boolean }>(
  ({ selected, isDisabled }) => {
    let color = '#0000008A';

    if (isDisabled) {
      color = disabledText;
    } else if (selected) {
      color = 'white';
    }

    return {
      color,
    };
  }
);

export const CardBottomLeftBox = styled(Box)({
  position: 'absolute',
  bottom: -10,
  left: '16px',
  right: '16px',
  padding: '8px',
  backgroundColor: 'transparent',
  zIndex: 1,
  marginTop: '10px',
  borderTop: '1px solid rgba(0, 0, 0, 0.38)',
});

export const DisabledInfoContainer = styled(Box)({
  display: 'flex',
  alignItems: 'center',
  gap: ehiTheme.spacing(0.5),
  marginBottom: '10px',
});

export const DisabledText = styled('span')`
  color: #999;
  font-size: 14px;
  font-weight: bold;
export const StyledStoreIcon = styled(StoreIcon)<{ selected: boolean }>((props) => ({
  color: props.selected ? 'white' : '#0000008A',
}))`;

export const BranchMapListItem = styled('div')`
  padding: ${ehiTheme.spacing(1, 0)};
`;
