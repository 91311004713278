import { Box, Paper, styled } from '@mui/material';

export const ResSummaryDrawerHeader = styled(Box)`
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin: 10px 20px;
`;

export const SummaryContent = styled(Paper)`
  flex-grow: 0;
  display: flex;
  flex-direction: column;
  border-radius: 0;
`;

export const DrawerContainer = styled('div')`
  display: flex;
  flex-direction: column;
  width: 360px;
  flex-grow: 1;
  height: 0;
  overflow-y: auto;
`;
