import { FC, useCallback, useState } from 'react';
import { Dialog } from 'components/shared/ui/dialogs/Dialog';
import { useTranslations } from 'components/shared/i18n';
import { FormControlLabel, Radio, RadioGroup } from '@mui/material';
import { ehiTheme } from '@ehi/ui';
import { useMediaQuery } from '@mui/system';
import {
  RateSourceFields,
  SearchByType,
} from 'components/flexFlow/rateAndBilling/editDialogs/rateSource/EditRateSourceDialogTypes';
import { RateSourceAccountNumberSearch } from 'components/flexFlow/rateAndBilling/editDialogs/rateSource/RateSourceAccountNumberSearch';
import { AccountSearch } from 'components/flexFlow/rateAndBilling/editDialogs/AccountSearch';
import { useRateSource } from 'components/flexFlow/rateAndBilling/editDialogs/rateSource/useRateSource';
import { useAccountSearchContext } from 'context/accountSearch/AccountSearchContext';
import { useAppSelector } from 'redux/hooks';
import { selectEnterpriseBrandAndHasDateTime } from 'redux/selectors/bookingEditor';
import { retrieveBusinessAccount } from 'services/businessAccount/businessAccountService';
import { parseBillingAccountNumber } from 'utils/rateAndBillingUtils';

type RateSourceDialogProps = {
  closeModal: () => void;
};

export const EditRateSourceDialog: FC<RateSourceDialogProps> = ({ closeModal }) => {
  const { t } = useTranslations();
  const [searchBy, setSearchBy] = useState<SearchByType>(SearchByType.AccountNumber);
  const isEnterpriseBrandAndHasDateTime = useAppSelector(selectEnterpriseBrandAndHasDateTime);

  const isMobileOrTablet = useMediaQuery('(max-width:800px)');
  const { addOrModifyRateSource } = useRateSource();
  const { setIsAccountNumberFieldHidden, isAccountNumberFieldHidden, setSelectedAccountUrn } =
    useAccountSearchContext();

  const handleClosingModal = useCallback(() => {
    setSearchBy(SearchByType.AccountNumber);
    closeModal();
  }, [closeModal]);

  const handleSelect = useCallback(
    async (accountUrn: string) => {
      if (isEnterpriseBrandAndHasDateTime) {
        setIsAccountNumberFieldHidden(true);
        setSelectedAccountUrn(accountUrn);
      } else {
        const { carrierSafetyIdentifiers } = await retrieveBusinessAccount(parseBillingAccountNumber(accountUrn));
        addOrModifyRateSource({
          accountNumber: accountUrn,
          handleCloseModal: handleClosingModal,
          carrierSafetyNumbers: carrierSafetyIdentifiers ?? [],
        });
      }
    },
    [
      setIsAccountNumberFieldHidden,
      setSelectedAccountUrn,
      addOrModifyRateSource,
      handleClosingModal,
      isEnterpriseBrandAndHasDateTime,
    ]
  );

  const onBackClicked = useCallback(() => {
    setIsAccountNumberFieldHidden(false);
    setSelectedAccountUrn(undefined);
  }, [setIsAccountNumberFieldHidden, setSelectedAccountUrn]);

  return (
    <Dialog
      PaperProps={{
        sx: {
          height: '94%',
          width: isMobileOrTablet ? '94%' : '52%',
        },
      }}
      data-testid='rateSourceDialog'
      id='rateSourceDialog'
      contentPadding={0}
      open={true}
      title={t('rateAndBilling.rateSource')}
      a11yKey='content'
      fullScreen
      showDividers
      actions={{
        primaryAction: {
          label: t('common.cancel'),
          onClick: handleClosingModal,
        },
      }}>
      {!isAccountNumberFieldHidden && (
        <RadioGroup row style={{ padding: ehiTheme.spacing(2) }}>
          <FormControlLabel
            name={RateSourceFields.SearchBy}
            checked={searchBy === SearchByType.AccountNumber}
            control={<Radio color='primary' />}
            label={t('rateAndBilling.accountNumber')}
            onClick={(): void => setSearchBy(SearchByType.AccountNumber)}
          />
          <FormControlLabel
            name={RateSourceFields.SearchBy}
            checked={searchBy === SearchByType.AccountSearch}
            control={<Radio color='primary' />}
            label={t('rateAndBilling.accountSearch')}
            onClick={(): void => setSearchBy(SearchByType.AccountSearch)}
          />
        </RadioGroup>
      )}
      {searchBy === SearchByType.AccountNumber || isAccountNumberFieldHidden ? (
        <RateSourceAccountNumberSearch handleCloseModal={handleClosingModal} onBackClicked={onBackClicked} />
      ) : (
        <AccountSearch handleSelect={handleSelect} />
      )}
    </Dialog>
  );
};
