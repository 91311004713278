import { FC, useMemo } from 'react';
import { Outlet, useLocation } from 'react-router-dom';
import { ReservationPageSpacingWrapper } from 'components/shared/routeWrapper/ReservationPageSpacingWrapper';
import CustomerHeader from 'components/customerHeader/CustomerHeader';
import { useAppSelector } from 'redux/hooks';
import { selectReservationData } from 'redux/selectors/bookingEditor';
import { useReservationRouteWrapper } from 'components/shared/routeWrapper/useReservationRouteWrapper';
import { LoadableView } from 'components/shared/ui/spinner/loadableView/LoadableView';
import { useEffectWhen } from 'hooks/useEffectWhen';
import NetworkError from 'components/shared/errors/NetworkError';
import { mapLoadingState } from 'components/shared/ui/spinner/loadableView/LoadableViewUtils';
import { FlexFlowLoadingSkeleton } from 'components/shared/ui/skeletons/FlexFlowLoadingSkeleton';
import { logError } from 'components/shared/logger/splunkLogger';
import { ErrorSeverity } from '@ehi/analytics';

const ReservationRouteWrapper: FC = () => {
  const hasReservation = useAppSelector(selectReservationData);
  const { serviceState, retrieveSessionForView, retrieveSessionForModify } = useReservationRouteWrapper();
  const { pathname } = useLocation();

  const loadingState = useMemo(() => {
    return mapLoadingState(!hasReservation, !!serviceState?.errors);
  }, [serviceState.errors, hasReservation]);

  useEffectWhen(() => {
    if (pathname.includes('view')) {
      retrieveSessionForView();
    } else {
      retrieveSessionForModify();
    }
  }, !hasReservation);

  useEffectWhen(() => {
    logError({
      error: {
        message: 'Error Refreshing Session',
        supportInformation: {
          currentPath: pathname,
          serviceError: serviceState?.errors,
        },
      },
      severity: ErrorSeverity.Fatal,
    });
  }, !!serviceState.errors);

  return (
    <LoadableView loadingComponent={<FlexFlowLoadingSkeleton />} errorComponent={<NetworkError />} state={loadingState}>
      <CustomerHeader />
      <ReservationPageSpacingWrapper>
        <Outlet />
      </ReservationPageSpacingWrapper>
    </LoadableView>
  );
};

export default ReservationRouteWrapper;
