import { FC } from 'react';
import { AppTheme } from 'components/theme/AppTheme';
import { QueryClientProviderWrapper } from 'context/queryClient/QueryClientProviderWrapper';
import { ErrorBoundary } from 'react-error-boundary';
import ErrorWrapper from 'components/shared/errors/ErrorWrapper';
import { handleError } from 'utils/errorUtils';
import { AppRouterProvider } from 'app/router/AppRouterProvider';
import 'utils/commonValidationUtils';

const App: FC = () => {
  return (
    <ErrorBoundary FallbackComponent={ErrorWrapper} onError={handleError}>
      <QueryClientProviderWrapper>
        <AppTheme>
          {/* Everything above here does not need routing data or authentication */}
          <AppRouterProvider />
        </AppTheme>
      </QueryClientProviderWrapper>
    </ErrorBoundary>
  );
};
export default App;
