import {
  EmeraldClubLoyaltyMembership,
  EnterprisePlusLoyaltyMembership,
  SearchResult,
} from 'services/renter/driverProfile/driverProfileTypes';
import { Brand, LoyaltyProgram } from 'utils/constants';
import { paymentTypes } from 'utils/paymentUtils';
import { EHI_DOMAINS, generateReferenceUrn } from 'utils/urnUtils';

export const addAdditionalDriverError = 'add additional driver error';

export const LOYALTY = 'loyalty';
export const CURRENT = 'current';
export const PRIMARY_DRIVER = 'primaryDriver';
export const RATE_SOURCE = 'rateSource';

export const prioritizeByBrand = (profiles: SearchResult[], rentalBrand: string | undefined): SearchResult[] => {
  const loyaltyProfiles: SearchResult[] = [];
  const nonLoyaltyProfiles: SearchResult[] = [];

  profiles.forEach((profile) => {
    const loyaltyType = profile.loyaltyMembership?.type;

    if (loyaltyType) {
      if (
        (rentalBrand === Brand.ENTERPRISE &&
          (loyaltyType === LoyaltyProgram.ENTERPRISE_PLUS || loyaltyType === LoyaltyProgram.EMERALD_CLUB)) ||
        (rentalBrand === Brand.NATIONAL && loyaltyType === LoyaltyProgram.EMERALD_CLUB) ||
        (rentalBrand === Brand.ALAMO && loyaltyType === LoyaltyProgram.ALAMO_INSIDERS) ||
        !rentalBrand
      ) {
        loyaltyProfiles.push(profile);
      }
    } else {
      nonLoyaltyProfiles.push(profile);
    }
  });

  return [...loyaltyProfiles, ...nonLoyaltyProfiles];
};

export const addPaymentMethodByLoyaltyMembership = (
  existingPaymentMethod: string,
  defaultEhiDatabase: string,
  loyaltyMembershipData?: EmeraldClubLoyaltyMembership | EnterprisePlusLoyaltyMembership
): { paymentMethod: string; cardProvided: boolean } | undefined => {
  const isDriverHasCreditCardPayment = (): boolean | undefined =>
    loyaltyMembershipData?.paymentMethods &&
    !!loyaltyMembershipData?.paymentMethods?.find((method) => {
      return method.type === paymentTypes.Credit_Card;
    });

  if (existingPaymentMethod || !isDriverHasCreditCardPayment()) {
    return;
  } else {
    return {
      paymentMethod: generateReferenceUrn(
        EHI_DOMAINS.rental.name,
        EHI_DOMAINS.rental.booking + ':' + EHI_DOMAINS.rental.person + ':' + EHI_DOMAINS.rental.paymentMethod,
        paymentTypes.Credit_Card,
        defaultEhiDatabase
      ),
      cardProvided: false,
    };
  }
};
