import { FC, useMemo } from 'react';
import CustomerHeader from 'components/customerHeader/CustomerHeader';
import { ReservationPageSpacingWrapper } from 'components/shared/routeWrapper/ReservationPageSpacingWrapper';
import { Outlet } from 'react-router-dom';
import { useAppSelector } from 'redux/hooks';
import { selectBookingEditorId } from 'redux/selectors/bookingEditor';
import { useReservationRouteWrapper } from 'components/shared/routeWrapper/useReservationRouteWrapper';
import { mapLoadingState } from 'components/shared/ui/spinner/loadableView/LoadableViewUtils';
import { useEffectWhen } from 'hooks/useEffectWhen';
import { FullScreenSpinner } from 'components/shared/ui/spinner/FullScreenSpinner';
import NetworkError from 'components/shared/errors/NetworkError';
import { LoadableView } from 'components/shared/ui/spinner/loadableView/LoadableView';

export const CreateRouteWrapper: FC = () => {
  const editorId = useAppSelector(selectBookingEditorId);
  const { serviceState, retrieveSessionForCreate } = useReservationRouteWrapper();

  const loadingState = useMemo(() => {
    return mapLoadingState(!editorId, !!serviceState?.errors);
  }, [serviceState?.errors, editorId]);

  useEffectWhen(() => {
    retrieveSessionForCreate();
  }, !editorId);

  return (
    <LoadableView loadingComponent={<FullScreenSpinner />} errorComponent={<NetworkError />} state={loadingState}>
      <CustomerHeader />
      <ReservationPageSpacingWrapper>
        <Outlet />
      </ReservationPageSpacingWrapper>
    </LoadableView>
  );
};
