import { getDefaultAxiosInstance } from 'components/shared/axios/axiosConfiguration';
import {
  AncillaryType,
  CancelReasons,
  EquipmentType,
  ProtectionType,
  ReservationLifeCycleTypes,
  ReservationStatuses,
} from './rentalReferenceTypes';

const RENTAL_REFERENCE_ENDPOINT = '/reference/rental';
const RESERVATION_LIFECYCLE_TYPE_ENDPOINT = '/booking/reservation/lifecycle';
const RES_STATUS_ENDPOINT = '/booking/reservation/status';

export const retrieveAllEquipmentTypes = async (): Promise<EquipmentType[]> => {
  const axiosInstance = getDefaultAxiosInstance();
  const response = await axiosInstance.get(`${RENTAL_REFERENCE_ENDPOINT}/equipmentType`, {
    genericUrl: `${RENTAL_REFERENCE_ENDPOINT}/equipmentType`,
  });
  return response.data;
};

export const retrieveAllProtectionTypes = async (countryCode: string): Promise<ProtectionType[]> => {
  const axiosInstance = getDefaultAxiosInstance();
  const response = await axiosInstance.get(`${RENTAL_REFERENCE_ENDPOINT}/protectionType?saleCountry=${countryCode}`, {
    genericUrl: `${RENTAL_REFERENCE_ENDPOINT}/protectionType?saleCountry={countryCode}`,
  });
  return response.data;
};

export const retrieveAllAncillaryFeeTypes = async (): Promise<AncillaryType[]> => {
  const axiosInstance = getDefaultAxiosInstance();
  const response = await axiosInstance.get(`${RENTAL_REFERENCE_ENDPOINT}/ancillaryFeeType`, {
    genericUrl: `${RENTAL_REFERENCE_ENDPOINT}/ancillaryFeeType`,
  });
  return response.data;
};

export const retrieveReservationLifeCycleTypes = async (): Promise<ReservationLifeCycleTypes[]> => {
  const axiosInstance = getDefaultAxiosInstance();
  const response = await axiosInstance.get(`${RENTAL_REFERENCE_ENDPOINT}${RESERVATION_LIFECYCLE_TYPE_ENDPOINT}`, {
    genericUrl: `${RENTAL_REFERENCE_ENDPOINT}${RESERVATION_LIFECYCLE_TYPE_ENDPOINT}`,
  });
  return response.data;
};

export const retrieveReservationStatuses = async (): Promise<ReservationStatuses[]> => {
  const axiosInstance = getDefaultAxiosInstance();
  const response = await axiosInstance.get(`${RENTAL_REFERENCE_ENDPOINT}${RES_STATUS_ENDPOINT}`, {
    genericUrl: `${RENTAL_REFERENCE_ENDPOINT}${RES_STATUS_ENDPOINT}`,
  });
  return response.data;
};

export const retrieveAllCancelReasons = async (): Promise<CancelReasons[]> => {
  const axiosInstance = getDefaultAxiosInstance();
  const response = await axiosInstance.get(`${RENTAL_REFERENCE_ENDPOINT}/booking/reservation/cancellationReason`, {
    genericUrl: `${RENTAL_REFERENCE_ENDPOINT}/booking/reservation/cancellationReason`,
  });
  return response.data;
};
