import { useTranslations } from 'components/shared/i18n';
import { VehicleCard } from 'components/flexFlow/vehicle/VehicleCard';
import { RouterPaths, toSibling } from 'app/router/RouterPaths';
import { Context, FC, forwardRef, ReactElement, useEffect, useMemo, useState } from 'react';
import { filterVehicleList } from 'components/flexFlow/vehicle/vehicleSearch/vehicleSearchUtils';
import { useWatch } from 'react-hook-form';
import { VehicleSearchFields, VehicleSectionProps } from 'components/flexFlow/vehicle/VehicleTypes';
import { NoResultsView } from 'components/shared/ui/noResultsView/NoResultsView';
import { ListProps, Virtuoso } from 'react-virtuoso';
import { useAppSelector } from 'redux/hooks';
import { selectHasLocationData } from 'redux/selectors/bookingEditor';
import { useNavigate } from 'react-router';
import { VehicleListHeader, VehicleListItem } from 'components/flexFlow/vehicle/VehiclePage.styles';
import { ehiTheme } from '@ehi/ui';
import { Box } from '@mui/material';
import { Trans } from 'react-i18next';
import { StyledLink } from 'components/shared/ui/noResultsView/NoResultsViewStyles';

export const VehicleList: FC<VehicleSectionProps> = ({ vehicles }) => {
  const { t } = useTranslations();
  const navigate = useNavigate();
  const hasLocationData = useAppSelector(selectHasLocationData);
  const [scrollParent, setScrollParent] = useState<HTMLElement | undefined>(undefined);

  useEffect(() => {
    // This sets scroll parent for Virtuoso, so it can use window scrolling
    setScrollParent((window.document.getElementById('vehicle-page-content') as HTMLElement) ?? undefined);
  }, []);

  const [carClass, vehicleType, fuelType, passengerNumber] = useWatch({
    name: [
      VehicleSearchFields.VehicleClass,
      VehicleSearchFields.VehicleType,
      VehicleSearchFields.FuelType,
      VehicleSearchFields.PassengerNumber,
    ],
  });

  const vehicleList = useMemo(() => {
    if (vehicles?.length && (carClass || vehicleType || fuelType || passengerNumber)) {
      return filterVehicleList(vehicles, carClass, vehicleType, fuelType, passengerNumber);
    } else {
      return vehicles;
    }
  }, [vehicles, carClass, vehicleType, fuelType, passengerNumber]);

  function VehicleCountHeader(): ReactElement {
    return (
      <VehicleListHeader>
        {`${t('common.showing')} ${vehicleList?.length ?? 0} ${t('common.results')}`}
      </VehicleListHeader>
    );
  }
  const VehicleListWrapper = forwardRef<HTMLDivElement, ListProps & { context?: Context<unknown> }>((props, ref) => {
    return (
      <Box
        {...props}
        ref={ref}
        sx={{ display: 'flex', flexDirection: 'column', gap: ehiTheme.spacing(1.5), justifyContent: 'center' }}>
        {props.children}
      </Box>
    );
  });

  return (
    <Box padding={ehiTheme.spacing(3)} style={{ backgroundColor: ehiTheme.palette.background.default }}>
      {hasLocationData && vehicleList.length > 0 ? (
        <Virtuoso
          useWindowScroll
          customScrollParent={scrollParent}
          totalCount={vehicleList.length}
          components={{ Header: VehicleCountHeader, Item: VehicleListItem, List: VehicleListWrapper }}
          increaseViewportBy={150}
          itemContent={(index: number): ReactElement => {
            return <VehicleCard vehicle={vehicleList[index]} />;
          }}
        />
      ) : (
        <NoResultsView
          noResultsTitle='search.noResults'
          noResultsDescription={
            !hasLocationData ? (
              <Trans
                i18nKey='vehicle.noResultsDescription'
                components={{
                  Link: (
                    <StyledLink
                      data-testid={'navigationLink'}
                      onClick={
                        !hasLocationData
                          ? (): void => {
                              navigate(...toSibling(RouterPaths.WhenAndWhere));
                            }
                          : undefined
                      }
                    />
                  ),
                }}
              />
            ) : undefined
          }
        />
      )}
    </Box>
  );
};
