import Grid, { GridProps } from '@mui/material/Grid';
import { styled } from '@mui/material';
import { StyledComponent } from '@emotion/styled';

export const GridContainer: StyledComponent<Omit<GridProps, 'xs' | ' sm' | 'md' | 'lg' | 'xl' | 'item'>> = styled(Grid)(
  {
    margin: 0,
    padding: 0,
    width: '100%',
    '.MuiFormControl-root': {
      width: '100%',
    },
  }
);
GridContainer.defaultProps = {
  container: true,
  spacing: 2,
};

export const GridVerticalContainer = styled(Grid)({
  display: 'flex',
  flexDirection: 'column',
  margin: 0,
  padding: 0,
  width: '100%',
  '.MuiFormControl-root': {
    width: '100%',
  },
});
GridVerticalContainer.defaultProps = {
  container: true,
};

export const GridHorizontalContainer = styled(Grid)({
  display: 'flex',
  flexDirection: 'row',
  margin: 0,
  padding: 0,
  width: '100%',
  '.MuiFormControl-root': {
    width: '100%',
  },
});
GridHorizontalContainer.defaultProps = {
  container: true,
};

const gridItemProps = {
  item: true,
  xs: 12,
  sm: 4,
};

export const GridItem = styled(Grid)(({ theme }) => ({
  '&&': {
    padding: theme.spacing(1),
  },
}));
GridItem.defaultProps = gridItemProps;
