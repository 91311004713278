import { TFunction } from 'i18next';
import * as Yup from 'yup';
import { WhenAndWhereFields } from 'components/flexFlow/whenAndWhere/WhenAndWhereTypes';
import { combineDateAndTime, compareDateTime } from 'utils/dateUtils';
import { DateTime } from 'luxon';

export const whenAndWhereValidationSchema = (
  t: TFunction<'translation'>,
  startLocationCurrentTime: DateTime | string
) =>
  Yup.object().shape({
    startDate: Yup.date()
      .nullable()
      .transform((curr, orig) => (orig === '' ? null : curr))
      .typeError(t('validation.invalidDateFormat'))
      .min(DateTime.now().startOf('day').toJSDate(), t('validation.startDateCurrentOrFutureDate'))
      .test(WhenAndWhereFields.StartDate, t('validation.startDateRequired'), function (value, context) {
        const { startTime, returnDate, returnTime } = context.parent;
        return !(!value && (startTime || returnDate || returnTime));
      }),
    startTime: Yup.mixed<DateTime>()
      .nullable()
      .transform((curr, orig) => (orig === '' ? null : curr))
      .typeError(t('validation.invalidTimeFormat'))
      .when('startDate', {
        is: (startDate: any) => !!startDate,
        then: (schema: any) => schema.required(t('validation.requiredField')),
      })
      .test(WhenAndWhereFields.StartTime, t('validation.futureLocationTime'), function (value, context) {
        const { startDate } = context.parent;
        if (value && startDate && startLocationCurrentTime) {
          const startDateTime = combineDateAndTime(DateTime.fromJSDate(startDate), value, value?.zone?.name);
          return compareDateTime(startDateTime, startLocationCurrentTime);
        } else {
          return !!startLocationCurrentTime;
        }
      }),

    returnDate: Yup.date()
      .nullable()
      .transform((curr, orig) => (orig === '' ? null : curr))
      .typeError(t('validation.invalidDateFormat'))
      .min(DateTime.now().startOf('day').toJSDate(), t('validation.returnDateCurrentOrFutureDate'))
      .test(WhenAndWhereFields.ReturnDate, t('validation.returnDateRequired'), function (value, context) {
        const { returnTime } = context.parent;
        return !(!value && returnTime);
      }),
    returnTime: Yup.mixed<DateTime>()
      .nullable()
      .transform((curr, orig) => (orig === '' ? null : curr))
      .typeError(t('validation.invalidTimeFormat'))
      .when('returnDate', {
        is: (returnDate: any) => !!returnDate,
        then: (schema: any) => schema.required(t('validation.requiredField')),
      }),
  });
