import { FC, useMemo, useState } from 'react';
import { Dialog } from 'components/shared/ui/dialogs/Dialog';
import { useYupValidationResolver } from 'components/shared/forms/useYupValidationResolver';
import { addNotesInitialValues, addNotesSchema } from 'components/notes/addNote/addNotesUtils';
import { AddNotesFields, AddNotesValues } from 'components/notes/addNote/AddNotesTypes';
import { FormProvider, useForm } from 'react-hook-form';

import { AddNotesTextBox } from 'components/shared/notes/NotesButton.styles';
import { ehiTheme } from '@ehi/ui';
import { useTranslations } from 'components/shared/i18n';
import { Box } from '@mui/material';
import { TextAreaField } from 'components/shared/forms/TextAreaField';
import { addNote } from 'services/booking/bookingService';
import { useUpdateAndRefreshEditor } from 'hooks/bookingEditor/useUpdateAndRefreshEditor';
import { useAppSelector } from 'redux/hooks';
import { selectBookingEditorId, selectNotes } from 'redux/selectors/bookingEditor';
import { Note } from 'services/booking/bookingTypes';
import { useAuthStore } from '@ehi/auth';
import { ProgressOverlay } from 'components/shared/ui/spinner/ProgressOverlay';
import { useAlert } from 'components/shared/alert/AlertContext';

interface AddNotesDialogProps {
  open: boolean;
  onClose: () => void;
}

export const AddNotesDialog: FC<AddNotesDialogProps> = ({ open, onClose }) => {
  const { t } = useTranslations();
  const resolver = useYupValidationResolver(addNotesSchema(t));
  const NOTES_MAX_CHARS = 500;
  const { updateAndRefresh } = useUpdateAndRefreshEditor();
  const bookingEditorId = useAppSelector(selectBookingEditorId);
  const { eid } = useAuthStore();
  const existingEditorNotes = useAppSelector(selectNotes);
  const { showAlert } = useAlert();
  const formInitialValues: AddNotesValues = useMemo(() => addNotesInitialValues(), []);
  const [loading, setLoading] = useState(false);

  const formMethods = useForm<AddNotesValues>({
    resolver: resolver,
    defaultValues: formInitialValues,
  });

  const notes = formMethods.watch(AddNotesFields.Notes);

  const handleSubmit = async (): Promise<void> => {
    setLoading(true);
    const noteRequestBody: Note[] = [];
    const newNote: Note = {
      content: notes,
    };
    noteRequestBody.push(newNote);
    // if we don't maintain the editor session list and send the full list we lose anything previously added.
    if (existingEditorNotes) {
      noteRequestBody.push(...existingEditorNotes);
    }
    const { errors } = await updateAndRefresh(() => addNote(bookingEditorId, noteRequestBody, eid));
    if (errors) {
      setLoading(false);
      await showAlert({ responseMessages: errors });
    } else {
      setLoading(false);
      onClose();
    }
  };

  return (
    <FormProvider {...formMethods}>
      <Dialog
        data-testid='addNoteDialog'
        contentPadding={0}
        open={open}
        a11yKey='content'
        title={t('notes.addNote')}
        maxWidth={'sm'}
        fullWidth
        actions={{
          secondaryAction: {
            label: t('common.cancel'),
            onClick: onClose,
          },
          primaryAction: {
            label: t('common.submit'),
            onClick: formMethods.handleSubmit(handleSubmit),
            overrideButtonProps: { disabled: notes === '' },
          },
        }}>
        <Box style={{ paddingTop: ehiTheme.spacing(0.5) }}>
          <AddNotesTextBox>
            <TextAreaField
              header={t('notes.addNote')}
              displayHeader={false}
              name={AddNotesFields.Notes}
              maxChars={NOTES_MAX_CHARS}
              rows={12}
              marginBottom={'0'}
            />
            <ProgressOverlay inProgress={loading} />
          </AddNotesTextBox>
        </Box>
      </Dialog>
    </FormProvider>
  );
};
