import { Box, Grid, styled } from '@mui/material';
import { Body1, Body2, Caption, EhiButton, ehiTheme } from '@ehi/ui';
import { Caption2 } from 'components/shared/ui/styles/Typography.styles';
import { FlexFlowCard, FlexiFlowCardInnerContainer } from 'components/shared/ui/card/Card';

export const RateAndBillingSubSectionBox = styled(Box)`
  display: flex;
  flex-direction: column;
  padding: ${ehiTheme.spacing(1.5, 3, 3, 3)};
  gap: 24px;
`;

export const RateAndBillingTitleBox = styled(Box)`
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

export const RateAndBillingLabel = styled(Caption2)`
  display: inline;
`;

export const RateAndBillingText = styled(Body2)`
  display: inline;
  font-weight: bold;
  padding-left: ${ehiTheme.spacing(1)};
`;

export const RateAndBillingInfoBox = styled(Box)`
  display: flex;
  width: 100%;
  background: #f5f5f5;
  padding: ${ehiTheme.spacing(2, 3, 2, 3)};
  margin-top: ${ehiTheme.spacing(3)};
`;

export const RateSourceLink = styled(Caption, {
  shouldForwardProp: (prop) => prop !== 'disabled',
})<{
  disabled?: boolean;
}>(({ disabled = false }) => {
  const color = disabled ? '#00000061' : '#4d789d';
  const cursor = disabled ? 'default' : 'pointer';
  return {
    display: 'flex',
    alignItems: 'center',
    cursor,
    color,
  };
});

export const RateAndBillingSearchContainer = styled(Grid)`
  display: flex;
  flex-direction: column;
  background: #f5f5f5;
  width: auto;
  padding: ${ehiTheme.spacing(2, 3)};
`;
RateAndBillingSearchContainer.defaultProps = {
  container: true,
};

export const RateAndBillingSearchLabel = styled(Body1)`
  padding-bottom: ${ehiTheme.spacing(2)};
`;

export const StyledFlexGrid = styled(Grid)`
  display: flex;
`;

StyledFlexGrid.defaultProps = {
  container: true,
};

export const AccountDetailsContainer = styled(Box)`
  display: flex;
  flex-direction: column;
  padding: ${ehiTheme.spacing(0, 3)};
`;

export const AccountListItem = styled('div')`
  padding: ${ehiTheme.spacing(1, 2)};
`;

export const StyledEhiEditButton = styled(EhiButton)`
  padding: ${ehiTheme.spacing(1.5, 1, 1.5, 1)};
  margin: 0;
  min-width: 0;
`;

export const StyledAccountsListBox = styled(Box, {
  shouldForwardProp: (prop) => prop !== 'containerHeight' && prop !== 'noResultsView',
})<{ containerHeight: number; noResultsView: boolean }>((props) =>
  props.noResultsView
    ? {
        backgroundColor: '#ffffff',
        paddingTop: ehiTheme.spacing(2),
        height: props.containerHeight,
        display: 'flex',
        justifyContent: 'center',
      }
    : {
        backgroundColor: '#ffffff',
        paddingTop: ehiTheme.spacing(2),
        height: props.containerHeight,
        position: 'relative',
      }
);

export const AdditionalInfoCaption = styled(Caption)`
  color: rgba(0, 0, 0, 0.54);
`;

export const RateAndBillingPage = styled(Box)({
  height: '0px',
  width: '100%',
  flexGrow: '1',
});

export const RateAndBillingFlexFlowCard = styled(FlexFlowCard)({
  height: 'auto',
  maxHeight: '100%',
  display: 'flex',
  flexDirection: 'column',
});

export const RateAndBillingFlexiFlowCardInnerContainer = styled(FlexiFlowCardInnerContainer)({
  padding: 0,
  margin: 0,
  border: 'none',
  boxShadow: 'none',
  height: 'auto',
  overflowY: 'scroll',
});

export const AdditionalInformationBox = styled(Box)`
  display: flex;
  flex-direction: column;
  background: ${ehiTheme.palette.background.default};
  padding: ${ehiTheme.spacing(0, 3)};
`;

export const CarrierSafetyTitleBox = styled(Box)`
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

export const CarrierSafetyContentBox = styled(Box)`
  display: flex;
  align-items: baseline;
  gap: ${ehiTheme.spacing(1)};
`;
