import { Box, MenuItem, styled } from '@mui/material';
import { Body2, EhiButton, ehiTheme, Subtitle1 } from '@ehi/ui';
import { FormTextField } from 'components/shared/forms/FormTextField';

export const ButtonContainer = styled(Box)`
  display: flex;
  justify-content: flex-end;
  padding: ${ehiTheme.spacing(0.5, 0)};
  border-top: 1px solid #ccc;
`;

export const EhiButtonReset = styled(EhiButton)(() => ({
  width: '40px',
  height: '36px',
  fontsize: '14px',
}));

export const HeaderContainer = styled(Box)`
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: ${ehiTheme.spacing(1.5, 2)};
`;

export const HeaderMenuItem = styled(MenuItem)`
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  padding: ${ehiTheme.spacing(1.5, 2)};
`;

export const HeaderTitle = styled(Subtitle1)`
  color: #000000de;
`;
HeaderTitle.defaultProps = {
  bold: true,
};

export const SubHeaderTitle = styled(Body2)`
  color: #0000008a;
  font-weight: bold;
`;

export const StyledFormTextField = styled(FormTextField)`
  width: 100%;
`;

const StickyContainer = styled(Box)`
  position: sticky;
  background-color: #ffffff;
  z-index: 100;
`;

export const HeaderSection = styled(StickyContainer)`
  position: sticky;
  top: 0;
`;

export const SubHeaderSection = styled(StickyContainer)`
  padding: ${ehiTheme.spacing(2)};
`;

export const FooterSection = styled(StickyContainer)`
  bottom: 0;
`;
