import { Box, Grid, IconButton, styled } from '@mui/material';
import { ehiTheme } from '@ehi/ui';
import InputAdornment from '@mui/material/InputAdornment';

export const StyledNotesButton = styled(IconButton)<{ background?: string }>(({ background = '' }) => {
  return {
    height: '40px',
    width: '40px',
    borderRadius: '50%',
    marginLeft: ehiTheme.spacing(1.5),
    '&:hover': {
      backgroundColor: background,
    },
  };
});

export const AddNotesTextBox = styled(Box)`
  margin: ${ehiTheme.spacing(0, 3)};
`;

export const StyledGridItem = styled(Grid)`
  display: flex;
  padding: ${ehiTheme.spacing(0.5, 0)};
  height: 100%;
`;
StyledGridItem.defaultProps = {
  item: true,
};

export const NotesInputAdornment = styled(InputAdornment)`
  margin-bottom: ${ehiTheme.spacing(31.5)};
  margin-right: ${ehiTheme.spacing(1.0)};
`;
