// Denotes the height of dialog footer and header along with the height of the account search section
import { Body2, EhiButton, ehiTheme } from '@ehi/ui';
import { useYupValidationResolver } from 'components/shared/forms/useYupValidationResolver';
import { useTranslations } from 'components/shared/i18n';
import { FC, ReactElement, useEffect, useMemo, useRef, useState } from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import { useLocationGroups, useLocationInfoQueries } from 'services/location/locationQueries';
import { DAILY_RENTAL, getGroupOptions } from 'utils/branchLookupUtils';
import { AccountSearchFields, AccountSearchValues } from './AccountSearchTypes';
import {
  accountSearchValidationSchema,
  getAccountSearchValuesInitialValues,
  getAccountTypeOptions,
} from './accountSearchUtils';
import { useBusinessAccountSearch } from './useBusinessAccountSearch';
import { Caption2 } from 'components/shared/ui/styles/Typography.styles';
import { useAccountSearchContext } from 'context/accountSearch/AccountSearchContext';
import { Box } from '@mui/material';
import { GridContainer, GridItem } from 'components/shared/ui/styles/Grid.styles';
import { SelectMenu } from 'components/shared/forms/SelectMenu';
import { OptionItem, SelectionVariant } from 'components/shared/forms/FormFieldTypes';
import { MaxLengthTextField } from 'components/shared/forms/MaxLengthTextField';
import { pageContainerBackground, PrimaryButton } from 'components/shared/ui/styles/Global.styles';
import { AccountListItem, StyledAccountsListBox } from 'components/flexFlow/rateAndBilling/RateAndBilling.styles';
import { Virtuoso } from 'react-virtuoso';
import { BusinessAccountCard } from './BusinessAccountCard';
import { transformAccountDetailsFromSearch } from 'components/shared/uiModels/rateAndBilling/rateAndBillingTransformer';
import { NoResultsView } from 'components/shared/ui/noResultsView/NoResultsView';
import { ProgressOverlay } from 'components/shared/ui/spinner/ProgressOverlay';
import { PhoneTextField } from 'components/shared/forms/PhoneTextField';
import { piiField } from '@ehi/support';
import { useEffectWhen } from 'hooks/useEffectWhen';
import { useAppSelector } from 'redux/hooks';
import { selectPickup } from 'redux/selectors/bookingEditor';

export const ACCOUNT_LIST_OFFSET = 470;
// Denotes the percent offset of the dialog height. Currently, the dialog is set at 94%
export const DIALOG_HEIGHT_PERCENT_OFFSET = 0.06;

export type AccountSearchType = {
  handleSelect: (accountUrn: string) => void;
};

export const AccountSearch: FC<AccountSearchType> = ({ handleSelect }: AccountSearchType) => {
  const { t } = useTranslations();
  const { previousSearchCriteria, previousSearchResults } = useAccountSearchContext();
  const pickupData = useAppSelector(selectPickup);
  const {
    locationQueriesComplete,
    groups: defaultGroups,
    branchCountryCode,
    branchGroup,
    corporateCountries,
    otherCountries,
  } = useLocationInfoQueries(pickupData);
  // This is a ref to keep the group options up to date when changing country. If not used, the group drop down
  // becomes behind and shows previous countries list in the input.
  const groupsRef = useRef<OptionItem[]>([]);
  const [scrollParent, setScrollParent] = useState<HTMLElement | undefined>(undefined);

  const { accounts, isFetchingAccounts, handleSearch, tooManyResults, noResults, handleReset, timeOutError } =
    useBusinessAccountSearch();
  const accountList = useMemo(
    () => (previousSearchResults ? previousSearchResults : accounts),
    [accounts, previousSearchResults]
  );

  const resolver = useYupValidationResolver(accountSearchValidationSchema(t));

  const initialValues = useMemo(
    () => (previousSearchCriteria ? previousSearchCriteria : getAccountSearchValuesInitialValues()),
    [previousSearchCriteria]
  );

  const formMethods = useForm({
    resolver,
    defaultValues: initialValues,
  });

  const formCountry = formMethods.watch(AccountSearchFields.Country);
  const modifiedCountryCode: string = useMemo(() => {
    if (formCountry !== branchCountryCode) {
      return formCountry;
    }
    return branchCountryCode;
  }, [branchCountryCode, formCountry]);

  const { isFetching: isLocationGroupsLoading, refetch: refetchOptions } = useLocationGroups(
    modifiedCountryCode,
    DAILY_RENTAL,
    false
  );

  // This sets initial state once all the location queries are complete
  useEffectWhen(() => {
    formMethods.reset({
      ...initialValues,
      groups: [branchGroup],
      country: branchCountryCode,
    });
    groupsRef.current = defaultGroups;
  }, locationQueriesComplete);

  // Calls when reset button is pressed or user selects default country in drop down
  useEffectWhen(() => {
    formMethods.resetField(AccountSearchFields.Groups, { defaultValue: [branchGroup] });
    groupsRef.current = defaultGroups;
  }, JSON.stringify(groupsRef.current) !== JSON.stringify(defaultGroups) && modifiedCountryCode === branchCountryCode);

  // Calls when non-default country is selected
  useEffect(() => {
    if (formMethods.formState.dirtyFields[AccountSearchFields.Country]) {
      refetchOptions().then((value) => {
        const groupOptions = getGroupOptions(value.data);
        formMethods.resetField(AccountSearchFields.Groups, { defaultValue: [] });
        groupsRef.current = groupOptions;
      });
    }
  }, [branchGroup, formMethods, modifiedCountryCode, refetchOptions]);

  useEffect(() => {
    // This sets scroll parent for Virtuoso, so it can use window scrolling
    setScrollParent((window.document.getElementById('scrolling-view-accounts') as HTMLElement) ?? undefined);
  }, []);

  const countrySelect = formMethods.watch(AccountSearchFields.Country);

  const onSubmit = async (values: AccountSearchValues): Promise<void> => {
    const isAllGroupsSelected = groupsRef.current.length === formMethods.getValues(AccountSearchFields.Groups)?.length;
    handleSearch(values, isAllGroupsSelected);
  };

  const AccountCountHeader = (): ReactElement => {
    return (
      <Body2 color='textSecondary' data-testid='resultsCount' margin={ehiTheme.spacing(2)}>{`${
        accountList?.length ?? 0
      } ${t('rateAndBilling.results')}`}</Body2>
    );
  };

  const AccountFooter = (): ReactElement => {
    return (
      <Caption2 display={'flex'} justifyContent={'center'} paddingBottom={ehiTheme.spacing(5)}>
        {t('rateAndBilling.noMoreItems')}
      </Caption2>
    );
  };

  return (
    <FormProvider {...formMethods}>
      <Box
        padding={ehiTheme.spacing(2)}
        style={{ background: pageContainerBackground }}
        data-testid='accountSearchContainer'>
        <GridContainer>
          <>
            <GridItem sm={6}>
              <SelectMenu
                formFieldName={AccountSearchFields.Country}
                label={t('common.country')}
                required
                defaultSelection={branchCountryCode}
                primaryList={{
                  list: corporateCountries,
                  subHeader: t('location.corporateCountries'),
                }}
                secondaryList={{
                  list: otherCountries,
                  subHeader: t('location.otherCountries'),
                }}
                selectionVariant={SelectionVariant.CHECKMARK}
                searchFieldPlaceholder={t('location.searchForCountry')}
                footerText={t('common.reset')}
              />
            </GridItem>
            <GridItem sm={6}>
              <SelectMenu
                formFieldName={AccountSearchFields.Groups}
                label={t('whenWhere.groupNumber')}
                defaultSelection={branchGroup}
                required
                primaryList={{
                  list: groupsRef.current,
                  subHeader: t('whenWhere.groupNumbers'),
                }}
                selectionVariant={SelectionVariant.CHECKBOX}
                allToggleLabel={t('whenWhere.allGroupNumbers')}
                searchFieldPlaceholder={t('whenWhere.searchForGroupNumber')}
                footerText={t('common.reset')}
              />
            </GridItem>
          </>
          <>
            <GridItem sm={6}>
              <MaxLengthTextField
                name={AccountSearchFields.AccountName}
                autoFocus
                type='text'
                label={t('rateAndBilling.accountName')}
                maxLength={60}
              />
            </GridItem>
            <GridItem sm={6}>
              <SelectMenu
                formFieldName={AccountSearchFields.AccountTypes}
                label={t('rateAndBilling.accountTypeLabel')}
                primaryList={{
                  list: getAccountTypeOptions(t),
                  subHeader: t('rateAndBilling.accountTypes'),
                }}
                selectionVariant={SelectionVariant.CHECKMARK}
                allToggleLabel={t('rateAndBilling.allAccountTypes')}
                footerText={t('common.reset')}
              />
            </GridItem>
          </>
          <GridItem sm={6}>
            <PhoneTextField
              country={countrySelect}
              name={AccountSearchFields.PhoneNumber}
              label={t('rateAndBilling.accountPhone')}
              data-testid='phone-number-field'
              className={piiField}
            />
          </GridItem>
        </GridContainer>
        <Box display={'flex'} justifyContent={'flex-end'}>
          <EhiButton
            type={'reset'}
            onClick={(): void => {
              formMethods.reset();
              handleReset();
            }}>
            {t('common.reset')}
          </EhiButton>
          <PrimaryButton data-testid='searchAccount' onClick={formMethods.handleSubmit(onSubmit)}>
            {t('common.search')}
          </PrimaryButton>
        </Box>
      </Box>
      <StyledAccountsListBox
        data-testid='accountSearchResults'
        containerHeight={window.innerHeight - window.innerHeight * DIALOG_HEIGHT_PERCENT_OFFSET - ACCOUNT_LIST_OFFSET}
        noResultsView={noResults || tooManyResults || timeOutError}>
        {accountList && accountList?.length > 0 && (
          <Virtuoso
            customScrollParent={scrollParent}
            totalCount={accountList.length}
            components={{ Header: AccountCountHeader, Item: AccountListItem, Footer: AccountFooter }}
            itemContent={(index: number): ReactElement => {
              return (
                <BusinessAccountCard
                  account={transformAccountDetailsFromSearch(accountList[index])}
                  showDivider
                  showSelect
                  handleSelect={(accountUrn: string): void => handleSelect(accountUrn)}
                />
              );
            }}
          />
        )}
        {noResults && (
          <NoResultsView
            noResultsTitle='rateAndBilling.noAccountsFound'
            noResultsDescription={t('rateAndBilling.tryAnotherSearch')}
            isBackgroundColor={false}
            pageTitle={true}
          />
        )}
        {tooManyResults && (
          <NoResultsView
            noResultsTitle='rateAndBilling.tooManyResults'
            noResultsDescription={t('rateAndBilling.refineSearch')}
            isBackgroundColor={false}
            pageTitle={true}
          />
        )}
        {timeOutError && (
          <NoResultsView
            noResultsTitle='rateAndBilling.timeOutMessage'
            noResultsDescription={t('rateAndBilling.pleaseTryAgain')}
            isBackgroundColor={false}
            pageTitle={true}
          />
        )}
      </StyledAccountsListBox>
      <ProgressOverlay inProgress={isLocationGroupsLoading || isFetchingAccounts || !locationQueriesComplete} />
    </FormProvider>
  );
};
