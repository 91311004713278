import { FlexRoute } from 'components/shared/flexiFlow/flexRoute';
import { RouterPaths, SIBLING_PREFIX } from 'app/router/RouterPaths';
import { Driver } from 'components/flexFlow/driver/Driver';
import { WhenAndWhere } from 'components/flexFlow/whenAndWhere/WhenAndWhere';
import { RateAndBilling } from 'components/flexFlow/rateAndBilling/RateAndBilling';
import { VehiclePage } from 'components/flexFlow/vehicle/VehiclePage';
import { AddOns, PEO_TAB_KEY, TabNames } from 'components/flexFlow/peo/AddOns';
import { Payment } from 'components/flexFlow/payment/Payment';
import { generateSearchParams } from 'utils/routing/urlUtils';
import { TFunction } from 'i18next';
import { Review } from 'components/flexFlow/review/Review';

export const getDefaultFlexRoutes = (t: TFunction): FlexRoute[] => [
  {
    titleKey: t('whenWhere.pageTitle'),
    path: RouterPaths.WhenAndWhere,
    component: WhenAndWhere,
    position: 0,
  },
  {
    titleKey: t('rateAndBilling.pageTitle'),
    path: RouterPaths.RateAndBill,
    component: RateAndBilling,
    position: 1,
  },
  {
    titleKey: t('common.vehicle'),
    path: RouterPaths.Vehicle,
    component: VehiclePage,
    position: 2,
  },
  {
    titleKey: t('peo.pageTitle'),
    path: RouterPaths.AddOns,
    component: AddOns,
    position: 3,
    to: {
      pathname: `${SIBLING_PREFIX}${RouterPaths.AddOns}`,
      search: generateSearchParams({
        [PEO_TAB_KEY]: TabNames.Protections,
      }),
    },
  },
  {
    titleKey: t('driver.pageTitle'),
    path: RouterPaths.Driver,
    component: Driver,
    position: 4,
  },
  {
    titleKey: t('payment.pageTitle'),
    path: RouterPaths.Payment,
    component: Payment,
    position: 5,
  },
  {
    titleKey: t('review.pageTitle'),
    path: RouterPaths.Review,
    component: Review,
    position: 6,
  },
];
