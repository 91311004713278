import { Body1 } from '@ehi/ui';
import { FC } from 'react';
import { transformToFormattedAddress } from 'services/location/locationTransformer';
import { AddressType, BranchV2, PhoneType } from 'services/location/locationTypes';

type RentalLocationAddressProps = {
  branch: BranchV2;
};

export const RentalLocationAddress: FC<RentalLocationAddressProps> = ({ branch }: RentalLocationAddressProps) => {
  const address = transformToFormattedAddress(branch, AddressType.PHYSICAL, PhoneType.OFFICE);
  return (
    <>
      <Body1 data-testid={'stationName'} bold>
        {address.addrStationId} {address.addrStationName}
      </Body1>
      <Body1 data-testid={'addressLine1'}>{address.addressLine1}</Body1>
      <Body1 data-testid={'addressLine2'}>{address.addressLine2}</Body1>
      <Body1 data-testid={'addressPostalLine'}>{address.addressPostalLine}</Body1>
      <Body1 data-testid={'locationPhone'}>{address.formattedPhone}</Body1>
    </>
  );
};
