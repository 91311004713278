import { Box, styled } from '@mui/material';
import { ehiTheme, H6 } from '@ehi/ui';

export const SearchContainer = styled(Box)(() => ({
  backgroundColor: ehiTheme.palette.background.default,
  borderBottom: `1px solid ${ehiTheme.ehi.palette.border.main}`,
  padding: ehiTheme.spacing(3),
}));

export const SearchButtonWrapper = styled(Box)`
  display: flex;
  justify-content: flex-end;
  align-items: 'center';
`;

export const FlexContainer = styled(Box)<{ $justifyContent?: string }>`
  display: flex;
  justify-content: ${(props): string => props.$justifyContent || 'flex-start'};
  flex-direction: column;
`;

export const DriverListItem = styled(Box)(() => ({
  display: 'block !important',
}));

export const DriverSearchName = styled(H6)`
  white-space: nowrap;
  text-align: start;
  font-size: 16px;
`;
