import { To, useLocation } from 'react-router-dom';
import { HashPaths, RouterPaths } from 'app/router/RouterPaths';
import {
  ADDITIONAL_INFO_ISSUE_CODES,
  BillToBookingIssues,
  DRIVER_ISSUE_CODES,
  DriverNotValidForBrandBookingIssue,
  PeoBookingIssues,
  RATE_AND_BILL_ISSUE_CODES,
  VEHICLE_ISSUE_CODES,
  VehicleAvailabilityBookingIssues,
  VehicleNotValidForYoungDriverBookingIssue,
  WHEN_AND_WHERE_ISSUE_CODES,
} from 'utils/bookingUtils';
import { useTranslation } from 'react-i18next';
import { generateSearchParams } from 'utils/routing/urlUtils';
import { PEO_TAB_KEY, TabNames } from 'components/flexFlow/peo/AddOns';
import { EditorIssue, Equipment, Protection, VehicleClassSelection } from 'services/booking/bookingTypes';
import { parseUrn } from 'utils/urnUtils';
import { DescriptionWithAction } from 'components/shared/alert/AlertDialogTypes';
import { EMPTY_VALUE } from 'utils/constants';
import { useEquipmentTypesQuery, useProtectionTypesQuery } from 'services/rentalReference/rentalReferenceQueries';
import { getPeoTitle } from 'utils/peoUtils';

export type ResActionsHookReturn = {
  getIssuesPath: (issueCode: string) => To | undefined;
  getDescriptionForDriverProfileBookingIssue: () => DescriptionWithAction;
  getDescriptionForVehicleBookingIssue: (
    vehicle: VehicleClassSelection | undefined,
    vehicleNotAvailableIssues?: EditorIssue[],
    additionalInfo?: string
  ) => DescriptionWithAction;
  getDescriptionForPeoBookingIssues: (
    peoIssues: EditorIssue[],
    equipment: Equipment[],
    protections: Protection[],
    additionalInfo: string
  ) => DescriptionWithAction[];
  getDescriptionForBillToBookingIssue: (billToIssues: EditorIssue[]) => DescriptionWithAction;
};

export const useBookingIssuesHelper = (): ResActionsHookReturn => {
  const { pathname } = useLocation();
  const { t } = useTranslation();
  const { data: equipmentDetails = [] } = useEquipmentTypesQuery();
  const { data: protectionDetails = [] } = useProtectionTypesQuery();

  const getIssuesPath = (issueCode: string): To | undefined => {
    const siblingPath = pathname.substring(0, pathname.lastIndexOf('/'));

    if (DRIVER_ISSUE_CODES.includes(issueCode)) {
      return { pathname: `${siblingPath}/${RouterPaths.Driver}`, hash: HashPaths.AddDriver };
    } else if (WHEN_AND_WHERE_ISSUE_CODES.includes(issueCode)) {
      return { pathname: `${siblingPath}/${RouterPaths.WhenAndWhere}` };
    } else if (VEHICLE_ISSUE_CODES.includes(issueCode)) {
      return { pathname: `${siblingPath}/${RouterPaths.Vehicle}` };
    } else if (PeoBookingIssues.EquipmentUnavailable.includes(issueCode)) {
      return {
        pathname: `${siblingPath}/${RouterPaths.AddOns}`,
        search: generateSearchParams({
          [PEO_TAB_KEY]: TabNames.Equipment,
        }),
      };
    } else if (PeoBookingIssues.ProtectionUnavailable.includes(issueCode)) {
      return {
        pathname: `${siblingPath}/${RouterPaths.AddOns}`,
        search: generateSearchParams({
          [PEO_TAB_KEY]: TabNames.Protections,
        }),
      };
    } else if (RATE_AND_BILL_ISSUE_CODES.includes(issueCode)) {
      return { pathname: `${siblingPath}/${RouterPaths.RateAndBill}` };
    } else if (ADDITIONAL_INFO_ISSUE_CODES.includes(issueCode)) {
      return { pathname: `${siblingPath}/${RouterPaths.RateAndBill}`, hash: HashPaths.EditAdditionalInfo };
    }
    return undefined;
  };

  const getDescriptionForDriverProfileBookingIssue = (): DescriptionWithAction => {
    return {
      message: t('snackbarMessages.loyaltyProfileRemoved', {
        additionalInfo: t('snackbarMessages.additionalInfo.notValidForBrand'),
      }),
      routePath: getIssuesPath(DriverNotValidForBrandBookingIssue),
    };
  };

  const getDescriptionForPeoBookingIssues = (
    peoIssues: EditorIssue[],
    equipment: Equipment[],
    protections: Protection[],
    additionalInfo: string
  ): DescriptionWithAction[] => {
    const equipmentNames = new Array<string>();
    const protectionNames = new Array<string>();
    peoIssues.forEach((issue) => {
      if (issue.code === PeoBookingIssues.EquipmentUnavailable && issue.paths) {
        const path = issue.paths[0];
        const pathIndex = Number(path.charAt(path.length - 1));
        if (pathIndex > -1 && pathIndex < equipment.length) {
          equipmentNames.push(getPeoTitle(parseUrn(equipment[pathIndex].type), equipmentDetails));
        }
      } else if (issue.code === PeoBookingIssues.ProtectionUnavailable && issue.paths) {
        const path = issue.paths[0];
        const pathIndex = Number(path.charAt(path.length - 1));
        if (pathIndex > -1 && pathIndex < protections.length) {
          protectionNames.push(getPeoTitle(parseUrn(protections[pathIndex].type), protectionDetails));
        }
      }
    });
    const peoDescriptions = new Array<DescriptionWithAction>();
    equipmentNames.forEach((item) => {
      peoDescriptions.push({
        message: t('snackbarMessages.peoRemoved', {
          peoName: item,
          additionalInfo,
        }),
        routePath: getIssuesPath(PeoBookingIssues.EquipmentUnavailable),
      });
    });
    protectionNames.forEach((item) => {
      peoDescriptions.push({
        message: t('snackbarMessages.peoRemoved', {
          peoName: item,
          additionalInfo: additionalInfo,
        }),
        routePath: getIssuesPath(PeoBookingIssues.ProtectionUnavailable),
      });
    });

    return peoDescriptions;
  };

  const getDescriptionForBillToBookingIssue = (billToIssues: EditorIssue[]): DescriptionWithAction => {
    if (billToIssues?.find((issue) => issue.code === BillToBookingIssues.BillToInvalidLocation)) {
      return {
        message: t('snackbarMessages.billToRemoved', {
          additionalInfo: t('snackbarMessages.additionalInfo.notValidForLocation'),
        }),
        routePath: getIssuesPath(BillToBookingIssues.BillToInvalidLocation),
      };
    } else {
      return {
        message: t('snackbarMessages.billToRemoved', {
          additionalInfo: t('snackbarMessages.additionalInfo.accountInactive'),
        }),
        routePath: getIssuesPath(BillToBookingIssues.BillToAccountInactive),
      };
    }
  };

  const getDescriptionForVehicleBookingIssue = (
    vehicle: VehicleClassSelection | undefined,
    vehicleNotAvailableIssues?: EditorIssue[],
    additionalInfo?: string
  ): DescriptionWithAction => {
    if (vehicleNotAvailableIssues) {
      if (
        vehicleNotAvailableIssues?.find(
          (issue) => issue.code === VehicleAvailabilityBookingIssues.PreferredVehicleNotAvailable
        )
      ) {
        return {
          message: t('snackbarMessages.vehicleRemoved', {
            vehicleSipp: vehicle?.preferred ? parseUrn(vehicle.preferred) : EMPTY_VALUE,
            additionalInfo,
          }),
          routePath: getIssuesPath(VehicleAvailabilityBookingIssues.PreferredVehicleNotAvailable),
        };
      } else {
        return {
          message: t('snackbarMessages.vehicleRemoved', {
            vehicleSipp: vehicle?.reserved ? parseUrn(vehicle.reserved) : EMPTY_VALUE,
            additionalInfo,
          }),
          routePath: getIssuesPath(VehicleAvailabilityBookingIssues.ReservedVehicleNotAvailable),
        };
      }
    } else {
      return {
        message: t('snackbarMessages.vehicleRemoved', {
          vehicleSipp: vehicle?.preferred ? parseUrn(vehicle.preferred) : EMPTY_VALUE,
          additionalInfo: t('snackbarMessages.additionalInfo.youngDriver'),
        }),
        routePath: getIssuesPath(VehicleNotValidForYoungDriverBookingIssue),
      };
    }
  };

  return {
    getIssuesPath,
    getDescriptionForDriverProfileBookingIssue,
    getDescriptionForVehicleBookingIssue,
    getDescriptionForPeoBookingIssues,
    getDescriptionForBillToBookingIssue,
  };
};
