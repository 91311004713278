import { FlexiFunctionComponent } from 'components/shared/flexiFlow/FlexFlowTypes';
import { StickyCardNavigation } from 'components/shared/ui/card/StickyCardNavigation';
import { BillToSection } from 'components/flexFlow/rateAndBilling/BillToSection';
import { RateSourceSection } from 'components/flexFlow/rateAndBilling/RateSourceSection';
import { EhiDivider } from 'components/shared/ui/styles/Divider.styles';
import {
  RateAndBillingFlexFlowCard,
  RateAndBillingFlexiFlowCardInnerContainer,
  RateAndBillingPage,
} from 'components/flexFlow/rateAndBilling/RateAndBilling.styles';
import { AdditionalInformationSection } from './AdditionalInformationSection';
import { ReferenceInformationSection } from 'components/flexFlow/rateAndBilling/ReferenceInformationSection';

export const RateAndBilling: FlexiFunctionComponent = ({ onNext, onPrevious }) => {
  return (
    <RateAndBillingPage>
      <RateAndBillingFlexFlowCard>
        <StickyCardNavigation onPrevious={onPrevious} onNext={onNext} showExitToRentalSummary={true} />
        <RateAndBillingFlexiFlowCardInnerContainer>
          <RateSourceSection />
          <EhiDivider />
          <BillToSection />
          <AdditionalInformationSection />
          <ReferenceInformationSection />
        </RateAndBillingFlexiFlowCardInnerContainer>
      </RateAndBillingFlexFlowCard>
    </RateAndBillingPage>
  );
};
