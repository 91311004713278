export enum SearchByType {
  AccountNumber = 'accountNumber',
  AccountSearch = 'accountSearch',
}

export interface RateSourceValues {
  [RateSourceFields.SearchBy]: string;
  [RateSourceFields.AccountNumber]: string;
  [RateSourceFields.PreviousAccountNumber]: string;
  [RateSourceFields.RateProduct]: string;
  [RateSourceFields.RatePlan]: string;
}

export enum RateSourceFields {
  SearchBy = 'searchBy',
  AccountNumber = 'accountNumber',
  PreviousAccountNumber = 'previousAccountNumber',
  RateProduct = 'rateProduct',
  RatePlan = 'ratePlan',
}
