import { useCallback } from 'react';
import {
  DriverForVerification,
  transformToElectronicVerificationRequest,
} from 'components/shared/uiModels/identityVerification/identityVerificationTransformer';
import {
  getElectronicIdentityVerification,
  retrieveVerificationHistory,
} from 'services/identityVerification/identityVerificationService';
import { DriverData } from 'components/shared/uiModels/driver/driverDataTypes';
import { assignReservationNumber } from 'services/booking/bookingService';
import { useAppDispatch, useAppSelector } from 'redux/hooks';
import { selectBookingEditorId, selectReservationUrn } from 'redux/selectors/bookingEditor';
import { safelyCatchError } from 'utils/errorUtils';
import { useAlert } from 'components/shared/alert/AlertContext';
import { FullHumanAndElectronicVerification } from 'services/identityVerification/identityVerificationTypes';
import { setVerificationData } from 'redux/slices/driver/driverSlice';
import { parseUrn } from 'utils/urnUtils';
import { logError } from 'components/shared/logger/splunkLogger';
import { ErrorSeverity } from '@ehi/analytics';
import { ServiceResultType } from 'services/types/ServiceResultTypes';

export type GetIdentityVerificationType = {
  getVerificationHistory: (context: string, showAlert?: boolean) => Promise<void | ServiceResultType>;
  runElectronicIdentityVerification: (
    driverDetails: DriverData,
    pickupLocation: string
  ) => Promise<void | ServiceResultType>;
};

export const useGetIdentityVerification = (): GetIdentityVerificationType => {
  const dispatch = useAppDispatch();
  const { showAlert } = useAlert();
  const bookingEditorId = useAppSelector(selectBookingEditorId);
  const reservationUrn = useAppSelector(selectReservationUrn);

  const runElectronicIdentityVerification = async (
    driverDetails: DriverData,
    pickupLocation: string
  ): Promise<void | ServiceResultType> => {
    let newReservationNumber;
    if (!reservationUrn) {
      await assignReservationNumber(bookingEditorId)
        .then((response) => {
          newReservationNumber = response;
        })
        .catch(async (error) => {
          const ehiErrorsResponse = safelyCatchError(error);
          if (ehiErrorsResponse?.errors) {
            await showAlert({
              responseMessages: ehiErrorsResponse?.errors,
            });
          }
        });
      if (!newReservationNumber) return;
    }

    const requestBody = transformToElectronicVerificationRequest(
      driverDetails as DriverForVerification,
      pickupLocation,
      reservationUrn || newReservationNumber || ''
    );

    await getElectronicIdentityVerification(requestBody).catch(async (error) => {
      const ehiErrorsResponse = safelyCatchError(error);
      if (ehiErrorsResponse?.errors) {
        await showAlert({
          responseMessages: ehiErrorsResponse?.errors,
        });
        return;
      }
    });

    return getVerificationHistory(requestBody.context, true);
  };

  const getVerificationHistory = useCallback(
    async (context: string, showAlert?: boolean): Promise<void | ServiceResultType> => {
      return retrieveVerificationHistory(context)
        .then((result) => {
          dispatch(setVerificationData(result as FullHumanAndElectronicVerification[]));
          return;
        })
        .catch(async (error) => {
          const ehiErrorsResponse = safelyCatchError(error);
          if (ehiErrorsResponse?.errors) {
            if (showAlert) {
              return { errors: ehiErrorsResponse?.errors };
            } else {
              const reservationNumber = parseUrn(context) || ' Reservation number not available';
              return logError({
                error: {
                  message: 'Error getting identity verification history for reservation ' + reservationNumber,
                  supportInformation: safelyCatchError(error),
                },
                severity: ErrorSeverity.Error,
              });
            }
          }
        });
    },
    [dispatch]
  );

  return {
    getVerificationHistory,
    runElectronicIdentityVerification,
  };
};
