import { FC } from 'react';
import { GridContainer } from 'components/shared/ui/styles/Grid.styles';
import RentalPickup from 'components/quickRes/RentalPickup';
import RentalReturn from 'components/quickRes/RentalReturn';
import { ehiTheme } from '@ehi/ui';
import { SectionGridItem } from 'components/quickRes/QuickRes.styles';

const QuickResDateTime: FC = () => {
  return (
    <GridContainer gap={ehiTheme.spacing(3)}>
      <SectionGridItem>
        <RentalPickup />
      </SectionGridItem>
      <SectionGridItem>
        <RentalReturn />
      </SectionGridItem>
    </GridContainer>
  );
};

export default QuickResDateTime;
