import { Box, Grid, styled } from '@mui/material';
import { FlexFlowCard, FlexiFlowCardInnerContainer } from 'components/shared/ui/card/Card';
import { ehiTheme } from '@ehi/ui';

export const RentalCard = styled(FlexiFlowCardInnerContainer)`
  margin: ${ehiTheme.spacing(0)};
`;

export const WhenAndWherePage = styled(Box)({
  height: '0px',
  width: '100%',
  flexGrow: '1',
});
export const WhenAndWhereFlexFlowCard = styled(FlexFlowCard)({
  height: 'auto',
  maxHeight: '100%',
  display: 'flex',
  flexDirection: 'column',
});

export const WhenAndWhereScrollContainer = styled(Box)({
  height: 'auto',
  overflowY: 'scroll',
  padding: ehiTheme.spacing(3),
});

export const WhenAndWhereGrid = styled(Grid)({
  gap: ehiTheme.spacing(2),
});
