import { RateSource } from 'services/booking/bookingTypes';
import { useAppSelector } from 'redux/hooks';
import { selectRateSource } from 'redux/selectors/bookingEditor';
import { useBillingAccountByAccountQuery, useBusinessAccountQuery } from 'services/businessAccount/accountQueries';

import { parseRateSourceAccountNumber } from 'utils/rateAndBillingUtils';
import { useMemo } from 'react';
import { EhiErrors } from 'services/types/EhiErrorsTypes';
import {
  RateAndBillingInfo,
  transformRateSourceInfoFromApi,
} from 'components/shared/uiModels/rateAndBilling/rateAndBillingTransformer';

type RateSourceAccountDetailsHookReturn = {
  rateSourceAccountNumber: string;
  editorRateSource: RateSource | undefined;
  rateSourceInfo: RateAndBillingInfo | undefined;
  isLoading: boolean;
  rateSourceErrors: EhiErrors | null;
  billingAccountErrors: EhiErrors | null;
};

export const useRateSourceAccountDetails = (): RateSourceAccountDetailsHookReturn => {
  const rateSource: RateSource | undefined = useAppSelector(selectRateSource);
  const rateSourceAccountNumber = useMemo(() => {
    return parseRateSourceAccountNumber(rateSource);
  }, [rateSource]);

  const {
    data: businessAccount,
    isFetching: isBusinessAccountLoading,
    error: businessAccountErrors,
  } = useBusinessAccountQuery(rateSourceAccountNumber);

  const {
    data: billingAccount,
    isFetching: isBillingAccountLoading,
    error: billingAccountErrors,
  } = useBillingAccountByAccountQuery(rateSourceAccountNumber);

  const rateSourceAccount: RateAndBillingInfo | undefined = useMemo(() => {
    return businessAccount
      ? transformRateSourceInfoFromApi(businessAccount, billingAccount, billingAccountErrors)
      : undefined;
  }, [billingAccount, billingAccountErrors, businessAccount]);

  return {
    rateSourceAccountNumber: rateSourceAccountNumber,
    editorRateSource: rateSource,
    rateSourceInfo: rateSourceAccount,
    isLoading: isBusinessAccountLoading || isBillingAccountLoading,
    rateSourceErrors: businessAccountErrors,
    billingAccountErrors: billingAccountErrors,
  };
};
