import { createContext, FC, ReactElement, ReactNode, useContext, useState } from 'react';
import { AccountSearchContextProps } from 'context/accountSearch/AccountSearchContextTypes';
import { AccountSearchValues } from 'components/flexFlow/rateAndBilling/editDialogs/AccountSearchTypes';
import { BusinessAccountSearchResult } from 'services/businessAccount/businessAccountTypes';

export const AccountSearchContext = createContext<AccountSearchContextProps>({
  isAccountNumberFieldHidden: false,
  setIsAccountNumberFieldHidden: () => undefined,
  previousSearchResults: undefined,
  previousSearchCriteria: undefined,
  storePreviousSearchHistory: () => undefined,
  selectedAccountUrn: undefined,
  setSelectedAccountUrn: () => undefined,
});

export const useAccountSearchContext = (): AccountSearchContextProps => {
  const context = useContext(AccountSearchContext);

  if (!context) {
    throw Error('AccountSearchContext is not initialized');
  }

  return context;
};

export const AccountSearchProvider: FC<{ children: ReactNode }> = ({ children }): ReactElement => {
  const [isAccountNumberFieldHidden, setIsAccountNumberFieldHidden] = useState(false);
  const [previousSearchResults, setPreviousSearchResults] = useState<BusinessAccountSearchResult[] | undefined>(
    undefined
  );
  const [previousSearchCriteria, setPreviousSearchCriteria] = useState<AccountSearchValues | undefined>(undefined);
  const [selectedAccountUrn, setSelectedAccountUrn] = useState<string | undefined>(undefined);

  const storePreviousSearchHistory = (
    searchCriteria?: AccountSearchValues,
    searchResults?: BusinessAccountSearchResult[]
  ): void => {
    setPreviousSearchResults(searchResults);
    setPreviousSearchCriteria(searchCriteria);
  };

  return (
    <AccountSearchContext.Provider
      value={{
        isAccountNumberFieldHidden,
        setIsAccountNumberFieldHidden,
        previousSearchResults,
        previousSearchCriteria,
        storePreviousSearchHistory,
        selectedAccountUrn,
        setSelectedAccountUrn,
      }}>
      {children}
    </AccountSearchContext.Provider>
  );
};
