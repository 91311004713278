import { EditorIssue } from 'services/booking/bookingTypes';
import {
  deleteVehicleClassSelection,
  removeRenter,
  updateAllAddOns,
  updateBusinessPayers,
} from 'services/booking/bookingService';
import { useAppSelector } from 'redux/hooks';
import {
  selectBookingEditorId,
  selectDriverProfileRenter,
  selectEquipment,
  selectProtections,
  selectVehicleClassSelection,
} from 'redux/selectors/bookingEditor';
import { useAlert } from 'components/shared/alert/AlertContext';
import {
  BillToBookingIssues,
  getEhiMessagesAsSnackbarMessages,
  getPersistedEquipment,
  getPersistedProtections,
  isBillToBookingIssueAvailable,
  isDriverBookingIssueAvailable,
  isPeoBookingIssueAvailable,
  isVehicleAvailabilityBookingIssueAvailable,
  isVehicleYoungDriverIssueAvailable,
  PeoBookingIssues,
  VehicleAvailabilityBookingIssues,
} from 'utils/bookingUtils';
import { transformToEditorAddOnsRequestBody } from 'services/booking/bookingTransformer';
import { useRefreshEditor } from 'hooks/bookingEditor/useRefreshEditor';
import { ResponseMessage } from 'services/types/ResponseMessageTypes';
import { useResSnackbarContext } from 'context/resSnackbar/ResSnackbarContext';
import { useCallback } from 'react';
import { DescriptionWithAction } from 'components/shared/alert/AlertDialogTypes';
import { To, useNavigate } from 'react-router-dom';
import { useTranslations } from 'components/shared/i18n';
import { useBookingIssuesHelper } from 'context/resActions/useBookingIssuesHelper';
import { unionBy } from 'lodash';
import { safelyCatchError } from 'utils/errorUtils';

type BookingIssueActionResult = {
  snackbarMessages: DescriptionWithAction[];
};

export type BookingIssueReturn = {
  handleBookingIssues: (
    issues: EditorIssue[],
    persistedEhiMessages: ResponseMessage[],
    availableBookingIssues: string[],
    additionalInfo: string,
    excludedBookingIssues?: string[]
  ) => Promise<void>;
};

export const useBookingIssue = (): BookingIssueReturn => {
  const navigate = useNavigate();
  const { t } = useTranslations();
  const { showAlert } = useAlert();
  const { refreshEditor } = useRefreshEditor();
  const { setSnackBarRes } = useResSnackbarContext();
  const {
    getDescriptionForVehicleBookingIssue,
    getDescriptionForPeoBookingIssues,
    getDescriptionForBillToBookingIssue,
    getDescriptionForDriverProfileBookingIssue,
  } = useBookingIssuesHelper();

  const bookingEditorId = useAppSelector(selectBookingEditorId);
  const equipment = useAppSelector(selectEquipment);
  const protections = useAppSelector(selectProtections);
  const driverProfile = useAppSelector(selectDriverProfileRenter);
  const vehicle = useAppSelector(selectVehicleClassSelection);

  const handleViewAction = useCallback(
    (snackbarMessages: DescriptionWithAction[]): (() => void) | undefined => {
      if (snackbarMessages.length === 1) {
        const routePath: To | undefined = snackbarMessages[0].routePath;
        return routePath ? (): void => navigate(routePath) : undefined;
      } else if (snackbarMessages.length > 1) {
        return async () =>
          showAlert({
            variant: 'warning',
            title: t('snackbarMessages.multipleChangesOccurred'),
            descriptions: snackbarMessages,
          });
      }

      return undefined;
    },
    [navigate, showAlert, t]
  );

  const handleShowingSnackbar = useCallback(
    (ehiMessages: DescriptionWithAction[], bookingIssueMessages: DescriptionWithAction[]) => {
      const allSnackbarMessages = [...unionBy(ehiMessages, 'message'), ...bookingIssueMessages];

      if (allSnackbarMessages.length > 0) {
        setSnackBarRes({
          onViewAction: handleViewAction(allSnackbarMessages),
          message:
            allSnackbarMessages.length > 1
              ? t('snackbarMessages.multipleChangesOccurred')
              : allSnackbarMessages[0].message,
          isOpen: true,
        });
      }
    },
    [handleViewAction, setSnackBarRes, t]
  );

  const handleRemoveVehicle = async (
    vehicleIssues?: EditorIssue[],
    additionalInfo?: string
  ): Promise<BookingIssueActionResult> => {
    try {
      deleteVehicleClassSelection(bookingEditorId).then(async () => {
        const hasSelectedAddons = !!(equipment || protections);
        if (hasSelectedAddons) {
          const requestBody = transformToEditorAddOnsRequestBody([], []);
          await updateAllAddOns(bookingEditorId, requestBody);
        }
      });
      const snackbarMessage = getDescriptionForVehicleBookingIssue(vehicle, vehicleIssues, additionalInfo);
      return { snackbarMessages: snackbarMessage ? [snackbarMessage] : [] };
    } catch (error) {
      return { snackbarMessages: [] };
    }
  };

  const handleRemovePeo = async (
    peoIssues: EditorIssue[],
    additionalInfo: string
  ): Promise<BookingIssueActionResult> => {
    const snackbarMessages = new Array<DescriptionWithAction>();
    const requestBody = transformToEditorAddOnsRequestBody(
      getPersistedEquipment(peoIssues, equipment ?? []),
      getPersistedProtections(peoIssues, protections ?? [])
    );
    await updateAllAddOns(bookingEditorId, requestBody).then(() => {
      snackbarMessages.push(
        ...getDescriptionForPeoBookingIssues(peoIssues, equipment ?? [], protections ?? [], additionalInfo)
      );
    });

    return { snackbarMessages: snackbarMessages };
  };

  const handleRemoveDriverProfile = async (): Promise<BookingIssueActionResult> => {
    let snackbarMessage: DescriptionWithAction | undefined;
    if (!driverProfile?.profile) {
      return { snackbarMessages: [] };
    }
    await removeRenter(bookingEditorId).then(() => {
      snackbarMessage = getDescriptionForDriverProfileBookingIssue();
    });
    return { snackbarMessages: snackbarMessage ? [snackbarMessage] : [] };
  };

  const handleRemoveBillTo = async (billToIssues: EditorIssue[]): Promise<BookingIssueActionResult> => {
    let snackbarMessage: DescriptionWithAction | undefined;
    await updateBusinessPayers(bookingEditorId, []).then(() => {
      snackbarMessage = getDescriptionForBillToBookingIssue(billToIssues);
    });

    return { snackbarMessages: snackbarMessage ? [snackbarMessage] : [] };
  };

  const handleBookingIssues = async (
    issues: EditorIssue[],
    persistedEhiMessages: ResponseMessage[],
    availableBookingIssues: string[],
    additionalInfo: string,
    excludedBookingIssues?: string[]
  ): Promise<void> => {
    if (issues.length < 1 && persistedEhiMessages.length < 1) {
      return;
    }
    const validSnackbarMessages = new Array<DescriptionWithAction>();
    try {
      if (isVehicleYoungDriverIssueAvailable(availableBookingIssues, issues)) {
        const { snackbarMessages } = await handleRemoveVehicle();
        snackbarMessages && validSnackbarMessages.push(...snackbarMessages);
      } else if (isVehicleAvailabilityBookingIssueAvailable(availableBookingIssues, issues)) {
        const { snackbarMessages } = await handleRemoveVehicle(
          issues.filter((issue) =>
            Object.values(VehicleAvailabilityBookingIssues).find((vehicleIssue) => vehicleIssue === issue.code)
          ),
          additionalInfo
        );
        snackbarMessages && validSnackbarMessages.push(...snackbarMessages);
      } else if (isPeoBookingIssueAvailable(availableBookingIssues, issues)) {
        const { snackbarMessages } = await handleRemovePeo(
          issues.filter((issue) => Object.values(PeoBookingIssues).find((peoIssue) => peoIssue === issue.code)),
          additionalInfo
        );
        snackbarMessages && validSnackbarMessages.push(...snackbarMessages);
      }

      if (isBillToBookingIssueAvailable(availableBookingIssues, issues)) {
        const { snackbarMessages } = await handleRemoveBillTo(
          issues.filter((issue) => Object.values(BillToBookingIssues).find((billToIssue) => billToIssue === issue.code))
        );
        snackbarMessages && validSnackbarMessages.push(...snackbarMessages);
      }
      if (isDriverBookingIssueAvailable(availableBookingIssues, issues)) {
        const { snackbarMessages } = await handleRemoveDriverProfile();
        snackbarMessages && validSnackbarMessages.push(...snackbarMessages);
      }
    } catch (error) {
      const ehiErrorsResponse = safelyCatchError(error);
      await showAlert({ responseMessages: ehiErrorsResponse.errors });
    } finally {
      const { data } = await refreshEditor(bookingEditorId);
      handleShowingSnackbar(
        getEhiMessagesAsSnackbarMessages(
          [...(persistedEhiMessages ?? []), ...(data?.ehiMessages ?? [])],
          excludedBookingIssues ?? []
        ),
        validSnackbarMessages
      );
    }
  };

  return {
    handleBookingIssues,
  };
};
