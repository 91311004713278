import CardContent from '@mui/material/CardContent';
import { Body2, ehiTheme, Subtitle1 } from '@ehi/ui';
import { Grid } from '@mui/material';
import { Caption2 } from 'components/shared/ui/styles/Typography.styles';
import { useLocale, useTranslations } from 'components/shared/i18n';
import { useAppSelector } from 'redux/hooks';
import { selectAppliedAncillaryFees, selectEquipment, selectProtections } from 'redux/selectors/bookingEditor';
import { ReservationSummaryCard } from 'components/resSummary/ReservationSummaryCard';
import { RouterPaths } from 'app/router/RouterPaths';
import {
  useAncillaryFeeTypesQuery,
  useEquipmentTypesQuery,
  useProtectionTypesQuery,
} from 'services/rentalReference/rentalReferenceQueries';
import { mapLoadingState } from 'components/shared/ui/spinner/loadableView/LoadableViewUtils';
import { PeoAmount } from 'components/flexFlow/peo/Peo.styles';
import {
  AddOns,
  formatAncillaryType,
  formatProtection,
  formattedEquipmentTypes,
  getItemRates,
  getPeoTitle,
  getQuantity,
} from 'utils/peoUtils';
import { Fragment } from 'react/jsx-runtime';
import { BaseEquipment, BaseProtection } from 'components/flexFlow/peo/peoTypes';
import { FC, ReactElement } from 'react';
import { EquipmentType } from 'services/rentalReference/rentalReferenceTypes';

export const AddOnsSummary: FC = (): ReactElement => {
  const { locale } = useLocale();
  const { t } = useTranslations();
  const protections = useAppSelector(selectProtections);
  const equipment = useAppSelector(selectEquipment);
  const appliedAncillaryFees = useAppSelector(selectAppliedAncillaryFees);

  // Destructure isFetching from each query
  const {
    data: equipmentDetails = [],
    isFetching: isFetchingEquipment,
    isError: isEquipmentError,
  } = useEquipmentTypesQuery();
  const {
    data: protectionDetails = [],
    isFetching: isFetchingProtection,
    isError: isProtectionError,
  } = useProtectionTypesQuery();

  const {
    data: ancillaryDetails = [],
    isFetching: isFetchingAncillary,
    isError: isAncillaryError,
  } = useAncillaryFeeTypesQuery();

  // Determine if any query is fetching
  const isFetching = isFetchingEquipment || isFetchingProtection || isFetchingAncillary;
  const isError = isEquipmentError || isProtectionError || isAncillaryError;

  const selectedProtections = (protections || []).map((item) => {
    return formatProtection(item);
  });
  const selectedEquipments = formattedEquipmentTypes(equipmentDetails, equipment);

  const ancillaryTypes = (appliedAncillaryFees?.additionalDriver || []).map((item) => formatAncillaryType(item));

  const combinedPeo = [...selectedProtections, ...selectedEquipments, ...ancillaryTypes];

  const getDetails = (addOnType: string): EquipmentType[] => {
    switch (addOnType) {
      case AddOns.Protection:
        return protectionDetails;
      case AddOns.Equipment:
        return equipmentDetails;
      case AddOns.Ancillary:
        return ancillaryDetails;
      default:
        return [];
    }
  };

  return (
    <ReservationSummaryCard
      title={t('reservationSummary.peo')}
      loading={mapLoadingState(isFetching, isError)}
      path={RouterPaths.AddOns}
      params='peoTab=ProtectionsTab'
      data-testid='addOnsSummaryCard'>
      <CardContent data-testid='addOnsSummaryContent'>
        <Grid container spacing={2}>
          {combinedPeo?.slice(0, 2).map((peo, index) => (
            <Fragment key={`peo-${index}`}>
              <Grid item xs={8} data-testid='peo'>
                <Body2 data-testid='peoTitle'>
                  {peo.code
                    ? getPeoTitle(peo.code, getDetails(peo.addOnType)) +
                      (peo.addOnType === AddOns.Equipment ? getQuantity(peo) : '')
                    : ''}
                </Body2>
                <Caption2 data-testid='peoCode'>
                  {peo.code} {(peo as BaseEquipment | BaseProtection)?.selectability ?? ''}
                </Caption2>
              </Grid>
              <Grid item xs={4} data-testid='peoAmount'>
                <PeoAmount>{peo.rate && getItemRates(peo.rate, t, locale)}</PeoAmount>
              </Grid>
            </Fragment>
          ))}
          {combinedPeo?.length > 2 && (
            <Grid item xs={12} textAlign={'center'} data-testid='moreItemsAdded'>
              <Caption2>{t('reservationSummary.moreItemsAdded')}</Caption2>
            </Grid>
          )}
          {combinedPeo.length === 0 && (
            <Grid item xs={12} data-testid='noPeoSelected'>
              <Subtitle1 color={ehiTheme.palette.text.secondary} data-testid='noAddOnsMessage'>
                {t('reservationSummary.noPeoSelected')}
              </Subtitle1>
            </Grid>
          )}
        </Grid>
      </CardContent>
    </ReservationSummaryCard>
  );
};
