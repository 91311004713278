import { StickyCardNavigation } from 'components/shared/ui/card/StickyCardNavigation';
import { ProtectionsTab } from 'components/flexFlow/peo/ProtectionsTab';
import { EquipmentTab } from 'components/flexFlow/peo/EquipmentTab';
import { FlexiFunctionComponent } from 'components/shared/flexiFlow/FlexFlowTypes';
import { mapLoadingState } from 'components/shared/ui/spinner/loadableView/LoadableViewUtils';
import { Tab, Tabs } from '@mui/material';
import { useTranslations } from 'components/shared/i18n';
import { createElement, FC, useCallback } from 'react';
import { createSearchParams, useLocation, useNavigate } from 'react-router-dom';
import { useQueryParamsByKey } from 'utils/routing/routeUtils';
import {
  selectEquipment,
  selectIsReadOnlyFlow,
  selectProtections,
  selectVehicleClassSelection,
} from 'redux/selectors/bookingEditor';
import { useAppSelector } from 'redux/hooks';
import { useSaveOnNavigateHelper } from 'hooks/useSaveOnNavigateHelper';
import { RouterPaths, toSibling } from 'app/router/RouterPaths';
import {
  AddOnsContentWrapper,
  AddOnsFlexFlowCard,
  AddOnsPage,
  AddOnsScrollContainer,
  PeoTabPanelWrapper,
  StyledPeoContainer,
} from './Peo.styles';
import { NoResultsView } from 'components/shared/ui/noResultsView/NoResultsView';
import { Trans } from 'react-i18next';
import { StyledLink } from 'components/shared/ui/noResultsView/NoResultsViewStyles';

export const PEO_TAB_KEY = 'peoTab';

export enum TabNames {
  Protections = 'ProtectionsTab',
  Equipment = 'EquipmentTab',
}

type TabPanelProps = {
  isActiveTab: boolean;
  tabName: string;
  component: FC;
  props?: any;
};

const PeoTabPanel: FC<TabPanelProps> = ({ isActiveTab, tabName, component, props }) => {
  return isActiveTab ? (
    <PeoTabPanelWrapper role='tabpanel' hidden={!isActiveTab} id={tabName} aria-labelledby={tabName}>
      {createElement(component, props)}
    </PeoTabPanelWrapper>
  ) : (
    <></>
  );
};

export const AddOns: FlexiFunctionComponent = ({ onNext, onPrevious }) => {
  const { t } = useTranslations();
  const navigate = useNavigate();
  const { search } = useLocation();
  const { saveOnNavigate } = useSaveOnNavigateHelper();
  const { peoTab = '' } = useQueryParamsByKey([PEO_TAB_KEY]);
  const equipment = useAppSelector(selectEquipment);
  const protections = useAppSelector(selectProtections);
  const vehicleClassSelection = useAppSelector(selectVehicleClassSelection);
  const isResViewOnly = useAppSelector(selectIsReadOnlyFlow);
  const protectionsCount = protections?.length;
  const equipmentCount = equipment?.length;

  const tabPages = [
    {
      title: `${t('peo.protections')} (${protectionsCount ?? 0})`,
      component: ProtectionsTab,
      value: TabNames.Protections,
    },
    {
      title: `${t('peo.equipment')} (${equipmentCount ?? 0})`,
      component: EquipmentTab,
      value: TabNames.Equipment,
    },
  ];

  const handleTabValueChange = useCallback(
    (_: any, newTab: string) => {
      const searchParams: URLSearchParams = createSearchParams(search);
      searchParams.set(PEO_TAB_KEY, newTab);
      saveOnNavigate({ search: searchParams.toString() }, { replace: true });
    },
    [saveOnNavigate, search]
  );

  const isNoResults = !isResViewOnly && !vehicleClassSelection?.preferred;

  return (
    <AddOnsPage>
      <AddOnsFlexFlowCard loadingState={mapLoadingState(false, false)}>
        <StickyCardNavigation onPrevious={onPrevious} onNext={onNext} showExitToRentalSummary={true} />
        <AddOnsScrollContainer>
          <StyledPeoContainer className={isNoResults ? 'peoNiResults' : ''}>
            {isNoResults ? (
              <NoResultsView
                noResultsTitle='peo.unableToDisplay'
                noResultsDescription={
                  <Trans
                    i18nKey='peo.noResultsDescription'
                    components={{
                      Link: (
                        <StyledLink
                          data-testid={'navigationLink'}
                          onClick={(): void => {
                            navigate(...toSibling(RouterPaths.Vehicle));
                          }}
                        />
                      ),
                    }}
                  />
                }
              />
            ) : (
              <AddOnsContentWrapper>
                <Tabs
                  value={peoTab}
                  onChange={handleTabValueChange}
                  indicatorColor='primary'
                  textColor='primary'
                  variant={'fullWidth'}>
                  {tabPages.map((page) => (
                    <Tab
                      label={page.title}
                      key={page.title}
                      value={page.value}
                      data-testid={`dialog-tab-${page.title}`}
                    />
                  ))}
                </Tabs>

                {tabPages.map((page) => (
                  <PeoTabPanel
                    isActiveTab={peoTab === page.value}
                    tabName={page.value}
                    component={page.component}
                    key={page.value}
                  />
                ))}
              </AddOnsContentWrapper>
            )}
          </StyledPeoContainer>
        </AddOnsScrollContainer>
      </AddOnsFlexFlowCard>
    </AddOnsPage>
  );
};
