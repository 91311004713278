import { FlexiFunctionComponent } from 'components/shared/flexiFlow/FlexFlowTypes';
import { StickyCardNavigation } from 'components/shared/ui/card/StickyCardNavigation';
import { useAppSelector } from 'redux/hooks';
import { useVehicleContentQuery } from 'services/vehicleContent/vehicleContentQueries';
import { VehicleList } from 'components/flexFlow/vehicle/VehicleList';
import {
  VehicleDividerWithFullWidth,
  VehicleFlexFlowCard,
  VehiclePage as VehiclePageWrapper,
  VehicleScrollContainer,
  VehicleSearchContainer,
} from 'components/flexFlow/vehicle/VehiclePage.styles';
import { VehicleSearchForm } from 'components/flexFlow/vehicle/vehicleSearch/VehicleSearchForm';
import { VehicleInfo } from 'components/flexFlow/vehicle/VehicleInfo';
import { selectHasLocationData, selectIsReadOnlyFlow } from 'redux/selectors/bookingEditor';
import { useVehicleRates } from 'services/booking/useVehicleRates';
import { FormProvider, useForm } from 'react-hook-form';
import { useYupValidationResolver } from 'components/shared/forms/useYupValidationResolver';
import {
  validationSchema,
  vehicleSearchInitialValues,
} from 'components/flexFlow/vehicle/vehicleSearch/vehicleSearchUtils';
import { VehicleSearchValues } from 'components/flexFlow/vehicle/VehicleTypes';
import { useContext, useMemo } from 'react';
import { transformVehicleListFromApiData } from 'components/shared/uiModels/vehicle/vehicleTransformer';
import { ProgressOverlay } from 'components/shared/ui/spinner/ProgressOverlay';
import NetworkError from 'components/shared/errors/NetworkError';
import { VehicleListContext } from 'context/vehicleList/VehicleListContext';
import { useReservationFlow } from 'hooks/useReservationFlow';
import { useAlert } from 'components/shared/alert/AlertContext';
import { useEffectWhen } from 'hooks/useEffectWhen';

export const VehiclePage: FlexiFunctionComponent = ({ onNext, onPrevious }) => {
  const hasLocationData = useAppSelector(selectHasLocationData);
  const isResViewOnly = useAppSelector(selectIsReadOnlyFlow);
  const resolver = useYupValidationResolver(validationSchema);
  const methods = useForm<VehicleSearchValues>({ resolver: resolver, defaultValues: vehicleSearchInitialValues });
  const { isModifyFlow } = useReservationFlow();
  const { displayVehicleList, toggleVehicleList } = useContext(VehicleListContext);
  const showVehicleContent = displayVehicleList || !isModifyFlow;
  const { showAlert } = useAlert();
  const {
    data: vehicleContent,
    isFetching: isVehicleContentLoading,
    isError: vehicleContentError,
  } = useVehicleContentQuery();
  const { vehicleRates, isLoading: isVehicleRatesLoading, errors: vehicleRatesError } = useVehicleRates();

  const vehicles = useMemo(() => {
    if (!(isVehicleContentLoading || isVehicleRatesLoading)) {
      return transformVehicleListFromApiData(vehicleContent, vehicleRates);
    }
    return [];
  }, [isVehicleContentLoading, isVehicleRatesLoading, vehicleContent, vehicleRates]);

  useEffectWhen(() => {
    void showAlert({ responseMessages: vehicleRatesError?.errors });
  }, !!vehicleRatesError);

  return (
    <VehiclePageWrapper>
      <FormProvider {...methods}>
        <VehicleFlexFlowCard>
          <StickyCardNavigation onPrevious={onPrevious} onNext={onNext} showExitToRentalSummary={true} />
          <VehicleScrollContainer id={'vehicle-page-content'}>
            <VehicleInfo vehicles={vehicles} />
            {!isResViewOnly && (
              <>
                {hasLocationData && (
                  <>
                    <VehicleDividerWithFullWidth
                      sx={{
                        borderBottomWidth: 1,
                      }}
                    />
                    <VehicleSearchContainer data-testid={'vehicleSearch'}>
                      <VehicleSearchForm
                        vehicles={vehicles}
                        isContentVisible={showVehicleContent}
                        onToggleContent={(): void => toggleVehicleList()}
                      />
                    </VehicleSearchContainer>
                  </>
                )}
                <VehicleDividerWithFullWidth
                  sx={{
                    borderBottomWidth: 1,
                  }}
                />
                {showVehicleContent && <VehicleList vehicles={vehicles} />}
              </>
            )}
          </VehicleScrollContainer>
        </VehicleFlexFlowCard>
        <ProgressOverlay inProgress={isVehicleContentLoading || isVehicleRatesLoading} />
        {vehicleContentError && <NetworkError />}
      </FormProvider>
    </VehiclePageWrapper>
  );
};
