import { Box, CardContent } from '@mui/material';
import { ReservationSummaryCard } from 'components/resSummary/ReservationSummaryCard';
import { Caption2, OverlineText } from 'components/shared/ui/styles/Typography.styles';
import { useTranslations } from 'components/shared/i18n';
import { Body2, ehiTheme, Subtitle1 } from '@ehi/ui';
import { RouterPaths } from 'app/router/RouterPaths';
import { mapLoadingState } from 'components/shared/ui/spinner/loadableView/LoadableViewUtils';
import { FC } from 'react';
import { useRateSourceAccountDetails } from 'services/businessAccount/useRateSourceAccountDetails';
import { useRetrieveBillingAccount } from 'services/businessAccount/useRetrieveBillingAccount';
import { useBranchInfoByUrnQuery } from 'services/location/locationQueries';
import { useAppSelector } from 'redux/hooks';
import { selectCarrierSafetyIdentifiers, selectPickup } from 'redux/selectors/bookingEditor';
import { isTruckLocation } from 'utils/locationUtils';

export const RateSourceAndBillToSummary: FC = () => {
  const { t } = useTranslations();
  const { rateSourceInfo, isLoading: isRateSourceInfoLoading, rateSourceErrors } = useRateSourceAccountDetails();
  const { billingAccountInfo, isLoading: isBillingAccountInfoLoading } = useRetrieveBillingAccount();
  const pickupData = useAppSelector(selectPickup);
  const { data: pickupBranch, isFetching: isFetchingPickupBranch } = useBranchInfoByUrnQuery(pickupData?.branch ?? '');
  const carrierSafetyIdentifiers = useAppSelector(selectCarrierSafetyIdentifiers);

  return (
    <ReservationSummaryCard
      title={t('reservationSummary.rateAndBilling')}
      loading={mapLoadingState(
        isRateSourceInfoLoading || isBillingAccountInfoLoading || isFetchingPickupBranch,
        !!rateSourceErrors
      )}
      path={RouterPaths.RateAndBill}
      errors={[...(rateSourceErrors?.errors || [])]}
      data-testid='rateSourceSummaryCard'>
      <CardContent data-testid='rateSourceSummaryContent' style={{ paddingBottom: 0 }}>
        <Box display={'flex'} flexDirection={'column'} paddingTop={ehiTheme.spacing(1)} data-testid='rateSourceDetails'>
          <Caption2>{t('rateAndBilling.rateSource')}</Caption2>
          <Subtitle1 bold data-testid='rateSourceAccountName'>
            {rateSourceInfo?.name ?? t('rateAndBilling.retail')}
          </Subtitle1>
          {rateSourceInfo?.type && <Body2 data-testid='rateSourceAccountType'>{rateSourceInfo.type}</Body2>}
        </Box>

        {isTruckLocation(pickupBranch?.type) && carrierSafetyIdentifiers && (
          <Box
            display={'flex'}
            flexDirection={'column'}
            paddingTop={ehiTheme.spacing(2)}
            gap={ehiTheme.spacing(0.5)}
            data-testid='carrierSafetyNumberDetails'>
            <Caption2>{t('rateAndBilling.carrierSafetyNumbers.title')}</Caption2>
            <Subtitle1>{carrierSafetyIdentifiers[0]}</Subtitle1>
            {carrierSafetyIdentifiers.length > 1 && (
              <OverlineText>
                {t('common.plusMore', {
                  count: carrierSafetyIdentifiers.length - 1,
                })}
              </OverlineText>
            )}
          </Box>
        )}

        {billingAccountInfo?.name && (
          <Box display={'flex'} flexDirection={'column'} paddingTop={ehiTheme.spacing(2)} data-testid='billToDetails'>
            <Caption2>{t('rateAndBilling.billTo.pageTitle')}</Caption2>
            <Subtitle1 bold data-testid='billingAccountName'>
              {billingAccountInfo.name}
            </Subtitle1>
          </Box>
        )}
      </CardContent>
    </ReservationSummaryCard>
  );
};
