import { FC, ReactElement, useState } from 'react';
import { SelectChangeEvent } from '@mui/material/Select';
import { useTranslations } from 'components/shared/i18n';
import { Dialog } from 'components/shared/ui/dialogs/Dialog';
import { H6 } from '@ehi/ui';
import {
  Container,
  LabelContainer,
  Placeholder,
  StyledInputLabel,
  StyledMenuItem,
  StyledSelect,
  StyledSelectWrapper,
} from 'components/flexFlow/driver/additionalDriver/QuickDriverDialog.styles';
import { ProgressOverlay } from 'components/shared/ui/spinner/ProgressOverlay';
import { useSaveDriver } from 'components/flexFlow/driver/useSaveDriver';
import { EMPTY_VALUE } from 'utils/constants';

interface QuickDriverDialogProps {
  onClose: () => void;
  driverCount: number;
}

export const QuickDriverDialog: FC<QuickDriverDialogProps> = ({ onClose, driverCount = 0 }) => {
  const { t } = useTranslations();
  const { updateNoProfileAdditionalDriversOnEditor } = useSaveDriver();

  const [numDrivers, setNumDrivers] = useState<string>(EMPTY_VALUE);
  const [loading, setLoading] = useState(false);

  const handleNumDriversChange = (event: SelectChangeEvent<unknown>): void => {
    setNumDrivers(() => event.target.value as string);
  };

  const onApplyClick = async (): Promise<void> => {
    setLoading(true);
    const { errors } = await updateNoProfileAdditionalDriversOnEditor(Number(numDrivers)).finally(() =>
      setLoading(false)
    );

    if (!errors) {
      onClose();
    }
  };

  const options = [];
  for (let i = 1; i <= 9 - driverCount; i++) {
    options.push({ label: i.toString(), value: i.toString() });
  }

  return (
    <Dialog
      data-testid='quick-add-driver-dialog'
      contentPadding={0}
      open={true}
      title={t('driver.additionalDrivers.addAdditionalDrivers')}
      a11yKey='content'
      maxWidth={'sm'}
      fullWidth
      actions={{
        secondaryAction: {
          label: t('common.cancel'),
          onClick: onClose,
        },
        primaryAction: {
          label: t('driver.additionalDrivers.addDrivers'),
          onClick: onApplyClick,
          overrideButtonProps: {
            disabled: !numDrivers,
          },
        },
      }}>
      <Container data-testid='dialog-container'>
        <H6 data-testid='dialog-title'>{t('driver.additionalDrivers.additionalDrivers')}</H6>
        <LabelContainer data-testid='label-container'>
          {numDrivers && (
            <StyledInputLabel htmlFor='num-drivers' data-testid='input-label'>
              {t('driver.additionalDrivers.numberDrivers')}
            </StyledInputLabel>
          )}

          <StyledSelectWrapper data-testid='select-wrapper'>
            <StyledSelect
              labelId='num-drivers-label'
              id='num-drivers'
              value={numDrivers}
              onChange={handleNumDriversChange}
              displayEmpty
              renderValue={(selected): ReactElement | string => {
                if (selected === '') {
                  return (
                    <Placeholder data-testid='placeholder'>{t('driver.additionalDrivers.numberDrivers')}</Placeholder>
                  );
                }
                return selected as string;
              }}
              data-testid='quickDriverSelect'>
              {options.map((option) => (
                <StyledMenuItem key={option.value} value={option.value} data-testid={`menu-item-${option.value}`}>
                  {option.label}
                </StyledMenuItem>
              ))}
            </StyledSelect>
          </StyledSelectWrapper>
        </LabelContainer>
      </Container>
      <ProgressOverlay inProgress={loading} />
    </Dialog>
  );
};
