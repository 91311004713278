import {
  FetchQueryOptions,
  QueryClient,
  QueryFilters,
  QueryKey,
  QueryObserverOptions,
  useQuery,
  UseQueryOptions,
  UseQueryResult,
} from '@tanstack/react-query';
import { DateTime } from 'luxon';
import {
  DOMAIN_CACHE_OPTIONS,
  RES_SCOPE_PREFIX,
  SESSION_CACHE_OPTIONS,
  TRANSACTION_CACHE_OPTIONS,
} from 'context/queryClient/cacheOptions';
import { getCurrentIso3Locale } from 'components/shared/i18n/locales';
import { EhiErrors } from 'services/types/EhiErrorsTypes';
import { useLocale } from 'components/shared/i18n';
import { isEhiError } from 'utils/errorUtils';

const doNotRetryCodes: number[] = [400, 401, 403, 404, 500];

interface ShouldRequestRetryOptions {
  failureTolerance?: number;
  additionalCodes?: number[];
}
/**
 * React Query calls the API multiple times on errors due to its built-in retry mechanism.By default, retries failed queries up to 3 times.
 * This is intended to handle temporary network issues or server errors. If the error persists, it can lead to multiple API calls.
 * Instead of disabling retries, we are limiting retries on errors. This will help especially when domain service is down.
 * */
export function shouldRequestRetry(
  failureCount: number,
  error: unknown,
  options: ShouldRequestRetryOptions = {}
): boolean {
  const { failureTolerance = 3, additionalCodes = [] } = options;
  const skipCodes = doNotRetryCodes.concat(additionalCodes);
  if (!isEhiError(error)) {
    return failureCount < failureTolerance;
  }
  return failureCount < failureTolerance && !skipCodes.includes(error.status as number);
}

export const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      // For persist to work properly, we want to pass QueryClient a cacheTime value to override the default during hydration.
      ...SESSION_CACHE_OPTIONS,
      refetchOnWindowFocus: false,
      // eslint-disable-next-line @typescript-eslint/explicit-function-return-type
      retry: (failureCount, error) => shouldRequestRetry(failureCount, error),
      // allows queries to resolve with offline cache if available
      networkMode: 'offlineFirst',
    },
  },
});

export function fetchQuery<T>(options: FetchQueryOptions<T>): Promise<T> {
  return queryClient.fetchQuery<T>(options);
}

export function getCache<T>(cacheKey: Array<string>): T | undefined {
  return queryClient.getQueryData<T>(cacheKey);
}

export function setCache<T>(cacheKey: Array<string>, data: T): T | undefined {
  return queryClient.setQueryData(cacheKey, data, { updatedAt: DateTime.now().toMillis() });
}

export function prefetch<T>(options: FetchQueryOptions<T>): Promise<void> {
  return queryClient.prefetchQuery<T>(options);
}

export function removeCache(filters?: QueryFilters): void {
  queryClient.removeQueries(filters);
}
export function updateCacheOptions(cacheKey: Array<string>, options: QueryObserverOptions): void {
  queryClient.setQueryDefaults(cacheKey, options);
}

export type OurQueryKey = string | (string | undefined)[];

export type OurQueryArgs<TQueryFnData> = Omit<UseQueryOptions<TQueryFnData, EhiErrors>, 'queryKey'> & {
  // override queryKey so that it's not optional, and must be a string or undefined
  queryKey: OurQueryKey;
};

export function generateQueryKeyWithLocale(
  key: (string | undefined)[] | string | undefined | QueryKey,
  locale?: string
): QueryKey {
  const currentLanguage = locale || getCurrentIso3Locale();

  return !Array.isArray(key) ? [currentLanguage, key] : [currentLanguage, ...key];
}

/**
 * Caches domain data for 15 hrs"
 */

export function fetchDomainQuery<T>({ queryKey, queryFn, ...options }: FetchQueryOptions<T>): Promise<T> {
  return queryClient.fetchQuery({
    queryKey: generateQueryKeyWithLocale(queryKey),
    queryFn,
    ...DOMAIN_CACHE_OPTIONS,
    ...options,
  });
}

export const useDomainQuery = <TQueryFnData = unknown>({
  queryKey,
  queryFn,
  ...options
}: OurQueryArgs<TQueryFnData>): UseQueryResult<TQueryFnData, EhiErrors> => {
  const { servicesIso3Locale } = useLocale();

  return useQuery({
    queryKey: generateQueryKeyWithLocale(queryKey, servicesIso3Locale),
    queryFn,
    ...DOMAIN_CACHE_OPTIONS,
    ...options,
  });
};

/**
 * Ensures query data is saved only within ticket scope, will be cleared upon leaving session or starting new session
 */
export const useResScopeQuery = <TQueryFnData = unknown>(
  options: OurQueryArgs<TQueryFnData>
): UseQueryResult<TQueryFnData, EhiErrors> => {
  return useQuery(buildResScopeQueryOptions(options));
};

/**
 * Extracted for easier use in combination with useQueries
 */
export const buildResScopeQueryOptions = <TQueryFnData = unknown>({
  queryKey,
  queryFn,
  ...options
}: OurQueryArgs<TQueryFnData>): UseQueryOptions<TQueryFnData, EhiErrors> => {
  return {
    queryKey: generateQueryKeyWithLocale([RES_SCOPE_PREFIX, ...queryKey]),
    queryFn,
    ...options,
    // Make ticket cache options last so they cannot be overriden
    ...TRANSACTION_CACHE_OPTIONS,
  };
};
