import { Box, Grid } from '@mui/material';
import { useTranslations } from 'components/shared/i18n';
import { Body2, EhiButton, ehiTheme, H6 } from '@ehi/ui';
import {
  RateAndBillingInfoBox,
  RateAndBillingLabel,
  RateAndBillingSubSectionBox,
  RateAndBillingText,
  RateAndBillingTitleBox,
  StyledEhiEditButton,
} from 'components/flexFlow/rateAndBilling/RateAndBilling.styles';
import { useAppSelector } from 'redux/hooks';
import { selectBookingEditorId, selectIsReadOnlyFlow, selectPickup } from 'redux/selectors/bookingEditor';
import { FC, useCallback, useMemo, useState } from 'react';
import { EditBillToDialog } from 'components/flexFlow/rateAndBilling/editDialogs/billTo/EditBillToDialog';
import { mask } from 'utils/maskUtils';
import { AccountSearchProvider } from 'context/accountSearch/AccountSearchContext';
import { SameAsRateSourceDialog } from 'components/flexFlow/rateAndBilling/editDialogs/billTo/SameAsRateSourceDialog';
import { ProgressOverlay } from 'components/shared/ui/spinner/ProgressOverlay';
import { updateBusinessPayers } from 'services/booking/bookingService';
import { useUpdateAndRefreshEditor } from 'hooks/bookingEditor/useUpdateAndRefreshEditor';
import { useAlert } from 'components/shared/alert/AlertContext';
import { SelectedAction } from 'components/shared/alert/AlertDialogTypes';
import { useRetrieveBillingAccount } from 'services/businessAccount/useRetrieveBillingAccount';
import { useRateSourceAccountDetails } from 'services/businessAccount/useRateSourceAccountDetails';
import { NoteBanner } from 'components/shared/banner/NoteBanner';

export const BillToSection: FC = () => {
  const { t } = useTranslations();
  const isReadOnly = useAppSelector(selectIsReadOnlyFlow);
  const bookingEditorId = useAppSelector(selectBookingEditorId);
  const pickUpInfo = useAppSelector(selectPickup);
  const hasStartLocation = useMemo(() => !!pickUpInfo?.branch, [pickUpInfo?.branch]);
  const [addFromRateSourceModal, setAddFromRateSourceModal] = useState(false);
  const [editBillToModal, setEditBillToModal] = useState(false);
  const [isUpdating, setIsUpdating] = useState(false);
  const { updateAndRefresh } = useUpdateAndRefreshEditor();
  const { showAlert } = useAlert();

  const { rateSourceInfo } = useRateSourceAccountDetails();
  const {
    billingAccountNumber,
    billingNumber,
    billingAccountInfo,
    isLoading: isBillingAccountInfoLoading,
  } = useRetrieveBillingAccount();

  const isSameAsRateSourceButton = useMemo(() => {
    if (billingAccountNumber || isReadOnly || !hasStartLocation) {
      return false;
    }

    if (rateSourceInfo?.hasBillingAccount || rateSourceInfo?.isBillingNumberRequired) {
      return true;
    }
  }, [
    billingAccountNumber,
    hasStartLocation,
    isReadOnly,
    rateSourceInfo?.hasBillingAccount,
    rateSourceInfo?.isBillingNumberRequired,
  ]);

  const handleRemovingBillingAccount = useCallback(async () => {
    const selectedAction = await showAlert({
      variant: 'destructive',
      title: t('rateAndBilling.billTo.removeBillTo'),
      description: t('rateAndBilling.billTo.billToRemovalConfirmation'),
      primaryActionText: t('common.remove'),
      secondaryActionText: t('common.cancel'),
    });
    if (selectedAction === SelectedAction.Primary) {
      setIsUpdating(true);
      const { errors } = await updateAndRefresh(async () => updateBusinessPayers(bookingEditorId, []));
      setIsUpdating(false);
      if (errors) {
        await showAlert({ responseMessages: errors });
      }
    }
  }, [bookingEditorId, showAlert, t, updateAndRefresh]);

  return (
    <RateAndBillingSubSectionBox data-testid='billToSection'>
      {!hasStartLocation && (
        <Box padding={ehiTheme.spacing(1.5, 0, 3, 0)}>
          <NoteBanner description={t('rateAndBilling.billTo.billToNoteDescription')} />
        </Box>
      )}
      <RateAndBillingTitleBox>
        <H6>{t('rateAndBilling.billTo.pageTitle')}</H6>
        <Box>
          {isSameAsRateSourceButton && (
            <EhiButton onClick={(): void => setAddFromRateSourceModal(true)}>
              {t('rateAndBilling.billTo.sameAsRateSource')}
            </EhiButton>
          )}
          {!isReadOnly && hasStartLocation && (
            <StyledEhiEditButton onClick={(): void => setEditBillToModal(true)}>{t('common.edit')}</StyledEhiEditButton>
          )}
        </Box>
      </RateAndBillingTitleBox>
      <Grid container>
        <Grid item xs={6} sm={6} data-testid='accountNumber'>
          <RateAndBillingLabel>{t('rateAndBilling.accountNumber')}</RateAndBillingLabel>
          {billingAccountNumber && <RateAndBillingText bold>{billingAccountNumber}</RateAndBillingText>}
        </Grid>
        <Grid item xs={6} sm={6} data-testid='billingNumber'>
          <RateAndBillingLabel>{t('rateAndBilling.billTo.billingNumber')}</RateAndBillingLabel>
          {billingNumber && <RateAndBillingText bold>{mask(billingNumber, 4)}</RateAndBillingText>}
        </Grid>
        {(billingAccountInfo?.name || billingAccountInfo?.type) && (
          <RateAndBillingInfoBox data-testid='billingAccountInfo' style={{ flexDirection: 'column' }}>
            <Grid container display={'flex'} alignItems={'center'}>
              <Grid item xs={9} sm={9}>
                <Box display={'flex'} alignItems={'center'}>
                  {billingAccountInfo?.name && (
                    <H6 data-testid='accountName' paddingRight={ehiTheme.spacing(1.5)}>
                      {billingAccountInfo?.name}
                    </H6>
                  )}
                  {billingAccountInfo?.type && (
                    <Body2 color={'#555759'} data-testid='accountType'>
                      {billingAccountInfo?.type}
                    </Body2>
                  )}
                </Box>
              </Grid>
              {!isReadOnly && (
                <Grid item xs={3} sm={3} display={'flex'} justifyContent={'flex-end'}>
                  <EhiButton
                    variant={'text'}
                    color={'error'}
                    size={'small'}
                    style={{ margin: 0 }}
                    onClick={handleRemovingBillingAccount}>
                    {t('common.remove')}
                  </EhiButton>
                </Grid>
              )}
            </Grid>
          </RateAndBillingInfoBox>
        )}
      </Grid>
      {addFromRateSourceModal && (
        <SameAsRateSourceDialog
          billingNumber={rateSourceInfo?.billingNumber ?? ''}
          onClose={(): void => setAddFromRateSourceModal(false)}
        />
      )}
      {editBillToModal && (
        <AccountSearchProvider>
          <EditBillToDialog onClose={(): void => setEditBillToModal(false)} />
        </AccountSearchProvider>
      )}
      <ProgressOverlay inProgress={isBillingAccountInfoLoading || isUpdating} />
    </RateAndBillingSubSectionBox>
  );
};
