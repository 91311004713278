import { FC, useMemo, useState } from 'react';
import { useTranslations } from 'components/shared/i18n';
import { Dialog } from 'components/shared/ui/dialogs/Dialog';
import { AddPreferencesTextBox } from 'components/notes/notesTab/cards/preferenceCard/PreferencesCard.styles';
import { updateRemarkForReservationEditor, deleteRemarkForReservationEditor } from 'services/booking/bookingService';
import { useAppSelector } from 'redux/hooks';
import { selectBookingEditorId, selectRemarks } from 'redux/selectors/bookingEditor';
import { useAlert } from 'components/shared/alert/AlertContext';
import { useUpdateAndRefreshEditor } from 'hooks/bookingEditor/useUpdateAndRefreshEditor';
import { useYupValidationResolver } from 'components/shared/forms/useYupValidationResolver';
import {
  addPreferencesInitialValues,
  addPreferencesSchema,
} from 'components/notes/notesTab/cards/preferenceCard/addPreferencesUtils';
import { FormProvider, useForm } from 'react-hook-form';
import {
  AddPreferencesFields,
  AddPreferencesValues,
} from 'components/notes/notesTab/cards/preferenceCard/AddPreferenceTypes';
import { TextAreaField } from 'components/shared/forms/TextAreaField';
import { ProgressOverlay } from 'components/shared/ui/spinner/ProgressOverlay';

const MAX_CHAR_LENGTH = 150;

export type PreferencesDialogProps = {
  onClose: () => void;
};

export const AddPreferencesDialog: FC<PreferencesDialogProps> = ({ onClose }) => {
  const { t } = useTranslations();

  const resolver = useYupValidationResolver(addPreferencesSchema(t));
  const bookingEditorId = useAppSelector(selectBookingEditorId);
  const preferences = useAppSelector(selectRemarks);

  const formInitialValues: AddPreferencesValues = useMemo(
    () => addPreferencesInitialValues(preferences),
    [preferences]
  );

  const formMethods = useForm<AddPreferencesValues>({
    resolver: resolver,
    defaultValues: formInitialValues,
    mode: 'onSubmit',
    reValidateMode: 'onSubmit',
  });

  const preferencesInput = formMethods.watch(AddPreferencesFields.Preferences);

  const { showAlert } = useAlert();
  const { updateAndRefresh } = useUpdateAndRefreshEditor();

  const [loading, setLoading] = useState(false);

  const handleSubmit = async (): Promise<void> => {
    setLoading(true);
    const { errors } = await updateAndRefresh(async (): Promise<void> => {
      preferencesInput?.trim().length > 0
        ? await updateRemarkForReservationEditor(bookingEditorId, preferencesInput)
        : await deleteRemarkForReservationEditor(bookingEditorId);
    });
    if (errors) {
      setLoading(false);
      await showAlert({ responseMessages: errors });
    } else {
      setLoading(false);
      onClose();
    }
  };

  return (
    <FormProvider {...formMethods}>
      <Dialog
        a11yKey={'add-preferences'}
        data-testid='addPreferencesDialog'
        open={true}
        fullWidth
        maxWidth={'sm'}
        title={t('notes.addPreferences')}
        contentPadding={0}
        actions={{
          primaryAction: {
            label: t('common.submit'),
            onClick: formMethods.handleSubmit(handleSubmit),
            overrideButtonProps: { disabled: preferences === preferencesInput },
          },
          secondaryAction: { label: t('common.cancel'), onClick: onClose },
        }}>
        <AddPreferencesTextBox>
          <TextAreaField
            header={t('notes.preferences')}
            displayHeader={false}
            name={AddPreferencesFields.Preferences}
            maxChars={MAX_CHAR_LENGTH}
            rows={6}
            marginBottom={'0'}
            errorIconMarginBottom={13.0}
            errorIconMarginRight={1.0}
          />
          <ProgressOverlay inProgress={loading} />
        </AddPreferencesTextBox>
      </Dialog>
    </FormProvider>
  );
};
