import { useAlert } from 'components/shared/alert/AlertContext';
import { Option } from 'components/shared/ui/OptionTypes';
import { DateTime } from 'luxon';
import { useCallback, useState } from 'react';
import { useAppSelector } from 'redux/hooks';
import { selectBrand, selectPickup, selectReturn } from 'redux/selectors/bookingEditor';
import { searchRatePlans } from 'services/ratePlan/ratePlanService';
import { Brand } from 'utils/constants';
import { safelyCatchError } from 'utils/errorUtils';
import { parseUrn } from 'utils/urnUtils';

export type UseRetrieveRatePlansTypes = {
  retrieveRatePlans: (accountUrn: string) => Promise<Option[] | undefined>;
  ratePlanOptions: Option[];
  clearRatePlans: () => void;
};
export const useRetrieveRatePlans = (): UseRetrieveRatePlansTypes => {
  const [ratePlanOptions, setRatePlanOptions] = useState<Option[]>([]);
  const { showAlert } = useAlert();
  const brand = useAppSelector(selectBrand);
  const pickupData = useAppSelector(selectPickup);
  const returnData = useAppSelector(selectReturn);
  const pickupDateTime = pickupData?.dateTime;
  const returnDateTime = returnData?.dateTime;
  const pickupBranch = pickupData?.branch;
  const returnBranch = pickupData?.branch;

  const clearRatePlans = (): void => {
    setRatePlanOptions([]);
  };

  const retrieveRatePlans = useCallback(
    async (accountUrn: string): Promise<Option[] | undefined> => {
      if (
        !(
          accountUrn &&
          brand &&
          parseUrn(brand) === Brand.ENTERPRISE &&
          pickupDateTime &&
          returnDateTime &&
          pickupBranch &&
          returnBranch
        )
      ) {
        return [];
      }

      try {
        const data = await searchRatePlans({
          businessAccount: accountUrn,
          pickupLocation: pickupBranch,
          pickupDateTime: pickupDateTime,
          returnDateTime: returnDateTime,
          returnLocation: returnBranch,
          quotedDateTime: DateTime.now().toString(),
        });
        const ratePlans = data
          ?.map((item) => {
            return { value: item.id, label: item.name };
          })
          ?.sort((a, b) => a.label.localeCompare(b.label));
        setRatePlanOptions(ratePlans);

        return ratePlans;
      } catch (error) {
        const ehiErrorsResponse = safelyCatchError(error);
        if (ehiErrorsResponse?.errors) {
          await showAlert({ responseMessages: ehiErrorsResponse?.errors });
        }
      }
    },
    [pickupBranch, returnBranch, pickupDateTime, returnDateTime, brand, showAlert]
  );

  return { retrieveRatePlans, ratePlanOptions, clearRatePlans };
};
