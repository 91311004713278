import { ServiceResultType } from 'services/types/ServiceResultTypes';
import { useRefreshEditor } from 'hooks/bookingEditor/useRefreshEditor';
import { useCallback } from 'react';
import { isEhiError, safelyCatchError } from 'utils/errorUtils';

export type UpdateAndRefreshEditorHook = {
  /**
   * @param updateFn Function used for update call to rental editor.  May be promise that throws errors or returns ServiceResult.
   * @returns Result of call chain (errors or success, will not return data from updateFn)
   */

  updateAndRefresh: (updateFn: () => Promise<unknown>) => Promise<ServiceResultType>;
};

export const useUpdateAndRefreshEditor = (): UpdateAndRefreshEditorHook => {
  const { refreshEditor } = useRefreshEditor();

  const updateAndRefresh = useCallback(
    async (updateFn: () => Promise<unknown>): Promise<ServiceResultType> => {
      try {
        const updateResult = await updateFn();

        if (isEhiError(updateResult) && updateResult.errors) {
          // if update failed, return errors
          return { errors: updateResult.errors };
        } else {
          // if update was successful, refresh editor
          const refreshResult = await refreshEditor(undefined);
          return {
            data: refreshResult?.data,
            success: !refreshResult?.errors,
            errors: refreshResult?.errors,
          };
        }
      } catch (er) {
        // If updateFn throws, return errors
        return safelyCatchError(er);
      }
    },
    [refreshEditor]
  );

  return { updateAndRefresh };
};
