import { TFunction } from 'i18next';
import { OptionItem } from 'components/shared/forms/FormFieldTypes';
import { DateTime } from 'luxon';
import { compareDateTime, MixedDateTime } from 'utils/dateUtils';
import { CardBrand } from 'services/payment/paymentReferenceTypes';
import { DriverData, PaymentMethod } from 'components/shared/uiModels/driver/driverDataTypes';
import { BranchEffectivePolicy } from 'services/location/locationTypes';
import { DEBT } from './constants';
import { formatCurrency } from './currencyUtils';
import { Charges } from 'services/booking/bookingTypes';
import { Iso2Locales } from '@ehi/location';

export enum paymentTypes {
  Business_Payer = 'BUSINESS_PAYER',
  Credit_Card = 'CREDIT_CARD',
  Debit_Card = 'DEBIT_CARD',
  Other = 'UNKNOWN',
}

export type TransformedPaymentDetails = {
  payerName: string;
  hasSavedCreditCardPaymentMethod: boolean | undefined;
  paymentMethods: PaymentMethod[] | undefined;
  renterCharges: string;
};

export const getPaymentTypeOptions = (
  t: TFunction<'translation'>,
  policies?: BranchEffectivePolicy[]
): OptionItem[] => {
  const hasDebitPolicy = !!policies?.find((policy) => policy.name.includes(DEBT));
  const paymentOptions = [
    { id: paymentTypes.Credit_Card, label: t('payment.credit') },
    { id: paymentTypes.Business_Payer, label: t('payment.billTo') },
    { id: paymentTypes.Other, label: t('common.other') },
  ];

  if (hasDebitPolicy) {
    paymentOptions.splice(1, 0, { id: paymentTypes.Debit_Card, label: t('payment.debit') });
  }
  return paymentOptions;
};

export const formatPayerName = (firstName?: string, lastName?: string): string => {
  if (lastName && firstName) {
    return `${firstName} ${lastName}`;
  } else if (!firstName && lastName) {
    return lastName;
  } else if (firstName && !lastName) {
    return firstName;
  }
  return '';
};

export const getPaymentType = (t: TFunction<'translation'>, paymentType?: string): string => {
  if (!paymentType) {
    return '';
  }

  return getPaymentTypeOptions(t).find((option) => option.id === paymentType)?.label ?? paymentType;
};

export const sortPaymentMethods = (methods: PaymentMethod[]): PaymentMethod[] => {
  const defaultMethod = methods.find((method) => method.isDefault);
  const otherMethods = methods.filter((method) => !method.isDefault);

  return defaultMethod ? [defaultMethod, ...otherMethods] : [...otherMethods];
};

export const getCCBrand = (cardCode?: string, cardBrands?: CardBrand[]): string => {
  if (!cardCode) {
    return '--';
  }

  return cardBrands?.find((brand) => brand.legacyCode === cardCode)?.name ?? cardCode;
};

export const formatExpirationDate = (t: TFunction<'translation'>, date?: string): string => {
  if (!date) {
    return '--';
  }

  return DateTime.fromFormat(date, t('format.monthDayYearShort')).toFormat(t('format.monthYear'));
};

export const checkForExpiredCard = (
  t: TFunction<'translation'>,
  getLocalizedDateTime: (timezone: string, dateTime?: MixedDateTime) => DateTime | undefined,
  expirationDate?: string,
  timezone?: string
): boolean => {
  const expirationDateEOM =
    expirationDate && DateTime.fromFormat(expirationDate, t('format.monthDayYearShort')).endOf('month');

  const currentLocationTime = timezone && (getLocalizedDateTime(timezone, DateTime.now()) as DateTime);

  return compareDateTime(currentLocationTime, expirationDateEOM);
};

const isSavedCreditCardMethod = (driverData: DriverData): boolean | undefined => {
  return (
    driverData?.paymentMethods &&
    !!driverData?.paymentMethods.find((method) => {
      return method.type === paymentTypes.Credit_Card;
    })
  );
};

const getRenterCharges = (locale: Iso2Locales, charges?: Charges): string => {
  return typeof charges?.totalCharges?.amount === 'number'
    ? formatCurrency(charges.totalCharges.amount, charges?.totalCharges?.currencyCode, locale)
    : formatCurrency(0.0, charges?.totalCharges?.currencyCode || '', locale);
};

const getPaymentMethods = (driverData: DriverData): PaymentMethod[] | undefined => {
  if (isSavedCreditCardMethod(driverData) && driverData?.paymentMethods) {
    return sortPaymentMethods(driverData?.paymentMethods);
  }
  return undefined;
};

export const transformPaymentDetails = (
  driverData: DriverData,
  locale: Iso2Locales,
  charges?: Charges
): TransformedPaymentDetails => {
  return {
    payerName: formatPayerName(driverData?.firstName, driverData?.lastName),
    hasSavedCreditCardPaymentMethod: isSavedCreditCardMethod(driverData),
    paymentMethods: getPaymentMethods(driverData),
    renterCharges: getRenterCharges(locale, charges),
  };
};
