import { Box, Grid, Typography, styled } from '@mui/material';
import { Caption, ehiTheme } from '@ehi/ui';
import { Caption2 } from 'components/shared/ui/styles/Typography.styles';
import { FlexFlowCard, FlexiFlowCardInnerContainer } from 'components/shared/ui/card/Card';

export const StyledBox = styled(Box)`
  display: flex;
  flex-direction: column;
`;

export const StyledGridContainer = styled(Grid)`
  display: flex;
  padding: ${ehiTheme.spacing(2)};
`;
StyledGridContainer.defaultProps = {
  container: true,
  spacing: 2,
};

export const VehicleSpecGridItem = styled(Grid)`
  display: flex;
  align-items: center;
  color: #0000008a;
`;
VehicleSpecGridItem.defaultProps = {
  item: true,
};

export const VehicleSpecText = styled(Caption)`
  padding-left: ${ehiTheme.spacing(0.5)};
`;

export const EstimatedChargesTitle = styled(Box)`
  margin-top: ${ehiTheme.spacing(4)};
  margin-bottom: ${ehiTheme.spacing(2)};
`;

export const EstimatedChargesContent = styled(Box)`
  background-color: #f5f5f5;
  padding: 32px;
  border-radius: 8px;
`;

export const DynamicGrid = styled(Grid, {
  shouldForwardProp: (prop) => prop !== 'py' && prop !== 'pb',
})<{ py?: number; pb?: number }>`
  border-bottom: 1px solid #e0e0e0;
  padding-top: ${({ py = 0 }): string => `${py * 8}px`};

  padding-bottom: ${({ pb = 0, py = 0 }): string => `${pb ? pb * 8 : py * 8}px`};
`;

export const EstimatedTotalText = styled(Caption2)`
  font-size: 13px;
  line-height: 16px;
  letter-spacing: 0.4px;
  color: #6b6b6b;
  margin-bottom: 4px;
  text-align: right;
  display: block;
`;

export const EstimatedTotalAmountText = styled(Typography)`
  font-size: 34px;
  font-weight: 400;
  line-height: 36px;
  text-align: right;
`;

export const ReviewPage = styled(Box)({
  height: '0px',
  width: '100%',
  flexGrow: '1',
});

export const ReviewFlexFlowCard = styled(FlexFlowCard)({
  height: 'auto',
  maxHeight: '100%',
  display: 'flex',
  flexDirection: 'column',
});

export const ReviewScrollContainer = styled(FlexiFlowCardInnerContainer)({
  border: 0,
  margin: ehiTheme.spacing(0),
  padding: ehiTheme.spacing(3),
  height: 'auto',
  maxHeight: '100%',
  overflowY: 'scroll',
});
