import { useCallback, useMemo, useState } from 'react';
import { useStartReservationSession } from 'components/shared/preprocessor/useStartReservationSession';
import {
  getPersistedEditorSessionData,
  getPersistedEditorSessionDataForResNumber,
  getPersistedReservationData,
} from 'components/shared/preprocessor/ReservationSessionHelper';
import { useParams } from 'react-router-dom';
import { ServiceState } from 'services/types/ServiceStateTypes';
import { AppErrorCode, UNKNOWN_ERROR } from 'utils/errorUtils';
import { setBookingEditorId } from 'redux/slices/booking/bookingEditorSlice';
import { useAppDispatch } from 'redux/hooks';
import { useRefreshEditor } from 'hooks/bookingEditor/useRefreshEditor';
import { ResponseMessage } from 'services/types/ResponseMessageTypes';
import { ReservationEditor } from 'services/booking/bookingTypes';
import { ServiceResultType } from 'services/types/ServiceResultTypes';

export type UseReservationRouteWrapperHookReturnTypes = {
  retrieveSessionForView: () => Promise<void>;
  retrieveSessionForCreate: () => Promise<ServiceResultType<ReservationEditor> | undefined>;
  retrieveSessionForModify: () => Promise<ServiceResultType<ReservationEditor> | undefined>;
  serviceState: ServiceState;
};

export const useReservationRouteWrapper = (): UseReservationRouteWrapperHookReturnTypes => {
  const { resNumber = '' } = useParams();
  const { startRetrieveReservationSession } = useStartReservationSession();
  const [sessionLoadingState, setSessionLoadingState] = useState<ServiceState>({});
  const { refreshEditor } = useRefreshEditor();
  const dispatch = useAppDispatch();

  const bookingEditorNotFound: ResponseMessage[] = useMemo(
    () => [
      {
        code: AppErrorCode.bookingEditorNotFound,
        localizedMessage: 'Unable to retrieve editor session',
        severity: 'ERROR',
      },
    ],
    []
  );

  const retrieveSessionForView = useCallback(async () => {
    const persistedData = getPersistedReservationData(resNumber);
    if (persistedData && persistedData.resNumber) {
      setSessionLoadingState({ loading: true });
      const { errors } = await startRetrieveReservationSession(resNumber);
      setSessionLoadingState({ success: !errors, errors: errors });
    } else {
      setSessionLoadingState({
        errors: [
          {
            ...UNKNOWN_ERROR,
            supportInfo: 'FAILED TO RETRIEVE RESERVATION',
          },
        ],
      });
    }
  }, [resNumber, startRetrieveReservationSession]);

  const retrieveSessionForCreate = useCallback(async (): Promise<ServiceResultType<ReservationEditor> | undefined> => {
    const persistedEditorData = getPersistedEditorSessionData();
    if (persistedEditorData && persistedEditorData.editorId) {
      setSessionLoadingState({ loading: true });
      // Note: refreshEditor sets reservation in redux
      const { data: editor, errors: editorErrors } = await refreshEditor(persistedEditorData.editorId);

      if (!editor) {
        setSessionLoadingState({ success: !editorErrors, errors: editorErrors });
        return { errors: editorErrors };
      } else {
        setSessionLoadingState({ loading: false });
        dispatch(setBookingEditorId(persistedEditorData.editorId));

        return { data: editor };
      }
    } else {
      setSessionLoadingState({
        errors: bookingEditorNotFound,
      });
      return undefined;
    }
  }, [bookingEditorNotFound, dispatch, refreshEditor]);

  const retrieveSessionForModify = useCallback(async (): Promise<ServiceResultType<ReservationEditor> | undefined> => {
    const persistedEditorData = getPersistedEditorSessionDataForResNumber(resNumber);
    if (persistedEditorData && persistedEditorData.editorId) {
      setSessionLoadingState({ loading: true });

      // Note: refreshEditor sets reservation in redux
      const { data: editor, errors: editorErrors } = await refreshEditor(persistedEditorData.editorId);

      if (!editor) {
        setSessionLoadingState({ success: !editorErrors, errors: editorErrors });
        return { errors: editorErrors };
      } else {
        setSessionLoadingState({ loading: false });
        dispatch(setBookingEditorId(persistedEditorData.editorId));

        return { data: editor };
      }
    } else {
      setSessionLoadingState({
        errors: bookingEditorNotFound,
      });
      return undefined;
    }
  }, [bookingEditorNotFound, dispatch, refreshEditor, resNumber]);

  return {
    serviceState: { ...sessionLoadingState },
    retrieveSessionForView: retrieveSessionForView,
    retrieveSessionForCreate: retrieveSessionForCreate,
    retrieveSessionForModify: retrieveSessionForModify,
  };
};
