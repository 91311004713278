import React, { FC, ReactElement, useCallback, useRef, useState } from 'react';
import { Dialog } from 'components/shared/ui/dialogs/Dialog';
import { useTranslations } from 'components/shared/i18n';
import { Autocomplete, Box, Chip, TextField } from '@mui/material';
import { Controller, FormProvider, useForm } from 'react-hook-form';
import { Body1, ehiTheme } from '@ehi/ui';
import { useYupValidationResolver } from 'components/shared/forms/useYupValidationResolver';
import * as Yup from 'yup';
import CancelIcon from '@mui/icons-material/Cancel';
import { ENTER_KEY, SPACE_KEY, TAB_KEY } from 'utils/constants';
import { useAppSelector } from 'redux/hooks';
import { selectCarrierSafetyIdentifiers } from 'redux/selectors/bookingEditor';
import {
  CarrierSafetyNumberDialogProps,
  CarrierSafetyNumbersForm,
} from 'components/flexFlow/rateAndBilling/editDialogs/rateSource/carrierSafetyNumber/EditCarrierSafetyNumberDialog.types';
import { useCarrierSafetyNumberUtility } from 'components/flexFlow/rateAndBilling/editDialogs/rateSource/carrierSafetyNumber/useCarrierSafetyNumberUtility';
import { useUpdateAndRefreshEditor } from 'hooks/bookingEditor/useUpdateAndRefreshEditor';
import { ProgressOverlay } from 'components/shared/ui/spinner/ProgressOverlay';

const isValidCarrierSafetyNumber = (carrierSafetyNumber: string): boolean => {
  return !(
    (carrierSafetyNumber && carrierSafetyNumber.length < 2) ||
    (carrierSafetyNumber && carrierSafetyNumber.length > 20) ||
    !carrierSafetyNumber?.match(/^[a-zA-Z0-9-]+$/)
  );
};

export const EditCarrierSafetyNumberDialog: FC<CarrierSafetyNumberDialogProps> = ({ closeModal }) => {
  const { t } = useTranslations();
  const carrierSafetyIdentifiers = useAppSelector(selectCarrierSafetyIdentifiers);
  const { updateCarrierSafetyIdentifier } = useCarrierSafetyNumberUtility();
  const { updateAndRefresh } = useUpdateAndRefreshEditor();
  const [loading, setLoading] = useState<boolean>(false);
  const inputRef = useRef<HTMLInputElement | null>(null);
  const formMethods = useForm<CarrierSafetyNumbersForm>({
    mode: 'onChange',
    reValidateMode: 'onChange',
    resolver: useYupValidationResolver(
      Yup.object().shape({
        addedCSNs: Yup.array()
          .of(Yup.string())
          .test('addedCSNs', t('validation.invalidCarrierSafetyIdentifier'), (value) => {
            return !value?.some((csn) => csn && !isValidCarrierSafetyNumber(csn));
          }),
      })
    ),
    defaultValues: {
      addedCSNs: carrierSafetyIdentifiers ?? [],
    },
  });
  const addedCSNs = formMethods.watch('addedCSNs');

  const handleKeyDown = (event: any): void => {
    switch (event.code) {
      case SPACE_KEY:
      case TAB_KEY: {
        event.preventDefault();
        event.stopPropagation();
        if (inputRef.current) {
          inputRef.current?.dispatchEvent(
            new KeyboardEvent('keydown', {
              key: ENTER_KEY,
              code: ENTER_KEY,
              bubbles: true,
              cancelable: true,
            })
          );
        }
        break;
      }
      default:
    }
  };

  const handleClosingModal = useCallback(() => {
    formMethods.resetField('addedCSNs');
    closeModal();
  }, [closeModal, formMethods]);

  const handleSave = useCallback(async () => {
    if (inputRef.current) {
      inputRef.current?.dispatchEvent(
        new KeyboardEvent('keydown', {
          key: ENTER_KEY,
          code: ENTER_KEY,
          bubbles: true,
          cancelable: true,
        })
      );
    }

    await formMethods.handleSubmit(async () => {
      setLoading(true);
      try {
        await updateAndRefresh(async () => {
          await updateCarrierSafetyIdentifier(formMethods.getValues('addedCSNs') ?? []);
        });
      } finally {
        setLoading(false);
      }
      closeModal();
    })();
  }, [closeModal, formMethods, updateAndRefresh, updateCarrierSafetyIdentifier]);

  return (
    <Dialog
      fullWidth
      maxWidth={'sm'}
      data-testid='editCarrierSafetyNumberDialog'
      id='editCarrierSafetyNumberDialog'
      contentPadding={ehiTheme.spacing(3)}
      open={true}
      title={t('rateAndBilling.carrierSafetyNumbers.title')}
      a11yKey='content'
      actions={{
        primaryAction: {
          label: t('common.save'),
          onClick: handleSave,
          overrideButtonProps: {
            disabled: !formMethods.formState.isValid,
          },
        },
        secondaryAction: {
          label: t('common.cancel'),
          onClick: handleClosingModal,
        },
      }}>
      <FormProvider {...formMethods}>
        <Box data-testid={'dialog-body'} sx={{ display: 'flex', flexDirection: 'column', gap: ehiTheme.spacing(2) }}>
          <Body1>{t('rateAndBilling.carrierSafetyNumbers.dialogBodyText')}</Body1>
          <Box>
            <Controller
              name={'addedCSNs'}
              control={formMethods.control}
              render={({ fieldState }): ReactElement => (
                <Autocomplete
                  disableClearable
                  options={[]}
                  value={addedCSNs}
                  freeSolo
                  multiple
                  onChange={(_, value): void => {
                    formMethods.setValue('addedCSNs', value);
                    formMethods.trigger('addedCSNs').catch(console.error);
                  }}
                  renderTags={(value, props): React.ReactNode =>
                    value.map((option, index) => {
                      if (!isValidCarrierSafetyNumber(option)) {
                        return (
                          <Chip color={'error'} variant={'outlined'} label={option} {...props({ index })} key={index} />
                        );
                      } else {
                        return (
                          <Chip
                            variant={'outlined'}
                            label={option}
                            {...props({ index })}
                            key={index}
                            deleteIcon={
                              <CancelIcon fontSize={'large'} style={{ color: ehiTheme.palette.primary.main }} />
                            }
                          />
                        );
                      }
                    })
                  }
                  renderInput={(params): React.ReactNode => {
                    params.inputProps.onKeyDown = handleKeyDown;
                    return (
                      <TextField
                        data-testid={'carrierSafetyNumberTextField'}
                        label={t('rateAndBilling.carrierSafetyNumbers.title')}
                        error={!!fieldState.error?.message}
                        helperText={fieldState.error?.message ?? t('rateAndBilling.carrierSafetyNumbers.helperText')}
                        placeholder={
                          addedCSNs.length > 0 ? '' : t('rateAndBilling.carrierSafetyNumbers.placeholderText')
                        }
                        {...params}
                        inputRef={inputRef}
                      />
                    );
                  }}
                />
              )}
            />
          </Box>
        </Box>
      </FormProvider>
      <ProgressOverlay inProgress={loading} />
    </Dialog>
  );
};
