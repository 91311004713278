import { Body1, ehiTheme } from '@ehi/ui';
import FormControlLabel from '@mui/material/FormControlLabel';
import Radio from '@mui/material/Radio';
import { Box, Grid, RadioGroup } from '@mui/material';
import { FC, useCallback, useMemo, FocusEvent, ChangeEvent } from 'react';
import { useTranslations } from 'components/shared/i18n';
import { useFormContext } from 'react-hook-form';
import { PhoneTextField } from 'components/shared/forms/PhoneTextField';
import { GridItem, GridVerticalContainer } from 'components/shared/ui/styles/Grid.styles';
import { ConsentFields, ConsentProps, ConsentStatus } from 'components/flexFlow/driver/consent/ConsentFormTypes';
import { useBranchInfoByUrnQuery, useCountriesQuery } from 'services/location/locationQueries';
import { DOUBLE_DASH, EMPTY_VALUE } from 'utils/constants';
import { generateCountryMenuItemsWithCorporate } from 'utils/formUtils';
import { SelectField } from 'components/shared/forms/SelectField';
import { Option } from 'components/shared/ui/OptionTypes';
import { Phone } from 'components/shared/uiModels/driver/driverDataTypes';
import { CORPORATE_COUNTRIES, CorporateCountry } from 'utils/countryUtils';
import { getCountryCode } from 'utils/locationUtils';
import { useAppDispatch, useAppSelector } from 'redux/hooks';
import { selectPickup } from 'redux/selectors/bookingEditor';
import { AddressType } from 'services/location/locationTypes';
import { parseUrn } from 'utils/urnUtils';
import { useReservationFlow } from 'hooks/useReservationFlow';
import {
  selectConsentData,
  selectRetrievedConsentData,
  selectUpdateCheckBox,
  selectConsentEdited,
} from 'redux/selectors/consent';
import {
  setSelectedStatus,
  setSelectedCountry,
  setPhoneNumber,
  setConsentUid,
  setUpdatePhoneCheckbox,
} from 'redux/slices/consent/consentSlice';
import { CheckboxField } from 'components/shared/forms/CheckboxField';
import { hasPreviousConsentData } from 'components/flexFlow/driver/consent/consentUtils';

export const ConsentRadioGroup: FC<ConsentProps> = ({ driverDetails, modifyReadOnly }) => {
  const { t } = useTranslations();
  const { setValue, trigger, reset } = useFormContext();
  const dispatch = useAppDispatch();
  const { data } = useCountriesQuery();
  const pickupData = useAppSelector(selectPickup);
  const { data: pickup } = useBranchInfoByUrnQuery(pickupData?.branch ?? EMPTY_VALUE);
  const { isModifyFlow, isCreateFlow } = useReservationFlow();
  const consentData = useAppSelector(selectRetrievedConsentData);
  const selectedConsentData = useAppSelector(selectConsentData);
  const isUpdatePhoneCheckboxSelected = useAppSelector(selectUpdateCheckBox);
  const consentEdited = useAppSelector(selectConsentEdited);

  const countryOptions = useMemo<Option<string>[]>(() => {
    if (!data?.length) return [];
    return generateCountryMenuItemsWithCorporate(data);
  }, [data]);

  const hasConsentData = useMemo(() => {
    return hasPreviousConsentData(consentData);
  }, [consentData]);

  const showCountryAndPhoneFields =
    ((isCreateFlow || !hasConsentData) && selectedConsentData?.selectedStatus === ConsentStatus.Accepted) ||
    isUpdatePhoneCheckboxSelected;

  const showUpdatePhoneCheckbox = isModifyFlow && (!modifyReadOnly || consentEdited);

  const getPrimaryPhoneNumber = useMemo((): Phone | undefined => {
    if (Object.values(CORPORATE_COUNTRIES).includes(driverDetails?.primaryPhone?.country as CorporateCountry)) {
      return driverDetails?.primaryPhone;
    }
    return undefined;
  }, [driverDetails]);

  const acceptedOnClick = useCallback(async (): Promise<void> => {
    setValue(ConsentFields.Status, ConsentStatus.Accepted);
    const phone = getPrimaryPhoneNumber;
    setValue(ConsentFields.Country, phone?.country ?? getCountryCode(pickup?.addresses, AddressType.PHYSICAL));
    // Set the phoneNumber field to dirty if it has empty value, to show this is required field
    setValue(ConsentFields.PhoneNumber, phone?.number ?? EMPTY_VALUE, { shouldDirty: true });

    dispatch(setSelectedStatus(ConsentStatus.Accepted));
    dispatch(setSelectedCountry(phone?.country ?? getCountryCode(pickup?.addresses, AddressType.PHYSICAL)));
    dispatch(setPhoneNumber(phone?.number ?? EMPTY_VALUE));
    // Trigger if the phoneNumber and country fields are empty to show validation errors.
    await trigger([ConsentFields.PhoneNumber, ConsentFields.Country]);
  }, [getPrimaryPhoneNumber, pickup?.addresses, setValue, dispatch, trigger]);

  const clearConsentFields = (consentStatus: ConsentStatus): void => {
    dispatch(setSelectedStatus(consentStatus));
    if (consentStatus === ConsentStatus.Revoked) {
      dispatch(setConsentUid(parseUrn(consentData?.urn)));
    }
    // Need to reset the phone number field to skip validation for Declined
    setValue(ConsentFields.PhoneNumber, EMPTY_VALUE);
    dispatch(setUpdatePhoneCheckbox(false));
    dispatch(setSelectedCountry(EMPTY_VALUE));
    dispatch(setPhoneNumber(EMPTY_VALUE));
    reset();
  };

  const handleInputChange = (e: FocusEvent<HTMLInputElement>): void => {
    const { name, value } = e.target;
    if (ConsentFields.Country === name) {
      dispatch(setSelectedCountry(value));
    } else if (ConsentFields.PhoneNumber === name) {
      dispatch(setPhoneNumber(value));
    }
  };

  const handleCheckboxChange = async (value: number): Promise<void> => {
    if (value == 1) {
      setValue(ConsentFields.Status, ConsentStatus.Accepted);
      dispatch(setSelectedStatus(ConsentStatus.Accepted));
      dispatch(setUpdatePhoneCheckbox(true));
      if (selectedConsentData?.selectedCountry) {
        setValue(ConsentFields.Country, selectedConsentData?.selectedCountry);
      } else {
        setValue(ConsentFields.Country, getCountryCode(pickup?.addresses, AddressType.PHYSICAL));
        dispatch(setSelectedCountry(getCountryCode(pickup?.addresses, AddressType.PHYSICAL)));
      }
      setValue(ConsentFields.PhoneNumber, EMPTY_VALUE, { shouldDirty: true });

      await trigger([ConsentFields.PhoneNumber]);
    } else {
      dispatch(setUpdatePhoneCheckbox(false));
      dispatch(setSelectedCountry(EMPTY_VALUE));
      dispatch(setPhoneNumber(EMPTY_VALUE));
      reset();
    }
  };

  return (
    <RadioGroup data-testid='consent-status-radio'>
      <Grid container marginLeft={-1}>
        <GridItem sm={4}>
          <FormControlLabel
            control={<Radio color='primary' data-testid={'consent-accepted-radio'} />}
            label={t('consent.accepted')}
            onClick={acceptedOnClick}
            checked={selectedConsentData?.selectedStatus === ConsentStatus.Accepted}
          />
          {isModifyFlow && hasConsentData && (
            <Body1 data-testid='consent-phoneNumber' marginTop={ehiTheme.spacing(-1)} marginLeft={ehiTheme.spacing(4)}>
              {consentData ? consentData?.phoneNumber : DOUBLE_DASH}
            </Body1>
          )}
          {showCountryAndPhoneFields && (
            <GridVerticalContainer>
              <GridItem sm={12}>
                <SelectField
                  name={ConsentFields.Country}
                  label={t('common.countryCode')}
                  data-testid={'consentCountry'}
                  options={countryOptions}
                  onChange={handleInputChange}
                />
              </GridItem>
              <GridItem sm={12}>
                <PhoneTextField
                  country={selectedConsentData?.selectedCountry}
                  name={ConsentFields.PhoneNumber}
                  label={t('consent.phoneNumber')}
                  data-testid={'consentPhoneNumber'}
                  onBlur={handleInputChange}
                />
              </GridItem>
            </GridVerticalContainer>
          )}
        </GridItem>
        <GridItem sm={8}>
          {showUpdatePhoneCheckbox && (
            <CheckboxField
              name={ConsentFields.UpdateCheckbox}
              data-testid={'consent-update-checkbox'}
              label={t('consent.updatePhone')}
              onChange={(e: ChangeEvent<HTMLInputElement>): void => {
                handleCheckboxChange(e.target.checked ? 1 : 0);
              }}
              checked={isUpdatePhoneCheckboxSelected}
            />
          )}
        </GridItem>
      </Grid>
      <Box marginTop={ehiTheme.spacing(-2)}>
        {!hasConsentData && (
          <FormControlLabel
            control={<Radio color='primary' data-testid={'consent-declined-radio'} />}
            label={t('consent.declined')}
            onClick={(): void => clearConsentFields(ConsentStatus.Declined)}
            checked={selectedConsentData?.selectedStatus === ConsentStatus.Declined}
          />
        )}
        {hasConsentData && isModifyFlow && (
          <FormControlLabel
            value={ConsentStatus.Revoked}
            control={<Radio color='primary' data-testid={'consent-revoked-radio'} />}
            label={t('consent.revoked')}
            onClick={(): void => clearConsentFields(ConsentStatus.Revoked)}
            checked={selectedConsentData?.selectedStatus === ConsentStatus.Revoked}
          />
        )}
      </Box>
    </RadioGroup>
  );
};
