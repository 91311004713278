import { ServiceState } from 'services/types/ServiceStateTypes';
import {
  AdditionalInfoAccountType,
  AdditionalInfoData,
  AdditionalInfoHookReturn,
  DropDownField,
  DropDownOption,
  FieldInfo,
  FieldType,
  TextField,
  UIAdditionalInfoField,
} from 'components/flexFlow/rateAndBilling/additionalInformation/AdditionalInfoTypes';
import { useRateSourceAccountDetails } from 'services/businessAccount/useRateSourceAccountDetails';
import { useAdditionalInformationQuery } from 'services/businessAccount/accountQueries';
import { useMemo } from 'react';
import {
  AdditionalInformationBase,
  AdditionalInformationText,
  RentalStage,
} from 'services/businessAccount/businessAccountTypes';
import { AdditionalInformation, NegotiatedRateSource, RateSource } from 'services/booking/bookingTypes';
import { useAppSelector } from 'redux/hooks';
import { selectBillingAccountAdditionalInformation, selectRateSource } from 'redux/selectors/bookingEditor';
import { transformAdditionalInformationInfoFromApi } from 'components/shared/uiModels/rateAndBilling/rateAndBillingTransformer';
import { useRetrieveBillingAccount } from 'services/businessAccount/useRetrieveBillingAccount';

export const useRetrieveAdditionalInformation = (): AdditionalInfoHookReturn => {
  const { rateSourceInfo, rateSourceAccountNumber, isLoading: isRateSourceInfoLoading } = useRateSourceAccountDetails();

  const {
    billingAccountInfo,
    billingAccountNumber,
    billingNumber,
    isLoading: isBillingAccountInfoLoading,
  } = useRetrieveBillingAccount();

  const rateSource: RateSource | undefined = useAppSelector(selectRateSource);
  const negotiatedRateSource = rateSource as NegotiatedRateSource;
  const editorBillingAccountAdditionalInformation = useAppSelector(selectBillingAccountAdditionalInformation);

  const {
    data: rateSourceAccountAdditionalInfo,
    isFetching: isFetchingForRateSourceAdditionalInfo,
    error: rateSourceAccountAdditionalInfoErrors,
  } = useAdditionalInformationQuery(rateSourceAccountNumber ?? '');

  const {
    data: billingAccountAdditionalInfo,
    isFetching: isFetchingForBillingAdditionalInfo,
    error: billingAccountAdditionalInfoErrors,
  } = useAdditionalInformationQuery(billingAccountNumber ?? '');

  const editorAdditionalInfo: AdditionalInformation[] = useMemo(() => {
    const addInfo = [];

    if (negotiatedRateSource && negotiatedRateSource.additionalInformation) {
      addInfo.push(...negotiatedRateSource.additionalInformation);
    }

    if (editorBillingAccountAdditionalInformation) {
      addInfo.push(...editorBillingAccountAdditionalInformation);
    }

    return addInfo;
  }, [negotiatedRateSource, editorBillingAccountAdditionalInformation]);

  const additionalInfo = useMemo((): AdditionalInfoData[] => {
    const additionalInfo: AdditionalInfoData[] = [];
    if (rateSourceAccountNumber && billingAccountNumber) {
      if (rateSourceAccountNumber === billingAccountNumber) {
        const transformedData = transformAdditionalInformationInfoFromApi(
          rateSourceAccountAdditionalInfo ?? [],
          AdditionalInfoAccountType.SAME_AS_RATE_SOURCE
        );
        additionalInfo.push({
          accountNumber: rateSourceAccountNumber,
          accountName: rateSourceInfo?.name,
          accountType: rateSourceInfo?.type,
          accountUrn: rateSourceInfo?.urn,
          billingNumber: billingNumber,
          additionalInfoFields: transformedData
            ?.map((field: AdditionalInformationBase) => adaptAdditionalInfoField(field, editorAdditionalInfo))
            ?.sort((a, b) => (a.ordinal as number) - (b.ordinal as number)),
          type: AdditionalInfoAccountType.SAME_AS_RATE_SOURCE,
        });
      } else {
        const transformedDataForRateSource = transformAdditionalInformationInfoFromApi(
          rateSourceAccountAdditionalInfo ?? [],
          AdditionalInfoAccountType.RATE_SOURCE
        );
        const transformedDataForBilling = transformAdditionalInformationInfoFromApi(
          billingAccountAdditionalInfo ?? [],
          AdditionalInfoAccountType.BILL_TO
        );
        additionalInfo.push(
          {
            accountNumber: rateSourceAccountNumber,
            accountName: rateSourceInfo?.name,
            accountType: rateSourceInfo?.type,
            accountUrn: rateSourceInfo?.urn,
            additionalInfoFields: transformedDataForRateSource
              ?.map((field: AdditionalInformationBase) => adaptAdditionalInfoField(field, editorAdditionalInfo))
              ?.sort((a, b) => (a.ordinal as number) - (b.ordinal as number)),
            type: AdditionalInfoAccountType.RATE_SOURCE,
          },
          {
            accountNumber: billingAccountNumber,
            accountName: billingAccountInfo?.name,
            accountType: billingAccountInfo?.type,
            accountUrn: billingAccountInfo?.urn,
            billingNumber: billingNumber,
            additionalInfoFields: transformedDataForBilling
              ?.map((field: AdditionalInformationBase) => adaptAdditionalInfoField(field, editorAdditionalInfo))
              ?.sort((a, b) => (a.ordinal as number) - (b.ordinal as number)),
            type: AdditionalInfoAccountType.BILL_TO,
          }
        );
      }
    } else if (rateSourceAccountNumber) {
      const transformedData = transformAdditionalInformationInfoFromApi(
        rateSourceAccountAdditionalInfo ?? [],
        AdditionalInfoAccountType.RATE_SOURCE
      );
      additionalInfo.push({
        accountNumber: rateSourceAccountNumber,
        accountName: rateSourceInfo?.name,
        accountType: rateSourceInfo?.type,
        accountUrn: rateSourceInfo?.urn,
        additionalInfoFields: transformedData
          ?.map((field: AdditionalInformationBase) => adaptAdditionalInfoField(field, editorAdditionalInfo))
          ?.sort((a, b) => (a.ordinal as number) - (b.ordinal as number)),
        type: AdditionalInfoAccountType.RATE_SOURCE,
      });
    } else if (billingAccountNumber) {
      const transformedDataForBilling = transformAdditionalInformationInfoFromApi(
        billingAccountAdditionalInfo ?? [],
        AdditionalInfoAccountType.BILL_TO
      );
      additionalInfo.push({
        accountNumber: billingAccountNumber,
        accountName: billingAccountInfo?.name,
        accountType: billingAccountInfo?.type,
        accountUrn: billingAccountInfo?.urn,
        billingNumber: billingNumber,
        additionalInfoFields: transformedDataForBilling
          ?.map((field: AdditionalInformationBase) => adaptAdditionalInfoField(field, editorAdditionalInfo))
          ?.sort((a, b) => (a.ordinal as number) - (b.ordinal as number)),
        type: AdditionalInfoAccountType.BILL_TO,
      });
    }

    return additionalInfo?.filter((data) => data.additionalInfoFields.length > 0);
  }, [
    rateSourceAccountAdditionalInfo,
    billingAccountAdditionalInfo,
    rateSourceAccountNumber,
    billingAccountInfo,
    billingAccountNumber,
    billingNumber,
    rateSourceInfo,
    editorAdditionalInfo,
  ]);

  const combinedErrors = [];
  if (rateSourceAccountAdditionalInfoErrors?.errors && rateSourceAccountAdditionalInfoErrors?.errors.length > 0) {
    combinedErrors.push(...rateSourceAccountAdditionalInfoErrors.errors);
  }
  if (billingAccountAdditionalInfoErrors?.errors && billingAccountAdditionalInfoErrors?.errors.length > 0) {
    combinedErrors.push(...billingAccountAdditionalInfoErrors.errors);
  }

  const combinedRetrieveState: ServiceState = {
    loading:
      isBillingAccountInfoLoading ||
      isRateSourceInfoLoading ||
      isFetchingForBillingAdditionalInfo ||
      isFetchingForRateSourceAdditionalInfo,
    errors: combinedErrors,
  };
  return {
    additionalInfo: additionalInfo,
    ...combinedRetrieveState,
  };
};

export const adaptAdditionalInfoField = (
  additionalInfoField: AdditionalInformationBase,
  editorAdditionalInfo: AdditionalInformation[]
): UIAdditionalInfoField => {
  const reservationEditorFieldData = editorAdditionalInfo.find((field) =>
    field?.fieldId?.includes(additionalInfoField.urn.toString())
  );

  return {
    fieldName: additionalInfoField?.translation?.name ?? '',
    fieldValue: reservationEditorFieldData?.value,
    dataType: (additionalInfoField as AdditionalInformationText)?.dataType,
    ordinal: additionalInfoField?.ordinal,
    fieldId: additionalInfoField?.urn,
    isOnlyForBusinessPayer: additionalInfoField?.onlyForBusinessPayer,
    helperText: additionalInfoField?.translation?.helperText ?? '',
    isRequiredAtReservation: additionalInfoField?.requiredRentalStages?.includes(RentalStage.RESERVATION) ?? false,
    fieldInfo: transformFieldType(additionalInfoField),
  };
};

const transformFieldType = (field: AdditionalInformationBase): FieldInfo => {
  switch (field.type) {
    case FieldType.DROP_DOWN: {
      return {
        type: FieldType.DROP_DOWN,
        // Fix: Types are wrongly defined in the AdditionalInformationBase for dropDownValues
        // should be dropDownValues instead just values
        dropDownOption: (field as any)?.dropDownValues
          ?.sort((a: { ordinal: number }, b: { ordinal: number }) => a.ordinal - b.ordinal)
          ?.map((option: { value: string }) => {
            return {
              text: option.value,
            } as DropDownOption;
          }),
      } as DropDownField;
    }
    default: {
      return {
        type: FieldType.TEXT,
        // Note: Each account has different max length for Date
        // This is to make sure user not entering more than max length and also AM validation message does not mention about length
        maxLength:
          (field as AdditionalInformationText).dataType === FieldType.DATE
            ? (field as AdditionalInformationText).maxLength
            : undefined,
      } as TextField;
    }
  }
};
