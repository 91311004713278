export const formatName = (lastName?: string, firstName?: string): string => {
  if (lastName && firstName) {
    return `${lastName}, ${firstName}`;
  } else if (lastName && !firstName) {
    return lastName;
  } else if (!lastName && firstName) {
    return firstName;
  }
  return '';
};
