import { FC, useCallback } from 'react';
import { type Marker } from '@googlemaps/markerclusterer';
import { BranchLocation, BranchLookupMarker } from './BranchLookupMarker';
import useMarkerClusterer from './useMarkerClusterer';

export type ClusteredBranchLookupMarkersProps = {
  branches: BranchLocation[];
  handleMarkerClick: (stationId: string) => void;
};

export const ClusteredBranchLookupMarkers: FC<ClusteredBranchLookupMarkersProps> = ({
  branches,
  handleMarkerClick,
}) => {
  const { registerMarker } = useMarkerClusterer(branches);

  const handleBranchClick = useCallback(
    (branchLocation: BranchLocation) => {
      const stationId = branchLocation.stationId ?? '';
      handleMarkerClick(stationId);
    },
    [handleMarkerClick]
  );

  const handleSetMarkerRef = useCallback(
    (marker: Marker | null, branch: BranchLocation) => {
      const stationId = branch.stationId ?? '';
      registerMarker(marker, stationId);
    },
    [registerMarker]
  );

  return (
    <>
      {branches.map((branch) => (
        <BranchLookupMarker
          key={branch.stationId}
          branch={branch}
          onClick={handleBranchClick}
          setMarkerRef={(marker): void => handleSetMarkerRef(marker, branch)}
        />
      ))}
    </>
  );
};
