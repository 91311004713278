import { FC, ReactElement, useCallback, useMemo, useState } from 'react';
import {
  AccountDetailsContainer,
  RateAndBillingSearchContainer,
  RateAndBillingSearchLabel,
  StyledFlexGrid,
} from 'components/flexFlow/rateAndBilling/RateAndBilling.styles';
import { Box, Grid } from '@mui/material';
import { Body2, EhiButton, ehiTheme, H6 } from '@ehi/ui';
import { FormTextField } from 'components/shared/forms/FormTextField';
import { RateSourceFields } from 'components/flexFlow/rateAndBilling/editDialogs/rateSource/EditRateSourceDialogTypes';
import { FieldLoadingIndicator } from 'components/shared/forms/FieldLoadingIndicator';
import { InputIconButton } from 'components/shared/ui/InputIconButton/InputIconButton';
import { FieldClearIcon } from 'components/shared/ui/FieldClearIcon';
import { EMPTY_VALUE, ENTER_KEY, ENTER_KEY_CODE } from 'utils/constants';
import { useTranslations } from 'components/shared/i18n';
import { FieldValues, FormProvider, useForm } from 'react-hook-form';
import { NegotiatedRateSource, RateSource, RateSourceType } from 'services/booking/bookingTypes';
import {
  transformAccountDetailsFromRetrieve,
  transformRateSourceInfoFromApi,
} from 'components/shared/uiModels/rateAndBilling/rateAndBillingTransformer';
import { modifyRateSource } from 'services/booking/bookingService';
import { useUpdateAndRefreshEditor } from 'hooks/bookingEditor/useUpdateAndRefreshEditor';
import { useAppSelector } from 'redux/hooks';
import {
  selectBookingEditorId,
  selectBrand,
  selectEnterpriseBrandAndHasDateTime,
  selectRateSource,
} from 'redux/selectors/bookingEditor';
import { useAccountContactInfoQuery, useBusinessAccountQuery } from 'services/businessAccount/accountQueries';
import { ReservationAccount } from 'services/businessAccount/businessAccountTypes';
import { EhiErrors } from 'services/types/EhiErrorsTypes';
import { QueryObserverResult } from '@tanstack/react-query';
import { logError } from 'components/shared/logger/splunkLogger';
import { EHI_DOMAINS, generateUrn, parseUrn } from 'utils/urnUtils';
import { getAppConfigCache } from 'services/appConfig/appConfigService';
import { useRateSource } from 'components/flexFlow/rateAndBilling/editDialogs/rateSource/useRateSource';
import { BusinessAccountCard } from 'components/flexFlow/rateAndBilling/editDialogs/BusinessAccountCard';
import {
  PeoBookingIssues,
  ProtectionPriceMismatchBookingIssue,
  RateProductIsModifiedBookingIssue,
  VehicleAvailabilityBookingIssues,
} from 'utils/bookingUtils';
import { useBookingIssue } from 'services/booking/useBookingIssue';
import { useRetrieveRatePlans } from 'components/flexFlow/rateAndBilling/editDialogs/useRetrieveRatePlans';
import { SelectField } from 'components/shared/forms/SelectField';
import { ProgressOverlay } from 'components/shared/ui/spinner/ProgressOverlay';
import {
  hasRatePlans,
  parseRateProduct,
  parseRateSourceAccountNumber,
  rateSourceAccountNumberSearchInitialValues,
  showAccountDetails,
  showRateProduct,
} from 'utils/rateAndBillingUtils';
import { useAccountSearchContext } from 'context/accountSearch/AccountSearchContext';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import { useEffectWhen } from 'hooks/useEffectWhen';

type AccountNumberSearchContainerProps = {
  handleCloseModal: () => void;
  onBackClicked?: () => void;
};

export const RateSourceAccountNumberSearch: FC<AccountNumberSearchContainerProps> = ({
  handleCloseModal,
  onBackClicked,
}) => {
  const { t } = useTranslations();
  const { updateAndRefresh } = useUpdateAndRefreshEditor();
  const { addOrModifyRateSource } = useRateSource();
  const { handleBookingIssues } = useBookingIssue();
  const { retrieveRatePlans, ratePlanOptions, clearRatePlans } = useRetrieveRatePlans();
  const { isAccountNumberFieldHidden, selectedAccountUrn } = useAccountSearchContext();
  const appConfig = getAppConfigCache();
  const defaultEhiDatabase = appConfig?.defaultEhiDatabase ?? '';

  const bookingEditorId = useAppSelector(selectBookingEditorId);
  const brand = useAppSelector(selectBrand);
  const isEnterpriseBrandAndHasDateTime = useAppSelector(selectEnterpriseBrandAndHasDateTime);
  const rateSource: RateSource | undefined = useAppSelector(selectRateSource);
  const currentRateProduct = parseRateProduct(rateSource);
  const negotiatedRateSource = rateSource as NegotiatedRateSource;
  const editorAccountNumber = parseRateSourceAccountNumber(rateSource);

  const [loadingAccountNumber, setLoadingAccountNumber] = useState(false);
  const [loadingRateProduct, setLoadingRateProduct] = useState(false);
  const [loadingApplyRatePlan, setLoadingApplyRatePlan] = useState(false);

  const initialValues = useMemo(
    () =>
      rateSourceAccountNumberSearchInitialValues(
        currentRateProduct,
        editorAccountNumber,
        negotiatedRateSource?.ratePlan,
        parseUrn(selectedAccountUrn)
      ),
    [currentRateProduct, editorAccountNumber, negotiatedRateSource?.ratePlan, selectedAccountUrn]
  );

  const formMethods = useForm({
    defaultValues: initialValues,
  });

  const { setValue, getValues, setError, clearErrors, handleSubmit } = formMethods;
  const [accountNumber, rateProduct, previousAccountNumber, ratePlan] = formMethods.watch([
    RateSourceFields.AccountNumber,
    RateSourceFields.RateProduct,
    RateSourceFields.PreviousAccountNumber,
    RateSourceFields.RatePlan,
  ]);
  const { data: previousAccount } = useBusinessAccountQuery(previousAccountNumber);
  const { data: previousAccountContact } = useAccountContactInfoQuery(previousAccountNumber);

  // These can be refetch when previous account is not same as current account number
  const { data: currentAccountContact, refetch: refetchCurrentAccountContact } = useAccountContactInfoQuery(
    accountNumber,
    false
  );
  const { data: currentAccount, refetch: refetchAccountDetails } = useBusinessAccountQuery(accountNumber, {
    enabled: false,
  });

  const previousAccountDetails = useMemo(() => {
    return transformAccountDetailsFromRetrieve(previousAccount, previousAccountContact);
  }, [previousAccount, previousAccountContact]);
  const currentAccountDetails = useMemo(() => {
    return transformAccountDetailsFromRetrieve(currentAccount, currentAccountContact);
  }, [currentAccount, currentAccountContact]);

  const handleLogError = useCallback((error: unknown, message: string) => {
    logError({ error, message: message });
  }, []);

  const fetchRatePlans = useCallback(
    async (accountUrn: string) => {
      await retrieveRatePlans(accountUrn);
    },
    [retrieveRatePlans]
  );

  useEffectWhen(() => {
    if (previousAccountDetails && previousAccountDetails?.accountNumberUrn) {
      fetchRatePlans(selectedAccountUrn || previousAccountDetails.accountNumberUrn);
    }
  }, !!(previousAccountDetails?.accountNumberUrn && isEnterpriseBrandAndHasDateTime));

  const isAccountNumberMatchingWithEditorAccount = useMemo(() => {
    return accountNumber === editorAccountNumber;
  }, [editorAccountNumber, accountNumber]);

  const handleApplyingRateProduct = useCallback(
    async (values: FieldValues) => {
      const rateProductValue = values[RateSourceFields.RateProduct];

      try {
        setLoadingRateProduct(true);
        const rateSourceRequestBody: RateSource = {
          type: RateSourceType.RETAIL,
          rateProduct: rateProductValue
            ? generateUrn(
                EHI_DOMAINS.rentalRate.name,
                `${EHI_DOMAINS.rentalRate.brand}:${parseUrn(brand)}:${EHI_DOMAINS.rentalRate.rateProduct}`,
                rateProductValue.toUpperCase(),
                defaultEhiDatabase
              )
            : undefined,
        };
        const { data, errors } = await updateAndRefresh(() => modifyRateSource(bookingEditorId, rateSourceRequestBody));
        if (!errors) {
          handleCloseModal();
          if (data) {
            const availableBookingIssues = [
              ...Object.values(VehicleAvailabilityBookingIssues),
              ...Object.values(PeoBookingIssues),
            ];
            const excludedBookingIssues = [ProtectionPriceMismatchBookingIssue, RateProductIsModifiedBookingIssue];
            await handleBookingIssues(
              data.issue ?? [],
              data.ehiMessages ?? [],
              availableBookingIssues,
              t('snackbarMessages.additionalInfo.rateProduct'),
              excludedBookingIssues
            );
          }
        } else {
          setError(RateSourceFields.RateProduct, { message: t('rateAndBilling.invalidRateProduct') });
        }
      } finally {
        setLoadingRateProduct(false);
      }
    },
    [brand, defaultEhiDatabase, updateAndRefresh, t, bookingEditorId, handleCloseModal, handleBookingIssues, setError]
  );

  const handleApplyingAccountNumber = useCallback(
    async (values: FieldValues) => {
      setLoadingAccountNumber(true);
      const accountNumberValue = values[RateSourceFields.AccountNumber];

      // Applying with no Account Number: close the modal and apply the best rated retail rates to the reservation
      if (accountNumberValue === EMPTY_VALUE) {
        await handleApplyingRateProduct(values);
        return;
      }
      await refetchAccountDetails()
        .then(async (response: QueryObserverResult<ReservationAccount, EhiErrors>) => {
          const { data: accountDetails, error: accountErrors } = response;
          setLoadingAccountNumber(false);
          if (!accountDetails || accountErrors) {
            setError(RateSourceFields.AccountNumber, { message: t('rateAndBilling.invalidAccountNumber') });
            return;
          }

          if (accountDetails.urn) {
            const businessAccount = transformRateSourceInfoFromApi(accountDetails);

            // Refetch current account contact, when account is valid
            await refetchCurrentAccountContact();
            const ratePlans = await retrieveRatePlans(accountDetails.urn);
            if (ratePlans && ratePlans?.length > 0) {
              if (ratePlans?.length === 1) {
                await addOrModifyRateSource({
                  accountNumber: accountDetails.urn,
                  ratePlanId: ratePlans[0].value,
                  handleCloseModal: handleCloseModal,
                  carrierSafetyNumbers: businessAccount.carrierSafetyIdentifiers ?? [],
                });
              }
            } else {
              await addOrModifyRateSource({
                accountNumber: accountDetails.urn,
                handleCloseModal: handleCloseModal,
                carrierSafetyNumbers: businessAccount.carrierSafetyIdentifiers ?? [],
              });
            }
          } else {
            setError(RateSourceFields.AccountNumber, { message: t('rateAndBilling.invalidAccountNumber') });
          }
        })
        .catch((error) => {
          handleLogError(
            error,
            `Unable to save account number ${getValues(RateSourceFields.AccountNumber)} to editor id ${bookingEditorId}`
          );
        })
        .finally(() => {
          setLoadingAccountNumber(false);
        });
    },
    [
      addOrModifyRateSource,
      bookingEditorId,
      getValues,
      handleApplyingRateProduct,
      handleCloseModal,
      handleLogError,
      refetchAccountDetails,
      setError,
      retrieveRatePlans,
      refetchCurrentAccountContact,
      t,
    ]
  );

  const handleKeyPress = async (e: any, submitFn: (values: FieldValues) => Promise<void>): Promise<void> => {
    if (e.code === ENTER_KEY || e.keycode === ENTER_KEY_CODE) {
      const onFormSubmit = handleSubmit(submitFn);
      await onFormSubmit();
    }
  };

  const isAccountNumberApplyButtonDisabled = useCallback((): boolean => {
    const isAccountNumberDefault = editorAccountNumber === (accountNumber as string)?.trim();
    return isAccountNumberDefault || loadingAccountNumber;
  }, [accountNumber, editorAccountNumber, loadingAccountNumber]);

  const handleApplyRatePlans = useCallback(
    async (values: FieldValues) => {
      setLoadingApplyRatePlan(true);
      const ratePlanValue = values[RateSourceFields.RatePlan];

      await addOrModifyRateSource({
        accountNumber: currentAccountDetails.accountNumberUrn || previousAccountDetails.accountNumberUrn,
        ratePlanId: ratePlanValue,
        handleCloseModal: handleCloseModal,
        carrierSafetyNumbers:
          (currentAccountDetails.carrierSafetyNumbers || previousAccountDetails.carrierSafetyNumbers) ?? [],
      });
      setLoadingApplyRatePlan(false);
    },
    [
      addOrModifyRateSource,
      currentAccountDetails.accountNumberUrn,
      currentAccountDetails.carrierSafetyNumbers,
      previousAccountDetails.accountNumberUrn,
      previousAccountDetails.carrierSafetyNumbers,
      handleCloseModal,
    ]
  );
  const renderRatePlansOptions = useMemo((): ReactElement => {
    return (
      <RateAndBillingSearchContainer margin={ehiTheme.spacing(2, 3)} data-testid='ratePlanContainer'>
        <RateAndBillingSearchLabel>{t('rateAndBilling.ratePlanSelection')}</RateAndBillingSearchLabel>
        <StyledFlexGrid spacing={1} data-testid='ratePlanField'>
          <Grid item xs={6} sm={6}>
            <SelectField
              fullWidth
              required={true}
              name={RateSourceFields.RatePlan}
              label={t('rateAndBilling.rateSourceRatePlan')}
              data-testid={RateSourceFields.RatePlan}
              options={ratePlanOptions}
              hasNoneOption={false}
              onChange={(event: any): void => {
                setValue(RateSourceFields.RatePlan, event.target.value);
              }}
            />
          </Grid>
          <Grid item xs={3} sm={3}>
            <EhiButton
              data-testid='applyRatePlans'
              variant='contained'
              disabled={!!(negotiatedRateSource?.ratePlan && ratePlanOptions.length === 1)}
              style={{ marginTop: 0 }}
              onClick={handleSubmit(handleApplyRatePlans)}>
              {t('common.apply')}
            </EhiButton>
          </Grid>
        </StyledFlexGrid>
      </RateAndBillingSearchContainer>
    );
  }, [ratePlanOptions, handleApplyRatePlans, handleSubmit, setValue, negotiatedRateSource?.ratePlan, t]);

  return (
    <FormProvider {...formMethods}>
      <Box>
        {isAccountNumberFieldHidden ? (
          <EhiButton variant={'text'} onClick={onBackClicked} startIcon={<ArrowBackIcon />}>
            <Body2>{t('common.back')}</Body2>
          </EhiButton>
        ) : (
          <RateAndBillingSearchContainer margin={ehiTheme.spacing(2, 0)} data-testid='accountNumberContainer'>
            <Grid item>
              <RateAndBillingSearchLabel>{t('rateAndBilling.enterAccountNumber')}</RateAndBillingSearchLabel>
              <StyledFlexGrid spacing={1} data-testid='searchField'>
                <Grid item xs={6} sm={6}>
                  <FormTextField
                    name={RateSourceFields.AccountNumber}
                    label={t('rateAndBilling.accountNumber')}
                    data-testid='input'
                    autoFocus={true}
                    fullWidth
                    required
                    onKeyDown={(e: any): Promise<void> => handleKeyPress(e, handleApplyingAccountNumber)}
                    onChange={(e): void => setValue(RateSourceFields.AccountNumber, e.target.value.toUpperCase())}
                    InputProps={{
                      endAdornment: loadingAccountNumber ? (
                        <FieldLoadingIndicator />
                      ) : (
                        <InputIconButton
                          icon={<FieldClearIcon />}
                          label={t('common.clear')}
                          onClick={(): void => {
                            setValue(RateSourceFields.AccountNumber, EMPTY_VALUE);
                            clearErrors(RateSourceFields.AccountNumber);
                            ratePlanOptions?.length > 0 && clearRatePlans();
                          }}
                          disabled={(accountNumber as string)?.length === 0}
                        />
                      ),
                    }}
                  />
                </Grid>
                <Grid item xs={3} sm={3}>
                  <EhiButton
                    data-testid='applyAccountNumber'
                    variant='contained'
                    style={{ marginTop: 0 }}
                    disabled={isAccountNumberApplyButtonDisabled()}
                    onClick={handleSubmit(handleApplyingAccountNumber)}>
                    {t('common.apply')}
                  </EhiButton>
                </Grid>
              </StyledFlexGrid>
            </Grid>
          </RateAndBillingSearchContainer>
        )}
        {showAccountDetails(
          ratePlanOptions,
          currentAccountDetails?.accountNumber ? currentAccountDetails : previousAccountDetails,
          brand,
          isAccountNumberMatchingWithEditorAccount
        ) && (
          <AccountDetailsContainer data-testid='rateSourceAccountDetails'>
            <BusinessAccountCard
              account={currentAccountDetails?.accountNumber ? currentAccountDetails : previousAccountDetails}
              titleColor={'black'}
            />
          </AccountDetailsContainer>
        )}

        {!accountNumber && rateProduct && !ratePlan && (
          <AccountDetailsContainer>
            <H6>{t('rateAndBilling.retail')}</H6>
          </AccountDetailsContainer>
        )}
        {showRateProduct(isAccountNumberMatchingWithEditorAccount, brand, ratePlanOptions?.length) && (
          <RateAndBillingSearchContainer margin={ehiTheme.spacing(2, 3)} data-testid='rateProductContainer'>
            <RateAndBillingSearchLabel>{t('rateAndBilling.enterRateProduct')}</RateAndBillingSearchLabel>
            <StyledFlexGrid spacing={1} data-testid='rateProductField'>
              <Grid item xs={6} sm={6}>
                <FormTextField
                  name={RateSourceFields.RateProduct}
                  label={t('rateAndBilling.rateProduct')}
                  data-testid='input'
                  fullWidth
                  onChange={(e): void => setValue(RateSourceFields.RateProduct, e.target.value)}
                  onKeyDown={(e: any): Promise<void> => handleKeyPress(e, handleApplyingRateProduct)}
                  InputProps={{
                    endAdornment: loadingRateProduct ? (
                      <FieldLoadingIndicator />
                    ) : (
                      <InputIconButton
                        icon={<FieldClearIcon />}
                        label={t('common.clear')}
                        onClick={(): void => {
                          setValue(RateSourceFields.RateProduct, EMPTY_VALUE);
                          clearErrors(RateSourceFields.RateProduct);
                        }}
                        disabled={(rateProduct as string).length === 0}
                      />
                    ),
                  }}
                />
              </Grid>
              <Grid item xs={3} sm={3}>
                <EhiButton
                  data-testid='applyRateProduct'
                  variant='contained'
                  style={{ marginTop: 0 }}
                  onClick={handleSubmit(handleApplyingRateProduct)}>
                  {t('common.apply')}
                </EhiButton>
              </Grid>
            </StyledFlexGrid>
          </RateAndBillingSearchContainer>
        )}
        {hasRatePlans(ratePlanOptions, brand) && renderRatePlansOptions}
        <ProgressOverlay inProgress={loadingApplyRatePlan} />
      </Box>
    </FormProvider>
  );
};
