import { FC, useMemo } from 'react';
import { useLocale, useTranslations } from 'components/shared/i18n';
import { NoPayments } from 'components/notes/historyTab/paymentHistory/noPayments/NoPayments';
import { Body2 } from '@ehi/ui';
import { EhiDivider } from 'components/shared/ui/styles/Divider.styles';
import {
  PaymentCell,
  PaymentHistoryTitle,
  StyledBox,
  StyledDateAndTime,
} from 'components/notes/historyTab/paymentHistory/PaymentHistory.styles';
import { Grid } from '@mui/material';
import { Title } from 'components/shared/ui/styles/Typography.styles';
import { Box, useMediaQuery } from '@mui/system';
import { shadowStyle } from 'components/shared/ui/styles/Global.styles';
import { HistoryCard } from 'components/notes/historyTab/HistoryTab.styles';
import { useGetPaymentHistoryQuery } from 'services/payment/paymentHistoryQueries';
import { useAppSelector } from 'redux/hooks';
import { selectReservationData } from 'redux/selectors/bookingEditor';
import { parseUrn } from 'utils/urnUtils';
import { transformToFormattedPaymentHistory } from 'services/payment/paymentHistoryTransformer';
import { ProgressOverlay } from 'components/shared/ui/spinner/ProgressOverlay';

export const PaymentHistory: FC = () => {
  const { t } = useTranslations();
  const { locale } = useLocale();
  const isMobileOrTablet = useMediaQuery('(max-width:800px)');
  const reservationData = useAppSelector(selectReservationData);
  const { data: paymentHistories, isFetching: isLoading } = useGetPaymentHistoryQuery(
    parseUrn(reservationData?.reservation)
  );

  const formattedHistories = useMemo(() => {
    return (
      paymentHistories &&
      transformToFormattedPaymentHistory(paymentHistories, locale, t('format.monthDayYear'), t('format.time'))
    );
  }, [locale, paymentHistories, t]);

  return (
    <HistoryCard sx={{ width: isMobileOrTablet ? '100%' : '75%', boxShadow: shadowStyle }} data-testid='paymentHistory'>
      <PaymentHistoryTitle>
        <Title>
          <b>{t('notes.history.paymentHistory')}</b>
        </Title>
      </PaymentHistoryTitle>
      <EhiDivider />
      {formattedHistories && formattedHistories.length ? (
        <>
          <Grid container>
            <PaymentCell item xs={2}>
              <Body2 bold>{t('notes.history.dateAndTime')}</Body2>
            </PaymentCell>
            <PaymentCell item xs={4}>
              <Body2 bold>{t('notes.history.paymentType')}</Body2>
            </PaymentCell>
            <PaymentCell item xs={2}>
              <Body2 bold>{t('notes.history.amount')}</Body2>
            </PaymentCell>
            <PaymentCell item xs={4}>
              <Body2 bold>{t('notes.history.transaction')}</Body2>
            </PaymentCell>
          </Grid>
          {formattedHistories?.map((history, index) => {
            return (
              <Box key={index}>
                <EhiDivider />
                <Grid container>
                  <PaymentCell item xs={2}>
                    <StyledBox>
                      <StyledDateAndTime>{history.date}</StyledDateAndTime>
                      <StyledDateAndTime>{history.time}</StyledDateAndTime>
                    </StyledBox>
                  </PaymentCell>
                  <PaymentCell item xs={4}>
                    <StyledBox>
                      <Body2>
                        <b>{history.saleType}</b>
                      </Body2>
                      <Body2>{history.name}</Body2>
                      <Body2>
                        {history.brand} {history.cardNumber}
                        <span>&nbsp;&nbsp;&nbsp;</span>
                        {t('notes.history.exp')} {history.expDate}
                      </Body2>
                    </StyledBox>
                  </PaymentCell>
                  <PaymentCell item xs={2}>
                    <Body2>{history.amount}</Body2>
                  </PaymentCell>
                  <PaymentCell item xs={4}>
                    <StyledBox>
                      <Body2>{history.transactionType}</Body2>
                      <Body2>
                        <b>{history.status}</b>
                      </Body2>
                    </StyledBox>
                  </PaymentCell>
                </Grid>
              </Box>
            );
          })}
        </>
      ) : (
        <NoPayments />
      )}
      <ProgressOverlay inProgress={isLoading} />
    </HistoryCard>
  );
};
